import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { PapperBlock } from "dan-components";
import PropTypes from "prop-types";
import brand from "dan-api/dummy/brand";

import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AllInclusive from "@material-ui/icons/AllInclusive";
import Brightness5 from "@material-ui/icons/Brightness5";
import People from "@material-ui/icons/People";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Paper from "@material-ui/core/Paper";
import Icon from "@material-ui/core/Icon";
import logo from "dan-images/logo.png";
import styles from "./user-jss";
import ModalInformation from "../Ortak/ModalInformation";
import LinearProgress from "@material-ui/core/LinearProgress";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ReactCodeInput from "react-verification-code-input";
import {
  formDataSetAdmin,
  adminLoginControl,
  setModalCodeAdmin,
  codeControl,
} from "../../../redux/modules/admin";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export class AdminLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      password: "",
      checkedB: false,
      arrayvar: {},
      fields: {},
      errors: {},
      time: 180,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    this.stopTimerCode();
  }

  stopTimerCode() {
    clearInterval(this.timerCode);
    this.setState({
      time: 180,
    });
    this.props.setModalCodeAdmin(false).then(() => {});
  }

  tickCode() {
    this.setState({
      time: this.state.time - 1,
    });
    if (this.state.time == 0) {
      this.clearCode();
    }
  }

  clearCode() {
    this.setState({
      time: 0,
    });
  }

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  async handleSubmit(event) {
    event.preventDefault();
    await this.props.adminLoginControl(this.state.arrayvar).then(() => {});
    if (this.props.IsSmsModal) {
      clearInterval(this.timerCode);
      this.setState({
        time: 180,
      });
      this.timerCode = setInterval(() => this.tickCode(), 1000);
    }
  }

  handleChange = (prop) => (event) => {
    this.state.arrayvar[prop] = event.target.value;
    this.setState({
      arrayvar: this.state.arrayvar,
    });
  };

  handleChangeCode = (vals) => {
    if (vals.length >= 6) {
      this.props.codeControl(vals).then(() => {});
    }
  };

  modalClose = () => {
    this.props.formDataSetAdmin("modalOpen", false).then(() => {});
  };

  codeControl() {
    const { classes, IsSmsModal } = this.props;

    if (this.state.time == 0) {
      this.stopTimerCode();
    }

    return (
      <Dialog open={IsSmsModal} TransitionComponent={Transition}>
        <DialogTitle style={{ padding: "24px 24px 5px", marginBottom: 10 }}>
          {"SMS Onayı"}
          <Typography
            className={classes.instructions}
            style={{
              float: "right",
              fontSize: 15,
              fontWeight: 600,
              color: "#F44336",
              padding: 5,
              border: "1px solid",
              borderRadius: 5,
            }}
          >
            {"Kalan Süre :"} {this.state.time} {"sn"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {"Cep telefonunuza gelen onay kodunu giriniz!"}
          </DialogContentText>
          <div style={{ marginTop: 20, textAlign: "center" }}>
            <ReactCodeInput
              ref={this.input}
              id={"codewall"}
              type={"number"}
              fields={6}
              autoFocus={true}
              style={{ marginTop: 20 }}
              className={classes.codeStyle}
              onChange={this.handleChangeCode}
              onComplete={(val) => console.log("complete", val)}
            />
          </div>
        </DialogContent>
        {this.props.IsLoginStatus ? (
          <LinearProgress style={{ marginTop: 10 }} />
        ) : null}
        <DialogActions>
          <Button
            style={{ backgroundColor: "#e74c3c", color: "white" }}
            onClick={() => this.stopTimerCode()}
            color="primary"
          >
            {"Çıkış"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { classes } = this.props;
    const { showPassword } = this.state;
    const title = brand.name + " - Admin Login Ekranı";
    const description = brand.desc;
    return (
      <div className={classes.rootFull}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
        </Helmet>
        {this.codeControl()}
        <ModalInformation
          event={this.modalClose}
          open={this.props.modalOpen}
          title={this.props.modalTitle}
          text={this.props.modalText}
        />
        <div className={classes.container}>
          <div className={classes.fullFormWrap}>
            <Paper
              className={classNames(classes.fullWrap, true && classes.petal)}
            >
              <div className={classes.topBar}>
                <NavLink to="/" className={classes.brand}>
                  <img src={logo} alt={brand.name} />
                  {brand.name}
                </NavLink>
              </div>
              <Typography variant="h4" className={classes.title} gutterBottom>
                {"Yönetim Girişi"}
              </Typography>
              <Typography
                variant="caption"
                className={classes.subtitle}
                gutterBottom
                align="center"
              >
                {"Lütfen kullanıcı bilgilerinizi giriniz!"}
              </Typography>
              <section className={classes.pageFormWrap}>
                <form onSubmit={this.handleSubmit}>
                  <div>
                    <FormControl className={classes.formControl}>
                      <TextField
                        name="email"
                        placeholder="Kullanıcı Adı"
                        label="E-posta"
                        type={"email"}
                        required
                        onChange={this.handleChange("Username")}
                        className={classes.field}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl className={classes.formControl}>
                      <TextField
                        name="password"
                        type={showPassword ? "text" : "password"}
                        label="Şifre"
                        onChange={this.handleChange("Password")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        required
                        className={classes.field}
                      />
                    </FormControl>
                  </div>
                  {this.props.IsLoginStatus ? (
                    <LinearProgress style={{ marginTop: 10 }} />
                  ) : null}
                  <div className={classes.btnArea}>
                    <Button
                      disabled={this.props.IsLoginStatus}
                      variant="contained"
                      fullWidth
                      color="primary"
                      size="large"
                      type="submit"
                    >
                      {"Devam Et"}
                      <ArrowForward
                        className={classNames(
                          classes.rightIcon,
                          classes.iconSmall
                        )}
                      />
                    </Button>
                  </div>
                </form>
              </section>
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    IsSmsModal: state.get("adminReducer").IsSmsModal,
    modalOpen: state.get("adminReducer").form_dataAdmin.modalOpen,
    modalTitle: state.get("adminReducer").form_dataAdmin.modalTitle,
    modalText: state.get("adminReducer").form_dataAdmin.modalText,

    IsLoginStatus: state.get("adminReducer").IsLoginStatus,
  };
};

const mapDispatchToProps = {
  formDataSetAdmin,
  adminLoginControl,
  setModalCodeAdmin,
  codeControl,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AdminLogin));
