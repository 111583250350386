import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { connect } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import ModalInformation from "../../Ortak/ModalInformation";
import {
  setShiftBreakTimeVisible,
  saveShiftBreakTime,
  getShiftBreakTime,
  getCourierDetails,
} from "../../../../redux/modules/ShiftBreakTimeReducer";

import {
  endCourierDay,
  fetchCourierActivities,
} from "../../../../redux/modules/courierActivity";

const useStyles = makeStyles((theme) => ({
  dialog: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    margin: 0,
    "& .MuiDialog-container": {
      alignItems: "flex-end",
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    zIndex: 9999,
  },
  endWorkButton: {
    backgroundColor: "#dc3545",
    color: "#fff",
    padding: "8px 16px",
    borderRadius: "4px",
    fontSize: "14px",
    fontWeight: 500,
    width: "100%",
    "&:hover": {
      backgroundColor: "#bb2d3b",
    },
  },
  dialogPaper: {
    margin: 0,
    width: "100%",
    backgroundColor: "#1a1a1a",
    color: "#fff",
    borderRadius: "8px 8px 0 0",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#1a1a1a",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#333",
      borderRadius: "4px",
    },
  },
  timeContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    flex: 1,
    "& input": {
      backgroundColor: "#1a1a1a",
      border: "1px solid #333",
      borderRadius: "4px",
      color: "#fff",
      fontSize: "14px",
      padding: "6px",
      width: "120px",
      "&:disabled": {
        backgroundColor: "#242424",
        color: "#666",
      },
      "&::-webkit-calendar-picker-indicator": {
        filter: "invert(1)",
        opacity: 1,
        cursor: "pointer",
        "&:hover": {
          opacity: 0.8,
        },
      },
      "&::-webkit-inner-spin-button": {
        display: "none",
      },
      "&::-webkit-clear-button": {
        display: "none",
      },
    },
    "& span": {
      color: "#666",
    },
  },
  timer: {
    backgroundColor: "#1a1a1a",
    color: "#fff !important",
    border: "1px solid #333",
    borderRadius: "4px",
    padding: "6px",
    width: "120px",
    "&::-webkit-calendar-picker-indicator": {
      filter: "invert(1)",
      opacity: 1,
      cursor: "pointer",
      "&:hover": {
        opacity: 0.8,
      },
    },
  },
  title: {
    padding: "16px 10px",
    fontSize: "20px",
    fontWeight: 500,
    color: "#fff",
    backgroundColor: "#1a1a1a",
    borderBottom: "1px solid #333",
  },
  content: {
    padding: "16px 24px 24px",
    backgroundColor: "#1a1a1a",
  },
  label: {
    color: "#fff",
    fontSize: "14px",
    marginBottom: "8px",
    display: "block",
  },
  select: {
    width: "100%",
    backgroundColor: "#242424",
    border: "1px solid #333",
    borderRadius: "4px",
    color: "#fff",
    padding: "8px",
    marginBottom: "16px",
    "&:focus": {
      borderColor: "#666",
    },
  },
  input: {
    width: "100%",
    backgroundColor: "#242424",
    border: "1px solid #333",
    borderRadius: "4px",
    color: "#fff",
    padding: "8px",
    marginBottom: "16px",
    "&:focus": {
      borderColor: "#666",
    },
    "&::-webkit-calendar-picker-indicator": {
      filter: "invert(1)",
      opacity: 0.5,
      cursor: "pointer",
    },
    "&::-webkit-inner-spin-button": {
      display: "none",
    },
    "&::-webkit-clear-button": {
      display: "none",
    },
  },
  card: {
    backgroundColor: "#242424",
    borderRadius: "4px",
    padding: "16px",
    marginTop: "16px",
  },
  cardTitle: {
    color: "#fff",
    fontSize: "16px",
    marginBottom: "16px",
    fontWeight: 500,
  },
  dayRow: {
    display: "flex",
    alignItems: "center",
    padding: "8px",
    backgroundColor: "#1a1a1a",
    borderRadius: "4px",
    marginBottom: "8px",
    "&:hover": {
      backgroundColor: "#2a2a2a",
    },
  },
  dayLabel: {
    display: "flex",
    alignItems: "center",
    width: "120px",
    "& .MuiCheckbox-root": {
      color: "#666",
      "&.Mui-checked": {
        color: "#0d6efd",
      },
    },
    "& .MuiFormControlLabel-label": {
      color: "#fff",
    },
  },
  breakHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
  },
  addButton: {
    backgroundColor: "#198754",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#157347",
    },
  },
  breakRow: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#1a1a1a",
    padding: "8px",
    borderRadius: "4px",
    marginBottom: "8px",
    "& input": {
      backgroundColor: "#242424",
      border: "1px solid #333",
      borderRadius: "4px",
      fontSize: "14px",
      color: "#fff",
      padding: "6px",

      width: "120px",
      "&:disabled": {
        backgroundColor: "#242424",
        color: "#666",
      },
    },
    "& span": {
      color: "#666",
      marginRight: "8px",
    },
  },
  deleteButton: {
    minWidth: "32px",
    width: "32px",
    height: "32px",
    padding: 0,
    backgroundColor: "#dc3545",
    color: "#fff !important",
    marginLeft: "8px",
    borderRadius: "50%",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontSize: "20px",
    "&:hover": {
      backgroundColor: "#bb2d3b",
    },
    "& span": {
      color: "#fff",
      paddingLeft: "6px",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "12px",
    marginTop: "24px",
    paddingTop: "16px",
    borderTop: "1px solid #333",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
  },
  applyAllButton: {
    backgroundColor: "#198754",
    color: "#fff",
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#157347",
    },
  },
  nextDayLabel: {
    color: "#157347",
    fontSize: "12px",
    marginLeft: "4px",
  },
}));

const TimeInput = ({ value, onChange, disabled, className, isEndTime }) => {
  const classes = useStyles();
  const isNextDay = isEndTime && value && value.split(":")[0] < "06";

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <input
        type="time"
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={className}
      />
      {isNextDay && <span className={classes.nextDayLabel}>(Ertesi Gün)</span>}
    </div>
  );
};

const ShiftScheduleDialog = ({
  open,
  onClose,
  onSave,
  getShiftBreakTime,
  saveShiftBreakTime,
  setShiftBreakTimeVisible,
  courierId,
  getCourierDetails,
  paketYoldaCourierList,
  courierDetails,
  successMessage,
  endCourierDay,
  error,
  dispatch,
  breakDuration: initialBreakDuration,
  courierActivities,
  fetchCourierActivities,
}) => {
  const classes = useStyles();
  const [selectedCourier, setSelectedCourier] = useState(courierId || "");
  const [breakDuration, setBreakDuration] = useState(
    initialBreakDuration || 30
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalType, setModalType] = useState("success");

  const initialWorkSchedule = {
    Pazartesi: { active: false, start: "10:00", end: "18:00" },
    Salı: { active: false, start: "10:00", end: "18:00" },
    Çarşamba: { active: false, start: "10:00", end: "18:00" },
    Perşembe: { active: false, start: "10:00", end: "18:00" },
    Cuma: { active: false, start: "10:00", end: "18:00" },
    Cumartesi: { active: false, start: "10:00", end: "18:00" },
    Pazar: { active: false, start: "10:00", end: "18:00" },
  };

  const [workSchedule, setWorkSchedule] = useState(initialWorkSchedule);
  const [breakTimes, setBreakTimes] = useState([]);

  const [isEndingWork, setIsEndingWork] = useState(false);
  const [endWorkSuccess, setEndWorkSuccess] = useState(null);
  const [endWorkError, setEndWorkError] = useState(null);

  const handleEndCourierDay = () => {
    if (!selectedCourier) {
      setModalMessage("Kurye seçilmedi!");
      setModalType("error");
      setModalOpen(true);
      return;
    }

    setIsEndingWork(true);

    endCourierDay(selectedCourier)
      .then((result) => {
        if (result.success) {
          setEndWorkSuccess(result.message);

          setModalType("success");
          setModalOpen(true);

          setTimeout(() => {
            setModalOpen(false);
            onClose();
          }, 2000);
        } else {
          setEndWorkError(result.message);

          setModalType("error");
          setModalOpen(true);
        }
      })
      .catch((err) => {
        const errorMessage =
          err.message || "Kurye çalışması sonlandırılırken bir hata oluştu";
        setEndWorkError(errorMessage);

        setModalType("error");
        setModalOpen(true);
      })
      .finally(() => {
        setIsEndingWork(false);
      });
  };

  // Reset form function
  const resetForm = () => {
    setBreakDuration(30);
    setWorkSchedule(initialWorkSchedule);
    setBreakTimes([]);
  };

  useEffect(
    () => {
      if (open && courierId) {
        setSelectedCourier(courierId);
        getShiftBreakTime(courierId);
        getCourierDetails(courierId);
        fetchCourierActivities();
      }
    },
    [open, courierId]
  );

  useEffect(
    () => {
      if (courierDetails) {
        try {
          const data =
            typeof courierDetails === "string"
              ? JSON.parse(courierDetails)
              : courierDetails.toJS
                ? courierDetails.toJS()
                : courierDetails;

          if (data) {
            // Mola süresini ayarla
            const duration = parseInt(
              data.AllowedTime || data.BreakDuration || data.breakDuration || 30
            );
            setBreakDuration(duration);

            // Çalışma günlerini ayarla
            if (data.WorkDays && Array.isArray(data.WorkDays)) {
              const updatedSchedule = { ...initialWorkSchedule };

              data.WorkDays.forEach((day) => {
                const dayName = getDayName(day.Day);
                if (updatedSchedule[dayName]) {
                  updatedSchedule[dayName] = {
                    active: Boolean(day.IsSelected || day.isSelected),
                    start: day.StartTime || "10:00",
                    end: day.EndTime || "18:00",
                  };
                }
              });

              setWorkSchedule(updatedSchedule);
            }

            // Mola zamanlarını ayarla
            if (data.BreakTimes && Array.isArray(data.BreakTimes)) {
              const updatedBreakTimes = data.BreakTimes.map(
                (breakTime, index) => ({
                  id: index + 1,
                  start: breakTime.StartTime || "12:00",
                  end: breakTime.EndTime || "13:00",
                })
              );
              setBreakTimes(updatedBreakTimes);
            }
          }
        } catch (error) {
          console.error("Error processing courier details:", error);
        }
      }
    },
    [courierDetails]
  );

  useEffect(
    () => {
      if (successMessage || error) {
        setModalMessage(error || successMessage);
        setModalType(error ? "error" : "success");
        setModalOpen(true);

        if (successMessage) {
          setTimeout(() => {
            setModalOpen(false);
            onClose();
          }, 2000);
        }
      }
    },
    [successMessage, error]
  );

  const getDayName = (dayNumber) => {
    const days = {
      0: "Pazar",
      1: "Pazartesi",
      2: "Salı",
      3: "Çarşamba",
      4: "Perşembe",
      5: "Cuma",
      6: "Cumartesi",
    };
    return days[dayNumber];
  };

  const handleSave = () => {
    if (!selectedCourier) {
      setModalMessage("Kurye seçilmedi!");
      setModalType("error");
      setModalOpen(true);
      return;
    }

    saveShiftBreakTime({
      selectedCourier,
      breakDuration: parseInt(breakDuration) || 30,
      workSchedule,
      breakTimes,
    })
      .then(() => {
        if (typeof onSave === "function") {
          onSave();
        }
      })
      .catch((err) => {
        setModalMessage(err.message || "Bir hata oluştu");
        setModalType("error");
        setModalOpen(true);
      });
  };

  const handleDayToggle = (day) => {
    setWorkSchedule((prev) => ({
      ...prev,
      [day]: { ...prev[day], active: !prev[day].active },
    }));
  };

  const handleTimeChange = (day, type, value) => {
    setWorkSchedule((prev) => ({
      ...prev,
      [day]: { ...prev[day], [type]: value },
    }));
  };

  const handleAddBreakTime = () => {
    const newId =
      breakTimes.length > 0 ? Math.max(...breakTimes.map((b) => b.id)) + 1 : 1;
    setBreakTimes([...breakTimes, { id: newId, start: "12:00", end: "13:00" }]);
  };

  const handleDeleteBreakTime = (id) => {
    setBreakTimes(breakTimes.filter((b) => b.id !== id));
  };

  const handleCourierChange = (e) => {
    const newCourierId = e.target.value;

    if (
      newCourierId &&
      newCourierId !== "00000000-0000-0000-0000-000000000000"
    ) {
      setSelectedCourier(newCourierId);
      getShiftBreakTime(newCourierId);
      getCourierDetails(newCourierId);
      fetchCourierActivities();
    } else {
      setSelectedCourier("");
      resetForm();
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setModalMessage("");
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      resetForm();
      onClose();
    }
  };

  // Add this function to check if courier is active
  const isCourierActive = () => {
    if (!selectedCourier || !courierActivities) return false;
    const courierActivity = courierActivities.find(
      (c) =>
        c.CourierId === selectedCourier && c.ActivityType === 1 && !c.EndTime
    );
    return !!courierActivity;
  };

  const applyFirstDayToAll = () => {
    // Herhangi bir aktif günü bul
    const activeDay = Object.entries(workSchedule).find(
      ([_, schedule]) => schedule.active
    );

    if (!activeDay) {
      setModalMessage("Lütfen önce bir gün seçin ve saatlerini ayarlayın!");
      setModalType("error");
      setModalOpen(true);
      return;
    }

    const [selectedDayName, selectedDaySchedule] = activeDay;

    // Tüm günlere seçili günün saatlerini uygula
    const updatedSchedule = Object.keys(workSchedule).reduce(
      (acc, day) => ({
        ...acc,
        [day]: {
          active: true,
          start: selectedDaySchedule.start,
          end: selectedDaySchedule.end,
        },
      }),
      {}
    );

    setWorkSchedule(updatedSchedule);

    setModalMessage(
      `${selectedDayName} gününün saatleri tüm günlere uygulandı`
    );
    setModalType("success");
    setModalOpen(true);
  };

  const hasAnyDaySelected = () => {
    return Object.values(workSchedule).some((schedule) => schedule.active);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="shift-dialog-title"
        aria-describedby="shift-dialog-description"
        fullWidth
        maxWidth="md"
        keepMounted={false}
        disableEscapeKeyDown={false}
        disableEnforceFocus
        disableAutoFocus
        classes={{
          root: classes.dialog,
          paper: classes.dialogPaper,
        }}
        BackdropProps={{
          transitionDuration: {
            enter: 300,
            exit: 200,
          },
        }}
        onBackdropClick={(e) => {
          e.stopPropagation();
          handleClose(e, "backdropClick");
        }}
      >
        <DialogTitle
          id="shift-dialog-title"
          disableTypography
          className={classes.title}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>Kurye Vardiye Mola Shift Sistemi</span>
            <div style={{ width: "250px" }}>
              {isCourierActive() && (
                <Button
                  variant="contained"
                  className={classes.endWorkButton}
                  onClick={handleEndCourierDay}
                  disabled={isEndingWork || !selectedCourier}
                >
                  {isEndingWork
                    ? "İşlem Yapılıyor..."
                    : "Kurye Çalışmayı Sonlandır"}
                </Button>
              )}
            </div>
          </div>
        </DialogTitle>
        <DialogContent
          id="shift-dialog-description"
          className={classes.content}
        >
          <div>
            <label className={classes.label}>Kurye Seç</label>
            <select
              value={selectedCourier}
              onChange={handleCourierChange}
              className={classes.select}
            >
              <option value="">Seçiniz</option>
              {paketYoldaCourierList &&
                paketYoldaCourierList
                  .filter(
                    (courier) =>
                      courier.Id &&
                      courier.Id !== "00000000-0000-0000-0000-000000000000"
                  )
                  .map((courier) => (
                    <option key={courier.Id} value={courier.Id}>
                      {courier.NameSurname}
                    </option>
                  ))}
            </select>
          </div>

          <div>
            <label className={classes.label}>Mola Süresi (Dk)</label>
            <input
              type="number"
              value={breakDuration}
              onChange={(e) => setBreakDuration(e.target.value)}
              className={classes.input}
            />
          </div>

          <Grid container>
            <Grid item sm={6} xs={6}>
              <div className={classes.card}>
                <div className={classes.cardHeader}>
                  <div className={classes.cardTitle}>Çalışma Günleri</div>
                  {hasAnyDaySelected() && (
                    <Button
                      variant="contained"
                      size="small"
                      className={classes.applyAllButton}
                      onClick={applyFirstDayToAll}
                    >
                      Seçili Günü Tüm Günlere Uygula
                    </Button>
                  )}
                </div>
                {Object.entries(workSchedule).map(([day, schedule]) => (
                  <div key={day} className={classes.dayRow}>
                    <div className={classes.dayLabel}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={schedule.active}
                            onChange={() => handleDayToggle(day)}
                          />
                        }
                        label={day}
                      />
                    </div>
                    <div className={classes.timeContainer}>
                      <TimeInput
                        className={classes.timer}
                        value={schedule.start}
                        onChange={(e) =>
                          handleTimeChange(day, "start", e.target.value)
                        }
                        disabled={!schedule.active}
                        isEndTime={false}
                      />
                      <span>-</span>
                      <TimeInput
                        className={classes.timer}
                        value={schedule.end}
                        onChange={(e) =>
                          handleTimeChange(day, "end", e.target.value)
                        }
                        disabled={!schedule.active}
                        isEndTime={true}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </Grid>
            <Grid item sm={6} xs={6}>
              <div className={classes.card}>
                <div className={classes.breakHeader}>
                  <div className={classes.cardTitle}>
                    Mola Kısıtlama Süreleri
                  </div>
                  <Button
                    className={classes.addButton}
                    onClick={handleAddBreakTime}
                    variant="contained"
                    size="small"
                  >
                    + Yeni Mola Kısıtlama Ekle
                  </Button>
                </div>

                {breakTimes.map((breakTime) => (
                  <div key={breakTime.id} className={classes.breakRow}>
                    <span>Başlangıç</span>
                    <input
                      className={classes.timer}
                      type="time"
                      value={breakTime.start}
                      onChange={(e) => {
                        const newBreakTimes = breakTimes.map(
                          (bt) =>
                            bt.id === breakTime.id
                              ? { ...bt, start: e.target.value }
                              : bt
                        );
                        setBreakTimes(newBreakTimes);
                      }}
                    />
                    <span>-</span>
                    <span>Bitiş</span>
                    <input
                      className={classes.timer}
                      type="time"
                      value={breakTime.end}
                      onChange={(e) => {
                        const newBreakTimes = breakTimes.map(
                          (bt) =>
                            bt.id === breakTime.id
                              ? { ...bt, end: e.target.value }
                              : bt
                        );
                        setBreakTimes(newBreakTimes);
                      }}
                    />
                    <Button
                      className={classes.deleteButton}
                      onClick={() => {
                        const newBreakTimes = breakTimes.filter(
                          (bt) => bt.id !== breakTime.id
                        );
                        setBreakTimes(newBreakTimes);
                      }}
                    >
                      <span>x</span>
                    </Button>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
          <div className={classes.footer}>
            <Button variant="contained" color="secondary" onClick={handleSave}>
              Kaydet
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{ backgroundColor: "#F44336" }}
              onClick={onClose}
            >
              {"Çıkış"}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <ModalInformation
        visible={modalOpen}
        title={modalType === "error" ? "Hata" : "Bilgi"}
        description={modalMessage}
        type={modalType}
        onClose={handleModalClose}
        disableBackdropClick
        disableEscapeKeyDown
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  courierDetails: state.getIn(["shiftBreakTime", "courierDetails"]),
  paketYoldaCourierList: state.get("paketYoldaReducer").paketYoldaCourierList,
  workSchedule: state.getIn(["shiftBreakTime", "workSchedule"]),
  breakTimes: state.getIn(["shiftBreakTime", "breakTimes"]),
  breakDuration: state.getIn(["shiftBreakTime", "breakDuration"]),
  successMessage: state.getIn(["shiftBreakTime", "successMessage"]),
  error: state.getIn(["shiftBreakTime", "error"]),
  courierActivities: state
    .get("courierActivityReducer")
    .get("courierActivities")
    .toJS(),
});
const mapDispatchToProps = {
  getShiftBreakTime,
  saveShiftBreakTime,
  setShiftBreakTimeVisible,
  getCourierDetails,
  endCourierDay,
  fetchCourierActivities,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShiftScheduleDialog);
