import { fromJS, List } from "immutable";
import axios from "axios";
import moment from "moment";
import brand from "dan-api/dummy/brand";

// Action Types
const SET_COURIER_ACTIVITIES = "SET_COURIER_ACTIVITIES";
const SET_COURIER_ACTIVITIES_LOADING = "SET_COURIER_ACTIVITIES_LOADING";
const SET_COURIER_ACTIVITIES_FILTER = "SET_COURIER_ACTIVITIES_FILTER";
const END_COURIER_DAY_SUCCESS = "END_COURIER_DAY_SUCCESS";
const END_COURIER_DAY_ERROR = "END_COURIER_DAY_ERROR";

// Initial State
const initialState = {
  courierActivities: [],
  loading: false,
  filter: {
    startDate: moment()
      .add(-1, "days")
      .startOf("day")
      .toDate(),
    endDate: moment()
      .endOf("day")
      .toDate(),
    activityType: "",
    courierId: "",
  },
};

// Reducer
export default function courierActivityReducer(
  state = fromJS(initialState),
  action
) {
  switch (action.type) {
    case SET_COURIER_ACTIVITIES:
      return state.set("courierActivities", fromJS(action.payload));
    case SET_COURIER_ACTIVITIES_LOADING:
      return state.set("loading", action.payload);
    case SET_COURIER_ACTIVITIES_FILTER:
      const filter = action.payload;
      return state.set(
        "filter",
        fromJS({
          ...filter,
          startDate: filter.startDate
            ? moment(filter.startDate).toDate()
            : null,
          endDate: filter.endDate ? moment(filter.endDate).toDate() : null,
        })
      );
    case END_COURIER_DAY_SUCCESS:
      return state
        .set("lastEndedCourierId", action.payload)
        .set("endDayError", null);
    case END_COURIER_DAY_ERROR:
      return state.set("endDayError", action.payload);
    default:
      return state;
  }
}

// Action Creators
export const setCourierActivities = (activities) => ({
  type: SET_COURIER_ACTIVITIES,
  payload: activities,
});

export const setCourierActivitiesLoading = (loading) => ({
  type: SET_COURIER_ACTIVITIES_LOADING,
  payload: loading,
});

export const setCourierActivitiesFilter = (filter) => ({
  type: SET_COURIER_ACTIVITIES_FILTER,
  payload: filter,
});

export const endCourierDaySuccess = (courierId) => ({
  type: END_COURIER_DAY_SUCCESS,
  payload: courierId,
});

export const endCourierDayError = (error) => ({
  type: END_COURIER_DAY_ERROR,
  payload: error,
});

// Thunk Actions
export const fetchCourierActivities = () => {
  return async (dispatch, getState) => {
    dispatch(setCourierActivitiesLoading(true));
    try {
      const state = getState();
      const filter = state
        .get("courierActivityReducer")
        .get("filter")
        .toJS();

      // Prepare filter parameters
      const params = {};

      if (filter.startDate) {
        params.StartDate = moment(filter.startDate).format(
          "YYYY-MM-DDTHH:mm:ss"
        );
      }

      if (filter.endDate) {
        params.EndDate = moment(filter.endDate).format("YYYY-MM-DDTHH:mm:ss");
      }

      if (filter.activityType) {
        params.ActivityType = parseInt(filter.activityType);
      }

      if (filter.courierId) {
        params.CourierId = filter.courierId;
      }

      const response = await axios.get(
        `${brand.urlSocketPaketYolda}api/CourierActivity/report`,
        {
          params: params,
        }
      );

      if (response.data && response.data.isStatus) {
        let activities = [];
        try {
          if (typeof response.data.responseData === "string") {
            activities = JSON.parse(response.data.responseData);
          } else {
            activities = response.data.responseData;
          }
          dispatch(setCourierActivities(activities));
        } catch (e) {
          console.error("Data parse error:", e);
          dispatch(setCourierActivities([]));
        }
      } else {
        dispatch(setCourierActivities([]));
      }
    } catch (error) {
      console.error("Error fetching courier activities:", error);
      dispatch(setCourierActivities([]));
    } finally {
      dispatch(setCourierActivitiesLoading(false));
    }
  };
};

export const filterCourierActivities = (filter) => {
  return async (dispatch) => {
    try {
      const formattedFilter = {
        startDate: filter.startDate ? moment(filter.startDate).toDate() : null,
        endDate: filter.endDate ? moment(filter.endDate).toDate() : null,
        activityType: filter.activityType || "",
        courierId: filter.courierId || "",
      };

      dispatch(setCourierActivitiesFilter(formattedFilter));
      dispatch(fetchCourierActivities());
    } catch (error) {
      console.error("Error formatting filter:", error);
    }
  };
};

export const endCourierDay = (courierId) => {
  return async (dispatch) => {
    dispatch(setCourierActivitiesLoading(true));
    try {
      const response = await axios.post(
        `${brand.urlSocketPaketYolda}api/CourierActivity/end/${courierId}`
      );

      if (response.data && response.data.isStatus) {
        dispatch(endCourierDaySuccess(courierId));
        // Başarılı olduğunda yeni aktivite listesini çekelim
        dispatch(fetchCourierActivities());
        return { success: true, message: "Kurye günü başarıyla sonlandırıldı" };
      } else {
        const errorMessage =
          response.data.message || "Kurye günü sonlandırma başarısız oldu";
        dispatch(endCourierDayError(errorMessage));
        return { success: false, message: errorMessage };
      }
    } catch (error) {
      console.error("Kurye günü sonlandırma hatası:", error);
      const errorMessage =
        error.response?.data?.message ||
        "Kurye günü sonlandırma işlemi sırasında bir hata oluştu";
      dispatch(endCourierDayError(errorMessage));
      return { success: false, message: errorMessage };
    } finally {
      dispatch(setCourierActivitiesLoading(false));
    }
  };
};
