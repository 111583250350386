import React from "react";
import { Switch, Route } from "react-router-dom";
import Outer from "../Templates/Outer";
import {
  Login,
  Register,
  NotFound,
  AdminLogin,
  Demo,
  PaketYoldaLogin,
  CustomerScreen,
} from "../pageListAsync";
import QrMenu from "../Pages/QrMenu";

class Auth extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Outer>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/adminlogin" component={AdminLogin} />
          <Route path="/demo" component={Demo} />
          <Route path="/paketyolda" component={PaketYoldaLogin} />
          <Route path="/javikurye" component={PaketYoldaLogin} />
          <Route path="/qrmenu" component={QrMenu} />
          <Route path="/customerscreen" component={CustomerScreen} />
          <Route component={NotFound} />
        </Switch>
      </Outer>
    );
  }
}

export default Auth;
