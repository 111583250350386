import { fromJS } from "immutable";
import axios from "axios";
import brand from "dan-api/dummy/brand";

// Action Types
const PAYMENT_TYPES_START = "payment_types_start";
const PAYMENT_TYPES_SUCCESS = "payment_types_success";
const PAYMENT_TYPES_ERROR = "payment_types_error";
const SET_PAYMENT_TYPES = "set_payment_types";
const BLOCK_PAYMENT_TYPE_START = "block_payment_type_start";
const BLOCK_PAYMENT_TYPE_SUCCESS = "block_payment_type_success";
const BLOCK_PAYMENT_TYPE_ERROR = "block_payment_type_error";
const UNBLOCK_PAYMENT_TYPE_START = "unblock_payment_type_start";
const UNBLOCK_PAYMENT_TYPE_SUCCESS = "unblock_payment_type_success";
const UNBLOCK_PAYMENT_TYPE_ERROR = "unblock_payment_type_error";
const FETCH_BLOCKED_PAYMENT_TYPES_START = "fetch_blocked_payment_types_start";
const FETCH_BLOCKED_PAYMENT_TYPES_SUCCESS =
  "fetch_blocked_payment_types_success";
const FETCH_BLOCKED_PAYMENT_TYPES_ERROR = "fetch_blocked_payment_types_error";

// Initial State
const initialState = fromJS({
  paymentTypes: [],
  loading: false,
  error: null,
  blockingPaymentType: false,
  blockingError: null,
  unblockingPaymentType: false,
  unblockingError: null,
  blockedPaymentTypes: [],
  fetchingBlockedPaymentTypes: false,
  fetchBlockedPaymentTypesError: null,
});

// Actions
export function fetchPaymentTypes() {
  return async (dispatch) => {
    dispatch({ type: PAYMENT_TYPES_START });

    try {
      const response = await fetch(
        brand.urlSocketPaketYolda + "api/PaymentType"
      );
      if (response.ok) {
        const result = await response.json();

        if (result.responseData) {
          const data = JSON.parse(result.responseData);

          dispatch({
            type: SET_PAYMENT_TYPES,
            payload: data || [],
          });
          dispatch({ type: PAYMENT_TYPES_SUCCESS });
        } else {
          dispatch({
            type: PAYMENT_TYPES_ERROR,
            payload: "Veri formatı hatalı",
          });
        }
      } else {
        dispatch({
          type: PAYMENT_TYPES_ERROR,
          payload: "Ödeme tipleri alınamadı",
        });
      }
    } catch (error) {
      dispatch({
        type: PAYMENT_TYPES_ERROR,
        payload: error.message,
      });
    }
  };
}

export function blockPaymentTypeForCourier(blockedPayment) {
  return async (dispatch) => {
    dispatch({ type: BLOCK_PAYMENT_TYPE_START });

    try {
      const requestData = {
        courierId: blockedPayment.courierId,
        paymentTypeId: blockedPayment.paymentTypeId,
        brandId: blockedPayment.brandId,

        courierName: blockedPayment.courierName,
        paymentType: blockedPayment.paymentType,
      };

      const response = await fetch(
        brand.urlSocketPaketYolda + "api/BlockedCourierPaymentType",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      const result = await response.json();

      if (response.ok && result.isStatus) {
        dispatch({ type: BLOCK_PAYMENT_TYPE_SUCCESS });
        return { success: true, message: result.responseData };
      } else {
        dispatch({
          type: BLOCK_PAYMENT_TYPE_ERROR,
          payload: result.responseData || "Ödeme tipi engellenemedi",
        });
        return { success: false, message: result.responseData };
      }
    } catch (error) {
      dispatch({
        type: BLOCK_PAYMENT_TYPE_ERROR,
        payload: error.message,
      });
      return { success: false, message: error.message };
    }
  };
}

export function fetchBlockedPaymentTypes(courierId, brandId) {
  return async (dispatch) => {
    dispatch({ type: FETCH_BLOCKED_PAYMENT_TYPES_START });

    try {
      const response = await fetch(
        brand.urlSocketPaketYolda +
          `api/BlockedCourierPaymentType/courier/${courierId}?brandId=${brandId}`
      );

      if (response.ok) {
        const result = await response.json();

        if (result.responseData) {
          let data;
          try {
            data =
              typeof result.responseData === "string"
                ? JSON.parse(result.responseData)
                : result.responseData;

            const filteredData = Array.isArray(data)
              ? data.filter((item) => item.CourierId === courierId)
              : [];

            dispatch({
              type: FETCH_BLOCKED_PAYMENT_TYPES_SUCCESS,
              payload: filteredData,
            });
            return filteredData;
          } catch (parseError) {
            dispatch({
              type: FETCH_BLOCKED_PAYMENT_TYPES_ERROR,
              payload: "Veri format hatası",
            });
            return [];
          }
        } else {
          dispatch({
            type: FETCH_BLOCKED_PAYMENT_TYPES_SUCCESS,
            payload: [],
          });
          return [];
        }
      }

      dispatch({
        type: FETCH_BLOCKED_PAYMENT_TYPES_ERROR,
        payload: "Engellenmiş ödeme tipleri alınamadı",
      });
      return [];
    } catch (error) {
      dispatch({
        type: FETCH_BLOCKED_PAYMENT_TYPES_ERROR,
        payload: error.message,
      });
      return [];
    }
  };
}

export function unblockPaymentTypeForCourier(
  courierId,
  paymentTypeId,
  brandId
) {
  return async (dispatch) => {
    dispatch({ type: UNBLOCK_PAYMENT_TYPE_START });

    try {
      const response = await fetch(
        brand.urlSocketPaketYolda +
          `api/BlockedCourierPaymentType/courier/${courierId}/payment-type/${paymentTypeId}?brandId=${brandId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();

      if (response.ok && result.isStatus) {
        dispatch({ type: UNBLOCK_PAYMENT_TYPE_SUCCESS });
        return { success: true, message: result.responseData };
      } else {
        dispatch({
          type: UNBLOCK_PAYMENT_TYPE_ERROR,
          payload: result.responseData || "Ödeme tipi engeli kaldırılamadı",
        });
        return { success: false, message: result.responseData };
      }
    } catch (error) {
      dispatch({
        type: UNBLOCK_PAYMENT_TYPE_ERROR,
        payload: error.message,
      });
      return { success: false, message: error.message };
    }
  };
}

// Reducer
const PaymentTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_TYPES_START:
      return state.merge({
        loading: true,
        error: null,
      });

    case PAYMENT_TYPES_SUCCESS:
      return state.merge({
        loading: false,
        error: null,
      });

    case PAYMENT_TYPES_ERROR:
      return state.merge({
        loading: false,
        error: action.payload,
      });

    case SET_PAYMENT_TYPES:
      return state.merge({
        loading: false,
        error: null,
        paymentTypes: fromJS(action.payload),
      });

    case BLOCK_PAYMENT_TYPE_START:
      return state.merge({
        blockingPaymentType: true,
        blockingError: null,
      });

    case BLOCK_PAYMENT_TYPE_SUCCESS:
      return state.merge({
        blockingPaymentType: false,
        blockingError: null,
      });

    case BLOCK_PAYMENT_TYPE_ERROR:
      return state.merge({
        blockingPaymentType: false,
        blockingError: action.payload,
      });

    case UNBLOCK_PAYMENT_TYPE_START:
      return state.merge({
        unblockingPaymentType: true,
        unblockingError: null,
      });

    case UNBLOCK_PAYMENT_TYPE_SUCCESS:
      return state.merge({
        unblockingPaymentType: false,
        unblockingError: null,
      });

    case UNBLOCK_PAYMENT_TYPE_ERROR:
      return state.merge({
        unblockingPaymentType: false,
        unblockingError: action.payload,
      });

    case FETCH_BLOCKED_PAYMENT_TYPES_START:
      return state.merge({
        fetchingBlockedPaymentTypes: true,
        fetchBlockedPaymentTypesError: null,
      });

    case FETCH_BLOCKED_PAYMENT_TYPES_SUCCESS:
      return state.merge({
        fetchingBlockedPaymentTypes: false,
        fetchBlockedPaymentTypesError: null,
        blockedPaymentTypes: fromJS(action.payload),
      });

    case FETCH_BLOCKED_PAYMENT_TYPES_ERROR:
      return state.merge({
        fetchingBlockedPaymentTypes: false,
        fetchBlockedPaymentTypesError: action.payload,
      });

    default:
      return state;
  }
};

export default PaymentTypeReducer;
