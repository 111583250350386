import { fromJS } from "immutable";
import axios from "axios";
import brand from "dan-api/dummy/brand";

// Action Types
const GET_BUSINESS_LOCATION = "GET_BUSINESS_LOCATION";
const GET_BUSINESS_LOCATION_SUCCESS = "GET_BUSINESS_LOCATION_SUCCESS";
const GET_BUSINESS_LOCATION_FAILURE = "GET_BUSINESS_LOCATION_FAILURE";
const CLEAR_BUSINESS_LOCATION = "CLEAR_BUSINESS_LOCATION";

const GET_DELIVERY_AREA = "GET_DELIVERY_AREA";
const GET_DELIVERY_AREA_SUCCESS = "GET_DELIVERY_AREA_SUCCESS";
const GET_DELIVERY_AREA_FAILURE = "GET_DELIVERY_AREA_FAILURE";

const SET_DELIVERY_AREA = "SET_DELIVERY_AREA";
const SET_DELIVERY_AREA_SUCCESS = "SET_DELIVERY_AREA_SUCCESS";
const SET_DELIVERY_AREA_FAILURE = "SET_DELIVERY_AREA_FAILURE";

const initialState = {
  businessLocation: null,
  deliveryArea: null,
  loading: false,
  error: null,
};

export default function reducer(state = fromJS(initialState), action = {}) {
  switch (action.type) {
    case GET_BUSINESS_LOCATION:
    case GET_DELIVERY_AREA:
    case SET_DELIVERY_AREA:
      return state.merge({
        loading: true,
        error: null,
      });

    case GET_BUSINESS_LOCATION_SUCCESS:
      return state.merge({
        loading: false,
        businessLocation: action.payload,
        error: null,
      });

    case GET_DELIVERY_AREA_SUCCESS:
      return state.merge({
        loading: false,
        deliveryArea: action.payload,
        error: null,
      });

    case SET_DELIVERY_AREA_SUCCESS:
      return state.merge({
        loading: false,
        ...(action.payload ? { deliveryArea: action.payload } : {}),
        error: null,
      });

    case GET_BUSINESS_LOCATION_FAILURE:
    case GET_DELIVERY_AREA_FAILURE:
    case SET_DELIVERY_AREA_FAILURE:
      return state.merge({
        loading: false,
        error: action.error,
      });

    case CLEAR_BUSINESS_LOCATION:
      return state.merge({
        businessLocation: null,
        deliveryArea: null,
        error: null,
        loading: false,
      });

    default:
      return state;
  }
}

export const getDeliveryArea = (brandId, processId) => async (dispatch) => {
  dispatch({ type: GET_DELIVERY_AREA });
  try {
    const response = await axios.get(
      `${
        brand.urlSocketPaketYolda
      }api/Settings/getDeliveryArea?BrandId=${brandId}&ProcessId=${processId}`
    );

    if (response.data.isStatus) {
      let deliveryAreaData = null;
      try {
        const rawData = response.data.responseData;

        if (rawData) {
          let parsedData =
            typeof rawData === "string" ? JSON.parse(rawData) : rawData;

          if (parsedData.DeliveryAreaPolygon) {
            let deliveryArea =
              typeof parsedData.DeliveryAreaPolygon === "string"
                ? JSON.parse(parsedData.DeliveryAreaPolygon)
                : parsedData.DeliveryAreaPolygon;

            if (
              deliveryArea.type === "Circle" &&
              deliveryArea.center &&
              deliveryArea.radius
            ) {
              deliveryAreaData = {
                type: "Circle",
                radius: deliveryArea.radius,
                center: deliveryArea.center,
              };
            } else if (
              deliveryArea.type === "Polygon" &&
              Array.isArray(deliveryArea.coordinates)
            ) {
              let normalizedCoordinates = deliveryArea.coordinates;

              if (
                Array.isArray(deliveryArea.coordinates[0]) &&
                Array.isArray(deliveryArea.coordinates[0][0]) &&
                Array.isArray(deliveryArea.coordinates[0][0][0])
              ) {
                normalizedCoordinates = [deliveryArea.coordinates[0][0]];
              }

              deliveryAreaData = {
                type: "Polygon",
                coordinates: normalizedCoordinates,
              };
            }
          }
        }
      } catch (error) {
        console.error("Error parsing delivery area data:", error);
        dispatch({
          type: GET_DELIVERY_AREA_FAILURE,
          error: "Failed to parse delivery area data",
        });
        return;
      }

      dispatch({ type: GET_DELIVERY_AREA_SUCCESS, payload: deliveryAreaData });
    } else {
      dispatch({
        type: GET_DELIVERY_AREA_FAILURE,
        error: "Failed to get delivery area",
      });
    }
  } catch (error) {
    dispatch({ type: GET_DELIVERY_AREA_FAILURE, error: error.message });
  }
};

export const fetchBusinessLocations = (paketYoldaId) => async (dispatch) => {
  dispatch({ type: GET_BUSINESS_LOCATION });

  try {
    let apiUrl = `${brand.urlSocketPaketYolda}api/Brand/getPaketYoldaBrandList`;
    if (
      paketYoldaId &&
      paketYoldaId !== "00000000-0000-0000-0000-000000000000"
    ) {
      apiUrl += `?PaketYoldaId=${paketYoldaId}`;
    }

    const response = await axios.get(apiUrl);

    if (response.data.isStatus && response.data.responseData) {
      let businesses;

      try {
        businesses =
          typeof response.data.responseData === "string"
            ? JSON.parse(response.data.responseData)
            : response.data.responseData;
      } catch (parseError) {
        businesses = [];
      }

      dispatch({
        type: GET_BUSINESS_LOCATION_SUCCESS,
        payload: businesses,
      });
    } else {
      dispatch({
        type: GET_BUSINESS_LOCATION_FAILURE,
        error: "İşletme konumları alınamadı",
      });
    }
  } catch (error) {
    console.error("Error fetching business locations:", error);
    dispatch({
      type: GET_BUSINESS_LOCATION_FAILURE,
      error: error.message,
    });
  }
};

export const setDeliveryArea = (brandId, processId, data) => async (
  dispatch
) => {
  dispatch({ type: SET_DELIVERY_AREA });

  try {
    let coordinatesToUse = data.coordinates;
    if (
      Array.isArray(data.coordinates) &&
      Array.isArray(data.coordinates[0]) &&
      Array.isArray(data.coordinates[0][0]) &&
      Array.isArray(data.coordinates[0][0][0])
    ) {
      coordinatesToUse = data.coordinates[0][0];
    } else if (
      Array.isArray(data.coordinates) &&
      Array.isArray(data.coordinates[0]) &&
      Array.isArray(data.coordinates[0][0])
    ) {
      coordinatesToUse = data.coordinates[0];
    }

    let deliveryAreaData = {
      type: data.type,
      coordinates: [coordinatesToUse],
    };

    if (data.type === "Circle") {
      deliveryAreaData = {
        type: data.type,
        radius: data.radius,
        center: data.center,
      };
    }

    const requestData = {
      brandId: brandId,
      processId: processId,
      deliveryAreaPolygon: JSON.stringify(deliveryAreaData),
    };

    const response = await axios.post(
      `${brand.urlSocketPaketYolda}api/Settings/setDeliveryArea`,
      requestData
    );

    if (response.data.isStatus) {
      dispatch({
        type: SET_DELIVERY_AREA_SUCCESS,
        payload: deliveryAreaData,
      });

      setTimeout(() => {
        dispatch(getDeliveryArea(brandId, processId));
      }, 300);
    } else {
      dispatch({
        type: SET_DELIVERY_AREA_FAILURE,
        error: "Teslimat alanı kaydedilemedi",
      });
    }
  } catch (error) {
    dispatch({ type: SET_DELIVERY_AREA_FAILURE, error: error.message });
  }
};

export const clearBusinessLocation = () => ({
  type: CLEAR_BUSINESS_LOCATION,
});
