import { fromJS } from "immutable";
import axios from "axios";
import base64 from "base-64";
import brand from "dan-api/dummy/brand";

// Action Types
const SET_SHIFT_BREAK_TIME_VISIBLE = "set_shift_break_time_visible";
const SAVE_SHIFT_BREAK_TIME_START = "save_shift_break_time_start";
const SAVE_SHIFT_BREAK_TIME_SUCCESS = "save_shift_break_time_success";
const SAVE_SHIFT_BREAK_TIME_ERROR = "save_shift_break_time_error";
const GET_SHIFT_BREAK_TIME_START = "get_shift_break_time_start";
const GET_SHIFT_BREAK_TIME_SUCCESS = "get_shift_break_time_success";
const GET_SHIFT_BREAK_TIME_ERROR = "get_shift_break_time_error";
const GET_COURIER_DETAILS_START = "get_courier_details_start";
const GET_COURIER_DETAILS_SUCCESS = "get_courier_details_success";
const GET_COURIER_DETAILS_ERROR = "get_courier_details_error";

// Initial State
const initialState = fromJS({
  shiftBreakTimeVisible: false,
  selectedCourierId: null,
  loading: false,
  error: null,
  workSchedule: {
    Pazartesi: { active: false, start: "10:00", end: "18:00" },
    Salı: { active: false, start: "10:00", end: "18:00" },
    Çarşamba: { active: false, start: "10:00", end: "18:00" },
    Perşembe: { active: false, start: "10:00", end: "18:00" },
    Cuma: { active: false, start: "10:00", end: "18:00" },
    Cumartesi: { active: false, start: "10:00", end: "18:00" },
    Pazar: { active: false, start: "10:00", end: "18:00" },
  },
  breakTimes: [],
  breakDuration: 30,
  courierDetails: null,
  loadingCourierDetails: false,
  courierDetailsError: null,
});

// Gün adından sayıya dönüştürme fonksiyonu
const getDayOfWeek = (dayName) => {
  const days = {
    Pazar: 0,
    Pazartesi: 1,
    Salı: 2,
    Çarşamba: 3,
    Perşembe: 4,
    Cuma: 5,
    Cumartesi: 6,
  };
  return days[dayName] || 0;
};

// Actions
export function setShiftBreakTimeVisible(visible, courierId) {
  return {
    type: SET_SHIFT_BREAK_TIME_VISIBLE,
    payload: { visible, courierId },
  };
}

export function getCourierDetails(courierId) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COURIER_DETAILS_START });

      const response = await axios({
        method: "GET",
        url: `${brand.urlSocketPaketYolda}api/Courier/getCourier`,
        params: {
          CourierId: courierId,
        },
        headers: {
          Authorization:
            "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
        },
      });

      if (response.data.isStatus) {
        const courierData = JSON.parse(response.data.responseData);
        dispatch({
          type: GET_COURIER_DETAILS_SUCCESS,
          payload: courierData,
        });
      } else {
        throw new Error(
          response.data.responseData || "Kurye bilgileri alınamadı"
        );
      }
    } catch (error) {
      dispatch({
        type: GET_COURIER_DETAILS_ERROR,
        payload: error.message,
      });
    }
  };
}

export function saveShiftBreakTime(data) {
  return async (dispatch) => {
    try {
      dispatch({ type: SAVE_SHIFT_BREAK_TIME_START });

      const courierId = data.selectedCourier;
      const requestData = {
        CourierId: courierId,
        BreakDuration: parseInt(data.breakDuration) || 30,
        WorkDays: Object.entries(data.workSchedule)
          .filter(([_, schedule]) => schedule.active)
          .map(([day, schedule]) => ({
            IsSelected: true,
            Day: getDayOfWeek(day),
            StartTime: schedule.start,
            EndTime: schedule.end,
          })),
        BreakTimes: data.breakTimes.map((breakTime) => ({
          StartTime: breakTime.start,
          EndTime: breakTime.end,
        })),
      };

      const response = await axios({
        method: "POST",
        url: `${
          brand.urlSocketPaketYolda
        }api/ShiftBreakTime/SaveCourierShiftTime`,
        headers: {
          Authorization:
            "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
          "Content-Type": "application/json",
        },
        data: requestData,
      });

      if (response.data.isStatus) {
        dispatch({
          type: SAVE_SHIFT_BREAK_TIME_SUCCESS,
          payload: requestData,
        });
      } else {
        throw new Error(response.data.responseData || "Kayıt başarısız");
      }
    } catch (error) {
      console.error("Save ShiftBreakTime Error:", error);
      dispatch({
        type: SAVE_SHIFT_BREAK_TIME_ERROR,
        payload: error.message,
      });
    }
  };
}

export function getShiftBreakTime(courierId) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SHIFT_BREAK_TIME_START });

      const response = await axios({
        method: "GET",
        url: `${
          brand.urlSocketPaketYolda
        }api/ShiftBreakTime/GetCourierShiftTimes`,
        params: {
          courierId: courierId,
        },
        headers: {
          Authorization:
            "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
        },
      });

      if (response.data.isStatus) {
        const data = response.data.responseData
          ? JSON.parse(response.data.responseData)
          : {};
        dispatch({
          type: GET_SHIFT_BREAK_TIME_SUCCESS,
          payload: data,
        });
      } else {
        throw new Error(response.data.responseData || "Veri alınamadı");
      }
    } catch (error) {
      console.error("ShiftBreakTime Error:", error);
      dispatch({
        type: GET_SHIFT_BREAK_TIME_ERROR,
        payload: error.message,
      });
    }
  };
}

// Reducer
export default function shiftBreakTimeReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SHIFT_BREAK_TIME_VISIBLE:
      return state.merge({
        shiftBreakTimeVisible: action.payload.visible,
        selectedCourierId: action.payload.courierId,
      });

    case SAVE_SHIFT_BREAK_TIME_START:
      return state.merge({
        loading: true,
        error: null,
      });

    case SAVE_SHIFT_BREAK_TIME_SUCCESS:
      return state.merge({
        loading: false,
        error: null,
        successMessage: "Güncelleme işlemi başarılı!",
        breakDuration:
          action.payload.BreakDuration ||
          action.payload.AllowedTime ||
          state.get("breakDuration"),
      });

    case SAVE_SHIFT_BREAK_TIME_ERROR:
      return state.merge({
        loading: false,
        error: action.payload,
      });

    case GET_SHIFT_BREAK_TIME_START:
      return state.merge({
        loading: true,
        error: null,
      });

    case GET_SHIFT_BREAK_TIME_SUCCESS: {
      try {
        const data =
          typeof action.payload === "string"
            ? JSON.parse(action.payload)
            : action.payload;

        // Varsayılan work schedule oluştur
        const defaultWorkSchedule = {
          Pazartesi: { active: false, start: "10:00", end: "18:00" },
          Salı: { active: false, start: "10:00", end: "18:00" },
          Çarşamba: { active: false, start: "10:00", end: "18:00" },
          Perşembe: { active: false, start: "10:00", end: "18:00" },
          Cuma: { active: false, start: "10:00", end: "18:00" },
          Cumartesi: { active: false, start: "10:00", end: "18:00" },
          Pazar: { active: false, start: "10:00", end: "18:00" },
        };

        // Gün eşleştirme
        const dayMapping = {
          0: "Pazar",
          1: "Pazartesi",
          2: "Salı",
          3: "Çarşamba",
          4: "Perşembe",
          5: "Cuma",
          6: "Cumartesi",
        };

        // API'den gelen günleri işle
        if (data.WorkDays && Array.isArray(data.WorkDays)) {
          // Günleri sırala
          const sortedWorkDays = [...data.WorkDays].sort((a, b) => {
            const dayA = a.Day === 0 ? 7 : a.Day;
            const dayB = b.Day === 0 ? 7 : b.Day;
            return dayA - dayB;
          });

          // Günleri işle
          sortedWorkDays.forEach((workDay) => {
            const turkishDayName = dayMapping[workDay.Day];
            if (turkishDayName && defaultWorkSchedule[turkishDayName]) {
              defaultWorkSchedule[turkishDayName] = {
                active: Boolean(workDay.IsSelected || workDay.isSelected),
                start: workDay.StartTime || "10:00",
                end: workDay.EndTime || "18:00",
              };
            }
          });
        }

        // Mola zamanlarını işle
        let breakTimesData = [];
        if (
          data.BreakTimes &&
          Array.isArray(data.BreakTimes) &&
          data.BreakTimes.length > 0
        ) {
          breakTimesData = data.BreakTimes.map((breakTime, index) => ({
            id: index + 1,
            start: breakTime.StartTime || "12:00",
            end: breakTime.EndTime || "13:00",
          }));
        }

        return state.merge({
          loading: false,
          error: null,
          workSchedule: fromJS(defaultWorkSchedule),
          breakTimes: fromJS(breakTimesData),
          breakDuration: parseInt(data.BreakDuration || data.AllowedTime || 30),
          courierDetails: fromJS(data),
        });
      } catch (error) {
        console.error("Error details:", error.message);
        return state.merge({
          loading: false,
          error: "Vardiya verileri işlenirken hata oluştu",
          workSchedule: fromJS(initialState.get("workSchedule")),
          breakTimes: fromJS(initialState.get("breakTimes")),
          breakDuration: 30,
        });
      }
    }

    case GET_SHIFT_BREAK_TIME_ERROR:
      return state.merge({
        loading: false,
        error: action.payload,
      });

    case GET_COURIER_DETAILS_START:
      return state.merge({
        loadingCourierDetails: true,
        courierDetailsError: null,
      });

    case GET_COURIER_DETAILS_SUCCESS:
      return state.merge({
        loadingCourierDetails: false,
        courierDetails: fromJS(action.payload),
        courierDetailsError: null,
      });

    case GET_COURIER_DETAILS_ERROR:
      return state.merge({
        loadingCourierDetails: false,
        courierDetailsError: action.payload,
      });

    default:
      return state;
  }
}
