import { reactLocalStorage } from 'reactjs-localstorage';
import moment from 'moment';
import cookie from 'react-cookies';
import { store } from 'react-notifications-component';
const base64 = require('base-64');
const utf8 = require('utf8');
const uuidv4 = require('uuid/v4');

export function pavoPaymentList() {
  var list = [];
  list.push({ Id: "6b58cfec-9214-4fa6-a45c-dcfb15f3d80a", Name: 'Multinet' });
  list.push({ Id: "c79c4830-8ea9-4a2c-976a-0697ed7f0812", Name: 'Metropol' });
  return list;
}


export function gorupOrder(myObject) {
  const m = new Map();
  myObject.forEach(({ GroupId }) => {
    // Create a key with values that we want to group by
    // A list of key-value pairs is chosen to make use of `Object.fromEntries` later
    const hash = JSON.stringify([['GroupId', GroupId]]);
    m.set(hash, (m.get(hash) || 0) + 1);
  });
  const myOutputObject = [...m].map(([rec, count]) => ({
    ...Object.fromEntries(JSON.parse(rec)),
    count,
  }))

  const m1 = new Map();
  myOutputObject.forEach(({ count, GroupId }) => {
    var lvl = 128;
    var color = Math.floor(Math.random()*lvl)<<16 | Math.floor(Math.random()*lvl)<<8 | Math.floor(Math.random()*lvl);
    // Create a key with values that we want to group by
    // A list of key-value pairs is chosen to make use of `Object.fromEntries` later
    const hash = JSON.stringify([['order', count], ['Color', '#' + color.toString(16).padStart(6, "0") ], ['GroupId', GroupId]]);
    m1.set(hash, (m1.get(hash) || 0) + 1);
  });
  const myOutputObject1 = [...m1].map(([rec, count]) => ({
    ...Object.fromEntries(JSON.parse(rec)),
    count,
  }))
  
  return myOutputObject1;
}

export function soundPlay(value) {
  const track = document.getElementById('audio');
  if (value === 'play') {
    track.load();
    track.muted = false;
    track.play();
  } else if ('stop') {
    track.pause();
    track.muted = true;
    track.currentTime = 0;
  }
}

export function trMoneyConvert(money, digit) {
  var currency_symbol = "₺"

  var formattedOutput = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: digit,
  });
  return formattedOutput.format(money).replace(currency_symbol, '')
}
export function soundPlayCourier(value) {
  const track = document.getElementById('audioCourier');
  if (value === 'play') {
    track.load();
    track.muted = false;
    track.play();
  } else if ('stop') {
    track.pause();
    track.muted = true;
    track.currentTime = 0;
  }
}

export function renderPackageType(n) {
  let colorTableText = 'JaviPos';
  let colorTableTextDetail = '';
  const go = JSON.parse(n.YemekSepetiAddress).TrendyolPackageType;
  if (n.TableName == 'YemekSepeti' || n.TableName == 'YemekSepetiGlobal') {
    colorTableText = n.TableName;
    colorTableTextDetail = (go == 'VALE' ? 'Vale' : '');
  } else if (n.TableName == 'GetirYemek') {
    colorTableText = 'GetirYemek';
    colorTableTextDetail = (n.CustomerAddressId == '00000000-0000-0000-0000-000000000000' ? 'Getirsin' : '');
  } else if (n.TableName == 'TrendyolYemek') {
    colorTableText = 'TrendyolYemek';
    colorTableTextDetail = (go == 'GO' ? 'Go' : '');
  } else if (n.TableName == 'GoFody') {
    colorTableText = 'GoFody';
  } else if (n.TableName == 'MigrosYemek') {
    colorTableText = 'MigrosYemek';
    colorTableTextDetail = (go == 'MIGROS' ? 'Teslimatı' : '');
  }

  return colorTableText + ' ' + colorTableTextDetail;
}

export function setCollectionKalan(addition_data) {
  let total = 0;
  if (addition_data.Payments.length > 0) {
    addition_data.Payments.filter(x => x.IsDelete == false).map((n, i) => {
      total += n.Price;
    });
  }
  return calcTotalPrice(addition_data).replace(/[,]/, '.') - total;
}

export function setCollectionTotal(addition_data) {
  let total = 0;
  if (addition_data.Payments.length > 0) {
    addition_data.Payments.filter(x => x.IsDelete == false && x.PaymentType != 2).map((n, i) => {
      total += n.Price;
    });
  }
  return total;
}

export function calcTotalPrice(productItems) {
  let totalprice = 0;

  productItems.ProductItems.filter(x => x.IsTreat == false && x.IsDelete == false).map((n, i) => {
    let price = n.Price;
    n.Unit.map(n => {
      if (n.IsSelected) {
        price = n.Price;
      }
    });
    n.Property.map(n => {
      n.Item.map(n => {
        if (n.Default && n.Quantity == 0) {
          price += n.Price;
        } else if (n.Default && n.Quantity > 0) {
          price += (n.Price * n.Quantity);
        }
      });
    });

    n.Menu.map(n => {
      n.Item.map(n => {
        if (n.Default) {
          price += n.Price;
        }
      });

      if (n.MenuDetailSelected != null) {
        if (n.MenuDetailSelected.length != 0) {
          n.MenuDetailSelected.map(x => {
            x.Item.map(n => {
              if (n.Default && n.Quantity == 0) {
                price += n.Price;
              } else if (n.Default && n.Quantity > 0) {
                price += (n.Price * n.Quantity);
              }
            });
          });
        }
      }
    });

    totalprice += price * n.Quantity;
  });

  return totalprice.toFixed(2).replace(/[.]/, ',');
}

export function calcPriceTotal(ProductDetail) {
  if (ProductDetail.length == 0) {
    return 0;
  }
  let price = ProductDetail.Price;
  ProductDetail.Unit.map(n => {
    if (n.IsSelected) {
      price = n.Price;
    }
  });

  ProductDetail.Property.map(n => {
    n.Item.map(n => {
      if (n.Default && n.Quantity == 0) {
        price += n.Price;
      } else if (n.Default && n.Quantity > 0) {
        price += (n.Price * n.Quantity);
      }
    });
  });

  ProductDetail.Menu.map(n => {
    n.Item.map(n => {
      if (n.Default) {
        price += n.Price;
      }
    });

    if (n.MenuDetailSelected != null) {
      if (n.MenuDetailSelected.length != 0) {
        n.MenuDetailSelected.map(x => {
          x.Item.map(n => {
            if (n.Default && n.Quantity == 0) {
              price += n.Price;
            } else if (n.Default && n.Quantity > 0) {
              price += (n.Price * n.Quantity);
            }
          });
        });
      }
    }
  });

  return price;
}

export function NotDurNotification(title, text, container = "bottom-right", duration = 0) {
  store.add({
    id: uuidv4(),
    title: title,
    message: text,
    type: "danger",
    container: container,
    insert: "bottom",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "faster", "fadeOut"],
    slidingEnter: {
      duration: 300,
      timingFunction: "linear",
      delay: 0
    },
    slidingExit: {
      duration: 300,
      timingFunction: "linear",
      delay: 0
    },
    touchRevert: {
      duration: 600,
      timingFunction: "linear",
      delay: 0
    },
    touchSlidingExit: {
      swipe: {
        duration: 600,
        timingFunction: "linear",
        delay: 0
      },
      alpha: {
        duration: 300,
        timingFunction: "linear",
        delay: 0
      }
    },
    dismiss: {
      duration: duration,
      onScreen: !1,
      pauseOnHover: !0,
      waitForAnimation: !1,
      showIcon: !0,
      click: !0,
      touch: !0
    },
    onRemoval: (id, removedBy) => {

    }
  });
}

export function notificationShow(list, time) {
  list.map((n, i) => {
    const duration = time;

    store.add({
      id: uuidv4(),
      title: 'Paket İşlemleri Uyarısı',
      message: n.ResponseData,
      type: n.IsStatus ? 'success' : 'danger',
      container: 'bottom-right',
      insert: 'bottom',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'faster', 'fadeOut'],
      slidingEnter: {
        duration: 300,
        timingFunction: 'linear',
        delay: 0
      },
      slidingExit: {
        duration: 300,
        timingFunction: 'linear',
        delay: 0
      },
      touchRevert: {
        duration: 600,
        timingFunction: 'linear',
        delay: 0
      },
      touchSlidingExit: {
        swipe: {
          duration: 600,
          timingFunction: 'linear',
          delay: 0
        },
        alpha: {
          duration: 300,
          timingFunction: 'linear',
          delay: 0
        }
      },
      dismiss: {
        duration,
        onScreen: !1,
        pauseOnHover: !0,
        waitForAnimation: !1,
        showIcon: !0,
        click: !0,
        touch: !0
      },
      onRemoval: (id, removedBy) => {

      }
    });
  });
}


String.prototype.turkishToUpper = function () {
  let string = this;
  const letters = {
    i: 'İ', ş: 'Ş', ğ: 'Ğ', ü: 'Ü', ö: 'Ö', ç: 'Ç', ı: 'I'
  };
  string = string.replace(/(([iışğüçö]))/g, (letter) => letters[letter]);
  return string.toUpperCase();
};

String.prototype.turkishToLower = function () {
  let string = this;
  const letters = {
    İ: 'i', I: 'ı', Ş: 'ş', Ğ: 'ğ', Ü: 'ü', Ö: 'ö', Ç: 'ç'
  };
  string = string.replace(/(([İIŞĞÜÇÖ]))/g, (letter) => letters[letter]);
  return string.toLowerCase();
};

export function MakeRandomKey(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random()
      * charactersLength));
  }
  return result;
}

export function CapitalizeText(text) {
  const pieces = text.split(' ');
  for (let i = 0; i < pieces.length; i++) {
    const j = pieces[i].charAt(0).turkishToUpper();
    pieces[i] = j + pieces[i].substr(1).turkishToLower();
  }
  return pieces.join(' ');
}

export function ComplateCapitalizeText(text) {
  return text.toUpperCase();
}

export function ComplateLowerText(text) {
  return text.toLowerCase();
}

export function isEmpty(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) { return false; }
  }
  return true;
}

export function getIsLite() {
  const obj = reactLocalStorage.getObject('j_string');
  return obj;
}

export function getLicenceModulePackage() {
  if (cookie.load('UserType') != 'CallCenter') {
    const obj = reactLocalStorage.getObject('l_string');
    let l_string = [];
    if (!isEmpty(obj)) {
      const base = base64.decode(obj);
      l_string = JSON.parse(utf8.decode(base));
    }

    if (l_string.SubscriptionInfo == undefined) {
      return false;
    }

    if (l_string.SubscriptionInfo.Module.length == 0) {
      return false;
    }

    if (l_string.SubscriptionInfo.Module.filter(x => x.Id == '6e98ccb9-08b3-47ce-840e-2745ad3bf706').length > 0
      || l_string.SubscriptionInfo.Module.filter(x => x.Id == '9b5db60d-51a8-480d-8ea7-acc1ce3f88c0').length > 0
      || l_string.SubscriptionInfo.Module.filter(x => x.Id == '90f4970d-4698-4080-ba55-5ac2d174fcdb').length > 0
      || l_string.SubscriptionInfo.Module.filter(x => x.Id == 'c4297a5e-3e5b-4255-8022-14d4163e66c9').length > 0
      || l_string.SubscriptionInfo.Module.filter(x => x.Id == 'bd2df397-1ec8-4298-bfd3-d15f516ce4cf').length > 0) {
      return true;
    }
    return false;
  }
  return true;
}

export function getLicenceModule(Id) {
  if (cookie.load('UserType') != 'CallCenter') {
    const obj = reactLocalStorage.getObject('l_string');
    let l_string = [];
    if (!isEmpty(obj)) {
      const base = base64.decode(obj);
      l_string = JSON.parse(utf8.decode(base));
    }
    if (l_string.SubscriptionInfo == undefined) {
      return false;
    }
    if (l_string.SubscriptionInfo.Module.length == 0) {
      return false;
    }

    if (l_string.SubscriptionInfo.Module.filter(x => x.Id == Id).length == 0) {
      return false;
    }
    return l_string.SubscriptionInfo.Module.find(x => x.Id == Id).IsStatus;
  }
  return true;
}

export function getLicence(ope) {
  const obj = reactLocalStorage.getObject('l_string');
  let l_string = [];
  if (!isEmpty(obj)) {
    const base = base64.decode(obj);
    l_string = JSON.parse(utf8.decode(base));
  }

  if (l_string.length == 0) {
    return null;
  }

  if (ope == 'date') {
    return l_string.SubscriptionInfo.DaysLeft;
  } if (ope == 'data') {
    return l_string.SubscriptionInfo;
  } if (ope == 'plancode') {
    return (l_string.RECURRINGPLANCODE == null ? '' : l_string.RECURRINGPLANCODE);
  } if (ope == 'module') {
    return l_string.ModuleList.ModuleListItem;
  }
  return null;
}

export function renderTableLocationXY(message, index, width) {
  let location = { x: message.LocationX, y: message.LocationY };
  if (message.LocationX == 0 && message.LocationY == 0) {
    let sayi = index;
    sayi = Math.floor(sayi / 7);

    const y = (100 * sayi) + (2 * sayi);

    let mod = index;
    mod %= 7;
    const x = (mod * (width + 2));

    location = { x, y };
  }
  return location;
}

export function getCenterDate(StartDatetime, EndDatetime) {
  const StartTime = new Date(StartDatetime);
  const EndTime = new Date(EndDatetime);

  const start = new Date(new Date().setHours(StartTime.getHours(), StartTime.getMinutes(), StartTime.getSeconds(), 0));
  let end = new Date(new Date().setHours(EndTime.getHours(), EndTime.getMinutes(), EndTime.getSeconds(), 999));
  if (start > end) {
    const date = new Date();
    date.setDate(date.getDate() + 1);

    end = new Date(new Date(date).setHours(EndTime.getHours(), EndTime.getMinutes(), EndTime.getSeconds(), 999));
  }

  return { start, end };
}

export function getParameter(ope) {
  const obj = reactLocalStorage.getObject('p_string');
  let p_string = [];
  if (!isEmpty(obj)) {
    const base = base64.decode(obj);
    p_string = JSON.parse(utf8.decode(base));
  }

  if (ope == 'IsCustomerScreen') {
    return p_string.CustomerScreen.IsStatus;
  } if (ope == 'BusinessWorkingHours') {
    const TimeData = p_string.BusinessWorkingHours;

    const StartTime = (TimeData == null ? new Date() : new Date(TimeData.StartTime));
    const EndTime = (TimeData == null ? new Date() : new Date(TimeData.EndTime));

    const start = new Date(new Date().setHours(StartTime.getHours(), StartTime.getMinutes(), StartTime.getSeconds(), 0));
    let end = new Date(new Date().setHours(EndTime.getHours(), EndTime.getMinutes(), EndTime.getSeconds(), 999));
    if (start > end) {
      const date = new Date();
      date.setDate(date.getDate() + 1);

      end = new Date(new Date(date).setHours(EndTime.getHours(), EndTime.getMinutes(), EndTime.getSeconds(), 999));
    }
    return { start, end };
  } if (ope == 'IsBarcodeSystem') {
    return p_string.IsBarcodeSystem;
  } if (ope == 'IsScales') {
    return p_string.Scales.IsStatus;
  } if (ope == 'CallerId') {
    return p_string.CallerId.IsStatus;
  } if (ope == 'SelfSales') {
    return p_string.SelfSales;
  } if (ope == 'ReturnTables') {
    return p_string.ReturnTables;
  } if (ope == 'ProQuantityVisible') {
    return p_string.ProQuantityVisible;
  } if (ope == 'PackagePrint') {
    return p_string.PackagePrint;
  } if (ope == 'PaymentTypes') {
    return p_string.PaymentTypes;
  } if (ope == 'YemekSepeti') {
    return p_string.YemekSepeti;
  } if (ope == 'ExpenseType') {
    return p_string.ExpenseType;
  } if (ope == 'WebApiUserId') {
    return p_string.WebApiUserId;
  } if (ope == 'GetirYemek') {
    return p_string.GetirYemek;
  } if (ope == 'GoFody') {
    return p_string.GoFody;
  } if (ope == 'TrendyolYemek') {
    return p_string.TrendyolYemek;
  } if (ope == 'DefaultCity') {
    return p_string.DefaultCity;
  } if (ope == 'Telegram') {
    return p_string.Telegram;
  } if (ope == 'IsEntegrationSystem') {
    return p_string.IsEntegrationSystem;
  } if (ope == 'LocalPrinterSystem') {
    return p_string.LocalPrinterSystem;
  } if (ope == 'SanalSantral') {
    return p_string.SanalSantral;
  } if (ope == 'MaxiJett') {
    return p_string.MaxiJett;
  } if (ope == 'CirakApp') {
    return p_string.CirakApp;
  } if (ope == 'IsAdditionClosedPrinted') {
    return p_string.IsAdditionClosedPrinted;
  } if (ope == 'IsProductPriceVisible') {
    return p_string.IsProductPriceVisible;
  } if (ope == 'IsPaketYolda') {
    return p_string.IsPaketYolda;
  } if (ope == 'MigrosYemek') {
    return p_string.MigrosYemek;
  } if (ope == 'IsCourierPartAccount') {
    return p_string.IsCourierPartAccount;
  } if (ope == 'Paycell') {
    return p_string.Paycell;
  } if (ope == 'HizliBilisim') {
    return p_string.HizliBilisim;
  } if (ope == 'Franchise') {
    return p_string.Franchise;
  } if (ope == 'RankingSystem') {
    return p_string.RankingSystem;
  } if (ope == 'BarcodeType') {
    return p_string.BarcodeType;
  } if (ope == 'JaviKurye') {
    return p_string.JaviKurye;
  } if (ope == 'IsKitchenScreen') {
    return p_string.IsKitchenScreen;
  } if (ope == 'IsCookingTime') {
    return p_string.IsCookingTime;
  } if (ope == "PackagingTime") {
    return p_string.PackagingTime;
  } if (ope == "IsGlassLabel") {
    return p_string.IsGlassLabel;
  } if (ope == "PlugCuttingLimit") {
    return p_string.PlugCuttingLimit;
  } if (ope == 'IsPaycell') {
    return p_string.IsPaycell;
  } if (ope == 'Vigo') {
    return p_string.Vigo;
  } if (ope == 'Pavo') {
    return p_string.Pavo;
  } if (ope == 'HızliBilisim') {
    return p_string.HızliBilisim;
  }

  return null;
}

Number.prototype.formatMoney = function (fractionDigits, decimal, separator) {
  fractionDigits = isNaN(fractionDigits = Math.abs(fractionDigits)) ? 4 : fractionDigits;

  decimal = typeof (decimal) === "undefined" ? "." : decimal;

  separator = typeof (separator) === "undefined" ? "," : separator;

  var number = this;

  var neg = number < 0 ? "-" : "";

  var wholePart = parseInt(number = Math.abs(+number || 0).toFixed(fractionDigits)) + "";

  var separtorIndex = (separtorIndex = wholePart.length) > 3 ? separtorIndex % 3 : 0;

  return neg +

    (separtorIndex ? wholePart.substr(0, separtorIndex) + separator : "") +

    wholePart.substr(separtorIndex).replace(/(\d{3})(?=\d)/g, "$1" + separator) +

    (fractionDigits ? decimal + Math.abs(number - wholePart).toFixed(fractionDigits).slice(2) : "");

};

export function formatMoney(raw) {
  return Number(raw).formatMoney(4, ',', '.');
}

export function getPaketYolda(ope) {
  const obj = reactLocalStorage.getObject('py_string');
  let py_string = [];
  if (!isEmpty(obj)) {
    const base = base64.decode(obj);
    py_string = JSON.parse(utf8.decode(base));
  }

  if (py_string.length == 0) {
    return null;
  }
  
  if (ope == 'IsOfficialDistributor') {
    return py_string.IsOfficialDistributor;
  } if (ope == 'FinalyPaymentDate') {
    return py_string.FinalyPaymentDate;
  } if (ope == 'IsPendingPayment') {
    return py_string.IsPendingPayment;
  } if (ope == 'BusinessWorkingHours') {
    const start = py_string.StartDatetime;
    const end = py_string.EndDatetime;

    const StartTime = (start == null ? new Date() : new Date(start));
    const EndTime = (end == null ? new Date() : new Date(end));

    return { start: StartTime, end: EndTime };
  } if (ope == 'CourierIsSee') {
    return py_string.CourierIsSee;
  }
  return null;
}
