import React from "react";
import { createSelector } from "reselect";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";

import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

import TablePagination from "@material-ui/core/TablePagination";
import {
  setPaketYoldaLogReportFilterData,
  getPaketYoldaBrandList,
  getPaketYoldaCourierList,
  getPaketYoldaBusinessList,
  getNewCourierList,
  getOrderGeneralReport,
  getLogReportList,
} from "../../../../redux/modules/paketyolda";
import { getPackageAdditionDetail } from "../../../../redux/modules/addition";
import { getPaketYolda } from "../../../../function/GeneralFunction";
import CircularProgress from "@material-ui/core/CircularProgress";
import DatePicker, { registerLocale } from "react-datepicker";
import "../datepicker.css";
import tr from "date-fns/locale/tr";

import TooltipMui from "@material-ui/core/Tooltip";
import TbPaginationActions from "../../Ortak/TbPaginationActions";
import MapClusterReport from "../../SettingPage/Report/SubReportPage/Maps/MapClusterReport";
import PackageAdditionDetail from "../../PackagePage/PackageAdditionDetail";
import {
  generateLogCourierPdfReport,
  generateLogCourierExcelReport,
} from "./services/exportServices";
import axios from "axios";
import {
  fetchCourierActivities,
  filterCourierActivities,
} from "../../../../redux/modules/courierActivity";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Input from "@material-ui/core/Input";

const styles = (theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

registerLocale("tr", tr);

// Custom Pagination Actions Component
function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ flexShrink: 0, marginLeft: 20 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

class CourierLog extends React.Component {
  constructor() {
    super();
    this.state = {
      filterActive: false,
      page: 0,
      rowsPerPage: 10,
      tempFilter: {
        startDate: moment()
          .add(-1, "days")
          .startOf("day")
          .toDate(),
        endDate: moment()
          .endOf("day")
          .toDate(),
        activityType: "",
        courierId: "",
      },
    };
  }

  async componentDidMount() {
    if (this.props.paketYoldaReportMenu === "CourierLog") {
      const { courierActivitiesFilter } = this.props;
      this.setState({ tempFilter: { ...courierActivitiesFilter } });
      await this.props.fetchCourierActivities();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.paketYoldaReportMenu !== this.props.paketYoldaReportMenu &&
      this.props.paketYoldaReportMenu === "CourierLog"
    ) {
      const { courierActivitiesFilter } = this.props;
      this.setState({ tempFilter: { ...courierActivitiesFilter } });
      this.props.fetchCourierActivities();
    }
  }

  handleChangeFilterReport = (name) => (date) => {
    this.setState((prevState) => ({
      tempFilter: {
        ...prevState.tempFilter,
        [name]: date,
      },
    }));
  };

  handleActivityTypeChange = (event) => {
    this.setState((prevState) => ({
      tempFilter: {
        ...prevState.tempFilter,
        activityType: event.target.value,
      },
    }));
  };

  handleCourierChange = (event) => {
    this.setState((prevState) => ({
      tempFilter: {
        ...prevState.tempFilter,
        courierId: event.target.value,
      },
    }));
  };

  handleClickPopover = (event) => {
    this.props.getPackageAdditionDetail(
      event.currentTarget.id,
      event.currentTarget
    );
  };

  async handleFilterGeneral() {
    this.setState({ filterActive: false });
    await this.props.filterCourierActivities(this.state.tempFilter);
  }

  handlePdfExport = () => {
    const { courierActivities, paketYoldaCourierList } = this.props;
    const filteredActivities =
      courierActivities?.filter((activity) =>
        paketYoldaCourierList?.some(
          (courier) => courier.Id === activity.CourierId
        )
      ) || [];

    if (filteredActivities.length > 0) {
      const activitiesWithNames = filteredActivities.map((activity) => {
        const courier = paketYoldaCourierList?.find(
          (c) => c.Id === activity.CourierId
        );
        return {
          ...activity,
          CourierName: courier.NameSurname,
        };
      });
      generateLogCourierPdfReport(activitiesWithNames);
    }
  };

  handleExcelExport = () => {
    const { courierActivities, paketYoldaCourierList } = this.props;
    const filteredActivities =
      courierActivities?.filter((activity) =>
        paketYoldaCourierList?.some(
          (courier) => courier.Id === activity.CourierId
        )
      ) || [];

    if (filteredActivities.length > 0) {
      const activitiesWithNames = filteredActivities.map((activity) => {
        const courier = paketYoldaCourierList?.find(
          (c) => c.Id === activity.CourierId
        );
        return {
          ...activity,
          CourierName: courier.NameSurname,
        };
      });
      generateLogCourierExcelReport(activitiesWithNames);
    }
  };

  getActivityTypeText = (type) => {
    switch (type) {
      case 1:
        return { text: "Çalışıyor", color: "#27ae60" };
      case 2:
        return { text: "Molada", color: "#f39c12" };
      case 3:
        return { text: "Çevrimdışı", color: "#e74c3c" };
      case 4:
        return { text: "Mola Bitti", color: "#2ecc71" };
      default:
        return { text: "Bilinmiyor", color: "#95a5a6" };
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  calculateActivitySummary = (activities) => {
    const summary = {
      totalWorkingTime: 0,
      totalBreakTime: 0,
    };

    // Sort activities by start time to process them in chronological order
    const sortedActivities = [...activities].sort((a, b) =>
      moment(a.StartTime).diff(moment(b.StartTime))
    );

    sortedActivities.forEach((activity) => {
      const startTime = moment(activity.StartTime);
      const endTime = activity.EndTime ? moment(activity.EndTime) : moment();
      const duration = moment.duration(endTime.diff(startTime));
      const durationInMinutes = duration.asMinutes();

      switch (activity.ActivityType) {
        case 3: // Çevrimdışı - This is working time
          summary.totalWorkingTime += durationInMinutes;
          break;
        case 4: // Mola Bitti - This indicates a break period just ended
          summary.totalBreakTime += durationInMinutes;
          break;
        default:
          break;
      }
    });

    return summary;
  };

  formatDuration = (minutes) => {
    if (!minutes || minutes < 0) return "-";
    const hours = Math.floor(minutes / 60);
    const mins = Math.floor(minutes % 60);
    const secs = Math.round((minutes % 1) * 60);
    return `${hours}s ${mins}dk ${secs}sn`;
  };

  calculateDuration = (startTime, endTime) => {
    if (!startTime || !endTime) return 0;
    const start = moment(startTime);
    const end = moment(endTime);
    return end.diff(start, "minutes", true);
  };

  render() {
    const {
      paketYoldaReportMenu,
      classes,
      paketYoldaCourierList,
      courierActivities,
      courierActivitiesLoading,
    } = this.props;

    const { tempFilter, page, rowsPerPage } = this.state;

    // Filter out activities from unknown couriers
    const filteredActivities =
      courierActivities?.filter((activity) =>
        paketYoldaCourierList?.some(
          (courier) => courier.Id === activity.CourierId
        )
      ) || [];

    if (paketYoldaReportMenu !== "CourierLog") {
      return null;
    }

    const renderFilterArea = () => (
      <Grid
        container
        spacing={1}
        style={{
          borderBottom: "1px solid #424242",
          padding: "16px",
          borderRadius: 0,
          marginBottom: 5,
        }}
      >
        <Grid item md={2} style={{ marginBottom: 8 }}>
          <FormControl fullWidth>
            <Typography
              style={{
                marginBottom: 4,
                color: "rgba(255, 255, 255, 0.7)",
                fontSize: "0.875rem",
              }}
            >
              {"Kurye Seçimi"}
            </Typography>
            <Select
              value={tempFilter.courierId || ""}
              onChange={this.handleCourierChange}
              style={{ width: "100%", borderRadius: 5, height: 38 }}
              input={<Input />}
            >
              <MenuItem value="">
                <em>Tümü</em>
              </MenuItem>
              {paketYoldaCourierList?.map((courier) => (
                <MenuItem key={courier.Id} value={courier.Id}>
                  {courier.NameSurname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2} style={{ marginBottom: 8 }}>
          <FormControl fullWidth>
            <Typography
              style={{
                marginBottom: 4,
                color: "rgba(255, 255, 255, 0.7)",
                fontSize: "0.875rem",
              }}
            >
              {"Aktivite Durumu"}
            </Typography>

            <Select
              value={tempFilter.activityType || ""}
              onChange={this.handleActivityTypeChange}
              style={{ width: "100%", borderRadius: 5, height: 38 }}
              input={<Input />}
            >
              <MenuItem value="">
                <em>Tümü</em>
              </MenuItem>
              <MenuItem value="1">Çalışıyor</MenuItem>
              <MenuItem value="2">Molada</MenuItem>
              <MenuItem value="3">Çevrimdışı</MenuItem>
              <MenuItem value="4">Mola Bitti</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2} style={{ marginBottom: 8 }}>
          <Typography
            style={{
              marginBottom: 4,
              color: "rgba(255, 255, 255, 0.7)",
              fontSize: "0.875rem",
            }}
          >
            {"Başlangıç Tarihi"}
          </Typography>
          <DatePicker
            selected={tempFilter.startDate}
            onChange={this.handleChangeFilterReport("startDate")}
            dateFormat="dd.MM.yyyy HH:mm"
            showTimeSelect
            timeIntervals={15}
            timeFormat="HH:mm"
            locale="tr"
            className={"inputDate"}
          />
        </Grid>
        <Grid item md={2} style={{ marginBottom: 8 }}>
          <Typography
            style={{
              marginBottom: 4,
              color: "rgba(255, 255, 255, 0.7)",
              fontSize: "0.875rem",
            }}
          >
            {"Bitiş Tarihi"}
          </Typography>
          <DatePicker
            selected={tempFilter.endDate}
            onChange={this.handleChangeFilterReport("endDate")}
            dateFormat="dd.MM.yyyy HH:mm"
            showTimeSelect
            timeIntervals={15}
            timeFormat="HH:mm"
            locale="tr"
            className={"inputDate"}
          />
        </Grid>
        <Grid
          item
          md={3}
          style={{
            display: "flex",
            alignItems: "flex-end",
            gap: "8px",
            marginBottom: 8,
          }}
        >
          <Button
            fullWidth
            disabled={courierActivitiesLoading}
            onClick={() => this.handleFilterGeneral()}
            style={{
              borderRadius: 5,
              height: 37,
              padding: "6px 24px",
              marginTop: 17,
            }}
            variant="contained"
            color="secondary"
            type="submit"
          >
            {"FİLTRELE"}
          </Button>
          <Button
            onClick={this.handleExcelExport}
            disabled={this.state.filterActive || !filteredActivities?.length}
            style={{
              minWidth: "40px",
              padding: "0px 25px",
              height: 37,
              backgroundColor: "#27ae60",
              borderRadius: 4,
              color: "#fafafa",
              marginTop: 15,
            }}
            variant="contained"
          >
            <Icon style={{ fontSize: 20 }}>description</Icon>
            <span style={{ marginLeft: 4, fontSize: 13 }}>Excel</span>
          </Button>
          <Button
            onClick={this.handlePdfExport}
            disabled={this.state.filterActive || !filteredActivities?.length}
            style={{
              minWidth: "40px",
              padding: "0px 25px",
              height: 37,
              backgroundColor: "#e74c3c",
              borderRadius: 4,
              color: "#fafafa",
              marginTop: 15,
            }}
            variant="contained"
          >
            <Icon style={{ fontSize: 20 }}>picture_as_pdf</Icon>
            <span style={{ marginLeft: 4, fontSize: 13 }}>PDF</span>
          </Button>
        </Grid>
      </Grid>
    );

    if (courierActivitiesLoading) {
      return (
        <Paper
          className={classes.root}
          elevation={0}
          style={{
            backgroundColor: "transparent",
            borderRadius: 0,
            height: "100%",
          }}
        >
          <div style={{ padding: 20 }}>
            {renderFilterArea()}
            <div style={{ width: "100%", textAlign: "center", paddingTop: 20 }}>
              <CircularProgress
                style={{ color: "#fafafa" }}
                thickness={4}
                className={classes.progress}
              />
            </div>
          </div>
        </Paper>
      );
    }

    return (
      <Paper
        className={classes.root}
        elevation={0}
        style={{
          backgroundColor: "transparent",
          borderRadius: 0,
          height: "100%",
        }}
      >
        <PackageAdditionDetail classes={classes} />
        <div
          style={{ height: "calc(100% - 43px)", padding: 5, overflowY: "auto" }}
        >
          {renderFilterArea()}
          <div
            style={{
              backgroundColor: "#313131",
              marginTop: 10,
              width: "100%",
              padding: 10,
              borderBottom: "1px solid rgba(209, 216, 224,.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {"Kurye Log Kayıtları"}
          </div>

          {!filteredActivities || filteredActivities.length === 0 ? (
            <div
              style={{ textAlign: "center", padding: "20px", color: "white" }}
            >
              <Typography variant="h6">
                Görüntülenecek veri bulunamadı. Lütfen filtreleri kontrol
                ediniz.
              </Typography>
            </div>
          ) : (
            <>
              <Table style={{ marginTop: 5, overflow: "hidden" }}>
                <TableHead style={{ backgroundColor: "#212121" }}>
                  <TableRow>
                    <TableCell
                      style={{ color: "white", fontSize: 13 }}
                      align="left"
                    >
                      {"Başlangıç Zamanı"}
                    </TableCell>
                    <TableCell
                      style={{ color: "white", fontSize: 13 }}
                      align="left"
                    >
                      {"Bitiş Zamanı"}
                    </TableCell>
                    <TableCell
                      style={{ color: "white", fontSize: 13 }}
                      align="left"
                    >
                      {"Süre"}
                    </TableCell>
                    <TableCell
                      style={{ color: "white", fontSize: 13 }}
                      align="left"
                    >
                      {"Kurye Adı"}
                    </TableCell>
                    <TableCell
                      style={{ color: "white", fontSize: 13 }}
                      align="left"
                    >
                      {"Aktivite Durumu"}
                    </TableCell>
                    <TableCell
                      style={{ color: "white", fontSize: 13 }}
                      align="center"
                    >
                      {"Aktif"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredActivities
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((activity, index) => {
                      const activityStatus = this.getActivityTypeText(
                        activity.ActivityType
                      );
                      const courier = paketYoldaCourierList?.find(
                        (c) => c.Id === activity.CourierId
                      );
                      const courierName = courier
                        ? courier.NameSurname
                        : "Bilinmeyen Kurye";
                      const courierPhone = courier ? courier.Phone : "";
                      const duration = this.calculateDuration(
                        activity.StartTime,
                        activity.EndTime
                      );

                      return (
                        <TableRow hover key={index}>
                          <TableCell align="left" style={{ padding: 5 }}>
                            {moment(activity.StartTime).format(
                              "DD.MM.YYYY HH:mm:ss"
                            )}
                          </TableCell>
                          <TableCell align="left" style={{ padding: 5 }}>
                            {activity.EndTime
                              ? moment(activity.EndTime).format(
                                  "DD.MM.YYYY HH:mm:ss"
                                )
                              : "-"}
                          </TableCell>
                          <TableCell align="left" style={{ padding: 5 }}>
                            {this.formatDuration(duration)}
                          </TableCell>
                          <TableCell align="left" style={{ padding: 5 }}>
                            <TooltipMui
                              title={
                                courierPhone
                                  ? `Tel: ${courierPhone}`
                                  : "Telefon bilgisi yok"
                              }
                              placement="top"
                            >
                              <span style={{ cursor: "pointer" }}>
                                {courierName}
                              </span>
                            </TooltipMui>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              padding: 5,
                              color: activityStatus.color,
                              fontWeight: 500,
                            }}
                          >
                            {activityStatus.text}
                          </TableCell>
                          <TableCell align="center" style={{ padding: 5 }}>
                            <Icon
                              style={{
                                color: activity.IsActive
                                  ? "#27ae60"
                                  : "#e74c3c",
                              }}
                            >
                              {activity.IsActive ? "check_circle" : "cancel"}
                            </Icon>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              <div style={{ width: "100%", display: "table" }}>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={filteredActivities.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  labelRowsPerPage="Sayfa başına kayıt"
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} / ${count}`
                  }
                  style={{ color: "white" }}
                />
              </div>
            </>
          )}
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  paketYoldaReportMenu: state.get("paketYoldaReducer").paketYoldaReportMenu,
  paketYoldaCourierList: state.get("paketYoldaReducer").paketYoldaCourierList,
  courierActivities: state
    .get("courierActivityReducer")
    .get("courierActivities")
    .toJS(),
  courierActivitiesLoading: state.get("courierActivityReducer").get("loading"),
  courierActivitiesFilter: state
    .get("courierActivityReducer")
    .get("filter")
    .toJS(),
  logReportList: state
    .get("courierActivityReducer")
    .get("courierActivities")
    .toJS(),
});

const mapDispatchToProps = {
  fetchCourierActivities,
  filterCourierActivities,
  setPaketYoldaLogReportFilterData,
  getPaketYoldaBrandList,
  getPaketYoldaBusinessList,
  getNewCourierList,
  getOrderGeneralReport,
  getPackageAdditionDetail,
  getLogReportList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(CourierLog)));
