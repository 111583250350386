import React from "react";
import logo from "dan-images/jarvis_orginal.png";

const Footer = () => {
  return (
    <footer
      style={{
        marginTop: "1rem",
        width: "100%",
        padding: "16px 0",
        backgroundColor: "#fff",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
        }}
      >
        <span
          style={{
            color: "#2851A3",
            fontSize: "14px",
            fontFamily: "Arial, sans-serif",
          }}
        >
          Powered By
        </span>
        <img src={logo} alt="Logo" style={{ height: "24px" }} />
      </div>
    </footer>
  );
};

export default Footer;
