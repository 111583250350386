import React from "react";
import { Switch, Route } from "react-router-dom";
import NotFound from "containers/Pages/Standalone/NotFoundDedicated";
import ClearCache from "react-clear-cache";
import teamwork from "dan-images/team_work.png";
import Icon from "@material-ui/core/Icon";
import { SocketNotificationPrinter } from "dan-components";
import cookie from "react-cookies";
import Auth from "./Auth";
import Application from "./Application";
import LoginDedicated from "../Pages/Standalone/LoginDedicated";
import CourierPIN from "../Pages/CourierPage/CourierPIN";
import KitchenPage from "../Pages/Kitchen/KitchenPage";
import PackageRackPage from "../Pages/PackageRack";
import MainPage from "../Pages/MainPage";
import PaketYoldaMainPage from "../Pages/PaketYolda/MainPage/PaketYoldaMainPage";
import CenterBrand from "../Pages/CenterBrandPage/CenterMainPage";
import AdminPanelPage from "../Pages/AdminPage/AdminPanelPage";
import PaketYoldaPanelPage from "../Pages/PaketYolda/PaketYoldaPanelPage";
import Report from "../Pages/SettingPage/ReportPage/Report";
import CourierPackageList from "../Pages/CourierPage/CourierPackageList";
import ThemeWrapper, { AppContext } from "./ThemeWrapper";

import AdminLogin from "../Pages/AdminPage/AdminLogin";
const { detect } = require("detect-browser");
const browser = detect();
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

class App extends React.Component {
  render() {
    const { classes } = this.props;

    if (
      window.location.pathname != "/customerscreen" &&
      window.location.pathname != "/adminlogin" &&
      window.location.pathname.indexOf("adminpanel") != -1
    ) {
      if (window.location.pathname != "/") {
        if (
          cookie.load("AdminLogin") == undefined ||
          cookie.load("AdminLogin") === "false"
        ) {
          window.location.href = "/adminlogin";
        }
      }
    }

    if (
      window.location.pathname.indexOf("javikurye") == -1 &&
      window.location.pathname.indexOf("paketyolda") == -1 &&
      window.location.pathname != "/demo" &&
      window.location.pathname != "/track" &&
      window.location.pathname != "/login" &&
      window.location.pathname != "/register" &&
      window.location.pathname.indexOf("admin") == -1 &&
      window.location.pathname.indexOf("courier") == -1 &&
      window.location.pathname.indexOf("rest") == -1 &&
      window.location.pathname.indexOf("qrorder") == -1 &&
      window.location.pathname.indexOf("qrmenu") == -1 &&
      window.location.pathname.indexOf("customerscreen") == -1
    ) {
      if (window.location.pathname != "/") {
        if (cookie.load("UserId") == undefined) {
          window.location.href = "/";
        }
      }
    }

    return (
      <ThemeWrapper>
        <SocketNotificationPrinter classes={classes} />
        <ClearCache>
          {({ isLatestVersion, emptyCacheStorage }) => (
            <div
              style={{
                display: !isLatestVersion ? "" : "none",
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 9998,
              }}
            >
              <div
                style={{
                  position: "fixed",
                  width: 450,
                  backgroundColor: "#424242",
                  zIndex: 9999,
                  padding: 20,
                  borderRadius: 10,
                  textAlign: "center",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {!isLatestVersion && (
                  <p>
                    <p>
                      <span
                        style={{
                          color: "white",
                          fontWeight: 500,
                          fontSize: 30,
                        }}
                      >
                        Merhaba
                      </span>
                    </p>
                    <br />
                    <p>
                      <span style={{ color: "white", fontWeight: 400 }}>
                        Her geçen gün daha iyi bir JaviPos için çalışıyoruz!
                      </span>
                    </p>
                    <br />
                    <p>
                      <span style={{ color: "white", fontWeight: 400 }}>
                        {"Yeni versiyonumuzu yayınladık."}
                        <br />
                        {
                          "Güncelle butonuna bastıktan sonra güncelleme yüklenmezse "
                        }
                        <span
                          style={{
                            color: "white",
                            fontWeight: 600,
                            fontSize: 20,
                          }}
                        >
                          CTRL+F5
                        </span>
                        {" tuş kombinasyonunu kullanabilirsiniz"}
                      </span>
                    </p>
                    <br />
                    <a
                      href="#"
                      style={{
                        color: "white",
                        textDecoration: "none",
                        padding: "10px 30px 10px 30px",
                        borderRadius: 5,
                        marginTop: 10,
                        fontWeight: 500,
                        backgroundColor: "#2980b9",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        emptyCacheStorage();
                        if (window.location.pathname.indexOf("rest") != -1) {
                          window.location.href = window.location.href;
                        } else if (
                          window.location.pathname.indexOf("track") != -1
                        ) {
                          window.location.href = window.location.href;
                        } else {
                          window.location.href = "/";
                        }
                      }}
                    >
                      {"Güncelle"}
                    </a>
                  </p>
                )}
              </div>
            </div>
          )}
        </ClearCache>
        <AppContext.Consumer>
          {(changeMode) => (
            <Switch>
              <Route path="/" exact component={LoginDedicated} />
              <Route
                path="/app"
                render={(props) => (
                  <Application {...props} changeMode={changeMode} />
                )}
              />
              <Route path="/courier" exact component={CourierPIN} />
              <Route path="/kitchen" exact component={KitchenPage} />
              <Route path="/packagerack" exact component={PackageRackPage} />
              <Route path="/mainpage" exact component={MainPage} />
              <Route
                path="/paketyoldamainpage"
                exact
                component={PaketYoldaMainPage}
              />
              <Route path="/centerbrand" exact component={CenterBrand} />
              <Route path="/adminpanel" exact component={AdminPanelPage} />
              <Route
                path="/courier/packages"
                exact
                component={CourierPackageList}
              />
              <Route path="/report" component={Report} />
              <Route
                path="/paketyoldapanel"
                exact
                component={PaketYoldaPanelPage}
              />
              <Route component={Auth} />
              <Route component={NotFound} />
            </Switch>
          )}
        </AppContext.Consumer>
      </ThemeWrapper>
    );
  }
}

export default App;
