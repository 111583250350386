import React from "react";
import { createSelector } from "reselect";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { CounterTrading } from "dan-components";
import logo from "dan-images/logo.png";
import colorfull from "dan-api/palette/colorfull";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import messageStyles from "dan-styles/Messages.scss";
import Popover from "@material-ui/core/Popover";
import Tooltip from "@material-ui/core/Tooltip";
import { EmptyData } from "dan-components";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TablePagination from "@material-ui/core/TablePagination";
import {
  setPaketYoldaRegionAddVisible,
  setAnchorEntegration,
  setMileageChargeVisible,
  setPaketYoldaBrandSettingsVisible,
  setPaketYoldaBrandSettingsData,
  setBrandPreparationTime,
  setEntegrationBrandAddModal,
  setEntBrandList,
  setEntegrationBrandAddData,
  setEntBrandDelete,
  getRestaurantSettings,
  setRestaurantSettings,
  setRestaurantSettingsData,
} from "../../../../redux/modules/paketyolda";

import { getEntegrationRestList } from "../../../../redux/modules/settings";
import { getPaketYolda } from "../../../../function/GeneralFunction";
import CircularProgress from "@material-ui/core/CircularProgress";
import Entegration from "../../../Templates/layouts/Entegration";
import cookie from "react-cookies";
import InputMask from "react-input-mask";
import BlockedCourier from "./BlockedCourier";
import { setBlockedCourierVisible } from "../../../../redux/modules/BlockedCourierReducer";
import DistanceRestriction from "./DistanceRestriction";

const separatorList = {
  comma: {
    name: "comma",
    regex: /^\d+(,\d{0,2})?$/,
  },
  period: {
    name: "period",
    regex: /^\d+(\.\d{0,6})?$/,
  },
};

const styles = (theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

class BusinessSettingsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brandSettingsData: null,
      distanceRestrictionVisible: false,
      selectedBusiness: null,
    };
  }

  async componentDidMount() {
    const { brandSettingsData } = this.props;
    const brandId = brandSettingsData?.BrandId;
    if (brandId) {
      await this.props.getRestaurantSettings(brandId);
      this.props.setRestaurantSettingsData({
        ...this.props.restaurantSettingsData,
        BrandId: brandId,
      });
    }
  }

  componentWillUnmount() {
    if (this.props.brandSettingsVisible) {
      this.props.setPaketYoldaBrandSettingsVisible(false, null);
    }

    if (this.props.entegrationBrandAddVisible) {
      this.props.setEntegrationBrandAddModal(false);
    }

    if (this.props.anchorElEntegrationDetail) {
      this.props.setAnchorEntegration(null);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      paketYoldaSettingsMenu,
      brandSettingsVisible,
      entegrationBrandAddVisible,
    } = this.props;

    if (
      prevProps.paketYoldaSettingsMenu === "BusinessSettings" &&
      paketYoldaSettingsMenu !== "BusinessSettings"
    ) {
      if (brandSettingsVisible) {
        this.props.setPaketYoldaBrandSettingsVisible(false, null);
      }

      if (entegrationBrandAddVisible) {
        this.props.setEntegrationBrandAddModal(false);
      }
    }
  }

  handleClose = () => {
    this.props.setAnchorEntegration(null);
  };

  handleSaveSettings = async () => {
    const { restaurantSettingsData, brandSettingsData } = this.props;

    try {
      if (!restaurantSettingsData || !brandSettingsData) {
        console.error("Veri eksik:", {
          restaurantSettingsData,
          brandSettingsData,
        });
        return;
      }

      const dataToSend = {
        BrandId: restaurantSettingsData.BrandId || brandSettingsData.BrandId,
        ProcessId:
          restaurantSettingsData.ProcessId ||
          brandSettingsData.ProcessId ||
          brandSettingsData.PaketYoldaId,
      };

      Object.keys(this.props.restaurantSettingsData).forEach((key) => {
        if (key !== "BrandId" && key !== "ProcessId") {
          dataToSend[key] =
            restaurantSettingsData[key] !== undefined
              ? restaurantSettingsData[key]
              : this.props.restaurantSettingsData[key];
        }
      });

      if (!dataToSend.BrandId || !dataToSend.ProcessId) {
        console.error("BrandId veya ProcessId eksik:", dataToSend);
        return;
      }

      dataToSend.PreparationTime = Number(dataToSend.PreparationTime) || 0;
      dataToSend.CreditCardCommissionRate =
        Number(dataToSend.CreditCardCommissionRate) || 0;
      dataToSend.MealCardCommissionRate =
        Number(dataToSend.MealCardCommissionRate) || 0;
      dataToSend.IsPOSUsed = Boolean(dataToSend.IsPOSUsed);

      await this.props.setRestaurantSettings(dataToSend);
      await this.props.setBrandPreparationTime(
        dataToSend.BrandId,
        dataToSend.ProcessId,
        dataToSend.PreparationTime
      );
      this.props.setPaketYoldaBrandSettingsVisible(false, null);
    } catch (error) {
      console.error("Kaydetme hatası:", error);
    }
  };

  handleChangeSettings = (name) => (event) => {
    const { restaurantSettingsData } = this.props;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    const updatedData = { ...restaurantSettingsData };

    updatedData[name] = value;

    this.props.setRestaurantSettingsData(updatedData);
  };

  renderEntegrationDetail() {
    const {
      anchorElEntegrationDetail,
      classes,
      paketYoldaSettingsMenu,
    } = this.props;

    if (
      paketYoldaSettingsMenu !== "BusinessSettings" ||
      !anchorElEntegrationDetail
    ) {
      return null;
    }

    return (
      <div style={{ display: "inline" }}>
        <Popover
          open={Boolean(anchorElEntegrationDetail)}
          anchorEl={anchorElEntegrationDetail}
          PaperProps={{ style: { borderRadius: 0 } }}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div
            style={{
              padding: 5,
              width: 450,
              borderRadius: 0,
              backgroundColor: "#424242",
              border: "4px solid #313131",
            }}
          >
            <Entegration type={"paketyolda"} classes={classes} />
          </div>
        </Popover>
      </div>
    );
  }

  handleClickPopover = (event) => {
    cookie.save("BrandId", event.currentTarget.id, { path: "/" });
    this.props.getEntegrationRestList(
      true,
      true,
      true,
      true,
      true,
      event.currentTarget,
      true
    );
  };

  handleClickSettings = async (brandId, processId, businessName) => {
    try {
      const initialData = {
        ...this.props.restaurantSettingsData,
        BusinessName: businessName,
        BrandId: brandId,
        ProcessId: processId,
      };

      this.props.setRestaurantSettingsData(initialData);

      await this.props.getRestaurantSettings(brandId, processId);

      const { restaurantSettingsData } = this.props;

      if (restaurantSettingsData) {
        const businessDetails = this.props.paketYoldaBusinessList.find(
          (business) =>
            business.BrandId === brandId && business.PaketYoldaId === processId
        );

        const updatedData = {
          ...restaurantSettingsData,
          BusinessName: businessName,
          BrandId: brandId,
          ProcessId: processId,
          PreparationTime:
            businessDetails?.PreparationTime ||
            restaurantSettingsData?.PreparationTime ||
            0,
        };

        this.props.setRestaurantSettingsData(updatedData);
      }

      this.props.setPaketYoldaBrandSettingsVisible(true, {
        BusinessName: businessName,
        BrandId: brandId,
        ProcessId: processId,
      });
    } catch (error) {
      console.error("Ayarlar yüklenirken hata:", error);

      this.props.setRestaurantSettingsData({
        ...this.props.restaurantSettingsData,
        BusinessName: businessName,
        BrandId: brandId,
        ProcessId: processId,
      });

      this.props.setPaketYoldaBrandSettingsVisible(true, {
        BusinessName: businessName,
        BrandId: brandId,
        ProcessId: processId,
      });
    }
  };

  renderSettings() {
    const {
      classes,
      brandSettingsVisible,
      restaurantSettingsData,
      brandSettingsData,
      paketYoldaSettingsMenu,
    } = this.props;

    if (
      paketYoldaSettingsMenu !== "BusinessSettings" ||
      !brandSettingsVisible ||
      !restaurantSettingsData ||
      !brandSettingsData
    ) {
      return null;
    }

    const businessName =
      restaurantSettingsData?.BusinessName ||
      brandSettingsData?.BusinessName ||
      "İşletme";

    return (
      <Dialog
        open={brandSettingsVisible}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
        PaperProps={{ classes: { root: classes.dialogPaper } }}
        onClose={() =>
          this.props.setPaketYoldaBrandSettingsVisible(false, null)
        }
      >
        <DialogTitle style={{ padding: "24px 24px 5px", marginBottom: 10 }}>
          {businessName} {"Ayarları"}
        </DialogTitle>
        <DialogContent style={{ overflow: "hidden auto" }}>
          <Grid container spacing={1}>
            <Grid item md={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="name-simple">{"Ad"}</InputLabel>
                <Input
                  placeholder="Ad"
                  value={restaurantSettingsData?.Name || ""}
                  onChange={this.handleChangeSettings("Name")}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="email-simple">{"E-posta"}</InputLabel>
                <Input
                  placeholder="E-posta"
                  value={restaurantSettingsData?.Email || ""}
                  onChange={this.handleChangeSettings("Email")}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="phone-simple">{"Cep Telefonu"}</InputLabel>
                <Input
                  placeholder="0500XXXXXXX"
                  value={restaurantSettingsData?.PhoneNumber || ""}
                  onChange={this.handleChangeSettings("PhoneNumber")}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="address-simple">{"Adres"}</InputLabel>
                <Input
                  placeholder="Adres"
                  value={restaurantSettingsData?.Address || ""}
                  onChange={this.handleChangeSettings("Address")}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="district-simple">{"İlçe"}</InputLabel>
                <Input
                  placeholder="İlçe"
                  value={restaurantSettingsData?.District || ""}
                  onChange={this.handleChangeSettings("District")}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="city-simple">{"Şehir"}</InputLabel>
                <Input
                  placeholder="Şehir"
                  value={restaurantSettingsData?.City || ""}
                  onChange={this.handleChangeSettings("City")}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="preparation-time-simple">
                  {"Hazırlanma Süresi"}
                </InputLabel>
                <Input
                  placeholder="Hazırlanma Süresi"
                  type="number"
                  value={restaurantSettingsData?.PreparationTime || 0}
                  onChange={this.handleChangeSettings("PreparationTime")}
                  inputProps={{
                    min: 0,
                    max: 100,
                    step: 1,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="credit-card-commission-simple">
                  {"Kredi Kartı Komisyon Oranı"}
                </InputLabel>
                <Input
                  placeholder="Kredi Kartı Komisyon Oranı"
                  type="number"
                  value={restaurantSettingsData?.CreditCardCommissionRate || 0}
                  onChange={this.handleChangeSettings(
                    "CreditCardCommissionRate"
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="meal-card-commission-simple">
                  {"Yemek Kartı Komisyon Oranı"}
                </InputLabel>
                <Input
                  placeholder="Yemek Kartı Komisyon Oranı"
                  type="number"
                  value={restaurantSettingsData?.MealCardCommissionRate || 0}
                  onChange={this.handleChangeSettings("MealCardCommissionRate")}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="technical-support-name-simple">
                  {"Teknik Destek"}
                </InputLabel>
                <Input
                  placeholder="Teknik Destek Ad Soyad"
                  value={restaurantSettingsData?.TechnicalSupportName || ""}
                  onChange={this.handleChangeSettings("TechnicalSupportName")}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="support-phone-simple">
                  {"Destek Telefonu"}
                </InputLabel>
                <Input
                  placeholder="Destek Telefonu"
                  value={restaurantSettingsData?.SupportPhone || ""}
                  onChange={this.handleChangeSettings("SupportPhone")}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="iban-simple">{"IBAN"}</InputLabel>
                <Input
                  placeholder="IBAN"
                  value={restaurantSettingsData?.IBAN || ""}
                  onChange={this.handleChangeSettings("IBAN")}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="company-name-simple">
                  {"Şirket Adı"}
                </InputLabel>
                <Input
                  placeholder="Şirket Adı"
                  value={restaurantSettingsData?.CompanyName || ""}
                  onChange={this.handleChangeSettings("CompanyName")}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="tax-office-simple">
                  {"Vergi Dairesi"}
                </InputLabel>
                <Input
                  placeholder="Vergi Dairesi"
                  value={restaurantSettingsData?.TaxOffice || ""}
                  onChange={this.handleChangeSettings("TaxOffice")}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="tax-number-simple">
                  {"Vergi Numarası"}
                </InputLabel>
                <Input
                  placeholder="Vergi Numarası"
                  value={restaurantSettingsData?.TaxNumber || ""}
                  onChange={this.handleChangeSettings("TaxNumber")}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="company-address-simple">
                  {"Şirket Adresi"}
                </InputLabel>
                <Input
                  placeholder="Şirket Adresi"
                  value={restaurantSettingsData?.CompanyAddress || ""}
                  onChange={this.handleChangeSettings("CompanyAddress")}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={restaurantSettingsData?.IsPOSUsed || false}
                    onChange={this.handleChangeSettings("IsPOSUsed")}
                    color="primary"
                  />
                }
                label="POS Kullanıyor Mu?"
                style={{ marginTop: 16 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleSaveSettings}
            variant="contained"
            color="secondary"
          >
            {"Kaydet"}
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "#F44336" }}
            onClick={() =>
              this.props.setPaketYoldaBrandSettingsVisible(false, null)
            }
            color="primary"
          >
            {"Çıkış"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleChangeBrandAdd = (name) => (event) => {
    const { entegrationBrandAdd } = this.props;
    entegrationBrandAdd[name] = event.target.value;
    this.props.setEntegrationBrandAddData(entegrationBrandAdd).then(() => {});
  };

  handleChangeBrandAddLocation(value, name) {
    const { entegrationBrandAdd } = this.props;
    entegrationBrandAdd[name] = value;
    this.props.setEntegrationBrandAddData(entegrationBrandAdd).then(() => {});
  }

  renderBrandAdd() {
    const {
      classes,
      entegrationBrandAddVisible,
      entegrationBrandAdd,
      paketYoldaSettingsMenu,
    } = this.props;

    if (
      paketYoldaSettingsMenu !== "BusinessSettings" ||
      !entegrationBrandAddVisible
    ) {
      return null;
    }

    return (
      <Dialog
        open={entegrationBrandAddVisible}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"xs"}
        PaperProps={{ classes: { root: classes.dialogPaper } }}
        onClose={() => this.props.setEntegrationBrandAddModal(false)}
      >
        <DialogTitle style={{ padding: "24px 24px 5px", marginBottom: 10 }}>
          {"Entegrasyon İşletme Tanımı"}
        </DialogTitle>
        <DialogContent style={{ overflow: "hidden auto" }}>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel htmlFor="name-simple">
                  {"İşletme AppKey"}
                </InputLabel>
                <Input
                  defaultValue={entegrationBrandAdd.Id || ""}
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  onChange={this.handleChangeBrandAdd("Id")}
                  type="text"
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel htmlFor="name-simple">{"İşletme Adı"}</InputLabel>
                <Input
                  defaultValue={entegrationBrandAdd.RestName || ""}
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  onChange={this.handleChangeBrandAdd("RestName")}
                  type="text"
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <FormControl
                className={classes.formControl}
                fullWidth
                style={{ marginTop: 10 }}
              >
                <InputMask
                  mask="0 999 999 99 99"
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  value={entegrationBrandAdd.BrandPhone || ""}
                  onChange={this.handleChangeBrandAdd("BrandPhone")}
                >
                  {() => (
                    <TextField
                      name={"Phone"}
                      placeholder="Telefon Numarası"
                      fullWidth
                      classes={{ root: classes.MuiFormControlLoginUser }}
                      required
                      margin="dense"
                      type="text"
                    />
                  )}
                </InputMask>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel htmlFor="name-simple">
                  {"Hazırlanma Süresi"}
                </InputLabel>
                <Input
                  defaultValue={entegrationBrandAdd.PreparationTime || 0}
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  onChange={this.handleChangeBrandAdd("PreparationTime")}
                  type="number"
                  fullWidth
                  inputProps={{
                    min: 0,
                    max: 100,
                    step: 1,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  label="İşletme Latitude"
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  value={entegrationBrandAdd.RestLat || 0}
                  onChange={({ target: { value } }) =>
                    this.handleChangeBrandAddLocation(value, "RestLat")
                  }
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  label="İşletme Longitude"
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  value={entegrationBrandAdd.RestLng || 0}
                  onChange={({ target: { value } }) =>
                    this.handleChangeBrandAddLocation(value, "RestLng")
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ backgroundColor: "#F44336" }}
            onClick={() =>
              this.props.setEntegrationBrandAddModal(false).then(() => {})
            }
            color="primary"
          >
            {"Çıkış"}
          </Button>
          <Button
            onClick={() => this.props.setEntBrandList().then(() => {})}
            variant="contained"
            color="secondary"
          >
            {"Kaydet"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleDistanceRestrictionClick = (business) => {
    this.setState({
      distanceRestrictionVisible: true,
      selectedBusiness: business,
    });
  };

  handleDistanceRestrictionClose = () => {
    this.setState({
      distanceRestrictionVisible: false,
      selectedBusiness: null,
    });
  };

  render() {
    const {
      paketYoldaBusinessListLoading,
      paketYoldaSettingsMenu,
      paketYoldaBusinessList,
      classes,
      entegrationBrandList,
      blockedCourierVisible,
      blockedCourierData,
    } = this.props;

    const { distanceRestrictionVisible, selectedBusiness } = this.state;

    if (paketYoldaSettingsMenu !== "BusinessSettings") {
      if (this.props.brandSettingsVisible) {
        this.props.setPaketYoldaBrandSettingsVisible(false, null);
      }

      if (this.props.entegrationBrandAddVisible) {
        this.props.setEntegrationBrandAddModal(false);
      }

      if (this.props.anchorElEntegrationDetail) {
        this.props.setAnchorEntegration(null);
      }

      return null;
    }

    if (paketYoldaBusinessListLoading) {
      return (
        <div style={{ width: "100%", textAlignLast: "center", paddingTop: 20 }}>
          <CircularProgress
            style={{ color: "#fafafa" }}
            thickness={4}
            className={classes.progress}
          />
        </div>
      );
    }

    return (
      <Paper
        className={classes.root}
        elevation={0}
        style={{
          backgroundColor: "transparent",
          borderRadius: 0,
          height: "100%",
        }}
      >
        {this.renderEntegrationDetail()}
        {this.renderSettings()}
        {this.renderBrandAdd()}
        <BlockedCourier
          open={blockedCourierVisible}
          businessName={blockedCourierData?.BusinessName}
        />
        <DistanceRestriction
          open={distanceRestrictionVisible}
          onClose={this.handleDistanceRestrictionClose}
          businessName={selectedBusiness?.BusinessName}
          selectedBusiness={selectedBusiness}
        />
        <div
          style={{ height: "calc(100% - 43px)", padding: 5, overflowY: "auto" }}
        >
          <div style={{ backgroundColor: "rgb(49, 49, 49)", padding: 5 }}>
            <div
              style={{
                backgroundColor: "rgb(66, 66, 66)",
                marginBottom: 10,
                width: "100%",
                padding: 10,
                borderBottom: "1px solid rgba(209, 216, 224,.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {"JaviPos Tanımlı Olan İşletmeler"}
            </div>
            <Table
              className={classNames(classes.table, classes.bordered)}
              style={{ marginTop: 10 }}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">{"İşletme Adı"}</TableCell>
                  <TableCell align="center">{"Otomatik Atama"}</TableCell>
                  <TableCell align="center">{"Hazırlanma Süresi"}</TableCell>
                  <TableCell align={"right"}>{"İşlemler"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paketYoldaBusinessList.map((n, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell align="left">{n.BusinessName}</TableCell>
                      <TableCell align="center">
                        <Icon
                          style={{
                            color: n.IsAutomaticSubmission
                              ? "#16a085"
                              : "#c0392b",
                          }}
                        >
                          {n.IsAutomaticSubmission
                            ? "check_box"
                            : "indeterminate_check_box"}
                        </Icon>
                      </TableCell>
                      <TableCell align="center">
                        {n.PreparationTime ?? 0}
                        {"dk"}
                      </TableCell>
                      <TableCell align={"right"}>
                        <Tooltip title={"Km Kısıtlama"}>
                          <IconButton
                            style={{
                              backgroundColor: "#3e3e3e",
                              marginRight: 5,
                              fontSize: 12,
                              padding: 8,
                            }}
                            onClick={() =>
                              this.handleDistanceRestrictionClick(n)
                            }
                          >
                            <Icon>{"explore"}</Icon>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"Ayarlar"}>
                          <IconButton
                            style={{
                              backgroundColor: "#3e3e3e",
                              marginRight: 5,
                              fontSize: 12,
                              padding: 8,
                            }}
                            onClick={() =>
                              this.handleClickSettings(
                                n.BrandId,
                                n.PaketYoldaId,
                                n.BusinessName
                              )
                            }
                          >
                            <Icon>{"settings"}</Icon>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"Kilometre Ücretlendirme"}>
                          <IconButton
                            style={{
                              backgroundColor: "#3e3e3e",
                              marginRight: 5,
                              fontSize: 12,
                              padding: 8,
                            }}
                            onClick={() =>
                              this.props
                                .setMileageChargeVisible(true, n.BrandId, 1)
                                .then(() => {})
                            }
                          >
                            <Icon>{"edit_road"}</Icon>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"Kurye Engelleme Listesi"}>
                          <IconButton
                            style={{
                              backgroundColor: "#3e3e3e",
                              marginRight: 5,
                              fontSize: 12,
                              padding: 8,
                            }}
                            onClick={() => {
                              this.props.setBlockedCourierVisible(true, {
                                BusinessName: n.BusinessName,
                                BrandId: n.BrandId,
                              });
                            }}
                          >
                            <Icon>{"block"}</Icon>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"Entegrasyon İşlemleri"}>
                          <IconButton
                            id={n.BrandId}
                            style={{
                              backgroundColor: "#3e3e3e",
                              marginRight: 5,
                              fontSize: 12,
                              padding: 8,
                            }}
                            onClick={this.handleClickPopover}
                          >
                            <Icon>{"extension"}</Icon>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <div
              style={{
                justifyContent: "space-between",
                backgroundColor: "rgb(66, 66, 66)",
                marginBottom: 10,
                width: "100%",
                padding: 10,
                borderTop: "1px solid rgba(209, 216, 224,.5)",
                borderBottom: "1px solid rgba(209, 216, 224,.5)",
                display: "flex",
                alignItems: "center",
              }}
            >
              {"Api Tanımlı Olan İşletmeler"}
              <Button
                style={{ textTransform: "capitalize" }}
                variant="contained"
                color="primary"
                onClick={() =>
                  this.props.setEntegrationBrandAddModal(true).then(() => {})
                }
              >
                <Icon style={{ marginRight: 5, fontSize: 15 }}>
                  {"extension"}
                </Icon>
                {"Entegrasyon Ekle"}
              </Button>
            </div>
            <Table
              className={classNames(classes.table, classes.bordered)}
              style={{ marginTop: 10 }}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">{"AppKey"}</TableCell>
                  <TableCell align="left">{"İşletme Adı"}</TableCell>
                  <TableCell align="left">{"Telefon Numarası"}</TableCell>
                  <TableCell align="left">{"Hazırlanma Süresi"}</TableCell>
                  <TableCell align={"right"}>{"İşlemler"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {entegrationBrandList.map((n, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell align="left">
                        <IconButton
                          style={{
                            backgroundColor: "#3e3e3e",
                            marginRight: 10,
                            fontSize: 12,
                            padding: 8,
                          }}
                          onClick={() => navigator.clipboard.writeText(n.Id)}
                        >
                          <Icon>{"content_copy"}</Icon>
                        </IconButton>
                        {n.Id}
                      </TableCell>
                      <TableCell align="left">{n.RestName}</TableCell>
                      <TableCell align="left">{n.BrandPhone}</TableCell>
                      <TableCell align="left">
                        {n.PreparationTime ?? 0}
                        {"dk"}
                      </TableCell>
                      <TableCell align={"right"}>
                        <Tooltip title={"Düzenle"}>
                          <IconButton
                            style={{
                              backgroundColor: "#3e3e3e",
                              marginRight: 5,
                              fontSize: 12,
                              padding: 8,
                            }}
                            onClick={() =>
                              this.props
                                .setEntegrationBrandAddModal(true, n.Id)
                                .then(() => {})
                            }
                          >
                            <Icon>{"edit"}</Icon>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"Kilometre Ücretlendirme"}>
                          <IconButton
                            style={{
                              backgroundColor: "#3e3e3e",
                              marginRight: 5,
                              fontSize: 12,
                              padding: 8,
                            }}
                            onClick={() =>
                              this.props
                                .setMileageChargeVisible(true, n.BrandId, 1)
                                .then(() => {})
                            }
                          >
                            <Icon>{"edit_road"}</Icon>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"İşletme Silme"}>
                          <IconButton
                            style={{
                              backgroundColor: "#3e3e3e",
                              marginRight: 5,
                              fontSize: 12,
                              padding: 8,
                            }}
                            onClick={() =>
                              confirm(
                                "İşletme kaydını sildiğinizde entegre olan firmadan işletme için oluşturulan siparişler JaviKurye sistemine aktarılamayacaktır. Onaylıyor musunuz?"
                              )
                                ? this.props
                                    .setEntBrandDelete(n.Id)
                                    .then(() => {})
                                : null
                            }
                          >
                            <Icon>{"delete"}</Icon>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </div>
      </Paper>
    );
  }
}

const valueSelector = (state, props) =>
  state.get("paketYoldaReducer").paketYoldaBusinessListUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
  paketYoldaBusinessListUpdate: value(state, props),
  paketYoldaBusinessList: state.get("paketYoldaReducer").paketYoldaBusinessList,
  paketYoldaSettingsMenu: state.get("paketYoldaReducer").paketYoldaSettingsMenu,
  paketYoldaBusinessListLoading: state.get("paketYoldaReducer")
    .paketYoldaBusinessListLoading,
  anchorElEntegrationDetail: state.get("paketYoldaReducer")
    .anchorElEntegrationDetail,
  brandSettingsVisible: state.get("paketYoldaReducer").brandSettingsVisible,
  restaurantSettingsData: state.get("paketYoldaReducer").restaurantSettingsData,
  entegrationBrandAdd: state.get("paketYoldaReducer").entegrationBrandAdd,
  entegrationBrandList: state.get("paketYoldaReducer").entegrationBrandList,
  entegrationBrandAddVisible: state.get("paketYoldaReducer")
    .entegrationBrandAddVisible,
  restaurantSettings: state.get("paketYoldaReducer").restaurantSettings,
  brandSettingsData: state.get("paketYoldaReducer").brandSettingsData,
  blockedCourierVisible:
    state.get("blockedCourierReducer").get("blockedCourierVisible") || false,
  blockedCourierData:
    state.get("blockedCourierReducer").get("blockedCourierData") || null,
});

const mapDispatchToProps = {
  setPaketYoldaRegionAddVisible,
  getEntegrationRestList,
  setAnchorEntegration,
  setMileageChargeVisible,
  setPaketYoldaBrandSettingsVisible,
  setPaketYoldaBrandSettingsData,
  setBrandPreparationTime,
  setEntegrationBrandAddModal,
  setEntBrandList,
  setEntegrationBrandAddData,
  setEntBrandDelete,
  getRestaurantSettings,
  setRestaurantSettings,
  setRestaurantSettingsData,
  setBlockedCourierVisible,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(BusinessSettingsList)));
