import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { getCourierListForBlocking } from "../../../../redux/modules/paketyolda";
import {
  getBlockedCouriers,
  setBlockedCourier,
  deleteBlockedCourier,
  setBlockedCourierVisible,
} from "../../../../redux/modules/BlockedCourierReducer";
import moment from "moment";

const styles = (theme) => ({
  dialogPaper: {
    backgroundColor: "#424242",
    color: "#fff",
  },
  table: {
    backgroundColor: "#313131",
  },
  blockedRow: {
    backgroundColor: "rgba(244, 67, 54, 0.1)",
  },
  tableContainer: {
    backgroundColor: "rgb(49, 49, 49)",
    padding: 5,
    marginBottom: 16,
  },
  tableTitle: {
    padding: "10px 15px",
    backgroundColor: "rgb(66, 66, 66)",
    marginBottom: 10,
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    padding: 20,
  },
  noteField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& .MuiInputBase-root": {
      color: "#fff",
    },
    "& .MuiInputLabel-root": {
      color: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(255, 255, 255, 0.23)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(255, 255, 255, 0.5)",
      },
    },
  },
  blockingReason: {
    color: "#ff9800",
    fontSize: "0.875rem",
    marginTop: 4,
  },
});

class BlockedCourier extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blockingNote: "",
      selectedCourier: null,
      showNoteDialog: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.fetchData();
    }
  }

  fetchData = () => {
    this.props.getCourierListForBlocking();
    this.props.getBlockedCouriers(this.props.brandId);
  };

  getCourierDetails = (blockedCourier) => {
    const { courierList } = this.props;
    const courier = courierList.find((c) => c.Id === blockedCourier.CourierId);
    return {
      ...blockedCourier,
      NameSurname:
        courier?.NameSurname || blockedCourier.CourierName || "İsimsiz Kurye",
      Phone: courier?.Phone || blockedCourier.Phone || "-",
    };
  };

  handleClose = () => {
    this.props.setBlockedCourierVisible(false, null);
  };

  handleToggleBlock = async (courier, isFromBlockedList = false) => {
    if (isFromBlockedList) {
      // Engeli kaldır
      await this.props.deleteBlockedCourier(courier.Id, this.props.brandId);
    } else {
      // Engelleme için not dialogunu aç
      this.setState({
        selectedCourier: courier,
        showNoteDialog: true,
        blockingNote: "",
      });
    }
  };

  handleNoteSubmit = async () => {
    const { selectedCourier, blockingNote } = this.state;
    if (selectedCourier) {
      await this.props.setBlockedCourier({
        Id: selectedCourier.Id,
        BrandId: this.props.brandId,
        CourierId: selectedCourier.Id,
        CourierName: selectedCourier.NameSurname || selectedCourier.CourierName,
        BlockingReason: blockingNote,
      });
      this.setState({
        showNoteDialog: false,
        selectedCourier: null,
        blockingNote: "",
      });
    }
  };

  handleNoteCancel = () => {
    this.setState({
      showNoteDialog: false,
      selectedCourier: null,
      blockingNote: "",
    });
  };

  render() {
    const {
      classes,
      open,
      businessName,
      courierList,
      blockedCouriers,
      loading,
      error,
    } = this.props;
    const { showNoteDialog, blockingNote } = this.state;

    if (error) {
      return (
        <Dialog
          open={open}
          onClose={this.handleClose}
          PaperProps={{ classes: { root: classes.dialogPaper } }}
        >
          <DialogContent>
            <Typography color="error">Hata: {error}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Kapat
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    return (
      <>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="md"
          PaperProps={{ classes: { root: classes.dialogPaper } }}
        >
          <DialogTitle style={{ padding: "24px 24px 5px", marginBottom: 10 }}>
            {businessName} - {"Kurye Engelleme Listesi"}
          </DialogTitle>
          <DialogContent style={{ overflow: "hidden auto" }}>
            {loading ? (
              <div className={classes.loadingContainer}>
                <CircularProgress />
              </div>
            ) : (
              <>
                {/* Engelli Kuryeler */}
                <div className={classes.tableContainer}>
                  <Typography
                    variant="subtitle1"
                    className={classes.tableTitle}
                  >
                    Engelli Kuryeler
                  </Typography>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">{"Kurye Adı"}</TableCell>
                        <TableCell align="left">{"Telefon"}</TableCell>
                        <TableCell align="center">
                          {"Engellenme Tarihi"}
                        </TableCell>
                        <TableCell align="left">{"Engelleme Sebebi"}</TableCell>
                        <TableCell align="right">{"İşlemler"}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {blockedCouriers.map((blockedCourier) => {
                        const courierDetails = this.getCourierDetails(
                          blockedCourier
                        );
                        return (
                          <TableRow
                            key={blockedCourier.Id}
                            className={classes.blockedRow}
                          >
                            <TableCell align="left">
                              {courierDetails.NameSurname}
                            </TableCell>
                            <TableCell align="left">
                              {courierDetails.Phone}
                            </TableCell>
                            <TableCell align="center">
                              {moment(blockedCourier.BlockedDate).format(
                                "DD.MM.YYYY HH:mm"
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {blockedCourier.BlockingReason || "-"}
                            </TableCell>
                            <TableCell align="right">
                              <Tooltip title="Engeli Kaldır">
                                <IconButton
                                  style={{
                                    backgroundColor: "#3e3e3e",
                                    marginRight: 5,
                                    fontSize: 12,
                                    padding: 8,
                                  }}
                                  onClick={() =>
                                    this.handleToggleBlock(courierDetails, true)
                                  }
                                >
                                  <Icon>lock_open</Icon>
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {blockedCouriers.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={5} align="center">
                            Engelli kurye bulunmamaktadır
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>

                {/* Tüm Kuryeler */}
                <div className={classes.tableContainer}>
                  <Typography
                    variant="subtitle1"
                    className={classes.tableTitle}
                  >
                    Tüm Kuryeler
                  </Typography>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">{"Kurye Adı"}</TableCell>
                        <TableCell align="left">{"Telefon"}</TableCell>
                        <TableCell align="center">{"Durum"}</TableCell>
                        <TableCell align="right">{"İşlemler"}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(courierList) &&
                        courierList
                          .filter(
                            (courier) =>
                              !blockedCouriers.some(
                                (blocked) => blocked.CourierId === courier.Id
                              )
                          )
                          .map((courier) => (
                            <TableRow key={courier.Id}>
                              <TableCell align="left">
                                {courier.NameSurname ||
                                  courier.CourierName ||
                                  "İsimsiz Kurye"}
                              </TableCell>
                              <TableCell align="left">
                                {courier.Phone}
                              </TableCell>
                              <TableCell align="center">{"Aktif"}</TableCell>
                              <TableCell align="right">
                                <Tooltip title="Engelle">
                                  <IconButton
                                    style={{
                                      backgroundColor: "#3e3e3e",
                                      marginRight: 5,
                                      fontSize: 12,
                                      padding: 8,
                                    }}
                                    onClick={() =>
                                      this.handleToggleBlock(courier, false)
                                    }
                                  >
                                    <Icon>block</Icon>
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </div>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              style={{ backgroundColor: "#F44336" }}
              onClick={this.handleClose}
              color="primary"
            >
              {"Çıkış"}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Not Ekleme Dialog */}
        <Dialog
          open={showNoteDialog}
          onClose={this.handleNoteCancel}
          PaperProps={{ classes: { root: classes.dialogPaper } }}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Engelleme Sebebi</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              multiline
              rows={4}
              variant="outlined"
              label="Not"
              fullWidth
              className={classes.noteField}
              value={blockingNote}
              onChange={(e) => this.setState({ blockingNote: e.target.value })}
              placeholder="Engelleme sebebini yazınız..."
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleNoteCancel} color="primary">
              İptal
            </Button>
            <Button
              onClick={this.handleNoteSubmit}
              color="secondary"
              variant="contained"
            >
              Engelle
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  open:
    state.get("blockedCourierReducer").get("blockedCourierVisible") || false,
  businessName:
    state.get("blockedCourierReducer").get("blockedCourierData")
      ?.BusinessName || "",
  brandId:
    state.get("blockedCourierReducer").get("blockedCourierData")?.BrandId || "",
  courierList: state.get("paketYoldaReducer").courierList || [],
  blockedCouriers:
    state
      .get("blockedCourierReducer")
      .get("blockedCouriers")
      ?.toJS() || [],
  loading: state.get("blockedCourierReducer").get("loading"),
  error: state.get("blockedCourierReducer").get("error"),
});

const mapDispatchToProps = {
  setBlockedCourierVisible,
  getCourierListForBlocking,
  getBlockedCouriers,
  setBlockedCourier,
  deleteBlockedCourier,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(BlockedCourier)));
