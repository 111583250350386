import React, { useState } from "react";
import styled from "styled-components";
import SlideFeedbackForm from "./SlideFeedbackForm";
import { FcGoogle } from "react-icons/fc";
import { FaTimes } from "react-icons/fa";

import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaTiktok,
  FaWhatsapp,
  FaStar,
  FaCommentAlt,
} from "react-icons/fa";

const MenuOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 998;
`;

const MenuContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 2rem;
  transform: translateY(${({ isOpen }) => (isOpen ? "0" : "100%")});
  transition: transform 0.3s ease;
  z-index: 999;
`;

const MenuHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #f0f0f0;
`;

const Logo = styled.img`
  height: 2.5rem;
`;

const RatingSection = styled.div`
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
`;

const RatingText = styled.p`
  text-align: center;
  margin: 1rem 0;
  color: #333;
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
`;

const RatingStars = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin: 1rem 0;
  color: #ffd700;
  font-size: 1.5rem;
`;

const RatingButton = styled.button`
  width: 144px;
  height: 50px;
  padding: 0.6rem 1.2rem;
  border-radius: 5px;
  border: none;
  background-color: #f1f1f1;
  margin: 1rem auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 13px;
  line-height: 17px;
  font-weight: 400;

  color: #727272 !important;
  transition: background-color 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #e5e5e5;
  }

  svg {
    font-size: 1.2rem;
  }
`;

const FeedbackSection = styled.div`
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
`;

const Button = styled.button`
  width: 144px;
  height: 50px;
  padding: 0.75rem;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #f8f8f8;
  margin: 0.5rem 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 13px;
  line-height: 17px;
  font-weight: 400;
  color: #727272 !important;

  &:hover {
    background-color: #f0f0f0;
  }

  svg {
    font-size: 1.2rem;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 9px;

  flex-wrap: wrap;
`;

const SocialIcon = styled.a`
  width: 42px;
  height: 42px;
  background-color: #e31e52;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
  }

  svg {
    font-size: 1.3rem;
  }

  &.facebook,
  &.twitter,
  &.instagram,
  &.youtube,
  &.tiktok,
  &.whatsapp {
    background-color: #e31e52;
  }
`;

const RatingModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 2rem;
  border-radius: 12px;
  z-index: 1000;
  width: 90%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 24px;
  font-weight: 300px;
  color: #727272;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const ModalButton = styled.button`
  padding: 0.8rem 1.5rem;
  margin: 0.5rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s ease;

  &.primary {
    background: #4285f4;
    color: white;
    &:hover {
      background: #3367d6;
    }
  }

  &.secondary {
    background: #f1f1f1;
    color: #333;
    &:hover {
      background: #e5e5e5;
    }
  }
`;

const SlideMenu = ({ menudata, isOpen, onClose }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleFeedbackClick = () => {
    onClose();
    setIsFormOpen(true);
  };

  const handleOpenReview = () => {
    window.open(menudata.GoogleReview, "_blank");
  };

  return (
    <>
      <MenuOverlay isOpen={isOpen} onClick={onClose} />

      <MenuContainer isOpen={isOpen}>
        <CloseButton onClick={onClose}>
          <FaTimes />
        </CloseButton>
        {menudata.Logo && (
          <MenuHeader>
            <Logo
              style={{ height: "4rem" }}
              src={menudata?.QrmenuLogo?.ImageUrl}
              alt="Logo"
            />
          </MenuHeader>
        )}

        {menudata.GoogleReview && (
          <RatingSection>
            <RatingText>
              İşletmemiz Hakkında Google Üzerinden
              <br /> Bizi Puanlamak İster misiniz?
            </RatingText>

            <RatingStars>
              {Array(5)
                .fill()
                .map((_, index) => (
                  <FaStar key={index} />
                ))}
            </RatingStars>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <RatingButton onClick={handleOpenReview}>
                <FcGoogle />
                Puan Ver
              </RatingButton>
            </div>
          </RatingSection>
        )}

        {menudata.DestinationAddress && (
          <FeedbackSection>
            <RatingText>
              İşletmemiz Hakkında Geri Bildirimde <br />
              Bulunabilirsiniz
            </RatingText>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{ color: "#424242" }}
                onClick={handleFeedbackClick}
              >
                <FaCommentAlt />
                Geri Bildirim
              </Button>
            </div>
          </FeedbackSection>
        )}

        <div>
          <RatingText>Bizi Takip Edin</RatingText>
          <SocialLinks>
            {menudata.Facebook && (
              <SocialIcon
                href={menudata.Facebook}
                target="_blank"
                className="facebook"
              >
                <FaFacebookF />
              </SocialIcon>
            )}
            {menudata.Twitter && (
              <SocialIcon
                href={menudata.Twitter}
                target="_blank"
                className="twitter"
              >
                <FaTwitter />
              </SocialIcon>
            )}
            {menudata.Instagram && (
              <SocialIcon
                href={menudata.Instagram}
                target="_blank"
                className="instagram"
              >
                <FaInstagram />
              </SocialIcon>
            )}
            {menudata.Youtube && (
              <SocialIcon
                href={menudata.Youtube}
                target="_blank"
                className="youtube"
              >
                <FaYoutube />
              </SocialIcon>
            )}
            {menudata.Tiktok && (
              <SocialIcon
                href={menudata.Tiktok}
                target="_blank"
                className="tiktok"
              >
                <FaTiktok />
              </SocialIcon>
            )}
            {menudata.Whatsapp && (
              <SocialIcon
                href={`https://wa.me/${menudata.Whatsapp}`}
                target="_blank"
                className="whatsapp"
              >
                <FaWhatsapp />
              </SocialIcon>
            )}
          </SocialLinks>
        </div>
      </MenuContainer>

      <SlideFeedbackForm
        isOpen={isFormOpen}
        menudata={menudata}
        onClose={() => setIsFormOpen(false)}
      />
    </>
  );
};

export default SlideMenu;
