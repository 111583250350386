import React from "react";
import { createSelector } from "reselect";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage, injectIntl } from "react-intl";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import styles from "./track-style-jss";
import Button from "@material-ui/core/Button";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Input from "@material-ui/core/Input";
import Icon from "@material-ui/core/Icon";
import BarcodeReader from "react-barcode-reader";
import NumPad from "react-numpad";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  setPaketYoldaCourierVisible,
  setPaketYoldaOrderStatusVisible,
  setModalPaketYolda,
  SetOrderStatus,
  paketYoldaOrderStatusLoading,
  setPaketYoldaLocationVisible,
  setCourierInfoRemove,
} from "../../../redux/modules/paketyolda";
import { getPackageAdditionDetail } from "../../../redux/modules/addition";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";
import PaketYoldaCourierMap from "./Maps/PaketYoldaCourierMap";
import IconButton from "@material-ui/core/IconButton";

class PaketYoldaCourierList extends React.Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {}

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.paketYoldaCourierListUpdate ==
      this.props.paketYoldaCourierListUpdate
    ) {
      return false;
    }
    return true;
  }

  renderOrderStatusResponse(data) {
    return data.map((n, i) => {
      if (!n.IsStatus) {
        return (
          <TableRow key={i}>
            <TableCell
              align="left"
              colSpan={2}
              style={{ color: "#e74c3c", fontWeight: 500 }}
            >
              {n.ResponseData}
            </TableCell>
          </TableRow>
        );
      }
      return (
        <TableRow key={i}>
          <TableCell align="left">
            <Icon style={{ color: n.IsStatus ? "#16a085" : "white" }}>
              {n.IsStatus ? "check_box" : "check_box_outline_blank"}
            </Icon>
          </TableCell>
          <TableCell align="left">{n.ResponseData}</TableCell>
        </TableRow>
      );
    });
  }

  async clickPackageStatus() {
    const { paketYoldaOrderList, paketYoldaOrderCourierSelected } = this.props;
    await this.props.paketYoldaOrderStatusLoading(true);
    this.props
      .SetOrderStatus(paketYoldaOrderList, paketYoldaOrderCourierSelected, 1)
      .then(() => {});
    await this.props.paketYoldaOrderStatusLoading(false);
  }

  renderOrderStatus() {
    const {
      paketYoldaOrderStatusLoading,
      classes,
      paketYoldaOrderCourierSelected,
      paketYoldaOrderList,
      paketYoldaOrderStatusData,
      paketYoldaOrderStatusVisible,
    } = this.props;

    if (paketYoldaOrderCourierSelected == null) {
      return;
    }

    return (
      <Dialog
        open={paketYoldaOrderStatusVisible}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disabled={true}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ padding: "24px 24px 5px", marginBottom: 10 }}
        >
          {"Kuryeye Paket Atama"}
        </DialogTitle>
        <DialogContent>
          {paketYoldaOrderStatusData.length == 0 ? (
            <DialogContentText id="alert-dialog-description">
              {"Toplam " +
                paketYoldaOrderList.filter((x) => x.IsSelected == true).length +
                " adet sipariş " +
                paketYoldaOrderCourierSelected.CourierName +
                " isimli kuryeye atanacaktır. Onaylıyormusunuz!"}
            </DialogContentText>
          ) : (
            <div className={classes.rootTable}>
              <Table
                className={classNames(classes.table, classes.bordered)}
                style={{ marginTop: 0 }}
              >
                <TableHead>
                  <TableRow>
                    {paketYoldaOrderStatusData.some((x) => x.IsStatus) ? (
                      <>
                        <TableCell align="left">{"Durum"}</TableCell>
                        <TableCell align="left">{"İşlem Sonucu"}</TableCell>
                      </>
                    ) : (
                      <TableCell align="left">{"İşlem Sonucu"}</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.renderOrderStatusResponse(paketYoldaOrderStatusData)}
                </TableBody>
              </Table>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!paketYoldaOrderStatusLoading}
            onClick={() => this.clickPackageStatus()}
            color="secondary"
            variant="contained"
            autoFocus
            style={{
              display: paketYoldaOrderStatusData.length > 0 ? "none" : "",
            }}
          >
            {"Tamam"}
          </Button>
          <Button
            style={{
              backgroundColor:
                paketYoldaOrderStatusData.length == 0 ? "#e74c3c" : "#00BFA5",
              color: "white",
            }}
            color="primary"
            onClick={() =>
              this.props
                .setPaketYoldaOrderStatusVisible(false, null)
                .then(() => {})
            }
          >
            {paketYoldaOrderStatusData.length == 0 ? "İptal" : "Tamam"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  async clickOrderStatusModalOpen(n) {
    const { paketYoldaOrderList } = this.props;
    if (paketYoldaOrderList.filter((x) => x.IsSelected == true).length == 0) {
      await this.props
        .setModalPaketYolda("Lütfen aktarmak için paket seçimi yapınız!")
        .then(() => {});
      return;
    }
    this.props.setPaketYoldaOrderStatusVisible(true, n).then(() => {});
  }

  compare(a, b) {
    const genreA = a.CourierName;
    const genreB = b.CourierName;

    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
  }

  handleClickPopover = (event) => {
    this.props.getPackageAdditionDetail(
      event.currentTarget.id,
      event.currentTarget
    );
  };

  renderOrderData() {
    const { paketYoldaLocationData, paketYoldaLocationVisible } = this.props;
    return (
      <Dialog
        open={paketYoldaLocationVisible}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disabled={true}
        maxWidth={"lg"}
      >
        <DialogContent style={{ height: "100vh" }}>
          <PaketYoldaCourierMap orderLocation={paketYoldaLocationData} />
          {paketYoldaLocationData.map((n, i) => {
            return (
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  backgroundColor: "#424242",
                  height: 120,
                  marginBottom: 5,
                }}
              >
                <div
                  style={{
                    backgroundColor: "rgba(22, 160, 133,1.0)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: 150,
                  }}
                >
                  <span
                    style={{
                      textAlign: "center",
                      display: "grid",
                      lineHeight: 1,
                      width: "100%",
                      fontSize: 30,
                      color: "white",
                      fontWeight: 600,
                    }}
                  >
                    {n.AdditionOrder}
                    <span
                      style={{
                        width: "100%",
                        fontSize: 16,
                        color: "white",
                        fontWeight: 500,
                      }}
                    >
                      {"#"}
                      {n.AdditionId}
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                    fontSize: 15,
                    position: "absolute",
                    left: 160,
                    top: 5,
                  }}
                >
                  <Tooltip title={"PAKET SERVİS"}>
                    <Icon
                      style={{ marginRight: 6, color: "white", fontSize: 20 }}
                    >
                      {"moped"}
                    </Icon>
                  </Tooltip>
                  {n.CustomerName}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                    fontSize: 15,
                    position: "absolute",
                    right: 60,
                    top: 5,
                  }}
                >
                  {n.CourierName}
                </div>
                <div
                  style={{
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 4,
                    right: 50,
                    alignItems: "center",
                    color: "white",
                    fontSize: 10,
                    position: "absolute",
                    left: 160,
                    top: 30,
                  }}
                >
                  <Icon
                    style={{ marginRight: 12, color: "white", fontSize: 15 }}
                  >
                    {"location_on"}
                  </Icon>
                  {n.AdresssText} {n.AdresssDesc}
                </div>
                <div
                  style={{
                    display: "flex",
                    fontSize: 18,
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    left: 150,
                    height: 25,
                  }}
                >
                  <div
                    style={{
                      fontWeight: 600,
                      fontSize: 15,
                      width: "50%",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {n.PackageStatus == "0" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#7f8c8d",
                          color: "white",
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: 12,
                          width: "100%",
                        }}
                      >
                        {"Beklemede"}
                      </div>
                    ) : n.PackageStatus == "1" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#27ae60",
                          color: "white",
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: 12,
                          width: "100%",
                        }}
                      >
                        {"Onaylandı"}
                      </div>
                    ) : n.PackageStatus == "2" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#8e44ad",
                          color: "white",
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: 12,
                          width: "100%",
                        }}
                      >
                        {"Hazırlandı"}
                      </div>
                    ) : n.PackageStatus == "3" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#FFB300",
                          color: "white",
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: 12,
                          width: "100%",
                        }}
                      >
                        {"Yola Çıktı"}
                      </div>
                    ) : n.PackageStatus == "4" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#3498db",
                          color: "white",
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: 12,
                          width: "100%",
                        }}
                      >
                        {"Teslim Edildi"}
                      </div>
                    ) : n.PackageStatus == "5" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#c0392b",
                          color: "white",
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: 12,
                          width: "100%",
                        }}
                      >
                        {"İptal Edildi"}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    style={{
                      zIndex: 1,
                      fontWeight: 600,
                      fontSize: 12,
                      width: "50%",
                      justifyContent: "center",
                      backgroundColor: "white",
                      color: "#313131",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {n.TotalPrice.toFixed(2)}
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "rgb(66, 66, 66)",
                    flexDirection: "column",
                    display: "flex",
                    fontSize: 18,
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    top: 0,
                    width: 50,
                    padding: 1,
                  }}
                >
                  <Button
                    id={n.Id}
                    onClick={this.handleClickPopover}
                    variant="outlined"
                    style={{
                      minWidth: "100%",
                      height: "33.33%",
                      backgroundColor: "rgb(41, 41, 41)",
                      borderRadius: 1,
                      borderWidth: 0,
                      marginBottom: 1,
                    }}
                  >
                    <Icon style={{ fontSize: 20 }}>{"description"}</Icon>
                  </Button>
                </div>
              </div>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#e74c3c", color: "white" }}
            color="primary"
            onClick={() =>
              this.props.setPaketYoldaLocationVisible(false, "").then(() => {})
            }
          >
            {"Çıkış"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const {
      paketYoldaCourierListLoading,
      classes,
      paketYoldaCourierList,
      paketYoldaCourierListVisible,
    } = this.props;
    return (
      <Dialog
        open={paketYoldaCourierListVisible}
        aria-labelledby="simple-dialog-title"
        maxWidth="md"
        fullWidth={true}
      >
        {this.renderOrderStatus()}
        {this.renderOrderData()}
        <DialogTitle
          style={{ padding: "20px 30px 10px", marginBottom: 5 }}
          id="simple-dialog-title"
        >
          <div style={{ alignItems: "center", display: "flex" }}>
            <span style={{ marginLeft: 5 }}>{"Kurye Seçimi ve Detayları"}</span>
          </div>
        </DialogTitle>
        <DialogContent style={{ height: "100vh", padding: "5px 24px 0px" }}>
          {paketYoldaCourierListLoading ? (
            <div
              style={{ width: "100%", textAlignLast: "center", paddingTop: 5 }}
            >
              <CircularProgress
                style={{ color: "#fafafa" }}
                thickness={4}
                className={classes.progress}
              />
            </div>
          ) : null}
          <Grid container spacing={1}>
            <Grid item md={6}>
              <div
                style={{
                  color: "#e74c3c",
                  fontSize: 12,
                  textAlign: "center",
                  width: "100%",
                  padding: 6,
                  border: "1px solid rgb(62, 62, 62)",
                  marginBottom: 10,
                  borderRadius: 5,
                }}
              >
                {"Çalışmayanlar"}
              </div>
              {paketYoldaCourierList
                .filter((x) => x.IsWorking == false)
                .sort(this.compare)
                .map((n, i) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 8,
                      }}
                    >
                      <Tooltip title={"Kurye'nin siparişleri"}>
                        <IconButton
                          style={{
                            backgroundColor: "#3e3e3e",
                            marginRight: 5,
                            fontSize: 12,
                            padding: 5,
                            borderRadius: 5,
                          }}
                          onClick={() =>
                            this.props
                              .setPaketYoldaLocationVisible(true, n.CourierId)
                              .then(() => {})
                          }
                        >
                          <Icon style={{ color: "white" }}>{"list_alt"}</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={!n.IsWorking ? "Kurye Çalışmıyor" : ""}>
                        <a
                          onClick={() => this.clickOrderStatusModalOpen(n)}
                          style={{
                            width: "100%",
                            display: "flex",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              borderRadius: 5,
                              width: "100%",
                              backgroundColor: !n.IsWorking
                                ? "rgba(231, 76, 60,1.0)"
                                : n.InstantPacketCount == 0
                                  ? "#424242"
                                  : n.InstantPacketCount > 0 &&
                                    n.IsInDelivery == false
                                    ? "#40739e"
                                    : "rgba(142, 68, 173,1.0)",
                              position: "relative",
                              height: 30,
                              color: "white",
                              padding: 5,
                            }}
                          >
                            <div
                              style={{
                                fontSize: 13,
                                fontWeight: 400,
                                color: "white",
                              }}
                            >
                              {n.CourierName}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: 20,
                                fontWeight: 600,
                                color: "white",
                              }}
                            >
                              <span style={{ fontSize: 10, marginRight: 10 }}>
                                {"(Son Konum: "}{" "}
                                {moment(n.UpdateTime).format("HH:mm")}
                                {")"}
                              </span>
                              {n.InstantPacketCount}
                            </div>
                          </div>
                        </a>
                      </Tooltip>
                      <Tooltip title={"Kurye Atama listesinden kaldır"}>
                        <IconButton
                          style={{
                            backgroundColor: "#3e3e3e",
                            marginLeft: 5,
                            fontSize: 12,
                            padding: 5,
                            borderRadius: 5,
                          }}
                          onClick={() =>
                            confirm(
                              "Kuryeyi listeden kaldırmak istediğinize emin misiniz?"
                            )
                              ? this.props
                                  .setCourierInfoRemove(n.CourierId)
                                  .then(() => {})
                              : null
                          }
                        >
                          <Icon style={{ color: "white" }}>{"delete"}</Icon>
                        </IconButton>
                      </Tooltip>
                    </div>
                  );
                })}
            </Grid>
            <Grid item md={6}>
              <div
                style={{
                  color: "#16a085",
                  fontSize: 12,
                  textAlign: "center",
                  width: "100%",
                  padding: 6,
                  border: "1px solid rgb(62, 62, 62)",
                  marginBottom: 10,
                  borderRadius: 5,
                }}
              >
                {"Çalışanlar"}
              </div>
              {paketYoldaCourierList
                .filter((x) => x.IsWorking == true)
                .sort(this.compare)
                .map((n, i) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 8,
                      }}
                    >
                      <Tooltip title={"Kurye'nin siparişleri"}>
                        <IconButton
                          style={{
                            backgroundColor: "#3e3e3e",
                            marginRight: 5,
                            fontSize: 12,
                            padding: 5,
                            borderRadius: 5,
                          }}
                          onClick={() =>
                            this.props
                              .setPaketYoldaLocationVisible(true, n.CourierId)
                              .then(() => {})
                          }
                        >
                          <Icon style={{ color: "white" }}>
                            {"inventory_2"}
                          </Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={!n.IsWorking ? "Kurye Çalışmıyor" : ""}>
                        <a
                          onClick={() => this.clickOrderStatusModalOpen(n)}
                          style={{
                            width: "100%",
                            display: "flex",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              borderRadius: 5,
                              width: "100%",
                              backgroundColor: !n.IsWorking
                                ? "rgba(231, 76, 60,1.0)"
                                : n.InstantPacketCount == 0
                                  ? "#424242"
                                  : n.InstantPacketCount > 0 &&
                                    n.IsInDelivery == false
                                    ? "#40739e"
                                    : "rgba(142, 68, 173,1.0)",
                              position: "relative",
                              height: 30,
                              color: "white",
                              padding: 5,
                            }}
                          >
                            <div
                              style={{
                                fontSize: 13,
                                fontWeight: 400,
                                color: "white",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {!n.IsRegionAdd ? (
                                <Tooltip
                                  title={
                                    "Kurye otomatik atama için herhangi bölgede ekli değildir."
                                  }
                                >
                                  <Icon
                                    style={{ marginRight: 5, color: "white" }}
                                  >
                                    {"south_america"}
                                  </Icon>
                                </Tooltip>
                              ) : (
                                ""
                              )}
                              {n.CourierName}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: 20,
                                fontWeight: 600,
                                color: "white",
                              }}
                            >
                              <span style={{ fontSize: 10, marginRight: 10 }}>
                                {"(Son Konum: "}{" "}
                                {moment(n.UpdateTime).format("HH:mm")}
                                {")"}
                              </span>
                              {n.InstantPacketCount}
                            </div>
                          </div>
                        </a>
                      </Tooltip>
                    </div>
                  );
                })}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: "#e74c3c",
              color: "white",
              marginRight: 5,
            }}
            onClick={() =>
              this.props.setPaketYoldaCourierVisible(false).then(() => {})
            }
            color="primary"
            autoFocus
          >
            {"Çıkış"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const valueSelector = (state, props) =>
  state.get("paketYoldaReducer").paketYoldaCourierListUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
  paketYoldaCourierListUpdate: value(state, props),
  paketYoldaCourierListVisible: state.get("paketYoldaReducer")
    .paketYoldaCourierListVisible,
  paketYoldaCourierList: state.get("paketYoldaReducer").paketYoldaCourierList,
  paketYoldaCourierListLoading: state.get("paketYoldaReducer")
    .paketYoldaCourierListLoading,
  paketYoldaOrderList: state.get("paketYoldaReducer").paketYoldaOrderList,
  paketYoldaOrderStatusVisible: state.get("paketYoldaReducer")
    .paketYoldaOrderStatusVisible,
  paketYoldaOrderStatusLoading: state.get("paketYoldaReducer")
    .paketYoldaOrderStatusLoading,
  paketYoldaOrderStatusData: state.get("paketYoldaReducer")
    .paketYoldaOrderStatusData,
  paketYoldaOrderCourierSelected: state.get("paketYoldaReducer")
    .paketYoldaOrderCourierSelected,

  paketYoldaCourierTrack: state.get("paketYoldaReducer").paketYoldaCourierTrack,
  paketYoldaLocationVisible: state.get("paketYoldaReducer")
    .paketYoldaLocationVisible,
  paketYoldaLocationData: state.get("paketYoldaReducer").paketYoldaLocationData,
});

const mapDispatchToProps = {
  setPaketYoldaCourierVisible,
  SetOrderStatus,
  setPaketYoldaOrderStatusVisible,
  setModalPaketYolda,
  paketYoldaOrderStatusLoading,
  setPaketYoldaLocationVisible,
  getPackageAdditionDetail,
  setCourierInfoRemove,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(PaketYoldaCourierList)));
