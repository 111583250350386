import { fromJS } from "immutable";
import axios from "axios";
import brand from "dan-api/dummy/brand";
import base64 from "base-64";

// Action Types
const QR_MENU_START = "qr_menu_start";
const QR_MENU_SUCCESS = "qr_menu_success";
const QR_MENU_ERROR = "qr_menu_error";
const SET_CATEGORIES = "set_categories";
const SET_PRODUCTS = "set_products";
const SET_MENU_DATA = "set_menu_data";

// Initial State
const initialState = fromJS({
  menuData: {},
  categoryList: [],
  productList: [],
  loading: false,
  error: null,
});

// Actions
export function getQrMenu(qrId) {
  return async (dispatch) => {
    dispatch({ type: QR_MENU_START });

    try {
      const response = await axios({
        method: "GET",
        url: `${brand.urlSocket}QrMenu/${qrId}`,
        headers: {
          Authorization: "Basic " + base64.encode("JarvisPos:Shark123*"),
        },
      });

      if (response.data.isStatus) {
        const parsedData = JSON.parse(response.data.responseData);
        dispatch({
          type: SET_MENU_DATA,
          payload: parsedData || {},
        });
      } else {
        dispatch({
          type: QR_MENU_ERROR,
          payload: "Veri alınamadı",
        });
      }
    } catch (error) {
      dispatch({
        type: QR_MENU_ERROR,
        payload: error.message,
      });
    }
  };
}

export function getQrMenuCategories(qrId) {
  return async (dispatch) => {
    dispatch({ type: QR_MENU_START });

    try {
      const response = await axios({
        method: "GET",
        url: `${brand.urlSocket}QrMenuCategory/${qrId}`,
        headers: {
          Authorization: "Basic " + base64.encode("JarvisPos:Shark123*"),
        },
      });

      if (response.data.isStatus) {
        const parsedData = JSON.parse(response.data.responseData);
        dispatch({
          type: SET_CATEGORIES,
          payload: parsedData.categoryList || [],
        });
      } else {
        dispatch({
          type: QR_MENU_ERROR,
          payload: "Kategoriler alınamadı",
        });
      }
    } catch (error) {
      dispatch({
        type: QR_MENU_ERROR,
        payload: error.message,
      });
    }
  };
}

export function getQrMenuProducts(qrId, categoryId) {
  return async (dispatch) => {
    dispatch({ type: QR_MENU_START });

    try {
      const response = await axios({
        method: "GET",
        url: `${brand.urlSocket}QrMenuProduct/${qrId}/${categoryId}`,
        headers: {
          Authorization: "Basic " + base64.encode("JarvisPos:Shark123*"),
        },
      });

      if (response.data.isStatus) {
        const parsedData = JSON.parse(response.data.responseData);
        dispatch({
          type: SET_PRODUCTS,
          payload: parsedData.productList || [],
        });
      } else {
        dispatch({
          type: QR_MENU_ERROR,
          payload: "Ürünler alınamadı",
        });
      }
    } catch (error) {
      dispatch({
        type: QR_MENU_ERROR,
        payload: error.message,
      });
    }
  };
}

export function getProductDetail(qrId, productId) {
  return async (dispatch) => {
    dispatch({ type: QR_MENU_START });

    try {
      const response = await axios({
        method: "GET",
        url: `${brand.urlSocket}ProductDetail/${qrId}/${productId}`,
        headers: {
          Authorization: "Basic " + base64.encode("JarvisPos:Shark123*"),
        },
      });

      if (response.data.isStatus) {
        const parsedData = JSON.parse(response.data.responseData);
        dispatch({
          type: SET_MENU_DATA,
          payload: parsedData || {},
        });
      } else {
        dispatch({
          type: QR_MENU_ERROR,
          payload: "Ürün detayı alınamadı",
        });
      }
    } catch (error) {
      dispatch({
        type: QR_MENU_ERROR,
        payload: error.message,
      });
    }
  };
}

// Reducer
const QrMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case QR_MENU_START:
      return state.merge({
        loading: true,
        error: null,
      });

    case QR_MENU_SUCCESS:
      return state.merge({
        loading: false,
        error: null,
        menuData: action.payload,
      });

    case QR_MENU_ERROR:
      return state.merge({
        loading: false,
        error: action.payload,
      });

    case SET_CATEGORIES:
      return state.merge({
        loading: false,
        error: null,
        categoryList: action.payload,
      });

    case SET_PRODUCTS:
      return state.merge({
        loading: false,
        error: null,
        productList: action.payload,
      });

    case SET_MENU_DATA:
      return state.merge({
        loading: false,
        error: null,
        menuData: action.payload,
      });

    default:
      return state;
  }
};

export default QrMenuReducer;
