import axios from "axios";
import base64 from "base-64";
import brand from "dan-api/dummy/brand";

// Action Types
const SEND_EMAIL_START = "send_email_start";
const SEND_EMAIL_SUCCESS = "send_email_success";
const SEND_EMAIL_ERROR = "send_email_error";

// Initial State
const initialState = {
  loading: false,
  error: null,
  success: false,
};

// Actions
export async function sendFeedbackEmail(formData) {
  return async (dispatch) => {
    dispatch({ type: SEND_EMAIL_START });

    const emailData = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      message: formData.message,
    };

    try {
      const response = await axios({
        method: "POST",
        url: brand.urlSocket + "api/Email/SendEmailForm",
        dataType: "json",
        headers: {
          Authorization: "Basic " + base64.encode("JarvisPos:Shark123*"),
          "Content-Type": "application/json",
        },
        data: emailData,
      });

      if (response.data.isStatus) {
        dispatch({
          type: SEND_EMAIL_SUCCESS,
          payload: response.data,
        });
        return true;
      } else {
        dispatch({
          type: SEND_EMAIL_ERROR,
          payload: "Email gönderilemedi",
        });
        return false;
      }
    } catch (error) {
      dispatch({
        type: SEND_EMAIL_ERROR,
        payload: error.message,
      });
      return false;
    }
  };
}

// Reducer
const emailReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_EMAIL_START:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      };

    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
      };

    case SEND_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };

    default:
      return state;
  }
};

export default emailReducer;
