import React from 'react';
import {
    createSelector
} from 'reselect';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../Settings/settings-jss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import classNames from 'classnames';
import Icon from '@material-ui/core/Icon';
import { EmptyData } from 'dan-components';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import {
    setProductPropertyListVisible, formDataSetStock, getStockPropertyDetail, setStockProperty, removeStockProperty,
    setSaveImageProduct, setModalStock
} from '../../../../redux/modules/stock';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import messageStyles from 'dan-styles/Messages.scss';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DatePicker from "react-datepicker";
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { CapitalizeText } from '../../../../function/GeneralFunction';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { PapperBlock } from 'dan-components';
import cookie from 'react-cookies';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import NumberFormat from 'react-number-format';
const uuidv4 = require('uuid/v4');
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import AlertDialog from '../../Ortak/AlertDialog';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function renderSuggestionsContainer(options) {
    const { containerProps, children } = options;

    return (
        <Paper {...containerProps} square>
            {children}
        </Paper>
    );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map((part, index) => (
                    part.highlight ? (
                        <span key={String(index)} style={{ fontWeight: 300 }}>
                            {part.text}
                        </span>
                    ) : (
                        <strong key={String(index)} style={{ fontWeight: 500 }}>
                            {part.text}
                        </strong>
                    )
                ))}
            </div>
        </MenuItem>
    );
}

function renderInput(inputProps) {
    const { classes, ref, ...other } = inputProps;

    return (
        <TextField
            fullWidth
            style={{ width: 500 }}
            InputProps={{
                inputRef: ref,
                classes: {
                    input: classes.input,
                },
                ...other,
            }}
        />
    );
}

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalScale={2}
            fixedDecimalScale={true}
            autocomplete="off"
            allowNegative={false}
            decimalSeparator=","
            thousandSeparator="."
            prefix="₺"
        />
    );
}

class ProductPropertyAddList extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {

    }

    state = {
        value: '',
        suggestions: [],
    };

    compareEntegrationProductList(a, b) {
        const genreA = a.Name;
        const genreB = b.Name;

        let comparison = 0;
        if (genreA > genreB) {
            comparison = 1;
        } else if (genreA < genreB) {
            comparison = -1;
        }
        return comparison;
    }

    handleClickOpenMenu = async () => {
        await this.props.formDataSetStock("menu", { "Id": "", "Name": "", "Order": 1, "Item": [], IsDelete: false, "MenuType": 1, "IsCompulsory": true }).then(() => { })
        this.props.formDataSetStock("openMenu", true).then(() => { })
    }

    handleClickOpenProperty = async () => {
        await this.props.formDataSetStock("property", { "Id": "", "Name": "", "Order": 1, "Type": 1, "Item": [], IsDelete: false, "MenuType": 0, "Max": 0 }).then(() => { })
        this.props.formDataSetStock("openProperty", true).then(() => { })
    }

    handleClickEditMenu = (item) => {
        this.props.getStockPropertyDetail(item, "menu", null, true).then(() => { })
    }

    handleClickEditProperty = (item) => {
        this.props.getStockPropertyDetail(item, "property", null, true).then(() => { })
    }

    handleCloseProperty = () => {
        this.props.formDataSetStock("openProperty", false).then(() => { })
    };

    handleChangeProperty = name => event => {
        this.props.stockReducer.property[name] = (name == "Type" ? Number(event.target.value) : CapitalizeText(event.target.value));
        this.props.formDataSetStock("property", this.props.stockReducer.property).then(() => { })
    };

    handleCloseMenu = () => {
        this.props.formDataSetStock("openMenu", false).then(() => { })
    }

    handleChangeMenu = name => event => {
        if (name == "Max") 
            this.props.stockReducer.menu[name] = event.target.checked ? 2 : 1;
        else
            this.props.stockReducer.menu[name] = (name == "IsCompulsory" ? event.target.checked : CapitalizeText(event.target.value));

        this.props.formDataSetStock("menu", this.props.stockReducer.menu).then(() => { })
    };

    PropertyItemAdd(propertyId) {
        const { property, propertyitem } = this.props.stockReducer;
        propertyitem.Id = uuidv4();
        propertyitem.PropertyId = propertyId;
        propertyitem.IsList = true;
        property.Item.push(JSON.parse(JSON.stringify(propertyitem)));
        this.props.formDataSetStock("property", property).then(() => { })
    }

    MenuItemAdd(propertyId) {
        const { menu, menuitem } = this.props.stockReducer;
        menuitem.Id = uuidv4();
        menuitem.PropertyId = propertyId;
        menuitem.IsList = true;
        menu.Item.push(JSON.parse(JSON.stringify(menuitem)));
        this.props.formDataSetStock("menu", menu).then(() => { })
    }

    PropertyItemDelete(itemId) {
        const { property } = this.props.stockReducer;
        property.Item.find(x => x.Id == itemId).IsDelete = true;
        this.props.formDataSetStock("property", property).then(() => { })
    }

    MenuItemDelete(itemId) {
        const { menu } = this.props.stockReducer;
        menu.Item.find(x => x.Id == itemId).IsDelete = true;
        this.props.formDataSetStock("menu", menu).then(() => { })
    }

    clickMenuItemModalOpen(MenuId, ItemId) {
        this.props.formDataSetStock("MenuId", MenuId).then(() => { })
        this.props.formDataSetStock("ItemId", ItemId).then(() => { })
        this.props.formDataSetStock("MenuItemSearchText", "").then(() => { })
        this.props.formDataSetStock("MenuItemModalOpen", true).then(() => { })
    }

    handleSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value),
        });
    };

    productAutoComplate(props) {
        const suggestionsApi = [];

        this.props.stockReducer.productListSearch.map((pro, key) => {
            suggestionsApi.push({ label: pro.Name, value: pro.Id });
        });

        return suggestionsApi;
    }

    getSuggestions(value) {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        let count = 0;

        return inputLength === 0 ? [] : this.productAutoComplate().filter(suggestion => {
            //onst keep = count < 10 && suggestion.label.toLowerCase().slice(0, inputLength) === inputValue;
            //console.log("suggestion.label.toLowerCase().indexOf(inputValue)",suggestion.label.toLowerCase().indexOf(inputValue))
            const keep = count < 15 && suggestion.label.toLowerCase().indexOf(inputValue) !== -1;
            if (keep) {
                count += 1;
            }

            return keep;
        });
    }

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    handleChangeMenuSelect = (event, { newValue }) => {
        this.props.formDataSetStock("MenuItemSearchText", newValue).then(() => { })
    };

    renderMenuItemAuto() {
        const { classes } = this.props;
        const { value, suggestions } = this.state;
        return (
            <Autosuggest
                theme={{
                    input: classes.inputText,
                    container: classes.container,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,

                }}
                renderInputComponent={renderInput}
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                renderSuggestionsContainer={renderSuggestionsContainer}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={{
                    classes,
                    placeholder: 'Lütfen Ürün Seçiniz',
                    value: this.props.stockReducer.MenuItemSearchText,
                    onChange: this.handleChangeMenuSelect
                }}
            />
        )
    }

    getSuggestionValue = (suggestion) => {
        this.props.formDataSetStock("MenuItemStockName", suggestion.label).then(() => { })
        this.props.formDataSetStock("MenuItemStockId", suggestion.value).then(() => { })
        return suggestion.label;
    }

    handleConfirmation = () => {
        const { menu } = this.props.stockReducer;
        menu.Item.find(x => x.Id == this.props.stockReducer.ItemId)["StockId"] = this.props.stockReducer.MenuItemStockId;
        menu.Item.find(x => x.Id == this.props.stockReducer.ItemId)["Name"] = this.props.stockReducer.MenuItemStockName;
        this.props.formDataSetStock("menu", menu).then(() => { })
        this.props.formDataSetStock("MenuItemModalOpen", false).then(() => { })
    };

    handleCloseModal = () => {
        this.props.formDataSetStock("MenuItemModalOpen", false).then(() => { })
    };

    renderMenuModal() {
        const { classes } = this.props;
        const { openMenu, menu } = this.props.stockReducer;

        return (
            <Dialog
                open={openMenu}
                onClose={this.handleCloseMenu}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth={'lg'}
            >
                <AlertDialog contentStyle={{ height: '100vh' }} contentTextStyle={{ height: '100%' }} eventConfirmation={this.handleConfirmation} eventClose={this.handleCloseModal} open={this.props.stockReducer.MenuItemModalOpen} title={"Menü Özellik Seçimi"} text={this.renderMenuItemAuto()} />
                <DialogTitle style={{ marginBottom: 0 }} id="form-dialog-title">{"Ürün Menü Grup Bilgileri"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {"Örn : Dürüm Seçimi, Pizza Seçimi, Burger Seçimi vb"}
                    </DialogContentText>
                    <Paper style={{ backgroundColor: '#424242', borderRadius: 5, padding: 5 }} className={classes.root}>
                        <Grid container spacing={1}>
                            <Grid item md={3}>
                                <TextField
                                    autoFocus
                                    margin="none"
                                    defaultValue={menu.Name}
                                    id="menuName"
                                    label="Grup Adı"
                                    onChange={this.handleChangeMenu("Name")}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={3}>
                                <TextField
                                    margin="none"
                                    id="menuOrder"
                                    label="Sıralama"
                                    defaultValue={menu.Order}
                                    type={"number"}
                                    onChange={this.handleChangeMenu("Order")}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={3} style={{ display: 'flex', alignItems: 'center' }}>
                                <FormControlLabel
                                    style={{ marginLeft: 0, marginTop: 13 }}
                                    control={(
                                        <Switch
                                            checked={(menu.Max > 1 ? true : false)}
                                            onChange={this.handleChangeMenu('Max')}
                                            value="Max"
                                        />
                                    )}
                                    label="Çoklu Seçim"
                                />
                            </Grid>
                            <Grid item md={3} style={{ display: 'flex', alignItems: 'center' }}>
                                <FormControlLabel
                                    style={{ marginLeft: 0, marginTop: 13 }}
                                    control={(
                                        <Switch
                                            checked={menu.IsCompulsory}
                                            onChange={this.handleChangeMenu('IsCompulsory')}
                                            value="IsCompulsory"
                                        />
                                    )}
                                    label="Seçim Zorunlu"
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper style={{ backgroundColor: '#424242', borderRadius: 5, padding: 5 }} className={classes.root}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="dense">{"İsim"}</TableCell>
                                    <TableCell align="dense">{"Fiyatı"}</TableCell>
                                    <TableCell align="dense">{"Paket Fiyatı"}</TableCell>
                                    <TableCell align="dense">{"Varsayılan"}</TableCell>
                                    <TableCell align="dense">{"Listede Göster"}</TableCell>
                                    <TableCell align="right">{"İşlem"}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    menu.Item.filter(x => x.IsDelete == false).map((x, i) => {
                                        return (
                                            <TableRow key={x.Id}>
                                                <TableCell padding="dense">
                                                    <Grid container>
                                                        <Grid item md={10}>
                                                            <Input disabled fullWidth placeholder="" id={x.StockId} value={x.Name} />
                                                        </Grid>
                                                        <Grid style={{ paddingLeft: 5, marginTop: 2 }} item md={2}>
                                                            <Button fullWidth variant="contained" style={{ borderRadius: 0, backgroundColor: '#FFA000' }} onClick={() => this.clickMenuItemModalOpen(menu.Id, x.Id)} color="secondary">
                                                                {"Seç"}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell style={{ width: 150 }} padding="dense" align="dense">
                                                    <TextField
                                                        fullWidth
                                                        value={x.Price}
                                                        onFocus={event => { event.target.select() }}
                                                        onChange={this.handleChangeMenuItem("Price", x.Id)}
                                                        id="price_propertyitem"
                                                        InputProps={{
                                                            inputComponent: NumberFormatCustom,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ width: 150 }} padding="dense" align="dense">
                                                    <TextField
                                                        fullWidth
                                                        value={x.PackagePrice}
                                                        onFocus={event => { event.target.select() }}
                                                        onChange={this.handleChangeMenuItem("PackagePrice", x.Id)}
                                                        id="price_propertyitem"
                                                        InputProps={{
                                                            inputComponent: NumberFormatCustom,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell align="dense">
                                                    <FormControlLabel
                                                        style={{ marginLeft: 0 }}
                                                        control={(
                                                            <Switch
                                                                checked={x.Default}
                                                                onChange={this.handleChangeMenuItem("Default", x.Id)}
                                                                value="checkedA"
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell align="dense">
                                                    <FormControlLabel
                                                        style={{ marginLeft: 0 }}
                                                        control={(
                                                            <Switch
                                                                checked={x.IsList}
                                                                onChange={this.handleChangeMenuItem("IsList", x.Id)}
                                                                value="checkedA"
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <IconButton style={{ backgroundColor: '#3e3e3e' }} aria-label="Delete" onClick={() => this.MenuItemDelete(x.Id)}>
                                                        <Icon>{"delete"}</Icon>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={this.handleCloseMenu} color="primary">
                        {"ÇIKIŞ"}
                    </Button>
                    <Button variant="contained" style={{ backgroundColor: '#f39c12' }} onClick={() => this.MenuItemAdd(menu.Id)} color="primary">
                        {"ALT ÖZELLİK EKLE"}
                    </Button>
                    <Button variant="contained" onClick={() => this.props.setStockProperty(menu).then(() => { })} color="secondary">
                        {"KAYDET"}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    handleChangePropertyItem = (name, item_id) => event => {
        const { property } = this.props.stockReducer;
        property.Item.find(x => x.Id == item_id)[name] = (name == "Default" || name == "IsList" ? event.target.checked : (name == "Price" || name == "PackagePrice" ? parseFloat(event.target.value) : CapitalizeText(event.target.value)));
        this.props.formDataSetStock("property", property).then(() => { })
    };

    handleChangeMenuItem = (name, item_id) => event => {
        const { menu } = this.props.stockReducer;
        menu.Item.find(x => x.Id == item_id)[name] = (name == "Default" || name == "IsList" ? event.target.checked : (name == "Price" || name == "PackagePrice" ? parseFloat(event.target.value) : CapitalizeText(event.target.value)));
        this.props.formDataSetStock("menu", menu).then(() => { })
    };

    addDefaultSrc(ev) {
        ev.target.src = 'https://cdn.javipos.com/broken.png';
    }

    onChangeImageFile = event => {
        var Id = event.target.id;
        var dataFile = event.target.files[0];

        const fileExtension = dataFile.name.split(".").at(-1);
        const allowedFileTypes = ["png"];
        if (!allowedFileTypes.includes(fileExtension)) {
            this.props.setModalStock("Sadece .png resim formatı desteklenmektedir.").then(() => { })
            return;
        }

        var filesize = ((dataFile.size / 1024) / 1024).toFixed(4); // MB
        if (filesize > 2) {
            this.props.setModalStock("Görsel dosya boyutu maksimum 2mb olabilir").then(() => { })
            return;
        }
        this.props.setSaveImageProduct(dataFile, Id, 4, 250, 250).then(() => { });
    };

    renderPropertyModal() {
        const { classes } = this.props;
        const { openProperty, property } = this.props.stockReducer;

        return (
            <Dialog
                open={openProperty}
                onClose={this.handleCloseProperty}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth={'lg'}
            >
                <DialogTitle style={{ marginBottom: 0 }} id="form-dialog-title">{"Ürün Özellik Grup Bilgileri"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {"Örn : Malzeme, Sos Seçimi, Ekmek Seçimi vb"}
                    </DialogContentText>
                    <Paper style={{ backgroundColor: '#424242', borderRadius: 5, padding: 5 }} className={classes.root}>
                        <Grid container spacing={1}>
                            <Grid item md={5}>
                                <TextField
                                    autoFocus
                                    margin="none"
                                    defaultValue={property.Name}
                                    id="name"
                                    label="Grup Adı"
                                    onChange={this.handleChangeProperty("Name")}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={2}>
                                <TextField
                                    margin="none"
                                    id="propertyorder"
                                    label="Sıralama"
                                    defaultValue={property.Order}
                                    type={"number"}
                                    onChange={this.handleChangeProperty("Order")}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={3}>
                                <FormControl style={{ marginTop: 15 }} component="fieldset" required className={classes.formControl}>
                                    <RadioGroup
                                        aria-label="type"
                                        name="type1"
                                        style={{ display: 'inline', flexDirection: 'row' }}
                                        value={Number(property.Type)}
                                        onChange={this.handleChangeProperty("Type")}
                                    >
                                        <FormControlLabel value={1} control={<Radio />} label="Tekli Seçim" />
                                        <FormControlLabel value={0} control={<Radio />} label="Çoklu Seçim" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item md={2}>
                                {
                                    (property.Type == 0 ?
                                        <TextField
                                            margin="none"
                                            id="maxselect"
                                            label="Maksimum seçim"
                                            defaultValue={property.Max}
                                            type={"number"}
                                            onChange={this.handleChangeProperty("Max")}
                                            fullWidth
                                        />
                                        : null)
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper style={{ backgroundColor: '#424242', borderRadius: 5, padding: 5 }} className={classes.root}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="left">{"Resim"}</TableCell>
                                    <TableCell padding="left">{"İsim"}</TableCell>
                                    <TableCell align="left">{"Fiyatı"}</TableCell>
                                    <TableCell align="left">{"Varsayılan"}</TableCell>
                                    <TableCell align="left">{"Listede Göster"}</TableCell>
                                    <TableCell align="right">{"İşlem"}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    property.Item.filter(x => x.IsDelete == false).map((x, i) => {
                                        return (
                                            <TableRow key={x.Id}>
                                                <TableCell padding="left">
                                                    <img onError={this.addDefaultSrc} style={{ height: 30, marginRight: 10 }} src={x.ImageUrl} />
                                                    <label htmlFor={x.Id}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            component="span"
                                                            style={{ backgroundColor: 'transparent', display: (x.Name.length == 0 ? 'none' : '') }}
                                                        >{<Icon>{"add_a_photo"}</Icon>}
                                                        </Button>
                                                    </label>
                                                    <Tooltip title="Özellik görsel boyutu 250x250 ve .png formatında olmalıdır!">
                                                        <input id={x.Id} style={{ display: "none" }} type="file" onChange={this.onChangeImageFile} />
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell padding="left">
                                                    <Input fullWidth placeholder="Örn: Ketçap, Susamlı Ekmek, Acısız vb" id="name_propertyitem" value={x.Name} onChange={this.handleChangePropertyItem("Name", x.Id)} />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <TextField
                                                        fullWidth
                                                        defaultValue={x.Price}
                                                        value={x.Price}
                                                        onFocus={event => { event.target.select() }}
                                                        onChange={this.handleChangePropertyItem("Price", x.Id)}
                                                        id="price_propertyitem"
                                                        InputProps={{
                                                            inputComponent: NumberFormatCustom,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <TextField
                                                        fullWidth
                                                        defaultValue={x.PackagePrice}
                                                        value={x.PackagePrice}
                                                        onFocus={event => { event.target.select() }}
                                                        onChange={this.handleChangePropertyItem("PackagePrice", x.Id)}
                                                        id="price_propertyitem"
                                                        InputProps={{
                                                            inputComponent: NumberFormatCustom,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <FormControlLabel
                                                        style={{ marginLeft: 0 }}
                                                        control={(
                                                            <Switch
                                                                checked={x.Default}
                                                                onChange={this.handleChangePropertyItem("Default", x.Id)}
                                                                value="checkedA"
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <FormControlLabel
                                                        style={{ marginLeft: 0 }}
                                                        control={(
                                                            <Switch
                                                                checked={x.IsList}
                                                                onChange={this.handleChangePropertyItem("IsList", x.Id)}
                                                                value="checkedA"
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <IconButton style={{ backgroundColor: '#3e3e3e' }} aria-label="Delete" onClick={() => this.PropertyItemDelete(x.Id)}>
                                                        <Icon>{"delete"}</Icon>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                    )
                                }
                            </TableBody>
                        </Table>
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={this.handleCloseProperty} color="primary">
                        {"ÇIKIŞ"}
                    </Button>
                    <Button variant="contained" style={{ backgroundColor: '#f39c12' }} onClick={() => this.PropertyItemAdd(property.Id)} color="primary">
                        {"ALT ÖZELLİK EKLE"}
                    </Button>
                    <Button variant="contained" onClick={() => this.props.setStockProperty(property).then(() => { })} color="secondary">
                        {"KAYDET"}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    handleClickAddPropertyGroup(item) {
        if (item.MenuType == 0) {
            this.props.getStockPropertyDetail(item, "property", this.props.stockReducer.data, false).then(() => { })
        } else if (item.MenuType == 1) {
            this.props.getStockPropertyDetail(item, "menu", this.props.stockReducer.data, false).then(() => { })
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Dialog
                open={this.props.productPropertyListVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'md'}
            >
                {this.renderMenuModal()}
                {this.renderPropertyModal()}
                <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    {"Ürün Özellik ve Menü Grubu İşlemleri"}
                </DialogTitle>
                <DialogContent style={{ height: '100vh', overflow: 'hidden auto' }}>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container spacing={1}>
                            <Grid item md={6}>
                                <Button fullWidth onClick={() => this.handleClickOpenProperty()} variant="contained" size="medium" color="secondary" style={{ borderRadius: 3, textTransform: 'none' }}>
                                    <Icon style={{ marginRight: 6 }}>{"playlist_add"}</Icon>
                                    {"Ürün Özellik Grubu Ekle"}
                                </Button>
                            </Grid>
                            <Grid item md={6}>
                                <Button fullWidth onClick={() => this.handleClickOpenMenu()} variant="contained" size="medium" color="secondary" style={{ borderRadius: 3, textTransform: 'none' }}>
                                    <Icon style={{ marginRight: 6 }}>{"fastfood"}</Icon>
                                    {"Ürün Menü Grubu Ekle"}
                                </Button>
                            </Grid>
                            <Grid md={12}>
                                <Paper style={{ backgroundColor: '#313131', borderRadius: 5, padding: 5 }} className={classes.root}>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell padding="dense">{"Grup Tipi"}</TableCell>
                                                <TableCell padding="dense">{"İşlem Tipi"}</TableCell>
                                                <TableCell align="dense">{"Adı"}</TableCell>
                                                <TableCell align="dense">{"Sıra"}</TableCell>
                                                <TableCell align="right">{"İşlem"}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.props.productPropertyListData.map((n, i) => {
                                                    return (
                                                        <TableRow hover key={i}>
                                                            <TableCell align="left">{(n.MenuType == 0 ? "Özellik" : "Menü")}</TableCell>
                                                            <TableCell align="left">{(n.MenuType == 0 ? (n.Type == 0 ? "Çoklu Seçim" : "Tekli Seçim") : (n.Max > 1 ? "Çoklu Seçim" : "Tekli Seçim"))}</TableCell>
                                                            <TableCell align="left">{n.Name}</TableCell>
                                                            <TableCell align="left">{n.Order}</TableCell>
                                                            <TableCell align="right">
                                                                {
                                                                    (this.props.stockReducer.productAddModal ?
                                                                        <Tooltip title="Seç">
                                                                            <IconButton style={{ backgroundColor: '#3e3e3e', marginRight: 5 }} onClick={() => this.handleClickAddPropertyGroup(n)}>
                                                                                <Icon>{"pan_tool"}</Icon>
                                                                            </IconButton>
                                                                        </Tooltip> :
                                                                        <div>
                                                                            <Tooltip title="Düzenle">
                                                                                <IconButton style={{ backgroundColor: '#3e3e3e', marginRight: 5 }} onClick={() => (n.MenuType == 0 ? this.handleClickEditProperty(n) : this.handleClickEditMenu(n))}>
                                                                                    <Icon>{"edit"}</Icon>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <Tooltip title="Sil">
                                                                                <IconButton style={{ backgroundColor: '#3e3e3e', marginRight: 5 }} onClick={() => this.props.removeStockProperty(n.Id, this.props.productPropertyListData).then(() => { })}>
                                                                                    <Icon>{"delete_outline"}</Icon>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </div>
                                                                    )
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setProductPropertyListVisible(false, "").then(() => { })} color="primary">
                        {"Çıkış"}
                    </Button>
                </DialogActions>
            </Dialog >
        );
    }
}

const valueSelector = (state, props) => state.get('stockReducer').productPropertyListUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    productPropertyListUpdate: value(state, props),
    productPropertyListVisible: state.get('stockReducer').productPropertyListVisible,
    productPropertyListLoading: state.get('stockReducer').productPropertyListLoading,
    productPropertyListData: state.get('stockReducer').productPropertyListData,

    stockReducer: state.get('stockReducer').form_data,
})

const mapDispatchToProps = {
    setProductPropertyListVisible,
    formDataSetStock,
    getStockPropertyDetail,
    setStockProperty,
    removeStockProperty,
    setSaveImageProduct,
    setModalStock
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(ProductPropertyAddList)));