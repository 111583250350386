import React, { useState } from "react";
import styled from "styled-components";
import { FaUser, FaPhone, FaEnvelope } from "react-icons/fa";
import axios from "axios";
import base64 from "base-64";
import logo from "dan-images/labada-logo.png";
import brand from "dan-api/dummy/brand";
import SuccessMessage from "./SuccessMessage";

const FormOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 998;
`;

const FormContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 2rem;
  transform: translateY(${({ isOpen }) => (isOpen ? "0" : "100%")});
  transition: transform 0.3s ease;
  z-index: 999;
`;

const Logo = styled.img`
  height: 2.5rem;
  margin: 0 auto 1.5rem;
  display: block;
`;

const FormText = styled.p`
  text-align: center;
  color: #333;
  font-size: 0.9rem;
  margin-bottom: 2rem;
  line-height: 1.4;
`;

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;

  svg {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    font-size: 1.1rem;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 12px 12px 40px;
  border: 1px solid ${(props) => (props.error ? "#e31e52" : "#eee")};
  border-radius: 8px;
  font-size: 0.9rem;
  outline: none;
  transition: border-color 0.2s;

  &::placeholder {
    color: #999;
  }

  &:focus {
    border-color: ${(props) => (props.error ? "#e31e52" : "#0066ff")};
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  border: 1px solid ${(props) => (props.error ? "#e31e52" : "#eee")};
  border-radius: 8px;
  font-size: 0.9rem;
  min-height: 120px;
  outline: none;
  transition: border-color 0.2s;
  resize: none;

  &::placeholder {
    color: #999;
  }

  &:focus {
    border-color: ${(props) => (props.error ? "#e31e52" : "#0066ff")};
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px;
  background: ${({ disabled }) => (disabled ? "#e0e0e0" : "#f5f5f5")};
  border: none;
  border-radius: 8px;
  color: ${({ disabled }) => (disabled ? "#999" : "#333")};
  font-size: 0.9rem;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
  transition: background 0.2s;

  &:hover {
    background: ${({ disabled }) => (disabled ? "#e0e0e0" : "#e5e5e5")};
  }
`;

const ErrorMessage = styled.div`
  color: #e31e52;
  font-size: 0.85rem;
  margin-top: 0.5rem;
  text-align: center;
`;

const FieldError = styled.span`
  color: #e31e52;
  font-size: 0.75rem;
  margin-top: 0.25rem;
  display: block;
`;

const SlideFeedbackForm = ({ menudata, isOpen, onClose }) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(email);
  };

  const validatePhone = (phone) => {
    const re = /^(\+90|0)?\s*5[0-9]{2}\s*[0-9]{3}\s*[0-9]{2}\s*[0-9]{2}$/;
    return re.test(phone.replace(/\s/g, ""));
  };

  const formatPhoneNumber = (value) => {
    const numbers = value.replace(/\D/g, "");

    if (numbers.length <= 11) {
      let formatted = numbers.replace(
        /(\d{4})(\d{3})(\d{2})(\d{2})/,
        "$1 $2 $3 $4"
      );
      if (formatted.startsWith("9")) {
        formatted = "0" + formatted;
      }
      return formatted.trim();
    }
    return value;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === "phone") {
      formattedValue = formatPhoneNumber(value);
    }

    setFormData((prev) => ({
      ...prev,
      [name]: formattedValue,
    }));

    setFieldErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "İsim alanı zorunludur";
    }

    if (!formData.email) {
      errors.email = "Email alanı zorunludur";
    } else if (!validateEmail(formData.email)) {
      errors.email = "Geçerli bir email adresi giriniz";
    }

    if (formData.phone && !validatePhone(formData.phone)) {
      errors.phone = "Geçerli bir telefon numarası giriniz";
    }

    if (!formData.message.trim()) {
      errors.message = "Mesaj alanı zorunludur";
    }

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await axios({
        method: "POST",
        url: brand.urlSocket + "api/Email/SendEmailForm",
        headers: {
          Authorization: "Basic " + base64.encode("JarvisPos:Shark123*"),
          "Content-Type": "application/json",
        },
        data: {
          ...formData,
          destinationAddress: menudata.DestinationAddress,
        },
      });

      if (response.data.isStatus) {
        onClose();
        setShowSuccess(true);
        resetForm();
      } else {
        setError("Email gönderilemedi. Lütfen tekrar deneyin.");
      }
    } catch (err) {
      setError(err.message || "Bir hata oluştu. Lütfen tekrar deneyin.");
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      phone: "",
      email: "",
      message: "",
    });
    setError(null);
    setFieldErrors({});
  };

  const handleSuccessClose = () => {
    setShowSuccess(false);
    resetForm();
  };

  return (
    <>
      <FormOverlay isOpen={isOpen} onClick={onClose} />
      <FormContainer isOpen={isOpen}>
        <Logo src={menudata?.QrmenuLogo?.ImageUrl} alt="Labada" />

        <FormText>
          İşletmemize iletmek istediğiniz olumlu ya da olumsuz geri bildirim
          için lütfen formu doldurun.
        </FormText>

        <form onSubmit={handleSubmit}>
          <InputWrapper>
            <FaUser />
            <Input
              type="text"
              name="name"
              placeholder="Adınız - Soyadınız *"
              value={formData.name}
              onChange={handleChange}
              disabled={loading}
              error={fieldErrors.name}
              required
            />
            {fieldErrors.name && <FieldError>{fieldErrors.name}</FieldError>}
          </InputWrapper>

          <InputWrapper>
            <FaPhone />
            <Input
              type="tel"
              name="phone"
              placeholder="Telefon Numaranız"
              value={formData.phone}
              onChange={handleChange}
              disabled={loading}
              error={fieldErrors.phone}
            />
            {fieldErrors.phone && <FieldError>{fieldErrors.phone}</FieldError>}
          </InputWrapper>

          <InputWrapper>
            <FaEnvelope />
            <Input
              type="email"
              name="email"
              placeholder="Mail Adresiniz *"
              value={formData.email}
              onChange={handleChange}
              disabled={loading}
              error={fieldErrors.email}
              required
            />
            {fieldErrors.email && <FieldError>{fieldErrors.email}</FieldError>}
          </InputWrapper>

          <TextArea
            name="message"
            placeholder="Lütfen Mesajınızı Detaylı Olarak Yazınız... *"
            value={formData.message}
            onChange={handleChange}
            disabled={loading}
            error={fieldErrors.message}
            required
          />
          {fieldErrors.message && (
            <FieldError>{fieldErrors.message}</FieldError>
          )}

          {error && <ErrorMessage>{error}</ErrorMessage>}

          <SubmitButton type="submit" disabled={loading}>
            {loading ? "Gönderiliyor..." : "Geri Bildirimi Gönder"}{" "}
            <span>→</span>
          </SubmitButton>
        </form>
      </FormContainer>
      <SuccessMessage isOpen={showSuccess} onClose={handleSuccessClose} />
    </>
  );
};

export default SlideFeedbackForm;
