import { fromJS } from "immutable";
import axios from "axios";
import brand from "dan-api/dummy/brand";
import cookie from "react-cookies";
var base64 = require("base-64");

// Action Types
const SET_BLOCKED_COURIER_VISIBLE = "SET_BLOCKED_COURIER_VISIBLE";
const SET_BLOCKED_COURIERS = "SET_BLOCKED_COURIERS";
const SET_BLOCKED_COURIER_LOADING = "SET_BLOCKED_COURIER_LOADING";
const SET_BLOCKED_COURIER_ERROR = "SET_BLOCKED_COURIER_ERROR";

// Initial State
const initialState = {
  blockedCourierVisible: false,
  blockedCourierData: null,
  blockedCouriers: [],
  loading: false,
  error: null,
};

// Reducer
export default function blockedCourierReducer(
  state = fromJS(initialState),
  action
) {
  switch (action.type) {
    case SET_BLOCKED_COURIER_VISIBLE:
      return state
        .set("blockedCourierVisible", action.payload.visible)
        .set("blockedCourierData", action.payload.data);
    case SET_BLOCKED_COURIERS:
      return state.set("blockedCouriers", fromJS(action.payload));
    case SET_BLOCKED_COURIER_LOADING:
      return state.set("loading", action.payload);
    case SET_BLOCKED_COURIER_ERROR:
      return state.set("error", action.payload);
    default:
      return state;
  }
}

// Action Creators
export const setBlockedCourierVisible = (visible, data = null) => ({
  type: SET_BLOCKED_COURIER_VISIBLE,
  payload: { visible, data },
});

export const setBlockedCouriers = (data) => ({
  type: SET_BLOCKED_COURIERS,
  payload: data,
});

export const setBlockedCourierLoading = (loading) => ({
  type: SET_BLOCKED_COURIER_LOADING,
  payload: loading,
});

export const setBlockedCourierError = (error) => ({
  type: SET_BLOCKED_COURIER_ERROR,
  payload: error,
});

// Thunk Actions
export const getBlockedCouriers = (brandId) => async (dispatch) => {
  try {
    dispatch(setBlockedCourierLoading(true));
    const response = await axios({
      method: "GET",
      url: brand.urlSocketPaketYolda + "api/BlockedCourier/getBlockedCouriers",
      params: { brandId },
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    });

    if (response.data.isStatus) {
      const blockedCouriers = JSON.parse(response.data.responseData);
      dispatch(setBlockedCouriers(blockedCouriers));
    }
  } catch (error) {
    console.error("Error fetching blocked couriers:", error);
    dispatch(setBlockedCourierError(error.message));
  } finally {
    dispatch(setBlockedCourierLoading(false));
  }
};

export const setBlockedCourier = (courierData) => async (dispatch) => {
  try {
    dispatch(setBlockedCourierLoading(true));
    const ProcessId = cookie.load("PaketYoldaId");

    await axios({
      method: "POST",
      url: brand.urlSocketPaketYolda + "api/BlockedCourier/setBlockedCourier",
      data: {
        ...courierData,
        ProcessId,
        BlockedDate: new Date().toISOString(),
        BlockingReason: courierData.BlockingReason || "",
      },
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    });

    dispatch(getBlockedCouriers(courierData.BrandId));
  } catch (error) {
    console.error("Error setting blocked courier:", error);
    dispatch(setBlockedCourierError(error.message));
  } finally {
    dispatch(setBlockedCourierLoading(false));
  }
};

export const deleteBlockedCourier = (id, brandId) => async (dispatch) => {
  try {
    dispatch(setBlockedCourierLoading(true));

    await axios({
      method: "DELETE",
      url:
        brand.urlSocketPaketYolda + "api/BlockedCourier/deleteBlockedCourier",
      params: { id },
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    });
    dispatch(getBlockedCouriers(brandId));
  } catch (error) {
    console.error("Error deleting blocked courier:", error);
    dispatch(setBlockedCourierError(error.message));
  } finally {
    dispatch(setBlockedCourierLoading(false));
  }
};
