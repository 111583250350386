import React, { Fragment } from 'react';
import {
    createSelector
} from 'reselect';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles, createTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { FormattedMessage, injectIntl } from 'react-intl';
import Icon from '@material-ui/core/Icon';
const uuidv4 = require('uuid/v4');
import {
    setFranchiseRegionVisible, getOSMGeoLocation, getRegionsListAdd, getRegionsListData, getRegionsSearchData, setRegion,
    getRegionDelete
} from '../../../redux/modules/centerbrand';
import { getStock } from '../../../redux/modules/stock';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SendIcon from '@material-ui/icons/Send';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import StarBorder from '@material-ui/icons/StarBorder';
import Collapse from '@material-ui/core/Collapse';
import ModalInformation from '../Ortak/ModalInformation';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import OrderHeat from './Report/OrderHeat';
import Reviews from './Report/Reviews';
import DailyMonthlyGraffic from './Report/DailyMonthlyGraffic';
import GeneralTotal from './Report/GeneralTotal';
import ProductSalesList from './Report/ProductSalesList';
import EntegrationList from './Report/EntegrationList';
import AdditionReportList from './Report/AdditionReportList';
import Logs from './Report/Logs';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import styles from '../CenterBrandPage/centerbrandpage-jss';
import DatePicker, { registerLocale } from "react-datepicker";
import CircularProgress from '@material-ui/core/CircularProgress';
import NumberFormat from 'react-number-format';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import AppRegionMap from './AppRegionMap'
import "leaflet/dist/leaflet.css";

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalScale={2}
            fixedDecimalScale={true}
            autocomplete="off"
            allowNegative={false}
            decimalSeparator=","
            thousandSeparator="."
            prefix="₺"
        />
    );
}

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class Sadakat extends React.Component {
    constructor(props) {
        super(props);
        this.state = { search: "", value: '', suggestions: [], inputList: {} }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.franchiseRegionUpdate == this.props.franchiseRegionUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {

    }

    handleChangeAppRegionData = (i, name) => (event) => {
        const { franchiseRegionData } = this.props;
        franchiseRegionData[i][name] = (name == "MinAmount" ? parseFloat(event.target.value) : (name == "IsStatus" ? event.target.checked : event.target.value));
        this.props.getRegionsListData(franchiseRegionData).then(() => { })
    };

    handleIsSelected(i) {
        const { franchiseRegionData } = this.props;
        franchiseRegionData.map((n, i) => {
            n.IsSelected = false;
        })
        franchiseRegionData[i].IsSelected = true;
        this.props.getRegionsListData(franchiseRegionData).then(() => { })
    }


    render() {
        const {
            classes,
            franchiseRegionVisible,
            franchiseRegionData,
            franchiseRegionLoading,
            regionSearchText,
            regionSearchData
        } = this.props;

        return (
            <Dialog
                fullScreen
                open={franchiseRegionVisible}
                TransitionComponent={Transition}
            >
                {(franchiseRegionLoading ?
                    <div style={{ justifyContent: 'center', zIndex: 99999, position: 'absolute', bottom: 0, left: 0, right: 0, top: 0, backgroundColor: 'rgba(45, 52, 54,.6)' }}>
                        <div style={{ justifyItems: 'center', display: 'inline-grid', transform: 'translate(-50%, -50%)', left: '50%', position: 'absolute', top: '50%' }}>
                            <CircularProgress color='#fff' disableShrink thickness={2} size={70} />
                            <span style={{ marginTop: 10 }}>{'Bilgiler kayıt ediliyor..'}</span>
                        </div>
                    </div> : null)}
                <AppBar className={classes.appBar} style={{ position: 'fixed', top: 0, width: '100%' }}>
                    <Toolbar>
                        <Button variant='contained' onClick={() => this.props.setFranchiseRegionVisible(false).then(() => { })}>
                            <Icon style={{ marginRight: 5 }}>{"arrow_back"}</Icon>
                        </Button>
                        <Typography variant="h6" color="inherit" className={classes.flex}>{"JaviApp Bölge Limitleri"}</Typography>
                        <Button color="inherit" variant="outlined" onClick={() => this.handleSave()}>{"BİLGİLERİ KAYDET"}</Button>
                    </Toolbar>
                </AppBar>
                <div className={classes.detailWrap} style={{ backgroundColor: '#292929', marginTop: 64 }}>
                    <Grid container style={{ height: 'calc(100vh - 64px)', overflow: 'hidden' }}>
                        <Grid item md={3} style={{ height: '100%', padding: 4, overflowY: 'auto' }}>
                            <Button style={{ marginBottom: 4, borderRadius: 0, backgroundColor: 'rgba(41, 128, 185,1.0)', color: 'white' }} fullWidth variant="contained" onClick={() => this.props.getRegionsListAdd().then(() => { })}>
                                <span style={{ fontSize: 14 }}>{"Bölge Ekle"}</span>
                                <Icon style={{ marginLeft: 5 }}>{"add"}</Icon>
                            </Button>
                            <div style={{ height: 'calc(100% - 41px)', backgroundColor: '#424242', padding: 5 }}>
                                {
                                    franchiseRegionData.map((n, i) => {
                                        return (
                                            <div style={{ opacity: (!n.IsSelected ? '0.3' : '1'), marginBottom: 10, padding: 5, width: '100%', borderRadius: 10, border: '1px solid rgba(236, 240, 241,.3)' }}>
                                                <Grid style={{ pointerEvents: (!n.IsSelected ? 'none' : 'all') }} container spacing={1}>
                                                    <Grid item md={9}>
                                                        <FormControlLabel
                                                            control={(
                                                                <Switch
                                                                    checked={n.IsStatus}
                                                                    onChange={this.handleChangeAppRegionData(i, "IsStatus")}
                                                                />
                                                            )}
                                                            label={"Bölgeyi Siparişe Aç"}
                                                        />
                                                    </Grid>
                                                    <Grid item md={3}>
                                                        <Button  fullWidth style={{ borderRadius: 5, backgroundColor: 'rgba(192, 57, 43,1.0)', color: 'white' }} variant="contained"  onClick={() => (confirm('Bölgeyi silmek istediğinize emin misiniz?') ? this.props.getRegionDelete(n.Id).then(() => { }) : null)}>
                                                            <Icon>{"delete"}</Icon>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item md={12}>
                                                        <Input
                                                            type="text"
                                                            placeholder='Bölge Adı Giriniz'
                                                            fullWidth
                                                            value={n.RegionName}
                                                            onChange={this.handleChangeAppRegionData(i, "RegionName")}
                                                        />
                                                    </Grid>
                                                    <Grid item md={12}>
                                                        <TextField
                                                            fullWidth
                                                            value={n.MinAmount}
                                                            onFocus={event => { event.target.select() }}
                                                            onChange={this.handleChangeAppRegionData(i, "MinAmount")}
                                                            InputProps={{
                                                                inputComponent: NumberFormatCustom
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Button style={{ marginTop: 8, marginBottom: 4, borderRadius: 10, backgroundColor: (!n.IsSelected ? 'rgba(127, 140, 141,1.0)' : 'rgba(22, 160, 133,1.0)'), color: 'white' }} fullWidth variant="contained" onClick={() => this.handleIsSelected(i)}>
                                                    <span style={{ fontSize: 14 }}>{"SEÇ"}</span>
                                                </Button>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Grid>
                        <Grid item md={9} style={{ height: '100%', padding: 4, position: 'relative' }}>
                            <div style={{ position: 'absolute', top: 5, right: 5, zIndex: 99999, backgroundColor: '#424242', borderRadius: 10, padding: 10 }}>
                                <TextField
                                    fullWidth
                                    placeholder='Örnek : Denizli Pamukkale Kınıklı Mahallesi'
                                    value={regionSearchText}
                                    onFocus={event => { event.target.select() }}
                                    onChange={(event) => this.props.getRegionsSearchData(event.target.value).then(() => { })}
                                    id="price_propertyitem"
                                />
                                <Button style={{ marginTop: 8, marginBottom: 4, borderRadius: 10, backgroundColor: 'rgba(243, 156, 18,1.0)', color: '#424242' }} fullWidth variant="contained" onClick={() => this.props.getOSMGeoLocation().then(() => { })}>
                                    <span style={{ fontSize: 14 }}>{"BÖLGEYİ BUL VE EKLE"}</span>
                                </Button>
                            </div>
                            <AppRegionMap IsUpdate={this.props.franchiseRegionUpdate} PolygonJson={franchiseRegionData?.find(x => x.IsSelected == true)?.PolygonArray ?? []} onClickData={(data) => this.props.setRegion(data).then(() => { })} />
                        </Grid>
                    </Grid>
                </div>
            </Dialog>
        );
    }
}

const valueSelector = (state, props) => state.get('centerBrandReducer').franchiseRegionUpdate;
const value = createSelector([valueSelector],
    (value) => value);


const mapStateToProps = (state, props) => ({
    franchiseRegionUpdate: value(state, props),
    franchiseRegionVisible: state.get('centerBrandReducer').franchiseRegionVisible,
    franchiseRegionLoading: state.get('centerBrandReducer').franchiseRegionLoading,
    franchiseRegionData: state.get('centerBrandReducer').franchiseRegionData,
    regionAdd: state.get('centerBrandReducer').regionAdd,
    regionSearchText: state.get('centerBrandReducer').regionSearchText,
    regionSearchData: state.get('centerBrandReducer').regionSearchData,
})

const mapDispatchToProps = {
    setFranchiseRegionVisible,
    getOSMGeoLocation,
    getRegionsListAdd,
    getRegionsListData,
    getRegionsSearchData,
    setRegion,
    getRegionDelete
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(Sadakat)));
