import { alpha } from '@material-ui/core/styles/colorManipulator';
import deepOrange from '@material-ui/core/colors/deepOrange';
import deepPurple from '@material-ui/core/colors/deepPurple';
import pink from '@material-ui/core/colors/pink';
import green from '@material-ui/core/colors/green';
const panelWidth = '100%';

const styles = theme => ({
  dialogPaperOrtak: {
    maxHeight: 'calc(100% - 10px)',
    maxWidth: 700,
  },
  dialogPaperMenu: {
    maxHeight: 'calc(100% - 10px)',
    maxWidth: 800,
    backgroundColor:'#424242'
  },
  dialogPaperOrtakAlert: {
    maxHeight: 'calc(100% - 10px)',
    maxWidth: 600
  }
});

export default styles;
