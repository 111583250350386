import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import { connect } from "react-redux";
import Loading from "dan-components/Loading";
import { withRouter } from "react-router-dom";

import {
  getQrMenu,
  getQrMenuCategories,
  getQrMenuProducts,
} from "../../../redux/modules/QrMenuReducer";

import Header from "../../../components/QrMenu/Header";
import HeroBanner from "../../../components/QrMenu/HeroBanner";
import CategoryScrollbar from "../../../components/QrMenu/CategoryScrollbar";
import MenuItemList from "../../../components/QrMenu/MenuItemList";
import Footer from "../../../components/QrMenu/Footer";

const styles = (theme) => ({
  rootFull: {
    width: "100%",
    backgroundColor: "#f5f5f5",
    minHeight: "100vh",
  },
  containerSide: {
    position: "relative",
    backgroundColor: "#f5f5f5",
  },
  opening: {
    padding: 12,
    marginTop: 18,
  },
});

class QrMenu extends React.Component {
  state = {
    activeCategory: null,
    qrId: null,
    isInitialLoad: true,
  };

  async componentDidMount() {
    const pathSegments = window.location.pathname.split("/");
    const qrId = pathSegments[pathSegments.length - 1];

    if (!qrId || qrId.trim() === "") {
      console.error("Invalid QR ID");
      return;
    }

    this.setState({ qrId });

    try {
      await this.props.getQrMenu(qrId);

      await this.props.getQrMenuCategories(qrId);

      if (this.props.categoryList && this.props.categoryList.length > 0) {
        const firstCategory = this.props.categoryList[0];
        this.setState({
          activeCategory: firstCategory.Id,
          isInitialLoad: false,
        });

        await this.props.getQrMenuProducts(qrId, firstCategory.Id);
      }
    } catch (error) {
      console.error("Error loading initial data:", error);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.loading !== nextProps.loading ||
      this.props.menuData !== nextProps.menuData ||
      this.props.categoryList !== nextProps.categoryList ||
      this.props.productList !== nextProps.productList ||
      this.state.activeCategory !== nextState.activeCategory ||
      this.state.isInitialLoad !== nextState.isInitialLoad
    );
  }

  handleCategorySelect = async (categoryId) => {
    if (this.state.activeCategory === categoryId) return;

    try {
      this.setState({ activeCategory: categoryId });
      await this.props.getQrMenuProducts(this.state.qrId, categoryId);
    } catch (error) {
      console.error("Error loading category products:", error);
    }
  };

  render() {
    const {
      classes,
      menuData,
      categoryList,
      productList,
      loading,
    } = this.props;

    if (loading && this.state.isInitialLoad) {
      return <Loading />;
    }

    return (
      <div className={classes.rootFull}>
        <div className={classNames(classes.containerSide)}>
          <div className={classes.opening}>
            <Header
              menudata={menuData?.toJS?.() || menuData}
              brandName={menuData?.get?.("BrandName") || menuData?.BrandName}
              logo={menuData?.get?.("Logo") || menuData?.Logo}
            />
            <HeroBanner menudata={menuData?.toJS?.() || menuData} />
            {categoryList &&
              (categoryList.size > 0 || categoryList.length > 0) && (
                <CategoryScrollbar
                  categories={categoryList?.toJS?.() || categoryList}
                  onCategorySelect={this.handleCategorySelect}
                  activeCategory={this.state.activeCategory}
                />
              )}
            <MenuItemList products={productList?.toJS?.() || productList} />
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const qrMenuState = state.get("QrMenuReducer");

  if (!qrMenuState) {
    return {
      menuData: {},
      categoryList: [],
      productList: [],
      loading: false,
      error: null,
    };
  }

  return {
    menuData: qrMenuState.get("menuData")?.toJS?.() || {},
    categoryList: qrMenuState.get("categoryList")?.toJS?.() || [],
    productList: qrMenuState.get("productList")?.toJS?.() || [],
    loading: qrMenuState.get("loading") || false,
    error: qrMenuState.get("error") || null,
  };
};

const mapDispatchToProps = {
  getQrMenu,
  getQrMenuCategories,
  getQrMenuProducts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(injectIntl(QrMenu))));
