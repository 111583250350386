import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import DeliveryAreaMap from "./DeliveryAreaMap";
import axios from "axios";
import {
  setDeliveryArea,
  getDeliveryArea,
  fetchBusinessLocations,
} from "../../../../redux/modules/DistanceRestrictionReducer";

const styles = (theme) => ({
  dialogPaper: {
    backgroundColor: "#424242",
    color: "#fff",
  },
  dialogTitle: {
    borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
  },
  dialogContent: {
    padding: theme.spacing(2),
    height: "100vh",
    width: "100%",
  },
  dialogActions: {
    borderTop: "1px solid rgba(255, 255, 255, 0.12)",
    padding: theme.spacing(1),
  },
  textField: {
    "& .MuiInputBase-input": {
      color: "#fff",
    },
    "& .MuiInputLabel-root": {
      color: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(255, 255, 255, 0.23)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(255, 255, 255, 0.5)",
      },
    },
  },
});

const mapStateToProps = (state) => ({
  deliveryArea: state.get("distanceRestrictionReducer").get("deliveryArea"),
  businessLocations: state
    .get("distanceRestrictionReducer")
    .get("businessLocation"),
  loading: state.get("distanceRestrictionReducer").get("loading"),
});

const mapDispatchToProps = (dispatch) => ({
  setDeliveryArea: (brandId, processId, data) =>
    dispatch(setDeliveryArea(brandId, processId, data)),
  getDeliveryArea: (brandId, processId) =>
    dispatch(getDeliveryArea(brandId, processId)),
  fetchBusinessLocations: (paketYoldaId) =>
    dispatch(fetchBusinessLocations(paketYoldaId)),
});

class DistanceRestriction extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.state = {
      polygonData: null,
      distanceKm: "",
      circleRadius: null,
      loading: false,
      businessLocation: null,
      error: null,
    };
  }

  componentDidMount() {
    if (this.props.selectedBusiness) {
      this.props.fetchBusinessLocations(
        this.props.selectedBusiness.PaketYoldaId
      );
      this.props.getDeliveryArea(
        this.props.selectedBusiness.BrandId,
        this.props.selectedBusiness.PaketYoldaId
      );
    }
  }

  componentDidUpdate(prevProps) {
    const {
      selectedBusiness,
      open,
      deliveryArea,
      businessLocations,
      loading,
    } = this.props;

    // Clear state when modal is closed and reopened
    if (!prevProps.open && open) {
      this.setState({
        polygonData: null,
        distanceKm: "",
        circleRadius: null,
        businessLocation: null,
        error: null,
      });
    }

    // Handle business change or modal open
    if (
      (!prevProps.open && open) ||
      (selectedBusiness &&
        (!prevProps.selectedBusiness ||
          prevProps.selectedBusiness.BrandId !== selectedBusiness.BrandId))
    ) {
      // Reset state for new business
      this.setState({
        polygonData: null,
        distanceKm: "",
        circleRadius: null,
        loading: true,
        businessLocation: null,
        error: null,
      });

      // Fetch data for the new business
      this.props.fetchBusinessLocations(selectedBusiness.PaketYoldaId);
      this.props.getDeliveryArea(
        selectedBusiness.BrandId,
        selectedBusiness.PaketYoldaId
      );
    }

    if (
      prevProps.businessLocations !== businessLocations &&
      businessLocations &&
      selectedBusiness
    ) {
      const businessLocationsArray = Array.isArray(businessLocations)
        ? businessLocations
        : businessLocations.toJS
          ? businessLocations.toJS()
          : [];

      const targetBrandId = (selectedBusiness.BrandId || "").toLowerCase();

      let currentBusiness = businessLocationsArray.find(
        (b) => (b.BrandId || "").toLowerCase() === targetBrandId
      );

      if (!currentBusiness && selectedBusiness.BusinessName) {
        currentBusiness = businessLocationsArray.find(
          (b) => b.BusinessName === selectedBusiness.BusinessName
        );
      }

      if (!currentBusiness && businessLocationsArray.length > 0) {
        currentBusiness = businessLocationsArray.find(
          (b) => b.Lat !== 0 && b.Lng !== 0
        );
      }

      if (currentBusiness) {
        this.setState({
          businessLocation: {
            Lat: currentBusiness.Lat,
            Lng: currentBusiness.Lng,
            BusinessName:
              currentBusiness.BusinessName || selectedBusiness.BusinessName,
          },
          loading: false,
        });
      } else if (!loading) {
        this.setState({
          error:
            "İşletme bilgisi bulunamadı. Lütfen koordinat bilgilerini kontrol ediniz.",
          loading: false,
        });
      }
    }

    if (prevProps.deliveryArea !== deliveryArea && deliveryArea) {
      try {
        const deliveryAreaJS = deliveryArea.toJS
          ? deliveryArea.toJS()
          : deliveryArea;

        if (
          deliveryAreaJS.type === "Circle" &&
          deliveryAreaJS.center &&
          deliveryAreaJS.radius
        ) {
          this.setState({
            polygonData: null,
            distanceKm: (deliveryAreaJS.radius / 1000).toString(),
            circleRadius: deliveryAreaJS.radius,
          });

          let attempts = 0;
          const maxAttempts = 5;

          const attemptRender = () => {
            attempts++;

            try {
              const mapRef = this.mapRef && this.mapRef.current;

              if (mapRef && mapRef.renderDeliveryArea) {
                mapRef.renderDeliveryArea(deliveryAreaJS);
              } else {
                if (attempts < maxAttempts) {
                  setTimeout(attemptRender, 500 * attempts);
                }
              }
            } catch (error) {
              if (attempts < maxAttempts) {
                setTimeout(attemptRender, 500 * attempts);
              }
            }
          };

          setTimeout(attemptRender, 200);

          return;
        }

        if (
          deliveryAreaJS.type === "Polygon" &&
          Array.isArray(deliveryAreaJS.coordinates)
        ) {
          let coordinates = null;

          if (
            Array.isArray(deliveryAreaJS.coordinates[0]) &&
            Array.isArray(deliveryAreaJS.coordinates[0][0])
          ) {
            coordinates = deliveryAreaJS.coordinates[0];
          } else {
            coordinates = deliveryAreaJS.coordinates;
          }

          this.setState({
            polygonData: coordinates,
            distanceKm: "",
            circleRadius: null,
          });
        }
      } catch (error) {
        console.error("Error parsing delivery area data:", error);
      }
    }
  }

  handleDistanceChange = (event) => {
    const value = event.target.value;
    if (value === "" || (!isNaN(value) && value >= 0)) {
      const radius = value ? parseFloat(value) * 1000 : null;

      this.setState({
        distanceKm: value,
        circleRadius: radius,
        polygonData: null,
      });

      const { businessLocation } = this.state;
      if (businessLocation?.Lat && businessLocation?.Lng) {
        if (radius) {
          const circleData = {
            type: "Circle",
            radius: radius,
            center: [businessLocation.Lng, businessLocation.Lat],
          };

          this.handleAreaChange(circleData);

          const mapRef = this.mapRef?.current;
          if (mapRef && mapRef.renderDeliveryArea) {
            setTimeout(() => {
              try {
                mapRef.renderDeliveryArea(circleData);
              } catch (error) {
                console.error("Error rendering delivery area:", error);
              }
            }, 100);
          }
        } else {
          this.handleAreaChange(null);
        }
      }
    }
  };

  handleSave = (mapData) => {
    const { selectedBusiness, setDeliveryArea } = this.props;
    const { polygonData, circleRadius, businessLocation } = this.state;

    if (!selectedBusiness) {
      console.error("No business selected for saving");
      return;
    }

    try {
      let deliveryAreaData;

      if (mapData) {
        deliveryAreaData = JSON.parse(JSON.stringify(mapData));
      } else if (circleRadius && businessLocation) {
        deliveryAreaData = {
          type: "Circle",
          radius: circleRadius,
          center: [businessLocation.Lng, businessLocation.Lat],
        };
      } else if (polygonData) {
        if (
          polygonData.type === "Polygon" &&
          Array.isArray(polygonData.coordinates)
        ) {
          deliveryAreaData = JSON.parse(JSON.stringify(polygonData));
        } else {
          deliveryAreaData = {
            type: "Polygon",
            coordinates: Array.isArray(polygonData[0])
              ? [polygonData]
              : [[polygonData]],
          };
        }
      } else {
        console.warn("No delivery area data to save");
        return;
      }

      const brandId = selectedBusiness.BrandId || selectedBusiness.brandId;
      const processId =
        selectedBusiness.PaketYoldaId || selectedBusiness.paketYoldaId;

      if (!brandId || !processId) {
        console.error("Missing brandId or processId for save operation");
        return;
      }

      const currentState = {
        polygonData: this.state.polygonData,
        circleRadius: this.state.circleRadius,
        distanceKm: this.state.distanceKm,
      };

      setDeliveryArea(brandId, processId, deliveryAreaData);

      try {
        const mapRef = this.mapRef?.current;
        if (mapRef && mapRef.renderDeliveryArea) {
          setTimeout(() => {
            try {
              mapRef.renderDeliveryArea(deliveryAreaData);
            } catch (renderError) {
              this.setState({
                polygonData: currentState.polygonData,
                circleRadius: currentState.circleRadius,
                distanceKm: currentState.distanceKm,
              });
            }
          }, 300);
        }
      } catch (error) {
        console.error("Error in mapRef:", error);
      }
    } catch (error) {
      console.error("Error in handleSave:", error);
    }
  };

  handleAreaChange = (data) => {
    if (data?.type === "Circle") {
      this.setState({
        circleRadius: data.radius,
        distanceKm: (data.radius / 1000).toString(),
        polygonData: null,
      });
    } else if (data?.type === "Polygon" && Array.isArray(data.coordinates)) {
      let coordinates;
      if (
        Array.isArray(data.coordinates[0]) &&
        Array.isArray(data.coordinates[0][0])
      ) {
        coordinates = data.coordinates[0];
      } else {
        coordinates = data.coordinates;
      }

      this.setState({
        polygonData: coordinates,
        distanceKm: "",
        circleRadius: null,
      });
    } else if (Array.isArray(data)) {
      this.setState({
        polygonData: data,
        distanceKm: "",
        circleRadius: null,
      });
    } else {
      this.setState({
        polygonData: null,
        distanceKm: "",
        circleRadius: null,
      });
    }
  };

  render() {
    const { classes, open, onClose } = this.props;
    const {
      loading,
      businessLocation,
      error,
      polygonData,
      distanceKm,
    } = this.state;

    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="lg"
        fullWidth
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle className={classes.dialogTitle}>
          {businessLocation?.BusinessName || "İşletme"} - Teslimat Alanı Belirle
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div style={{ display: "flex", width: "100%", height: "100%" }}>
            <div style={{ width: "30%", paddingLeft: 5, paddingRight: 5 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Teslimat Mesafesi (KM)"
                    variant="outlined"
                    fullWidth
                    value={distanceKm}
                    onChange={this.handleDistanceChange}
                    type="number"
                    className={classes.textField}
                    inputProps={{
                      min: 0,
                      step: 0.1,
                    }}
                    style={{ marginBottom: 20 }}
                  />
                  <Typography variant="caption" style={{ color: "#aaa" }}>
                    Mesafe girdiğinizde haritadaki alan temizlenir
                  </Typography>
                </Grid>
                {loading && (
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <CircularProgress />
                  </Grid>
                )}
                {error && (
                  <Grid item xs={12}>
                    <Typography color="error">{error}</Typography>
                  </Grid>
                )}
                {businessLocation && (
                  <Grid item xs={12}>
                    <Typography variant="body2" style={{ marginBottom: 12 }}>
                      <strong>İşletme Adı:</strong>{" "}
                      {businessLocation.BusinessName}
                    </Typography>
                    <Typography variant="body2" style={{ marginBottom: 12 }}>
                      <strong>Koordinatlar:</strong> {businessLocation.Lat},{" "}
                      {businessLocation.Lng}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </div>
            <div style={{ width: "70%", height: "calc(100vh - 200px)" }}>
              {businessLocation ? (
                <DeliveryAreaMap
                  ref={this.mapRef}
                  business={businessLocation}
                  deliveryArea={polygonData}
                  onAreaChange={this.handleAreaChange}
                  showSaveButton={true}
                  onSave={this.handleSave}
                />
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#333",
                  }}
                >
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <Typography>Konum bilgisi bulunamadı</Typography>
                  )}
                </div>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={() => {
              const mapRef = this.mapRef && this.mapRef.current;
              const {
                circleRadius,
                businessLocation,
                polygonData,
              } = this.state;

              if (circleRadius && businessLocation) {
                const circleData = {
                  type: "Circle",
                  radius: circleRadius,
                  center: [businessLocation.Lng, businessLocation.Lat],
                };
                this.handleSave(circleData);
                return;
              }

              if (mapRef) {
                mapRef.onSaveLocation();
              } else if (polygonData) {
                const formattedPolygon = {
                  type: "Polygon",
                  coordinates: Array.isArray(polygonData[0])
                    ? [polygonData]
                    : [[polygonData]],
                };
                this.handleSave(formattedPolygon);
              } else {
                this.handleSave();
              }
            }}
            style={{
              backgroundColor: "#2ecc71",
              color: "white",
              fontWeight: "bold",
            }}
            variant="contained"
          >
            Kaydet
          </Button>
          <Button
            style={{ backgroundColor: "#e74c3c", color: "white" }}
            onClick={onClose}
          >
            İptal
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DistanceRestriction));
