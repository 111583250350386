import axios from "axios";
import cookie from "react-cookies";
import brand from "dan-api/dummy/brand";
import { login } from "./login";
const base64 = require("base-64");
const uuidv4 = require("uuid/v4");

export const ADMIN_GLOBAL_FORM_DATA_SET = "admin_global_form_data_set";
export const ADMIN_ERROR = "admin_error";
export const ADMIN_CODEMODAL = "admin_codemodal";
export const SET_LICENCELIST = "set_licencelist";
export const GET_SUBSCRIBER_LIST_STATUS = "get_subscriber_list_status";
export const PAYNET_SUBSCRIBER_LIST = "paynet_subscriber_list";
export const ADMIN_SUBSCRIBER_PAGE_ISUPDATE = "admin_subscriber_page_isupdate";
export const PAYNET_SUBSCRIBER_LIST_LOADING = "paynet_subscriber_list_loading";
export const SEND_PAYMENT_LINK_MODAL_VISIBLE =
  "send_payment_link_modal_visible";
export const SEND_PAYMENT_LINK_MODAL_ISUPDATE =
  "send_payment_link_modal_isupdate";
export const SELECTED_SUBSCRIBER_DATA = "selected_subscriber_data";
export const SEND_PAYMENT_LINK_NAMESURNAME = "send_payment_link_namesurname";
export const SEND_PAYMENT_LINK_PHONE = "send_payment_link_phone";
export const SEND_PAYMENT_LINK_PRICE = "send_payment_link_price";
export const ALERT_MODAL_VISIBLE = "alert_modal_visible";
export const ALERT_MODAL_TITLE = "alert_modal_title";
export const ALERT_MODAL_TEXT = "alert_modal_text";
export const SUBSCRIBER_DETAIL_MODAL_VISIBLE =
  "subscriber_detail_modal_visible";
export const SUBSCRIBER_DETAIL_MODAL_ISUPDATE =
  "subscriber_detail_modal_isupdate";
export const SUBSCRIBER_DETAIL_LIST = "subscriber_detail_list";
export const MODALS_LOADING = "modals_loading";
export const SUBSCRIBER_PLAN_AMOUNT_UPDATE_MODAL_VISIBLE =
  "subscriber_plan_amount_update_modal_visible";
export const SUBSCRIBER_PLAN_AMOUNT_UPDATE_MODAL_ISUPDATE =
  "subscriber_plan_amount_update_modal_isupdate";
export const SUBSCRIBER_PLAN_UPDATE_AMOUNT =
  "subscriber_plan_update_modal_update";
export const SUBSCRIBER_EXTENSION_UPDATE_MODAL_VISIBLE =
  "subscriber_extension_update_modal_visible";
export const SUBSCRIBER_EXTENSION_UPDATE_MODAL_ISUPDATE =
  "subscriber_extension_update_modal_isupdate";
export const SUBSCRIBER_EXTENSION_UPDATE_MONTH =
  "subscriber_extension_update_month";
export const ADMINLOGIN_START = "adminlogin_start";
export const SUBSCRIBER_INVOICE_DATA = "subscriber_invoice_data";
export const SUBSCRIBER_INVOICE_MODAL_VISIBLE =
  "subscriber_invoice_modal_visible";
export const SUBSCRIBER_INVOICE_MODAL_ISUPDATE =
  "subscriber_invoice_modal_isupdate";

export const LICENCE_MODULE_DATA = "licence_module_data";
export const LICENCE_MODULE_DATA_VISIBLE = "licence_module_data_visible";
export const LICENCE_MODULE_DATA_UPDATE = "licence_module_data_update";

export const DEALER_LIST_VISIBLE = "dealer_list_visible";
export const DEALER_LIST_DATA = "dealer_list_data";
export const DEALER_LIST_UPDATE = "dealer_list_update";

export const DEALER_ADD_UPDATE = "dealer_add_update";
export const DEALER_ADD_VISIBLE = "dealer_add_visible";
export const DEALER_ADD_DATA = "dealer_add_data";

export const SET_BRAND_TRACK_COUNT = "set_brand_track_count";
export const SET_NEWBRAND_TRACK_COUNT = "set_newbrand_track_count";

const initialState = {
  form_dataAdmin: {
    modalOpen: false,
    modalTitle: "",
    modalText: "",
  },
  IsSmsModal: false,
  IsLoginStatus: false,
  LicenceList: [],
  IsAdminUpdate: false,
  getSubscriberListStatus: 1,
  paynetSubscriberList: [],
  adminSubscriberPageIsupdate: false,
  paynetSubscriberListLoading: false,
  sendPaymentLinkModalIsupdate: false,
  sendPaymentLinkModalVisible: false,
  selectedSubscriberData: [],
  sendPaymentLinkNameSurname: "",
  sendPaymentLinkPhone: "",
  sendPaymentLinkPrice: "",
  alertModalVisible: false,
  alertModalTitle: "",
  alertModalText: "",
  subscriberDetailModalIsupdate: false,
  subscriberDetailModalVisible: false,
  subscriberDetailList: [],
  modalsLoading: false,
  subscriberPlanAmountUpdateModalIsupdate: false,
  subscriberPlanAmountUpdateModalVisible: false,
  subscriberPlanUpdateAmount: "",
  subscriberPlanUpdateSubId: "",
  subscriberPlanUpdateRefNo: "",
  subscriberPlanUpdatePlanId: "00000000-0000-0000-0000-000000000000",
  subscriberPlanUpdateOldAmount: "",
  subscriberExtensionUpdateModalIsupdate: false,
  subscriberExtensionUpdateModalVisible: false,
  subscriberExtensionUpdateMonth: 1,
  subscriberExtensionSubId: "",
  subscriberExtensionBrandName: "",
  subscriberInvoiceData: null,
  subscriberInvoiceBrandId: "00000000-0000-0000-0000-000000000000",
  subscriberInvoiceModalVisible: null,
  subscriberInvoiceModalIsupdate: null,

  licenceModuleData: null,
  licenceModuleVisible: false,
  licenceModuleUpdate: false,
  licenceModuleBrandId: null,

  dealerListVisible: false,
  dealerListUpdate: false,
  dealerListData: [],
  dealerAdd: {
    Id: uuidv4(),
    CompanyTitle: "",
    Email: "",
    Code: "",
    NameSurname: "",
    Password: "",
    IsStatus: true,
    Phone: "",
    Address: "",
    TaxNumber: "",
    TaxAdministration: "",
  },
  dealerAddVisible: false,
  dealerAddUpdate: false,

  brandTrackCount: 0,
  newBrandTrackCount: 0,
};

export async function getLicenceModule(BrandId) {
  return async (dispatch, getState) => {
    await axios({
      method: "GET",
      url: brand.urlSocket + "api/Payment/getLicenceModule?BrandId=" + BrandId,
      dataType: "json",
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(
            setLicenceModuleData(
              JSON.parse(response.data.responseData),
              BrandId
            )
          );
          dispatch(getTrackCount(BrandId));
        } else {
          dispatch(alertModalChange(true, "Uyarı", response.data.responseData));
        }
      })
      .catch((error) => {
        dispatch(setModalAdmin(error));
      });
  };
}

export async function getTrackCount(BrandId) {
  return async (dispatch, getState) => {
    await axios({
      method: "GET",
      url: brand.urlSocket + "api/Settings/getTrackCount?BrandId=" + BrandId,
      dataType: "json",
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then((response) => {
        dispatch({
          type: SET_BRAND_TRACK_COUNT,
          payload: Number(response.data.responseData),
        });
      })
      .catch((error) => {
        dispatch(setModalAdmin(error));
      });
  };
}

export async function setTrackCount(BrandId, count) {
  return async (dispatch, getState) => {
    await axios({
      method: "GET",
      url:
        brand.urlSocket +
        "api/Settings/setTrackCount?BrandId=" +
        BrandId +
        "&count=" +
        count,
      dataType: "json",
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then((response) => {
        dispatch(getTrackCount(BrandId));
      })
      .catch((error) => {
        dispatch(setModalAdmin(error));
      });
  };
}

export async function setLicenceModule(BrandId, data) {
  return async (dispatch, getState) => {
    await axios({
      method: "POST",
      url: brand.urlSocket + "api/Payment/setLicenceModule?BrandId=" + BrandId,
      dataType: "json",
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
      data,
    })
      .then((response) => {
        dispatch(setLicenceModuleData(null, null));
        dispatch(alertModalChange(true, "Uyarı", response.data.responseData));
      })
      .catch((error) => {
        dispatch(setModalAdmin(error));
      });
  };
}

export async function codeControl(code) {
  return async (dispatch, getState) => {
    dispatch({ type: ADMINLOGIN_START });
    await axios({
      method: "GET",
      url:
        brand.urlSocket +
        "api/Admin/codeControl?Id=" +
        cookie.load("AdminId") +
        "&code=" +
        code,
      dataType: "json",
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          cookie.save("AdminLogin", true, { path: "/" });
          window.location.href = "/adminpanel";
        } else {
          dispatch(setModalAdmin(response.data.responseData));
        }
      })
      .catch((error) => {
        dispatch(setModalAdmin(error));
      });
    dispatch({ type: ADMINLOGIN_START });
  };
}

export async function adminCustomerLoginControl(BrandId, TenantId) {
  return async (dispatch, getState) => {
    await axios({
      method: "GET",
      url:
        brand.urlSocket +
        "api/User/adminCustomerLoginControl?BrandId=" +
        BrandId +
        "&TenantId=" +
        TenantId,
      dataType: "json",
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          const data = {
            Email: json.Username,
            Password: json.Password,
            IsPIN: false,
            IsRemember: false,
          };
          dispatch(login(data, true));
        }
      })
      .catch((error) => {
        dispatch(setModalAdmin(error));
      });
  };
}

export async function adminCenterBrandLogin(CenterBrandId, TenantId) {
  return async (dispatch, getState) => {
    await axios({
      method: "GET",
      url:
        brand.urlSocket +
        "api/User/adminCenterBrandLogin?CenterBrandId=" +
        CenterBrandId +
        "&TenantId=" +
        TenantId,
      dataType: "json",
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          const data = {
            Email: json.Username,
            Password: json.Password,
            IsPIN: false,
            IsRemember: false,
          };
          dispatch(login(data, true));
        } else {
          dispatch(setModalAdmin(response.data.responseData));
        }
      })
      .catch((error) => {
        dispatch(setModalAdmin(error));
      });
  };
}

export async function setExternalReceived(
  invoice_id,
  subscription_id,
  reference_no
) {
  console.log(invoice_id);
  return async (dispatch, getState) => {
    await axios({
      method: "GET",
      url:
        brand.urlSocket +
        "api/Payment/setExternalReceived?invoice_id=" +
        invoice_id,
      dataType: "json",
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(getSubscriberDetailList(subscription_id, reference_no));
        } else {
          dispatch(alertModalChange(true, "Uyarı", response.data.responseData));
        }
      })
      .catch((error) => {
        dispatch({
          type: PAYNET_SUBSCRIBER_LIST_LOADING,
          payload: false,
        });
      });
  };
}

export async function getPaynetSubscriber() {
  return async (dispatch, getState) => {
    const { getSubscriberListStatus } = getState().get("adminReducer");
    dispatch({
      type: PAYNET_SUBSCRIBER_LIST_LOADING,
      payload: true,
    });
    await axios({
      method: "GET",
      url:
        brand.urlSocket +
        "api/Payment/getPaynetSubscriberList?Status=" +
        getSubscriberListStatus,
      dataType: "json",
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch({
            type: PAYNET_SUBSCRIBER_LIST,
            payload: json,
          });
          dispatch({
            type: PAYNET_SUBSCRIBER_LIST_LOADING,
            payload: false,
          });
          dispatch({
            type: ADMIN_SUBSCRIBER_PAGE_ISUPDATE,
          });
        } else {
          dispatch({
            type: PAYNET_SUBSCRIBER_LIST_LOADING,
            payload: false,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: PAYNET_SUBSCRIBER_LIST_LOADING,
          payload: false,
        });
      });
  };
}

export async function getSendMessageLink() {
  return async (dispatch, getState) => {
    const {
      sendPaymentLinkNameSurname,
      sendPaymentLinkPhone,
      sendPaymentLinkPrice,
    } = getState().get("adminReducer");
    dispatch({
      type: MODALS_LOADING,
      payload: true,
    });
    if (
      sendPaymentLinkNameSurname == "" ||
      sendPaymentLinkPhone == "" ||
      sendPaymentLinkPrice == ""
    ) {
      dispatch(
        alertModalChange(
          true,
          "Uyarı",
          "Lütfen tüm alanları doldurduktan sonra tekrar deneyiniz."
        )
      );
      dispatch({
        type: MODALS_LOADING,
        payload: false,
      });
      return;
    }
    await axios({
      method: "GET",
      url:
        brand.urlSocket +
        "api/Payment/getPaynetSubscriberSendLinkMessage?Phone=" +
        sendPaymentLinkPhone +
        "&NameSurname=" +
        sendPaymentLinkNameSurname +
        "&Price=" +
        sendPaymentLinkPrice,
      dataType: "json",
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(
            alertModalChange(true, "Uyarı", "Ödeme linki başarıyla gönderildi.")
          );
          dispatch({
            type: MODALS_LOADING,
            payload: false,
          });
          dispatch(selectedSubscriberChange([], "sendMessage", false));
          dispatch(sendPaymentLinkNameSurnameChange(""));
          dispatch(sendPaymentLinkPriceChange(""));
          dispatch(sendPaymentLinkPhoneChange(""));
        } else {
          dispatch({
            type: MODALS_LOADING,
            payload: false,
          });
          dispatch(selectedSubscriberChange([], "sendMessage", false));
          dispatch(
            alertModalChange(
              true,
              "Uyarı",
              "Mesaj gönderilemedi. Lütfen tekrar deneyiniz."
            )
          );
          dispatch(sendPaymentLinkNameSurnameChange(""));
          dispatch(sendPaymentLinkPriceChange(""));
          dispatch(sendPaymentLinkPhoneChange(""));
        }
      })
      .catch((error) => {
        dispatch({
          type: MODALS_LOADING,
          payload: false,
        });
        dispatch(selectedSubscriberChange([], "sendMessage", false));
        dispatch(
          alertModalChange(
            true,
            "Uyarı",
            "Mesaj gönderilemedi. Lütfen tekrar deneyiniz."
          )
        );
        dispatch(sendPaymentLinkNameSurnameChange(""));
        dispatch(sendPaymentLinkPriceChange(""));
        dispatch(sendPaymentLinkPhoneChange(""));
      });
  };
}

export async function alertModalChange(bool, title, text) {
  return async (dispatch, getState) => {
    dispatch({
      type: ALERT_MODAL_VISIBLE,
      payload: bool,
    });
    dispatch({
      type: ALERT_MODAL_TITLE,
      payload: title,
    });
    dispatch({
      type: ALERT_MODAL_TEXT,
      payload: text,
    });
  };
}

export async function selectedSubscriberChange(data, openType, bool) {
  return async (dispatch, getState) => {
    dispatch({
      type: SELECTED_SUBSCRIBER_DATA,
      payload: data,
    });
    if (openType == "sendMessage") {
      dispatch(sendPaymentLinkVisibleChange(bool));
    } else if (openType == "SubscriberDetail") {
      await dispatch(subscriberDetailModalVisibleChange(bool));
      dispatch(
        getSubscriberDetailList(data.subscription_id, data.reference_no)
      );
    }
  };
}

export async function getSubscriberDetailList(subId, refNo) {
  return async (dispatch, getState) => {
    dispatch({
      type: MODALS_LOADING,
      payload: true,
    });
    await axios({
      method: "GET",
      url:
        brand.urlSocket +
        "api/Payment/getPaynetSubscriberDetail?SubId=" +
        subId +
        "&RefNo=" +
        refNo,
      dataType: "json",
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch({
            type: SUBSCRIBER_DETAIL_LIST,
            payload: json,
          });
          dispatch({
            type: MODALS_LOADING,
            payload: false,
          });
          dispatch({
            type: SUBSCRIBER_DETAIL_MODAL_ISUPDATE,
          });
        } else {
          dispatch({
            type: MODALS_LOADING,
            payload: false,
          });
          dispatch(
            alertModalChange(
              true,
              "Uyarı",
              "Liste çekilemedi. Lütfen tekrar deneyiniz."
            )
          );
          dispatch({
            type: SUBSCRIBER_DETAIL_MODAL_ISUPDATE,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: MODALS_LOADING,
          payload: false,
        });
        dispatch(
          alertModalChange(
            true,
            "Uyarı",
            "Liste çekilemedi. Lütfen tekrar deneyiniz."
          )
        );
        dispatch({
          type: SUBSCRIBER_DETAIL_MODAL_ISUPDATE,
        });
      });
  };
}

export async function setBrandStatus(BrandId, status) {
  return async (dispatch, getState) => {
    dispatch({
      type: MODALS_LOADING,
      payload: true,
    });
    await axios({
      method: "GET",
      url:
        brand.urlSocket +
        "api/Payment/setBrandStatus?BrandId=" +
        BrandId +
        "&status=" +
        status,
      dataType: "json",
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then((response) => {
        dispatch(getPaynetSubscriber());
        dispatch({
          type: MODALS_LOADING,
          payload: false,
        });
        dispatch(alertModalChange(true, "Uyarı", response.data.responseData));
        dispatch({
          type: SUBSCRIBER_DETAIL_MODAL_ISUPDATE,
        });
      })
      .catch((error) => {
        dispatch({
          type: MODALS_LOADING,
          payload: false,
        });
        dispatch(
          alertModalChange(
            true,
            "Uyarı",
            "Liste çekilemedi. Lütfen tekrar deneyiniz."
          )
        );
        dispatch({
          type: SUBSCRIBER_DETAIL_MODAL_ISUPDATE,
        });
      });
  };
}

export async function getSubscriberAmountPlanUpdate() {
  return async (dispatch, getState) => {
    const {
      subscriberPlanUpdateAmount,
      subscriberPlanUpdateSubId,
      subscriberPlanUpdateRefNo,
      subscriberPlanUpdatePlanId,
    } = getState().get("adminReducer");

    dispatch({
      type: MODALS_LOADING,
      payload: true,
    });

    await axios({
      method: "GET",
      url:
        brand.urlSocket +
        "api/Payment/getPaynetSubscriberAmountUpdate?SubId=" +
        subscriberPlanUpdateSubId +
        "&RefNo=" +
        subscriberPlanUpdateRefNo +
        "&Amount=" +
        subscriberPlanUpdateAmount +
        "&PlanId=" +
        subscriberPlanUpdatePlanId,
      dataType: "json",
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch({
            type: MODALS_LOADING,
            payload: false,
          });
          dispatch({
            type: SUBSCRIBER_PLAN_UPDATE_AMOUNT,
            payload: "",
          });
          dispatch(
            subscriberPlanAmountUpdateModalVisibleChange(
              false,
              "",
              "",
              "00000000-0000-0000-0000-000000000000",
              ""
            )
          );
          dispatch(alertModalChange(true, "Uyarı", json.message));
        } else {
          dispatch({
            type: MODALS_LOADING,
            payload: false,
          });
          dispatch(
            subscriberPlanAmountUpdateModalVisibleChange(
              false,
              "",
              "",
              "00000000-0000-0000-0000-000000000000",
              ""
            )
          );
          dispatch(
            alertModalChange(
              true,
              "Uyarı",
              "Plan güncellenemedi. Lütfen tekrar deneyiniz."
            )
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: MODALS_LOADING,
          payload: false,
        });
        dispatch(
          subscriberPlanAmountUpdateModalVisibleChange(
            false,
            "",
            "",
            "00000000-0000-0000-0000-000000000000",
            ""
          )
        );
        dispatch(
          alertModalChange(
            true,
            "Uyarı",
            "Plan güncellenemedi. Lütfen tekrar deneyiniz."
          )
        );
      });
  };
}

export async function subscriberPlanAmountUpdateModalVisibleChange(
  bool,
  subId,
  refNo,
  planId,
  amount
) {
  return async (dispatch, getState) => {
    dispatch({
      type: SUBSCRIBER_PLAN_AMOUNT_UPDATE_MODAL_VISIBLE,
      payload: bool,
      dataSubId: subId,
      dataRefNo: refNo,
      dataPlanId: planId,
      dataAmount: amount,
    });
    dispatch({
      type: SUBSCRIBER_PLAN_AMOUNT_UPDATE_MODAL_ISUPDATE,
    });
  };
}

export async function subscriberPlanUpdateAmountChange(price) {
  return async (dispatch, getState) => {
    dispatch({
      type: SUBSCRIBER_PLAN_UPDATE_AMOUNT,
      payload: price,
    });
    dispatch({
      type: SUBSCRIBER_PLAN_AMOUNT_UPDATE_MODAL_ISUPDATE,
    });
  };
}

export async function getSubscriberExtensionsMonth() {
  return async (dispatch, getState) => {
    const {
      subscriberExtensionUpdateMonth,
      subscriberExtensionSubId,
    } = getState().get("adminReducer");

    dispatch({
      type: MODALS_LOADING,
      payload: true,
    });

    await axios({
      method: "GET",
      url:
        brand.urlSocket +
        "api/Payment/getPaynetSubscriberExtensionMonth?BrId=" +
        subscriberExtensionSubId +
        "&Month=" +
        subscriberExtensionUpdateMonth,

      dataType: "json",
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch({
            type: MODALS_LOADING,
            payload: false,
          });
          dispatch({
            type: SUBSCRIBER_PLAN_UPDATE_AMOUNT,
            payload: "",
          });
          dispatch(subscriberExtensionModalVisibleChange(false, "", ""));
          dispatch(alertModalChange(true, "Uyarı", json));
        } else {
          dispatch({
            type: MODALS_LOADING,
            payload: false,
          });
          dispatch(subscriberExtensionModalVisibleChange(false, "", ""));
          dispatch(
            alertModalChange(
              true,
              "Uyarı",
              "Abonelik uzatılamadı. Lütfen tekrar deneyiniz."
            )
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: MODALS_LOADING,
          payload: false,
        });
        dispatch(subscriberExtensionModalVisibleChange(false, "", ""));
        dispatch(
          alertModalChange(
            true,
            "Uyarı",
            "Abonelik uzatılamadı. Lütfen tekrar deneyiniz."
          )
        );
      });
  };
}

export async function subscriberDetailModalVisibleChange(bool) {
  return async (dispatch, getState) => {
    dispatch({
      type: SUBSCRIBER_DETAIL_MODAL_VISIBLE,
      payload: bool,
    });
    dispatch({
      type: SUBSCRIBER_DETAIL_MODAL_ISUPDATE,
    });
  };
}

export async function subscriberExtensionMonthChange(month) {
  return async (dispatch, getState) => {
    dispatch({
      type: SUBSCRIBER_EXTENSION_UPDATE_MONTH,
      payload: month,
    });
    dispatch({
      type: SUBSCRIBER_EXTENSION_UPDATE_MODAL_ISUPDATE,
    });
  };
}

export async function subscriberExtensionModalVisibleChange(
  bool,
  subId,
  brandName
) {
  return async (dispatch, getState) => {
    if (subId != "00000000-0000-0000-0000-000000000000") {
      dispatch({
        type: SUBSCRIBER_EXTENSION_UPDATE_MODAL_VISIBLE,
        payload: bool,
        sSubID: subId,
        sBrandName: brandName,
      });
      dispatch({
        type: SUBSCRIBER_EXTENSION_UPDATE_MODAL_ISUPDATE,
      });
    } else {
      dispatch(alertModalChange(true, "Uyarı", "Abonelik bulunamadı."));
    }
  };
}

export async function sendPaymentLinkNameSurnameChange(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SEND_PAYMENT_LINK_NAMESURNAME,
      payload: data,
    });
  };
}

export async function sendPaymentLinkPhoneChange(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SEND_PAYMENT_LINK_PHONE,
      payload: data,
    });
  };
}

export async function sendPaymentLinkPriceChange(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SEND_PAYMENT_LINK_PRICE,
      payload: data,
    });
  };
}

export async function sendPaymentLinkVisibleChange(bool) {
  return async (dispatch, getState) => {
    dispatch({
      type: SEND_PAYMENT_LINK_MODAL_VISIBLE,
      payload: bool,
    });
    dispatch({
      type: SEND_PAYMENT_LINK_MODAL_ISUPDATE,
    });
  };
}

export async function getSubscriberListStatusChange(statusNumber) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_SUBSCRIBER_LIST_STATUS,
      payload: statusNumber,
    });
  };
}

export async function getSubscriberInvoiceHeader(brandId) {
  return async (dispatch, getState) => {
    dispatch({
      type: MODALS_LOADING,
      payload: true,
    });
    await axios({
      method: "GET",
      url: brand.urlSocket + "api/Payment/getInvoiceHeader?BrandId=" + brandId,
      dataType: "json",
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch({ type: SUBSCRIBER_INVOICE_DATA, payload: json });
          dispatch({
            type: MODALS_LOADING,
            payload: false,
          });
        } else {
          dispatch({
            type: MODALS_LOADING,
            payload: false,
          });
        }
      })
      .catch((error) => {
        alert(error);
        dispatch({
          type: MODALS_LOADING,
          payload: false,
        });
      });
  };
}

export async function setSubscriberInvoiceHeader(data, brandId) {
  return async (dispatch, getState) => {
    dispatch({
      type: MODALS_LOADING,
      payload: true,
    });
    data.BrandId = brandId;
    await axios({
      method: "POST",
      url: brand.urlSocket + "api/Payment/setInvoiceHeader",
      dataType: "json",
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
      data,
    })
      .then((response) => {
        dispatch({
          type: MODALS_LOADING,
          payload: false,
        });
        dispatch(setSubscriberInvoiceData(null));
        dispatch(
          subscriberInvoiceModalVisibleChange(
            false,
            "00000000-0000-0000-0000-000000000000"
          )
        );
        dispatch(alertModalChange(true, "Uyarı", response.data.responseData));
      })
      .catch((error) => {
        dispatch({
          type: MODALS_LOADING,
          payload: false,
        });
        dispatch(setSubscriberInvoiceData(null));
        dispatch(
          subscriberInvoiceModalVisibleChange(
            false,
            "00000000-0000-0000-0000-000000000000"
          )
        );
        dispatch(
          alertModalChange(
            true,
            "Uyarı",
            "Fatura bilgileri güncellenemedi. Lütfen tekrar deneyiniz."
          )
        );
      });
  };
}

export async function setSubscriberInvoiceData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SUBSCRIBER_INVOICE_DATA,
      payload: data,
    });
    dispatch({
      type: SUBSCRIBER_INVOICE_MODAL_ISUPDATE,
    });
  };
}

export async function subscriberInvoiceModalVisibleChange(bool, brandId) {
  return async (dispatch, getState) => {
    if (bool === true) {
      if (brandId != "00000000-0000-0000-0000-000000000000") {
        dispatch({
          type: SUBSCRIBER_INVOICE_MODAL_VISIBLE,
          payload: bool,
          brId: brandId,
        });
        dispatch({
          type: SUBSCRIBER_INVOICE_MODAL_ISUPDATE,
        });
      } else {
        dispatch(alertModalChange(true, "Uyarı", "Abonelik bulunamadı."));
      }
    } else {
      dispatch({
        type: SUBSCRIBER_INVOICE_MODAL_VISIBLE,
        payload: bool,
        brId: brandId,
      });
      dispatch({
        type: SUBSCRIBER_INVOICE_MODAL_ISUPDATE,
      });
    }
  };
}

export async function getLicesnce() {
  return async (dispatch, getState) => {
    await axios({
      method: "GET",
      url: brand.urlSocket + "api/Admin/getLicence",
      dataType: "json",
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          dispatch({ type: SET_LICENCELIST, payload: json });
        }
      })
      .catch((error) => {
        dispatch(setModalAdmin(error));
      });
  };
}

export async function adminLoginControl(data) {
  return async (dispatch, getState) => {
    dispatch({ type: ADMINLOGIN_START });
    await axios({
      method: "POST",
      url: brand.urlSocket + "api/Admin/adminLoginControl",
      dataType: "json",
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
      data,
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          cookie.save("AdminId", json.Id, { path: "/" });
          cookie.save("AdminName", json.Name, { path: "/" });
          dispatch(setModalCodeAdmin(true));
        } else {
          dispatch(setModalAdmin(response.data.responseData));
        }
      })
      .catch((error) => {
        dispatch(setModalAdmin(error));
      });
    dispatch({ type: ADMINLOGIN_START });
  };
}

export async function setModalAdmin(text) {
  return async (dispatch, getState) => {
    dispatch({
      type: ADMIN_ERROR,
      payload: text,
    });
  };
}

export async function setModalCodeAdmin(visible) {
  return async (dispatch, getState) => {
    dispatch({ type: ADMIN_CODEMODAL, payload: visible });
  };
}

export async function formDataSetAdmin(key, val) {
  return async (dispatch, getState) => {
    dispatch({
      type: ADMIN_GLOBAL_FORM_DATA_SET,
      payload: { key, val },
    });
  };
}

export async function setLicenceModuleData(data, BrandId) {
  return async (dispatch, getState) => {
    dispatch({
      type: LICENCE_MODULE_DATA,
      payload: data,
      brandid: BrandId,
    });
  };
}

export async function setDealerListVisible(visible) {
  return async (dispatch, getState) => {
    if (visible) {
      await dispatch(getDealerList());
    }
    dispatch({
      type: DEALER_LIST_VISIBLE,
      payload: visible,
    });
  };
}

export async function getDealerList() {
  return async (dispatch, getState) => {
    let aa = await axios({
      method: "GET",
      url: brand.urlSocket + "api/Admin/getDealerList",
      dataType: "json",
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then(function(response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({
            type: DEALER_LIST_DATA,
            payload: json,
          });
        }
      })
      .catch(function(error) {
        alert(error);
      });
  };
}

export async function setDealerAddVisible(visible) {
  return async (dispatch, getState) => {
    dispatch({
      type: DEALER_ADD_VISIBLE,
      payload: visible,
    });
  };
}

export async function setDealerAddData(data, IsVisible = false) {
  return async (dispatch, getState) => {
    dispatch({
      type: DEALER_ADD_DATA,
      payload: data,
      visible: IsVisible,
    });
  };
}

export async function getDealerSingle(Id) {
  return async (dispatch, getState) => {
    let aa = await axios({
      method: "GET",
      url: brand.urlSocket + "api/Admin/getDealerSingle?Id=" + Id,
      dataType: "json",
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
    })
      .then(function(response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch(setDealerAddData(json, true));
        }
      })
      .catch(function(error) {
        alert(error);
      });
  };
}

export async function getDealerAddUpdate() {
  return async (dispatch, getState) => {
    const { dealerAdd } = getState().get("adminReducer");

    let aa = await axios({
      method: "POST",
      url: brand.urlSocket + "api/Admin/getDealerAddUpdate",
      dataType: "json",
      headers: {
        Authorization:
          "Basic " + base64.encode("JarvisPos" + ":" + "Shark123*"),
      },
      data: dealerAdd,
    })
      .then(function(response) {
        if (response.data.isStatus) {
          dispatch(getDealerList());
          dispatch(getDealerSingle(dealerAdd.Id));
        }
      })
      .catch(function(error) {
        alert(error);
      });
  };
}

export async function setNewBrandTrackData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_NEWBRAND_TRACK_COUNT,
      payload: data,
    });
  };
}

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEWBRAND_TRACK_COUNT:
      return {
        ...state,
        newBrandTrackCount: action.payload,
        licenceModuleUpdate: !state.licenceModuleUpdate,
      };
    case SET_BRAND_TRACK_COUNT:
      return {
        ...state,
        brandTrackCount: action.payload,
        licenceModuleUpdate: !state.licenceModuleUpdate,
        newBrandTrackCount: 0,
      };
    case DEALER_ADD_DATA:
      return {
        ...state,
        dealerAddVisible:
          action.visible == true ? true : state.dealerAddVisible,
        dealerAdd: action.payload,
        dealerAddUpdate: !state.dealerAddUpdate,
      };
    case DEALER_ADD_VISIBLE:
      return {
        ...state,
        dealerAdd: {
          Id: uuidv4(),
          CompanyTitle: "",
          Email: "",
          Code: "",
          NameSurname: "",
          Password: "",
          IsStatus: true,
          Phone: "",
          Address: "",
          TaxNumber: "",
          TaxAdministration: "",
        },
        dealerAddUpdate: !state.dealerAddUpdate,
        dealerAddVisible: action.payload,
      };
    case DEALER_LIST_VISIBLE:
      return {
        ...state,
        dealerListVisible: action.payload,
        dealerListUpdate: !state.dealerListUpdate,
      };
    case DEALER_LIST_DATA:
      return {
        ...state,
        dealerListData: action.payload,
        dealerListUpdate: !state.dealerListUpdate,
      };
    case LICENCE_MODULE_DATA:
      return {
        ...state,
        licenceModuleBrandId: action.brandid,
        licenceModuleData: action.payload,
        licenceModuleUpdate: !state.licenceModuleUpdate,
        licenceModuleVisible: action.payload != null,
      };
    case ADMINLOGIN_START:
      return { ...state, IsLoginStatus: !state.IsLoginStatus };
    case ADMIN_GLOBAL_FORM_DATA_SET:
      return {
        ...state,
        form_dataAdmin: {
          ...state.form_dataAdmin,
          [action.payload.key]: action.payload.val,
        },
      };
    case ADMIN_ERROR:
      return {
        ...state,
        form_dataAdmin: {
          ...state.form_dataAdmin,
          modalOpen: true,
          modalText: action.payload,
          modalTitle: "Uyarı",
        },
      };
    case ADMIN_CODEMODAL:
      return { ...state, IsSmsModal: action.payload };
    case SET_LICENCELIST:
      return { ...state, LicenceList: action.payload };
    case GET_SUBSCRIBER_LIST_STATUS:
      return { ...state, getSubscriberListStatus: action.payload };
    case PAYNET_SUBSCRIBER_LIST:
      return { ...state, paynetSubscriberList: action.payload };
    case ADMIN_SUBSCRIBER_PAGE_ISUPDATE:
      return {
        ...state,
        adminSubscriberPageIsupdate: !state.adminSubscriberPageIsupdate,
      };
    case PAYNET_SUBSCRIBER_LIST_LOADING:
      return { ...state, paynetSubscriberListLoading: action.payload };
    case SEND_PAYMENT_LINK_MODAL_VISIBLE:
      return { ...state, sendPaymentLinkModalVisible: action.payload };
    case SEND_PAYMENT_LINK_MODAL_ISUPDATE:
      return {
        ...state,
        sendPaymentLinkModalIsupdate: !state.sendPaymentLinkModalIsupdate,
      };
    case SELECTED_SUBSCRIBER_DATA:
      return { ...state, selectedSubscriberData: action.payload };
    case SEND_PAYMENT_LINK_NAMESURNAME:
      return { ...state, sendPaymentLinkNameSurname: action.payload };
    case SEND_PAYMENT_LINK_PHONE:
      return { ...state, sendPaymentLinkPhone: action.payload };
    case SEND_PAYMENT_LINK_PRICE:
      return { ...state, sendPaymentLinkPrice: action.payload };
    case ALERT_MODAL_VISIBLE:
      return { ...state, alertModalVisible: action.payload };
    case ALERT_MODAL_TITLE:
      return { ...state, alertModalTitle: action.payload };
    case ALERT_MODAL_TEXT:
      return { ...state, alertModalText: action.payload };
    case SUBSCRIBER_DETAIL_MODAL_VISIBLE:
      return { ...state, subscriberDetailModalVisible: action.payload };
    case SUBSCRIBER_DETAIL_MODAL_ISUPDATE:
      return {
        ...state,
        subscriberDetailModalIsupdate: !state.subscriberDetailModalIsupdate,
      };
    case SUBSCRIBER_DETAIL_LIST:
      return { ...state, subscriberDetailList: action.payload };
    case MODALS_LOADING:
      return { ...state, modalsLoading: action.payload };
    case SUBSCRIBER_PLAN_AMOUNT_UPDATE_MODAL_VISIBLE:
      return {
        ...state,
        subscriberPlanAmountUpdateModalVisible: action.payload,
        subscriberPlanUpdateSubId: action.dataSubId,
        subscriberPlanUpdatePlanId: action.dataPlanId,
        subscriberPlanUpdateRefNo: action.dataRefNo,
        subscriberPlanUpdateOldAmount: action.dataAmount,
      };
    case SUBSCRIBER_PLAN_AMOUNT_UPDATE_MODAL_ISUPDATE:
      return {
        ...state,
        subscriberPlanAmountUpdateModalIsupdate: !state.subscriberPlanAmountUpdateModalIsupdate,
      };
    case SUBSCRIBER_PLAN_UPDATE_AMOUNT:
      return {
        ...state,
        subscriberPlanUpdateAmount: action.payload,
      };
    case SUBSCRIBER_EXTENSION_UPDATE_MODAL_VISIBLE:
      return {
        ...state,
        subscriberExtensionUpdateModalVisible: action.payload,
        subscriberExtensionSubId: action.sSubID,
        subscriberExtensionBrandName: action.sBrandName,
      };
    case SUBSCRIBER_EXTENSION_UPDATE_MODAL_ISUPDATE:
      return {
        ...state,
        subscriberExtensionUpdateModalIsupdate: !state.subscriberExtensionUpdateModalIsupdate,
      };
    case SUBSCRIBER_EXTENSION_UPDATE_MONTH:
      return { ...state, subscriberExtensionUpdateMonth: action.payload };
    case SUBSCRIBER_INVOICE_DATA:
      return { ...state, subscriberInvoiceData: action.payload };
    case SUBSCRIBER_INVOICE_MODAL_VISIBLE:
      return {
        ...state,
        subscriberInvoiceModalVisible: action.payload,
        subscriberInvoiceBrandId: action.brId,
      };
    case SUBSCRIBER_INVOICE_MODAL_ISUPDATE:
      return {
        ...state,
        subscriberInvoiceModalIsupdate: !state.subscriberInvoiceModalIsupdate,
      };
    default:
      return state;
  }
};

export default adminReducer;
