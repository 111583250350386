import React from "react";
import "leaflet";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw-src.css";
import Button from "@material-ui/core/Button";
import "../Maps/stylesPopup.css";

const style = {
  width: "100%",
  height: "100%",
  borderRadius: 0,
};

class RegionMap extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.map = null;
  }
  componentDidMount() {
    this.initMap();

    this.renderObjectOnMap(this.props.PolygonJson);
    this.updateBusiness(this.props.SelectedBusiness);
  }

  componentDidUpdate({ SelectedBusiness }) {
    if (this.props.SelectedBusiness.length !== SelectedBusiness.length) {
      this.updateBusiness(this.props.SelectedBusiness);
    }
  }

  async updateBusiness(markersData) {
    this.map.eachLayer(function(layerData) {
      if (layerData.options.type === "brand") {
        layerData.remove();
      }
    });

    markersData.map((n, i) => {
      var style = 'style="background-color: #227093;"';
      var stylePin = 'style="border-top:10px solid #227093;"';

      L.marker(
        [n.Lat, n.Lng],
        {
          zIndexOffset: 100 + i,
          type: "brand",
          name: n.JaviPosBrandId,
          icon: new L.DivIcon({
            className: "my-div-icon",
            html:
              "<div " +
              style +
              ' class="my-div-span-brand"><div ' +
              stylePin +
              ' class="arrow-down-order"></div><div class="arrow-down-text">' +
              n.BusinessName +
              '</div></div><div class="pulse"></div>',
            iconSize: [80, 30],
            iconAnchor: [40, 30],
          }),
        },
        { title: "" }
      ).addTo(this.featureGroup);
    });
  }

  renderObjectOnMap = (obj) => {
    if (obj != null) {
      if (obj.length != 0) {
        var geoJsonObject = JSON.parse(obj);

        this.map.eachLayer(function(layerData) {
          if (layerData.myTag === "myGeoJSON") {
            layerData.remove();
          }
        });

        if (Array.isArray(geoJsonObject)) {
          var coordinates = { type: "Polygon", coordinates: geoJsonObject };

          const myStyle = {
            color: "#3388ff",
            weight: 4,
            opacity: 0.5,
          };

          // 1. render in featureGroup
          L.geoJson(coordinates, {
            style: myStyle,
            onEachFeature: (feature, layer) => {
              this.featureGroup.addLayer(layer);
              this.map.fitBounds(layer.getBounds());
              layer.myTag = "myGeoJSON";
            },
          });
        }
      }
    }
  };

  initMap = () => {
    this.map = L.map("map").setView([38.6389114, 35.691706], 6);
    this.featureGroup = L.featureGroup().addTo(this.map);
    this.drawControl = new L.Control.Draw({
      draw: {
        polygon: true,
        polyline: false,
        rectangle: false,
        circle: false,
        marker: false,
        circlemarker: false,
      },
      edit: {
        featureGroup: this.featureGroup,
        // poly: {
        // 	allowIntersection: false
        // }
      },
    }).addTo(this.map);

    L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 18,
      attribution: "",
      id: "mapbox.streets",
    }).addTo(this.map);

    this.map.on("draw:created", (e) => {
      this.featureGroup.addLayer(e.layer);
    });
    this.map.on("draw:drawstart", (e) => {
      this.featureGroup.clearLayers();
    });
  };

  onSaveLocation = () => {
    const polygons = [];
    this.featureGroup.eachLayer((layer) =>
      polygons.push(layer.toGeoJSON().geometry.coordinates)
    );
    this.props.onClickData(polygons);
  };

  render() {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <div id="map" className="search__map" style={style} />
        <Button
          style={{ zIndex: 999, position: "absolute", bottom: 7, right: 80 }}
          onClick={this.onSaveLocation}
          variant="contained"
          color="secondary"
        >
          {"Kaydet"}
        </Button>
      </div>
    );
  }
}

export default RegionMap;
