import React, { Component, memo } from 'react';
import { connect } from 'react-redux';
import {
  createSelector
} from 'reselect';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { getPackageAdditionDetail } from '../../../redux/modules/addition';
import DynamicFont from 'react-dynamic-font';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import { trMoneyConvert } from '../../../function/GeneralFunction';

class PackageAdditionDetail extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.IsPackageAdditionDetailUpdate == this.props.IsPackageAdditionDetailUpdate) {
      return false;
    }
    return true;
  }

  renderAdditionDetailDraw(addi) {
    return (
      <Grid container spacing={1} style={{ flexDirection: 'row' }}>
        <Grid item md={5} style={{ textAlign: 'left' }}>
          <span style={{ marginLeft: 7, fontSize: 10, fontWeight: 500, color: 'white' }}>
            {"Adisyon No"}
          </span>
        </Grid>
        <Grid item md={7} style={{ textAlign: 'right' }}>
          <span style={{ marginRight: 7, fontSize: 10, fontWeight: 500, color: 'white', textTransform: 'capitalize' }}>{addi.AdditionId}</span>
        </Grid>
        <Grid item md={5} style={{ textAlign: 'left' }}>
          <span style={{ marginLeft: 7, fontSize: 10, fontWeight: 500, color: 'white' }}>
            {"Müşteri Adı"}
          </span>
        </Grid>
        <Grid item md={7} style={{ textAlign: 'right' }}>
          <span style={{ marginRight: 7, fontSize: 10, fontWeight: 500, color: 'white', textTransform: 'capitalize' }}>{addi.CustomerName}</span>
        </Grid>
      </Grid>
    )
  }


  renderProductDetail(property) {
    return property.map((n, x) => {
      return (
        <span key={x} style={{ display: 'block', fontSize: 9, fontWeight: 600, color: '#00BCD4', textTransform: 'capitalize' }}>{this.renderPropertyDetail(n.Item)}{(n.MenuDetailSelected != null ? (n.MenuDetailSelected.length > 0 ? "(" : "") : "")}{this.renderPropertyDetailMenu(n.MenuDetailSelected)}{(n.MenuDetailSelected != null ? (n.MenuDetailSelected.length > 0 ? ")" : "") : "")}</span>
      )
    })
  }

  renderPropertyDetail(item) {
    return item.map((n, x) => {
      if (n.Default) {
        return (
          <span key={x} style={{ marginLeft: 5, fontSize: 9, fontWeight: 600, color: '#00BCD4', textTransform: 'capitalize' }}>{n.Quantity > 0 ? n.Quantity + "x" : ""}{n.Name}</span>
        )
      } else {
        return null;
      }
    })
  }

  renderPropertyDetailMenu(MenuDetail) {
    if (MenuDetail != null) {
      if (MenuDetail.length > 0) {
        return MenuDetail.map((n, x) => {
          return n.Item.map((y, i) => {
            if (y.Default) {
              return (
                <span key={i} style={{ fontSize: 9, fontWeight: 600, color: '#00BCD4', textTransform: 'capitalize' }}>{" "}{y.Quantity > 0 ? y.Quantity + "x" : ""}{y.Name}{" "}</span>
              )
            } else {
              return null;
            }
          })
        })
      }
    }
  }

  renderProductDetailUnit(unit) {
    if (unit.length == 1) {
      return null;
    } else {
      return unit.map((n, x) => {
        if (n.IsSelected) {
          return "(" + n.UnitName + ")"
        }
      })
    }
  }

  renderAdditionListPrice(item) {
    let price = item.Price;
    item.Unit.map(n => {
      if (n.IsSelected) {
        price = n.Price
      }
    })
    item.Property.map(n => {
      n.Item.map(n => {
        if (n.Default && n.Quantity == 0) {
          price += n.Price
        } else if (n.Default && n.Quantity > 0) {
          price += (n.Price * n.Quantity)
        }
      })
    })

    item.Menu.map(n => {
      n.Item.map(n => {
        if (n.Default) {
          price += n.Price
        }
      })

      if (n.MenuDetailSelected != null) {
        if (n.MenuDetailSelected.length != 0) {
          n.MenuDetailSelected.map(x => {
            x.Item.map(n => {
              if (n.Default && n.Quantity == 0) {
                price += n.Price
              } else if (n.Default && n.Quantity > 0) {
                price += (n.Price * n.Quantity)
              }
            })
          })
        }
      }
    })

    price = price * item.Quantity;

    return price.toFixed(2).replace(/[.]/, ",");
  }

  renderProductDetailDraw(productList) {
    return productList.filter(x => x.IsDelete == false && x.ParentIndex == 0).map((n, i) => {
      return (
        <Grid key={i} item md={12} style={{ marginTop: 2, left: 2, marginBottom: 5, right: 2, flexWrap: 'nowrap' }}>
          <Grid container style={{ flexDirection: 'row' }}>
            <Grid item md={1}>
              <span style={{ marginLeft: 7, fontSize: 10, fontWeight: 500, color: 'white', marginRight: 5 }}>
                {n.Quantity}
              </span>
            </Grid>
            <Grid item md={7} style={{ textAlign: 'left' }}>
              <span style={{ marginLeft: 7, fontSize: 10, fontWeight: 500, color: 'white', textTransform: 'capitalize' }}>{(n.IsTreat ? "(İkram) " : "")}{n.Name} {this.renderProductDetailUnit(n.Unit)}</span>
            </Grid>
            <Grid item md={2}>
              <span style={{ marginLeft: 7, fontSize: 10, fontWeight: 500, color: 'white', marginRight: 5 }}>
                {n.PreparationFinishTime}{"dk"}
              </span>
            </Grid>
            <Grid item md={2} style={{ textAlign: 'right' }}>
              <span style={{ marginRight: 7, fontSize: 10, fontWeight: 500, color: 'white' }}>{this.renderAdditionListPrice(n)}</span>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={1}>
            </Grid>
            <Grid item md={11} style={{ whiteSpace: 'pre-line', textAlign: 'left' }}>
              <span style={{ fontSize: 9, fontWeight: 600, color: '#2980b9', textTransform: 'capitalize' }}>
                {this.renderProductDetail(n.Property)}
                {this.renderProductDetail(n.Menu)}
                {
                  (n.Description != null ? (n.Description.length != 0 ? <span style={{ marginLeft: 7, fontSize: 10, fontWeight: 600, color: '#2980b9', textTransform: 'capitalize' }}>{"("}{n.Description}{")"}</span> : null) : null)
                }
              </span>
            </Grid>
          </Grid>
        </Grid>
      )
    })
  }

  renderAdditionHistoryItem(n) {
    return n.map((n, i) => {
      return (
        <TableRow key={i}>
          <TableCell align='left' style={{ fontSize: 10, padding: 5 }}>{n.UserName}</TableCell>
          <TableCell align='left' style={{ fontSize: 10, padding: 5 }}>{moment(n.CreationTime).format('YYYY-MM-DD / HH:mm:ss')}</TableCell>
          <TableCell align='left' style={{ fontSize: 10, padding: 5, color: (n.OperationType == "Package" ? "#2196F3" : (n.OperationType == "Item" ? (n.OperationDetailType == 0 ? "#4CAF50" : (n.OperationDetailType == 1 ? "#FF9800" : "#F44336")) : (n.OperationDetailType == 0 ? "#4CAF50" : (n.OperationDetailType == 1 ? "#FF9800" : "#F44336")))) }}>{n.OperationText}</TableCell>
        </TableRow>
      )
    });
  }

  renderPackageStatusDraw(data, date, IsDeliveryEntegration) {
    return data.map((n, i) => {
      return (
        <div style={{
          padding: 5,
          backgroundColor:
            (n.StatusText == "Beklemede" ? '#7f8c8d' :
              (n.StatusText == "Onaylandı" ? '#43A047' :
                (n.StatusText == "Hazırlanıyor" ? '#8e44ad' :
                  (n.StatusText == "YolaCikti" ? (IsDeliveryEntegration ? '#8e44ad' : '#FFB300') :
                    (n.StatusText == "TeslimEdildi" ? '#3498db' :
                      (n.StatusText == "İptalEdildi" ? '#E53935' : ''))))))
          , display: 'flex', width: '100%', marginBottom: 5
        }}>
          <Grid item md={4} style={{ textAlign: 'left', fontSize: 14, fontWeight: 600 }}>
            {(!IsDeliveryEntegration ? n.StatusText : (n.StatusText == "Hazırlanıyor" ? "Hazırlandı" :
              (n.StatusText == "YolaCikti" ? "Hazırlandı" :
                (n.StatusText == "TeslimEdildi" ? "Kuryeye Teslim Edildi" : n.StatusText))))}
          </Grid>
          <Grid item md={4} style={{ textAlign: 'right', fontSize: 14, fontWeight: 600 }}>
            {moment(n.Datetime).format('HH:mm:ss')}
          </Grid>
          <Grid item md={4} style={{ textAlign: 'right', fontSize: 14, fontWeight: 600 }}>
            {moment.duration(moment(n.Datetime).diff(moment(date))).asMinutes().toFixed(0)} {"dk"}
          </Grid>
        </div>
      )
    })
  }

  renderPaymentsDraw(payments) {
    return payments.map((n, i) => {
      return (
        <Grid key={i} item md={12} style={{ marginTop: 5, left: 2, marginBottom: 5, right: 2, flexWrap: 'nowrap' }}>
          <Grid container style={{ flexDirection: 'row' }}>
            <Grid item md={10} style={{ textAlign: 'left' }}>
              <span style={{ marginLeft: 7, fontSize: 10, fontWeight: 500, color: 'white' }}>
                {(n.PaymentType != 2 ? n.PaymentName : "İndirim")}
                {(n.CustomerName != "" ? (n.CustomerName != null ? " - " + n.CustomerName : "") : "")}
              </span>
            </Grid>
            <Grid item md={2} style={{ textAlign: 'right' }}>
              <span style={{ marginRight: 7, fontSize: 10, fontWeight: 500, color: 'white', textTransform: 'capitalize' }}>{trMoneyConvert(n.Price,2)}</span>
            </Grid>
          </Grid>
        </Grid>
      )
    })
  }

  IsEntegrationDeliveryStatus(n) {
    var IsStatus = false;
    var go = n.YemekSepetiAddress.TrendyolPackageType;
    if (n.TableName == "YemekSepeti") {
      IsStatus = (go == "VALE" ? true : false);
    } else if (n.TableName == "GetirYemek") {
      IsStatus = (n.CustomerAddressId == "00000000-0000-0000-0000-000000000000" ? true : false);
    } else if (n.TableName == "TrendyolYemek") {
      IsStatus = (go == "GO" ? true : false);
    } else if (n.TableName == "MigrosYemek") {
      IsStatus = (go == "MIGROS" ? true : false);
    }
    return IsStatus;
  }

  renderAdditionDraw(n) {
    const { classes } = this.props;
    if (n == null) {
      return null;
    }

    var IsEntegrationDeliveryStatus = this.IsEntegrationDeliveryStatus(n);
console.log(n)
    return (
      <div style={{ display: 'block', zIndex: 99999 }}>
        <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#16a085', color: 'white' }}>{"Adisyon Detayları"}</div>
        <Grid container style={{ lineHeight: 1 }}>
          {this.renderAdditionDetailDraw(n)}
        </Grid>
        <Grid spacing={1} container style={{ padding: 8, lineHeight: 1 }}>
          <Grid item md={12} style={{ textAlign: 'left' }}>
            <span style={{ fontSize: 10, fontWeight: 500, color: 'white', textTransform: 'capitalize' }}>{"Adres:"} {n.CustomerAddressText}</span>
          </Grid>
          <Grid item md={12} style={{ textAlign: 'left' }}>
            <span style={{ fontSize: 10, fontWeight: 500, color: 'white', textTransform: 'capitalize' }}>{"Ödeme Notu:"} {n.PaymentNote}</span>
          </Grid>
          {
            (
              n.PackageCourierPhone.length > 0 ?
                <Grid item md={12} style={{ textAlign: 'left' }}>
                  <span style={{ fontSize: 10, fontWeight: 500, color: 'white', textTransform: 'capitalize' }}>{"Kurye Telefon Numarası:"} {n.PackageCourierPhone}</span>
                </Grid>
                :
                null
            )
          }
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#2980b9', color: 'white' }}>{"Ürün Detayları"}</div>
        <Grid container style={{ flexDirection: 'row' }}>
          <Grid item md={1}>
            <span style={{ fontSize: 9, marginLeft: 7 }}>{"Miktar"}</span>
          </Grid>
          <Grid item md={7} style={{ textAlign: 'left' }}>
            <span style={{ fontSize: 9, marginLeft: 7 }}>{"Adı"}</span>
          </Grid>
          <Grid item md={2}>
            <span style={{ fontSize: 9, marginLeft: 7 }}> {"H. Süresi"}</span>
          </Grid>
          <Grid item md={2} style={{ textAlign: 'right' }}>
            <span style={{ fontSize: 9, marginRight: 7 }}> {"Tutarı"}</span>
          </Grid>
        </Grid>
        <Grid container style={{ lineHeight: 1 }}>
          {this.renderProductDetailDraw(n.ProductItems)}
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#f39c12', color: 'white' }}>{"Adisyon Geçmişi"}</div>
        <Grid container style={{ lineHeight: 1 }}>
          <Table className={classNames(classes.table, classes.bordered)} style={{ marginTop: 0 }}>
            <TableHead>
              <TableRow>
                <TableCell align='left' style={{ fontSize: 10, padding: 5 }}>{"Kullanıcı"}</TableCell>
                <TableCell align='left' style={{ fontSize: 10, padding: 5 }}>{"Tarih"}</TableCell>
                <TableCell align='left' style={{ fontSize: 10, padding: 5 }}>{"İşlem"}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.renderAdditionHistoryItem(n.AdditionHistories)}
            </TableBody>
          </Table>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#9b59b6', color: 'white' }}>{"Ödeme Detayları"}</div>
        <Grid container style={{ lineHeight: 1 }}>
          {n.Payments != null ? this.renderPaymentsDraw(n.Payments) : null}
        </Grid>
        {
          (n.OrderType == 1 ?
            <div style={{ marginTop: 10, backgroundColor: '#313131' }}>
              <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#7f8c8d', color: 'white' }}>{"Paket Durum Değişiklikleri"}</div>
              <Grid container style={{ lineHeight: 1, padding: 10 }}>
                {n.PackageStatusList.length > 0 ? this.renderPackageStatusDraw(n.PackageStatusList, n.CreationTime, IsEntegrationDeliveryStatus) : null}
              </Grid>
            </div>
            : null)
        }

      </div>
    )
  }



  handleClose = () => {
    this.props.getPackageAdditionDetail("", null);
  };

  render() {
    const {
      classes,
      packageAdditionDetail,
      anchorElPackageDetail
    } = this.props;

    return (
      <div style={{ display: 'inline' }}>
        <Popover
          open={Boolean(anchorElPackageDetail)}
          anchorEl={anchorElPackageDetail}
          PaperProps={{ style: { borderRadius: 0 } }}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div style={{ padding: 5, width: 400, borderRadius: 0, backgroundColor: '#424242', border: '4px solid #313131' }}>{this.renderAdditionDraw(packageAdditionDetail)}</div>
        </Popover>
      </div>
    )
  }
}

const valueSelector = (state, props) => state.get('additionReducer').IsPackageAdditionDetailUpdate;
const value = createSelector([valueSelector],
  (value) => value);

const mapStateToProps = (state, props) => ({
  IsPackageAdditionDetailUpdate: value(state, props),
  packageAdditionDetail: state.get('additionReducer').packageAdditionDetail,
  anchorElPackageDetail: state.get('additionReducer').anchorElPackageDetail,
})

const mapDispatchToProps = {
  getPackageAdditionDetail
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PackageAdditionDetail);
