import React, { Fragment, useState, memo } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import styles from "../paketyolda-jss";
import {
  setPaketYoldaRegionAddData,
  setPaketYoldaRegionAddVisible,
  setModalPaketYolda,
  setRegion,
} from "../../../../redux/modules/paketyolda";
import { createSelector } from "reselect";
import moment from "moment";
import Icon from "@material-ui/core/Icon";
import "react-credit-cards/es/styles-compiled.css";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import classNames from "classnames";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);
import DatePicker, { registerLocale } from "react-datepicker";
import {
  CapitalizeText,
  ComplateLowerText,
} from "../../../../function/GeneralFunction";
import InputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RegionMap from "./RegionMap";
import cookie from "react-cookies";

class RegionAdd extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.paketYoldaRegionAddUpdate ==
      this.props.paketYoldaRegionAddUpdate
    ) {
      return false;
    }
    return true;
  }

  async componentDidMount() {}

  returnBusinessList() {
    const { paketYoldaBusinessList } = this.props;

    var list = [];
    paketYoldaBusinessList.map((n, i) => {
      list.push({
        Name: n.BusinessName,
        JaviPosBrandId: n.BrandId,
        RegionId: cookie.load("PaketYoldaId"),
        Lat: n.Lat,
        Lng: n.Lng,
      });
    });

    return list;
  }

  returnLatLngList() {
    const { paketYoldaBusinessList, paketYoldaRegionAddData } = this.props;
    var list = [];

    paketYoldaBusinessList.map((n, i) => {
      if (
        paketYoldaRegionAddData.RegionBusiness.filter(
          (x) => x.JaviPosBrandId == n.BrandId
        ).length > 0
      ) {
        list.push(n);
      }
    });

    return list;
  }

  returnCourirerList() {
    const { paketYoldaCourierList } = this.props;
    var list = [];
    paketYoldaCourierList.map((n, i) => {
      list.push({
        Name: n.NameSurname,
        CourierId: n.Id,
        RegionId: cookie.load("PaketYoldaId"),
      });
    });
    return list;
  }

  handleChangePaketYoldaRegionAdd = (Name) => (event) => {
    const pointer = event.target.selectionStart;
    const element = event.target;

    const { paketYoldaRegionAddData } = this.props;
    paketYoldaRegionAddData[Name] =
      Name == "RegionName"
        ? CapitalizeText(event.target.value)
        : event.target.value;
    this.props
      .setPaketYoldaRegionAddData(paketYoldaRegionAddData, true)
      .then(() => {});

    window.requestAnimationFrame(() => {
      element.selectionStart = pointer;
      element.selectionEnd = pointer;
    });
  };

  handleChangePaketYoldaRegionAuto(data, Name) {
    const { paketYoldaRegionAddData } = this.props;
    paketYoldaRegionAddData[Name] = data;
    this.props
      .setPaketYoldaRegionAddData(paketYoldaRegionAddData, true)
      .then(() => {});
  }

  async handleSubmitRegionAddSave(data) {
    const { paketYoldaRegionAddData } = this.props;
    if (data.length == 0) {
      this.props
        .setModalPaketYolda("Harita üzerinde bölge seçimi yapmalısınız!")
        .then(() => {});
      return;
    } else if (paketYoldaRegionAddData.RegionBusiness.length == 0) {
      this.props
        .setModalPaketYolda("İşletme seçimi yapmalısınız!")
        .then(() => {});
      return;
    } else if (paketYoldaRegionAddData.RegionCourier.length == 0) {
      this.props
        .setModalPaketYolda("Kurye Seçimi yapmalısınız!")
        .then(() => {});
      return;
    } else if (paketYoldaRegionAddData.RegionName.trim().length == 0) {
      this.props.setModalPaketYolda("Bölge adı girmelisiniz!").then(() => {});
      return;
    }

    await this.props.setRegion(data[0]).then(() => {});
  }

  render() {
    const {
      classes,
      paketYoldaRegionAddVisible,
      paketYoldaRegionAddData,
      paketYoldaRegionAddLoading,
    } = this.props;

    return (
      <Dialog
        open={paketYoldaRegionAddVisible}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"lg"}
        PaperProps={{ classes: { root: classes.dialogPaperRegionAdd } }}
      >
        <DialogTitle style={{ padding: "24px 24px 5px", marginBottom: 10 }}>
          {"Bölge Tanımı Ekleme ve Düzenleme"}
        </DialogTitle>
        <DialogContent
          style={{ overflow: "hidden auto", height: "100vh", width: "100%" }}
        >
          <div style={{ display: "flex", width: "100%", height: "100%" }}>
            <div style={{ width: "30%", paddingLeft: 5, paddingRight: 5 }}>
              <Grid container spacing={1}>
                <Grid item md={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="name-simple">{"Bölge Adı"}</InputLabel>
                    <Input
                      autoComplete="new-email"
                      fullWidth
                      required
                      placeholder={"Bölge Adı Giriniz"}
                      defaultValue={paketYoldaRegionAddData.RegionName}
                      onChange={this.handleChangePaketYoldaRegionAdd(
                        "RegionName"
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={this.returnBusinessList()}
                    value={paketYoldaRegionAddData.RegionBusiness}
                    getOptionSelected={(option, value) =>
                      option.JaviPosBrandId === value.JaviPosBrandId
                    }
                    onChange={(event, value) =>
                      this.handleChangePaketYoldaRegionAuto(
                        value,
                        "RegionBusiness"
                      )
                    }
                    getOptionLabel={(option) => option.Name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        classes={{ root: classes.MuiFormControlAuto }}
                        label="İşletme Seçimi"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={12}>
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={this.returnCourirerList()}
                    value={paketYoldaRegionAddData.RegionCourier}
                    getOptionSelected={(option, value) =>
                      option.CourierId === value.CourierId
                    }
                    onChange={(event, value) =>
                      this.handleChangePaketYoldaRegionAuto(
                        value,
                        "RegionCourier"
                      )
                    }
                    getOptionLabel={(option) => option.Name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        classes={{ root: classes.MuiFormControlAuto }}
                        label="Kurye Seçimi"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </div>
            <div style={{ width: "70%" }}>
              <RegionMap
                SelectedBusiness={this.returnLatLngList()}
                PolygonJson={paketYoldaRegionAddData.PolygonJson}
                onClickData={(data) => this.handleSubmitRegionAddSave(data)}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ backgroundColor: "#F44336" }}
            onClick={() =>
              this.props.setPaketYoldaRegionAddVisible(false).then(() => {})
            }
            color="primary"
          >
            {"Çıkış"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

RegionAdd.propTypes = {
  classes: PropTypes.object.isRequired,
};

const valueSelector = (state, props) =>
  state.get("paketYoldaReducer").paketYoldaRegionAddUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
  paketYoldaRegionAddUpdate: value(state, props),
  paketYoldaRegionAddVisible: state.get("paketYoldaReducer")
    .paketYoldaRegionAddVisible,
  paketYoldaRegionAddLoading: state.get("paketYoldaReducer")
    .paketYoldaRegionAddLoading,
  paketYoldaRegionAddData: state.get("paketYoldaReducer")
    .paketYoldaRegionAddData,

  paketYoldaCourierList: state.get("paketYoldaReducer").paketYoldaCourierList,
  paketYoldaBusinessList: state.get("paketYoldaReducer").paketYoldaBusinessList,
});

const mapDispatchToProps = {
  setPaketYoldaRegionAddVisible,
  setPaketYoldaRegionAddData,
  setModalPaketYolda,
  setRegion,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(RegionAdd)));
