import React from 'react';
import {
    createSelector
} from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { CounterTrading } from 'dan-components';
import logo from 'dan-images/logo.png';
import colorfull from 'dan-api/palette/colorfull';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import messageStyles from 'dan-styles/Messages.scss';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import { EmptyData } from 'dan-components';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TablePagination from '@material-ui/core/TablePagination';
import {
    setPaketYoldaRegionAddVisible, setAnchorEntegration, setMileageChargeVisible, setPaketYoldaBrandSettingsVisible,
    setPaketYoldaBrandSettingsData, setBrandPreparationTime, setEntegrationBrandAddModal, setEntBrandList, setEntegrationBrandAddData,
    setEntBrandDelete
} from '../../../../redux/modules/paketyolda';
import {
    getEntegrationRestList
} from '../../../../redux/modules/settings';
import { getPaketYolda } from '../../../../function/GeneralFunction';
import CircularProgress from '@material-ui/core/CircularProgress';
import Entegration from '../../../Templates/layouts/Entegration';
import cookie from 'react-cookies';
import InputMask from 'react-input-mask';

const separatorList = {
    comma: {
        name: "comma",
        regex: /^\d+(,\d{0,2})?$/
    },
    period: {
        name: "period",
        regex: /^\d+(\.\d{0,6})?$/
    }
};

const styles = theme => ({
    nested: {
        paddingLeft: theme.spacing(4),
    }
});

class BusinessSettingsList extends React.Component {
    constructor() {
        super();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.paketYoldaBusinessListUpdate == this.props.paketYoldaBusinessListUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {

    }

    handleClose = () => {
        this.props.setAnchorEntegration(null);
    };

    renderEntegrationDetail() {
        const { anchorElEntegrationDetail, classes } = this.props;
        return (
            <div style={{ display: 'inline' }}>
                <Popover
                    open={Boolean(anchorElEntegrationDetail)}
                    anchorEl={anchorElEntegrationDetail}
                    PaperProps={{ style: { borderRadius: 0 } }}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <div style={{ padding: 5, width: 450, borderRadius: 0, backgroundColor: '#424242', border: '4px solid #313131' }}>
                        <Entegration type={"paketyolda"} classes={classes} />
                    </div>
                </Popover>
            </div>
        )
    }

    handleClickPopover = event => {
        cookie.save('BrandId', event.currentTarget.id, { path: '/' });
        this.props.getEntegrationRestList(true, true, true, true, true, event.currentTarget, true);
    };

    handleChangeSettings = name => event => {
        const { brandSettingsData } = this.props;
        brandSettingsData[name] = event.target.value;
        this.props.setPaketYoldaBrandSettingsData(brandSettingsData).then(() => { })
    };

    renderSettings() {
        const { classes, brandSettingsVisible, brandSettingsData } = this.props;

        return (
            <Dialog
                open={brandSettingsVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'sm'}
                PaperProps={{ classes: { root: classes.dialogPaper } }}
            >
                <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    {brandSettingsData?.BusinessName ?? ""} {"Ayarları"}
                </DialogTitle>
                <DialogContent style={{ overflow: 'hidden auto' }}>
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                            <FormControl fullWidth className={classes.formControl}>
                                <InputLabel htmlFor="name-simple">{'Hazırlanma Süresi'}</InputLabel>
                                <Input
                                    autoComplete="preparation-time"
                                    type="number"
                                    fullWidth
                                    inputProps={{
                                        min: 0,
                                        max: 100,
                                        step: 1
                                    }}
                                    required
                                    value={brandSettingsData?.PreparationTime ?? 0}
                                    onChange={this.handleChangeSettings("PreparationTime")}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.setBrandPreparationTime().then(() => { })} variant="contained" color="secondary">
                        {"Kaydet"}
                    </Button>
                    <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setPaketYoldaBrandSettingsVisible(false, null).then(() => { })} color="primary">
                        {"Çıkış"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleChangeBrandAdd = name => event => {
        const { entegrationBrandAdd } = this.props;
        entegrationBrandAdd[name] = event.target.value;
        this.props.setEntegrationBrandAddData(entegrationBrandAdd).then(() => { })
    };

    handleChangeBrandAddLocation(value, name) {
        const { entegrationBrandAdd } = this.props;
        entegrationBrandAdd[name] = value;
        this.props.setEntegrationBrandAddData(entegrationBrandAdd).then(() => { })
    };

    renderBrandAdd() {
        const { classes, entegrationBrandAddVisible, entegrationBrandAdd } = this.props;

        return (
            <Dialog
                open={entegrationBrandAddVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'xs'}
                PaperProps={{ classes: { root: classes.dialogPaper } }}
            >
                <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                    {"Entegrasyon İşletme Tanımı"}
                </DialogTitle>
                <DialogContent style={{ overflow: 'hidden auto' }}>
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel htmlFor="name-simple">{"İşletme Adı"}</InputLabel>
                                <Input
                                    defaultValue={entegrationBrandAdd.RestName || ""}
                                    onFocus={event => { event.target.select() }}
                                    onChange={this.handleChangeBrandAdd("RestName")}
                                    type="text"
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={12}>
                            <FormControl className={classes.formControl} fullWidth style={{ marginTop: 10 }}>
                                <InputMask
                                    mask="0 999 999 99 99"
                                    onFocus={event => { event.target.select() }}
                                    value={entegrationBrandAdd.BrandPhone || ''}
                                    onChange={this.handleChangeBrandAdd("BrandPhone")}
                                >
                                    {() => <TextField
                                        name={"Phone"}
                                        placeholder='Telefon Numarası'
                                        fullWidth
                                        classes={{ root: classes.MuiFormControlLoginUser }}
                                        required
                                        margin="dense"
                                        type="text"
                                    />}
                                </InputMask>
                            </FormControl>
                        </Grid>
                        <Grid item md={12}>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel htmlFor="name-simple">{"Hazırlanma Süresi"}</InputLabel>
                                <Input
                                    defaultValue={entegrationBrandAdd.PreparationTime || 0}
                                    onFocus={event => { event.target.select() }}
                                    onChange={this.handleChangeBrandAdd("PreparationTime")}
                                    type="number"
                                    fullWidth
                                    inputProps={{
                                        min: 0,
                                        max: 100,
                                        step: 1
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6}>
                            <FormControl className={classes.formControl} fullWidth>
                                <TextField
                                    label="İşletme Latitude"
                                    onFocus={event => { event.target.select() }}
                                    value={entegrationBrandAdd.RestLat || 0}
                                    onChange={({ target: { value } }) => this.handleChangeBrandAddLocation(value, "RestLat")}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6}>
                            <FormControl className={classes.formControl} fullWidth>
                                <TextField
                                    label="İşletme Longitude"
                                    onFocus={event => { event.target.select() }}
                                    value={entegrationBrandAdd.RestLng || 0}
                                    onChange={({ target: { value } }) => this.handleChangeBrandAddLocation(value, "RestLng")}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setEntegrationBrandAddModal(false).then(() => { })} color="primary">
                        {"Çıkış"}
                    </Button>
                    <Button onClick={() => this.props.setEntBrandList().then(() => { })} variant="contained" color="secondary">
                        {"Kaydet"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    render() {
        const { paketYoldaBusinessListLoading, paketYoldaSettingsMenu, paketYoldaBusinessList, classes, entegrationBrandList } = this.props;

        if (paketYoldaSettingsMenu != "BusinessSettings") {
            return null;
        }

        if (paketYoldaBusinessListLoading) {
            return <div style={{ width: '100%', textAlignLast: 'center', paddingTop: 20 }}>
                <CircularProgress
                    style={{ color: '#fafafa' }}
                    thickness={4}
                    className={classes.progress}
                />
            </div>
        }

        return (
            <Paper className={classes.root} elevation={0} style={{ backgroundColor: 'transparent', borderRadius: 0, height: '100%' }}>
                {this.renderEntegrationDetail()}
                {this.renderSettings()}
                {this.renderBrandAdd()}
                <div style={{ height: 'calc(100% - 43px)', padding: 5, overflowY: 'auto' }}>
                    <div style={{ backgroundColor: 'rgb(49, 49, 49)', padding: 5 }}>
                        <div style={{ backgroundColor: 'rgb(66, 66, 66)', marginBottom: 10, width: '100%', padding: 10, borderBottom: '1px solid rgba(209, 216, 224,.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {"JaviPos Tanımlı Olan İşletmeler"}
                        </div>
                        <Table className={classNames(classes.table, classes.bordered)} style={{ marginTop: 10 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left'>{'İşletme Adı'}</TableCell>
                                    <TableCell align='center'>{'Otomatik Atama'}</TableCell>
                                    <TableCell align='center'>{'Hazırlanma Süresi'}</TableCell>
                                    <TableCell align={"right"}>{'İşlemler'}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paketYoldaBusinessList.map((n, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell align='left'>{n.BusinessName}</TableCell>
                                            <TableCell align='center'>
                                                <Icon style={{ color: (n.IsAutomaticSubmission ? "#16a085" : "#c0392b") }}>{(n.IsAutomaticSubmission ? "check_box" : "indeterminate_check_box")}</Icon>
                                            </TableCell>
                                            <TableCell align='center'>{n.PreparationTime ?? 0}{"dk"}</TableCell>
                                            <TableCell align={"right"}>
                                                <Tooltip title={"Ayarlar"}>
                                                    <IconButton
                                                        style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                        onClick={() => this.props.setPaketYoldaBrandSettingsVisible(true, n).then(() => { })}>
                                                        <Icon>{"settings"}</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={"Kilometre Ücretlendirme"}>
                                                    <IconButton
                                                        style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                        onClick={() => this.props.setMileageChargeVisible(true, n.BrandId, 1).then(() => { })}>
                                                        <Icon>{"edit_road"}</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={"Entegrasyon İşlemleri"}>
                                                    <IconButton
                                                        id={n.BrandId}
                                                        style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                        onClick={this.handleClickPopover}>
                                                        <Icon>{"extension"}</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        <div style={{ justifyContent: 'space-between', backgroundColor: 'rgb(66, 66, 66)', marginBottom: 10, width: '100%', padding: 10, borderTop: '1px solid rgba(209, 216, 224,.5)', borderBottom: '1px solid rgba(209, 216, 224,.5)', display: 'flex', alignItems: 'center' }}>
                            {"Api Tanımlı Olan İşletmeler"}
                            <Button style={{ textTransform: 'capitalize' }} variant="contained" color="primary" onClick={() => this.props.setEntegrationBrandAddModal(true).then(() => { })}>
                                <Icon style={{ marginRight: 5, fontSize: 15 }}>{"extension"}</Icon>
                                {"Entegrasyon Ekle"}
                            </Button>
                        </div>
                        <Table className={classNames(classes.table, classes.bordered)} style={{ marginTop: 10 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left'>{'AppKey'}</TableCell>
                                    <TableCell align='left'>{'İşletme Adı'}</TableCell>
                                    <TableCell align='left'>{'Telefon Numarası'}</TableCell>
                                    <TableCell align='left'>{'Hazırlanma Süresi'}</TableCell>
                                    <TableCell align={"right"}>{'İşlemler'}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {entegrationBrandList.map((n, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell align='left'>
                                                <IconButton
                                                    style={{ backgroundColor: '#3e3e3e', marginRight: 10, fontSize: 12, padding: 8 }}
                                                    onClick={() => navigator.clipboard.writeText(n.Id)}
                                                >
                                                    <Icon>{"content_copy"}</Icon>
                                                </IconButton>
                                                {n.Id}
                                            </TableCell>
                                            <TableCell align='left'>{n.RestName}</TableCell>
                                            <TableCell align='left'>{n.BrandPhone}</TableCell>
                                            <TableCell align='left'>{n.PreparationTime ?? 0}{"dk"}</TableCell>
                                            <TableCell align={"right"}>
                                                <Tooltip title={"Düzenle"}>
                                                    <IconButton
                                                        style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                        onClick={() => this.props.setEntegrationBrandAddModal(true, n.Id).then(() => { })}>
                                                        <Icon>{"edit"}</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={"Kilometre Ücretlendirme"}>
                                                    <IconButton
                                                        style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                        onClick={() => this.props.setMileageChargeVisible(true, n.BrandId, 1).then(() => { })}>
                                                        <Icon>{"edit_road"}</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={"İşletme Silme"}>
                                                    <IconButton
                                                        style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                                                        onClick={() => (confirm('İşletme kaydını sildiğinizde entegre olan firmadan işletme için oluşturulan siparişler JaviKurye sistemine aktarılamayacaktır. Onaylıyor musunuz?') ? this.props.setEntBrandDelete(n.Id).then(() => { }) : null)}>
                                                        <Icon>{"delete"}</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </Paper>
        )
    }
}

const valueSelector = (state, props) => state.get('paketYoldaReducer').paketYoldaBusinessListUpdate;
const value = createSelector([valueSelector],
    (value) => value);


const mapStateToProps = (state, props) => ({
    paketYoldaBusinessListUpdate: value(state, props),
    paketYoldaBusinessList: state.get('paketYoldaReducer').paketYoldaBusinessList,
    paketYoldaSettingsMenu: state.get('paketYoldaReducer').paketYoldaSettingsMenu,
    paketYoldaBusinessListLoading: state.get('paketYoldaReducer').paketYoldaBusinessListLoading,
    anchorElEntegrationDetail: state.get('paketYoldaReducer').anchorElEntegrationDetail,

    brandSettingsVisible: state.get('paketYoldaReducer').brandSettingsVisible,
    brandSettingsData: state.get('paketYoldaReducer').brandSettingsData,

    entegrationBrandAdd: state.get('paketYoldaReducer').entegrationBrandAdd,
    entegrationBrandList: state.get('paketYoldaReducer').entegrationBrandList,
    entegrationBrandAddVisible: state.get('paketYoldaReducer').entegrationBrandAddVisible,
})

const mapDispatchToProps = {
    setPaketYoldaRegionAddVisible,
    getEntegrationRestList,
    setAnchorEntegration,
    setMileageChargeVisible,
    setPaketYoldaBrandSettingsVisible,
    setPaketYoldaBrandSettingsData,
    setBrandPreparationTime,
    setEntegrationBrandAddModal,
    setEntBrandList,
    setEntegrationBrandAddData,
    setEntBrandDelete
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(BusinessSettingsList)));
