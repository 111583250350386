import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styles from '../../CenterBrandPage/centerbrandpage-jss';
import {
  setPaketYoldaCenterListVisible, setPaketYoldaCenterAddVisible, getCenter, setPyBrandListVisible, modalClosePaketYolda,
  setCenterInactive, getPaketYoldaCenterList
} from '../../../../redux/modules/paketyolda';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CenterAdd from './CenterAdd';
import BrandList from './BrandList';
import ModalInformation from '../../Ortak/ModalInformation';

class CenterList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedType: true };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.paketYoldaCenterListUpdate == this.props.paketYoldaCenterListUpdate) {
      return false;
    }
    return true;
  }

  async componentDidMount() { }

  modalClose = () => {
    this.props.modalClosePaketYolda().then(() => { })
  };


  handleChange = async (event) => {
    this.setState({ selectedType : !this.state.selectedType})
    this.props.getPaketYoldaCenterList(!this.state.selectedType).then(() => { });
};

  render() {
    const { modalOpen, modalText, modalTitle, classes, paketYoldaCenterListVisible, paketYoldaCenterList } = this.props;

    return (
      <Dialog
        open={paketYoldaCenterListVisible}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'lg'}
        PaperProps={{ classes: { root: classes.dialogPaper } }}
      >
        {modalOpen ? <ModalInformation event={this.modalClose} open={modalOpen} title={modalTitle} text={modalText} /> : null}
        <CenterAdd classes={classes} />
        <BrandList classes={classes} />
        <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
          {"JaviKurye Merkez İşlemleri"}
        </DialogTitle>
        <DialogContent style={{ overflow: 'hidden auto', height: '100vh' }}>
          <Grid style={{ paddingBottom:10, borderBottom: '1px solid #424242' }} container spacing={1}>
            <Grid md={3}>
              <FormControl fullWidth className={classes.formControl} style={{ margin: 0 }}>
                <Typography style={{ textAlign:'left', marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1 }} className={classes.flex}>{"Hesap Aktif / Pasif Durumu"}</Typography>
                <Select
                  fullWidth
                  style={{ width: '100%', borderRadius: 5, height: 38 }}
                  value={this.state.selectedType}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'age',
                    id: 'age-simple',
                  }}
                >
                  <MenuItem value={true}>{"Aktif"}</MenuItem>
                  <MenuItem value={false}>{"Pasif"}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Table className={classNames(classes.table, classes.bordered)} style={{ marginTop: 10 }}>
            <TableHead>
              <TableRow>
                <TableCell>{'Aktif'}</TableCell>
                <TableCell>{'Firma Adı'}</TableCell>
                <TableCell>{'Email'}</TableCell>
                <TableCell>{'Şifre'}</TableCell>
                <TableCell>{'Yetkili Kişi'}</TableCell>
                <TableCell>{'Telefon'}</TableCell>
                <TableCell style={{ display:(paketYoldaCenterList.filter(x=>x.IsActive == true).length > 0 ? '' : 'none') }} align={"right"}>{'İşlemler'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paketYoldaCenterList.map((n, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>
                      {<Icon>{n.IsActive ? 'check_box' : 'check_box_outline_blank'}</Icon>}
                    </TableCell>
                    <TableCell>{n.BrandNames}</TableCell>
                    <TableCell>{n.Email}</TableCell>
                    <TableCell>{n.Password}</TableCell>
                    <TableCell>{n.AuthorizedPerson}</TableCell>
                    <TableCell>{n.Phone}</TableCell>
                    <TableCell style={{ display:(n.IsActive ? '' : 'none') }} align={"right"}>
                      <Tooltip title={"Düzenle"}>
                        <IconButton
                          style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                          onClick={() => this.props.getCenter(n.Id).then(() => { })}>
                          <Icon>{"edit"}</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={"Ana Bayi Ekle"}>
                        <IconButton
                          style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                          onClick={() => this.props.setPyBrandListVisible(true, "00000000-0000-0000-0000-000000000000", n.Id).then(() => { })}>
                          <Icon>{"add_home_work"}</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={"Merkezi Pasife Çek"}>
                        <IconButton
                          style={{ backgroundColor: '#3e3e3e', marginRight: 5, fontSize: 12, padding: 8 }}
                          onClick={() => (confirm('JaviKurye Merkez ve Şubeleri pasife çekmek istediğinize emin misiniz?') ? this.props.setCenterInactive(n.Id).then(() => { }) : null)}>
                          <Icon>{"delete"}</Icon>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => this.props.setPaketYoldaCenterAddVisible(true).then(() => { })} color="primary">
            {"JaviKurye Merkez Ekle"}
          </Button>
          <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setPaketYoldaCenterListVisible(false).then(() => { })} color="primary">
            {"Çıkış"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CenterList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const valueSelector = (state, props) => state.get('paketYoldaReducer').paketYoldaCenterListUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
  paketYoldaCenterListUpdate: value(state, props),
  paketYoldaCenterListVisible: state.get('paketYoldaReducer').paketYoldaCenterListVisible,
  paketYoldaCenterList: state.get('paketYoldaReducer').paketYoldaCenterList,

  modalOpen: state.get('paketYoldaReducer').form_data.modalOpen,
  modalTitle: state.get('paketYoldaReducer').form_data.modalTitle,
  modalText: state.get('paketYoldaReducer').form_data.modalText,

});

const mapDispatchToProps = {
  setPaketYoldaCenterListVisible,
  setPaketYoldaCenterAddVisible,
  getCenter,
  setPyBrandListVisible,
  modalClosePaketYolda,
  setCenterInactive,
  getPaketYoldaCenterList
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(CenterList)));
