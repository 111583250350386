import React, { Fragment, useState, memo } from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";
import { PropTypes } from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import styles from "../../CenterBrandPage/centerbrandpage-jss";
import {
  setPaketYoldaNewCourierAddData,
  setPaketYoldaNewCourierAddVisible,
  setCourierAdd,
} from "../../../../redux/modules/paketyolda";
import {
  fetchPaymentTypes,
  blockPaymentTypeForCourier,
  unblockPaymentTypeForCourier,
  fetchBlockedPaymentTypes,
} from "../../../../redux/modules/PaymentTypeReducer";
import { createSelector } from "reselect";
import moment from "moment";
import Icon from "@material-ui/core/Icon";
import "react-credit-cards/es/styles-compiled.css";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import classNames from "classnames";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);
import DatePicker, { registerLocale } from "react-datepicker";
import {
  CapitalizeText,
  ComplateLowerText,
} from "../../../../function/GeneralFunction";
import InputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Divider from "@material-ui/core/Divider";
import ListSubheader from "@material-ui/core/ListSubheader";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";

class CourierAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPaymentTypes: [],
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.paketYoldaNewCourierAddUpdate ===
        this.props.paketYoldaNewCourierAddUpdate &&
      nextProps.paymentTypes === this.props.paymentTypes &&
      nextProps.paymentTypesLoading === this.props.paymentTypesLoading &&
      nextProps.blockedPaymentTypes === this.props.blockedPaymentTypes &&
      nextState.selectedPaymentTypes === this.state.selectedPaymentTypes
    ) {
      return false;
    }
    return true;
  }

  async componentDidMount() {
    this.handleSubmitCourierAdd = this.handleSubmitCourierAdd.bind(this);
    await this.fetchAndSetBlockedPaymentTypes();
  }

  async componentDidUpdate(prevProps) {
    const { paketYoldaNewCourierAddData } = this.props;
    const prevData = prevProps.paketYoldaNewCourierAddData;

    if (
      (paketYoldaNewCourierAddData.Id !== prevData.Id ||
        paketYoldaNewCourierAddData.BrandId !== prevData.BrandId) &&
      paketYoldaNewCourierAddData.Id &&
      paketYoldaNewCourierAddData.BrandId
    ) {
      await this.fetchAndSetBlockedPaymentTypes();
    }

    if (
      !prevData.ShowPaymentTypes &&
      paketYoldaNewCourierAddData.ShowPaymentTypes
    ) {
      this.props.fetchPaymentTypes();
    }
  }

  async fetchAndSetBlockedPaymentTypes() {
    const { paketYoldaNewCourierAddData } = this.props;

    if (paketYoldaNewCourierAddData.Id && paketYoldaNewCourierAddData.BrandId) {
      try {
        const blockedTypes = await this.props.fetchBlockedPaymentTypes(
          paketYoldaNewCourierAddData.Id,
          paketYoldaNewCourierAddData.BrandId
        );

        if (blockedTypes && Array.isArray(blockedTypes)) {
          const courierBlockedTypes = blockedTypes.filter(
            (type) => type.CourierId === paketYoldaNewCourierAddData.Id
          );

          this.setState({
            selectedPaymentTypes: courierBlockedTypes.map(
              (type) => type.PaymentTypeId
            ),
          });
        } else {
          this.setState({ selectedPaymentTypes: [] });
        }
      } catch (error) {
        this.setState({ selectedPaymentTypes: [] });
      }
    } else {
      console.log("Missing courier ID or brand ID", {
        courierId: paketYoldaNewCourierAddData.Id,
        brandId: paketYoldaNewCourierAddData.BrandId,
      });
    }
  }

  handlePaymentTypeChange = (paymentTypeId) => (event) => {
    const { selectedPaymentTypes } = this.state;
    if (event.target.checked) {
      this.setState({
        selectedPaymentTypes: [...selectedPaymentTypes, paymentTypeId],
      });
    } else {
      this.setState({
        selectedPaymentTypes: selectedPaymentTypes.filter(
          (id) => id !== paymentTypeId
        ),
      });
    }
  };

  handleChangePaketYoldaCourier = (Name) => async (event) => {
    const { paketYoldaNewCourierAddData } = this.props;
    const isSwitch =
      Name == "IspaymentTypeChange" ||
      Name == "IsRevokeAuthorization" ||
      Name == "IsReceivingCourierPackages" ||
      Name == "IsActive" ||
      Name == "IsReceivingPackage" ||
      Name == "ShowPaymentTypes" ||
      Name == "IsOfficialDistributor";

    const pointer = !isSwitch ? event.target.selectionStart : null;
    const element = !isSwitch ? event.target : null;

    paketYoldaNewCourierAddData[Name] = isSwitch
      ? event.target.checked
      : CapitalizeText(event.target.value);

    if (Name === "ShowPaymentTypes" && event.target.checked) {
      this.props.fetchPaymentTypes();
    }

    await this.props.setPaketYoldaNewCourierAddData(
      paketYoldaNewCourierAddData
    );

    if (!isSwitch && element) {
      window.requestAnimationFrame(() => {
        element.selectionStart = pointer;
        element.selectionEnd = pointer;
      });
    }
  };

  handleSubmitCourierAdd = async (event) => {
    event.preventDefault();
    const { selectedPaymentTypes } = this.state;
    const {
      paketYoldaNewCourierAddData,
      paymentTypes,
      blockedPaymentTypes,
    } = this.props;

    const currentlyBlockedTypeIds = blockedPaymentTypes
      .filter((type) => type.CourierId === paketYoldaNewCourierAddData.Id)
      .map((type) => type.PaymentTypeId);

    const typesToBlock = selectedPaymentTypes.filter(
      (id) => !currentlyBlockedTypeIds.includes(id)
    );
    const typesToUnblock = currentlyBlockedTypeIds.filter(
      (id) => !selectedPaymentTypes.includes(id)
    );

    const promises = [];

    if (typesToBlock.length > 0) {
      const blockPromises = typesToBlock.map((paymentTypeId) => {
        const paymentType = paymentTypes.find((pt) => pt.Id === paymentTypeId);

        const blockedPayment = {
          courierId: paketYoldaNewCourierAddData.Id,
          paymentTypeId: paymentTypeId,
          brandId: paketYoldaNewCourierAddData.BrandId,
          brandName: paketYoldaNewCourierAddData.BrandName || "",
          courierName: paketYoldaNewCourierAddData.NameSurname || "",
          paymentType: paymentType ? paymentType.Name : "",
        };

        return this.props.blockPaymentTypeForCourier(blockedPayment);
      });
      promises.push(...blockPromises);
    }

    if (typesToUnblock.length > 0) {
      const unblockPromises = typesToUnblock.map((paymentTypeId) => {
        return this.props.unblockPaymentTypeForCourier(
          paketYoldaNewCourierAddData.Id,
          paymentTypeId,
          paketYoldaNewCourierAddData.BrandId
        );
      });
      promises.push(...unblockPromises);
    }

    try {
      const results = await Promise.all(promises);
      const errors = results.filter((r) => !r.success);

      if (errors.length > 0) {
        console.error("Bazı ödeme tipleri güncellenemedi:", errors);
      }

      await this.fetchAndSetBlockedPaymentTypes();
    } catch (error) {
      console.error("Ödeme tipleri güncellenirken hata oluştu:", error);
    }

    await this.props.setCourierAdd();
  };

  render() {
    const {
      classes,
      paketYoldaNewCourierAddVisible,
      paketYoldaNewCourierAddData,
      paketYoldaNewCourierAddLoading,
      paymentTypes,
      paymentTypesLoading,
      blockingPaymentType,
      unblockingPaymentType,
      fetchingBlockedPaymentTypes,
    } = this.props;

    const isLoading =
      paketYoldaNewCourierAddLoading ||
      blockingPaymentType ||
      unblockingPaymentType ||
      fetchingBlockedPaymentTypes;

    return (
      <Dialog
        open={paketYoldaNewCourierAddVisible}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
        PaperProps={{ classes: { root: classes.dialogPaper } }}
      >
        <form
          autoComplete="off"
          style={{ display: "contents" }}
          onSubmit={this.handleSubmitCourierAdd}
        >
          <DialogTitle style={{ padding: "24px 24px 5px", marginBottom: 10 }}>
            {"Kurye Ekleme/Düzenleme"}
          </DialogTitle>
          <DialogContent style={{ overflow: "hidden auto" }}>
            <Grid container spacing={1}>
              <Grid item md={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={paketYoldaNewCourierAddData.IsActive}
                      onChange={this.handleChangePaketYoldaCourier("IsActive")}
                    />
                  }
                  label={"Aktif"}
                />
              </Grid>
              <Grid item md={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        paketYoldaNewCourierAddData.IsRevokeAuthorization
                      }
                      onChange={this.handleChangePaketYoldaCourier(
                        "IsRevokeAuthorization"
                      )}
                    />
                  }
                  label={"Paketi Reddetebilsin"}
                />
              </Grid>
              <Grid item md={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={paketYoldaNewCourierAddData.IsReceivingPackage}
                      onChange={this.handleChangePaketYoldaCourier(
                        "IsReceivingPackage"
                      )}
                    />
                  }
                  label={"Başkasına Atama Yapabilsin"}
                />
              </Grid>
              <Grid item md={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        paketYoldaNewCourierAddData.IsReceivingCourierPackages
                      }
                      onChange={this.handleChangePaketYoldaCourier(
                        "IsReceivingCourierPackages"
                      )}
                    />
                  }
                  label={"Atama Bekleyenleri Görüntülesin"}
                />
              </Grid>
              <Grid item md={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={paketYoldaNewCourierAddData.IspaymentTypeChange}
                      onChange={this.handleChangePaketYoldaCourier(
                        "IspaymentTypeChange"
                      )}
                    />
                  }
                  label={"Ödeme Değişikliği Yapabilsin"}
                />
              </Grid>
              <Grid item md={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        paketYoldaNewCourierAddData.ShowPaymentTypes || false
                      }
                      onChange={this.handleChangePaketYoldaCourier(
                        "ShowPaymentTypes"
                      )}
                    />
                  }
                  label={"Kuryenin Taşıyamayacağı Ödeme Yöntemleri"}
                />
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="name-simple">{"Adı Soyadı"}</InputLabel>
                  <Input
                    fullWidth
                    required
                    placeholder={"Adı Soyadı Giriniz"}
                    value={paketYoldaNewCourierAddData.NameSurname}
                    onChange={this.handleChangePaketYoldaCourier("NameSurname")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="name-simple">
                    {"Mola Süresi (Dk)"}
                  </InputLabel>
                  <Input
                    type={"number"}
                    required
                    placeholder={"Mola Süresi"}
                    value={paketYoldaNewCourierAddData.AllowedTime}
                    onChange={this.handleChangePaketYoldaCourier("AllowedTime")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <InputMask
                  mask="0 999 999 99 99"
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  value={paketYoldaNewCourierAddData.Phone}
                  onChange={this.handleChangePaketYoldaCourier("Phone")}
                >
                  {() => (
                    <TextField
                      label={"Kullanıcı Adı (Telefon)"}
                      name={"Phone"}
                      fullWidth
                      required
                      style={{ marginTop: 0 }}
                      margin="normal"
                      type="text"
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="name-simple">{"Şifre"}</InputLabel>
                  <Input
                    type="password"
                    autoComplete="new-password"
                    fullWidth
                    required
                    placeholder={"Şifre Giriniz"}
                    value={paketYoldaNewCourierAddData.Password}
                    onChange={this.handleChangePaketYoldaCourier("Password")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="name-simple">
                    {"İşyerimPos Terminal Id"}
                  </InputLabel>
                  <Input
                    fullWidth
                    placeholder={"Terminal Id Giriniz"}
                    value={paketYoldaNewCourierAddData.TerminalId}
                    onChange={this.handleChangePaketYoldaCourier("TerminalId")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="name-simple">
                    {"Alabileceği Paket Sayısı"}
                  </InputLabel>
                  <Input
                    fullWidth
                    required
                    type="number"
                    placeholder={"Alabileceği Paket Sayısı"}
                    value={paketYoldaNewCourierAddData.CourierMaxPackage}
                    onChange={this.handleChangePaketYoldaCourier(
                      "CourierMaxPackage"
                    )}
                    inputProps={{
                      min: 0,
                      max: 20,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="plate">{"Kurye Plakası"}</InputLabel>
                  <Input
                    fullWidth
                    placeholder={"Kurye Plakası Giriniz"}
                    value={paketYoldaNewCourierAddData.CourierPlate}
                    onChange={this.handleChangePaketYoldaCourier(
                      "CourierPlate"
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="company-name">{"Şirket Adı"}</InputLabel>
                  <Input
                    fullWidth
                    placeholder={"Şirket Adı Giriniz"}
                    value={paketYoldaNewCourierAddData.CompanyName}
                    onChange={this.handleChangePaketYoldaCourier("CompanyName")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="tax-office">
                    {"Vergi Dairesi"}
                  </InputLabel>
                  <Input
                    fullWidth
                    placeholder={"Vergi Dairesi Giriniz"}
                    value={paketYoldaNewCourierAddData.TaxOffice}
                    onChange={this.handleChangePaketYoldaCourier("TaxOffice")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="tax-number">
                    {"Vergi Numarası"}
                  </InputLabel>
                  <Input
                    fullWidth
                    placeholder={"Vergi Numarası Giriniz"}
                    value={paketYoldaNewCourierAddData.TaxNumber}
                    onChange={this.handleChangePaketYoldaCourier("TaxNumber")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="company-address">
                    {"Şirket Adresi"}
                  </InputLabel>
                  <Input
                    fullWidth
                    multiline
                    rows={2}
                    placeholder={"Şirket Adresi Giriniz"}
                    value={paketYoldaNewCourierAddData.CompanyAddress}
                    onChange={this.handleChangePaketYoldaCourier(
                      "CompanyAddress"
                    )}
                  />
                </FormControl>
              </Grid>
              {paketYoldaNewCourierAddData.ShowPaymentTypes &&
                paymentTypes.length > 0 && (
                  <Grid item md={12}>
                    <FormControl
                      component="fieldset"
                      fullWidth
                      className={classes.formControl}
                    >
                      <Typography variant="subtitle1" gutterBottom>
                        Ödeme Tipleri
                      </Typography>
                      <Grid container spacing={2}>
                        {paymentTypes.map((type, index) => (
                          <Grid item md={4} key={type.Id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.selectedPaymentTypes.includes(
                                    type.Id
                                  )}
                                  onChange={this.handlePaymentTypeChange(
                                    type.Id
                                  )}
                                />
                              }
                              label={type.Name}
                              style={{ marginRight: 0 }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </FormControl>
                  </Grid>
                )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={isLoading}
              type={"submit"}
              variant="contained"
              color="secondary"
            >
              {"Kaydet"}
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: "#F44336", color: "white" }}
              onClick={() =>
                this.props
                  .setPaketYoldaNewCourierAddVisible(false)
                  .then(() => {})
              }
            >
              {"Çıkış"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

CourierAdd.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchPaymentTypes: PropTypes.func.isRequired,
  blockPaymentTypeForCourier: PropTypes.func.isRequired,
  unblockPaymentTypeForCourier: PropTypes.func.isRequired,
  fetchBlockedPaymentTypes: PropTypes.func.isRequired,
  paymentTypes: PropTypes.array.isRequired,
  paymentTypesLoading: PropTypes.bool.isRequired,
  blockingPaymentType: PropTypes.bool.isRequired,
  unblockingPaymentType: PropTypes.bool.isRequired,
  fetchingBlockedPaymentTypes: PropTypes.bool.isRequired,
  blockedPaymentTypes: PropTypes.array.isRequired,
};

const valueSelector = (state, props) =>
  state.get("paketYoldaReducer").paketYoldaNewCourierAddUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
  paketYoldaNewCourierAddUpdate: value(state, props),
  paketYoldaNewCourierAddVisible: state.get("paketYoldaReducer")
    .paketYoldaNewCourierAddVisible,
  paketYoldaNewCourierAddLoading: state.get("paketYoldaReducer")
    .paketYoldaNewCourierAddLoading,
  paketYoldaNewCourierAddData: state.get("paketYoldaReducer")
    .paketYoldaNewCourierAddData,
  paymentTypes:
    state
      .get("paymentTypes")
      .get("paymentTypes")
      .toJS() || [],
  paymentTypesLoading: state.get("paymentTypes").get("loading"),
  blockingPaymentType: state.get("paymentTypes").get("blockingPaymentType"),
  unblockingPaymentType: state.get("paymentTypes").get("unblockingPaymentType"),
  fetchingBlockedPaymentTypes: state
    .get("paymentTypes")
    .get("fetchingBlockedPaymentTypes"),
  blockedPaymentTypes:
    state
      .get("paymentTypes")
      .get("blockedPaymentTypes")
      .toJS() || [],
});

const mapDispatchToProps = {
  setPaketYoldaNewCourierAddVisible,
  setPaketYoldaNewCourierAddData,
  setCourierAdd,
  fetchPaymentTypes,
  blockPaymentTypeForCourier,
  unblockPaymentTypeForCourier,
  fetchBlockedPaymentTypes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(CourierAdd)));
