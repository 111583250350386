import React from "react";
import { createSelector } from "reselect";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";

import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

import TablePagination from "@material-ui/core/TablePagination";
import {
  setPaketYoldaLogReportFilterData,
  getPaketYoldaBrandList,
  getPaketYoldaCourierList,
  getPaketYoldaBusinessList,
  getNewCourierList,
  getOrderGeneralReport,
  getLogReportList,
} from "../../../../redux/modules/paketyolda";
import { getPackageAdditionDetail } from "../../../../redux/modules/addition";
import { getPaketYolda } from "../../../../function/GeneralFunction";
import CircularProgress from "@material-ui/core/CircularProgress";
import DatePicker, { registerLocale } from "react-datepicker";
import "../datepicker.css";
import tr from "date-fns/locale/tr";

import TooltipMui from "@material-ui/core/Tooltip";
import TbPaginationActions from "../../Ortak/TbPaginationActions";
import MapClusterReport from "../../SettingPage/Report/SubReportPage/Maps/MapClusterReport";
import PackageAdditionDetail from "../../PackagePage/PackageAdditionDetail";
import {
  generateCourierWorkPdfReport,
  generateCourierWorkExcelReport,
} from "./services/exportServices";
import axios from "axios";
import {
  fetchCourierActivities,
  filterCourierActivities,
} from "../../../../redux/modules/courierActivity";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Input from "@material-ui/core/Input";

const styles = (theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

registerLocale("tr", tr);

// Custom Pagination Actions Component
function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ flexShrink: 0, marginLeft: 20 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

class CourierWorkReport extends React.Component {
  constructor() {
    super();
    this.state = {
      filterActive: false,
      page: 0,
      rowsPerPage: 10,
      tempFilter: {
        startDate: moment()
          .startOf("day")
          .toDate(),
        endDate: moment()
          .endOf("day")
          .toDate(),
        activityType: "",
        courierId: "",
      },
    };
  }

  async componentDidMount() {
    if (this.props.paketYoldaReportMenu === "CourierWorkReport") {
      const { courierActivitiesFilter } = this.props;
      this.setState({ tempFilter: { ...courierActivitiesFilter } });
      await this.props.getPaketYoldaCourierList();
      await this.props.fetchCourierActivities();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.paketYoldaReportMenu !== this.props.paketYoldaReportMenu &&
      this.props.paketYoldaReportMenu === "CourierWorkReport"
    ) {
      const { courierActivitiesFilter } = this.props;
      this.setState({ tempFilter: { ...courierActivitiesFilter } });
      this.props.getPaketYoldaCourierList();
      this.props.fetchCourierActivities();
    }
  }

  handleChangeFilterReport = (name) => (date) => {
    this.setState((prevState) => ({
      tempFilter: {
        ...prevState.tempFilter,
        [name]: date,
      },
    }));
  };

  handleCourierChange = (event) => {
    this.setState((prevState) => ({
      tempFilter: {
        ...prevState.tempFilter,
        courierId: event.target.value,
      },
    }));
  };

  async handleFilterGeneral() {
    this.setState({ filterActive: false });
    await this.props.getPaketYoldaCourierList();
    await this.props.filterCourierActivities(this.state.tempFilter);
  }

  handlePdfExport = async () => {
    const { courierActivities, paketYoldaCourierList } = this.props;
    const filteredActivities =
      courierActivities?.filter((activity) =>
        paketYoldaCourierList?.some(
          (courier) => courier.Id === activity.CourierId
        )
      ) || [];

    if (filteredActivities.length > 0) {
      const activitiesWithNames = filteredActivities.map((activity) => {
        const courier = paketYoldaCourierList?.find(
          (c) => c.Id === activity.CourierId
        );
        return {
          ...activity,
          CourierName: courier.NameSurname,
        };
      });

      const reportData = {
        CourierData: activitiesWithNames,
        FilterData: this.state.tempFilter,
      };

      generateCourierWorkPdfReport(reportData);
    }
  };

  handleExcelExport = async () => {
    const { courierActivities, paketYoldaCourierList } = this.props;
    const filteredActivities =
      courierActivities?.filter((activity) =>
        paketYoldaCourierList?.some(
          (courier) => courier.Id === activity.CourierId
        )
      ) || [];

    if (filteredActivities.length > 0) {
      const activitiesWithNames = filteredActivities.map((activity) => {
        const courier = paketYoldaCourierList?.find(
          (c) => c.Id === activity.CourierId
        );
        return {
          ...activity,
          CourierName: courier.NameSurname,
        };
      });

      const reportData = {
        CourierData: activitiesWithNames,
        FilterData: this.state.tempFilter,
      };

      generateCourierWorkExcelReport(reportData);
    }
  };

  getActivityTypeText = (type) => {
    switch (type) {
      case 1:
        return { text: "Çalışıyor", color: "#27ae60" };
      case 2:
        return { text: "Molada", color: "#f39c12" };
      case 3:
        return { text: "Çevrimdışı", color: "#e74c3c" };
      case 4:
        return { text: "Mola Bitti", color: "#2ecc71" };
      default:
        return { text: "Bilinmiyor", color: "#95a5a6" };
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  calculateActivitySummary = (activities) => {
    if (!activities || activities.length === 0) {
      return {
        courierStats: {},
      };
    }

    const { paketYoldaCourierList } = this.props;
    const courierStats = {};

    // Sadece geçerli kuryelerin aktivitelerini filtrele
    const filteredActivities = activities.filter((activity) =>
      paketYoldaCourierList?.some(
        (courier) => courier.Id === activity.CourierId
      )
    );

    // Aktiviteleri sırala
    const sortedActivities = [...filteredActivities].sort(
      (a, b) => moment(a.StartTime).valueOf() - moment(b.StartTime).valueOf()
    );

    // Kuryeler için temel bilgileri ve aktiviteleri grupla
    sortedActivities.forEach((activity) => {
      if (!courierStats[activity.CourierId]) {
        const courier = paketYoldaCourierList?.find(
          (c) => c.Id === activity.CourierId
        );
        courierStats[activity.CourierId] = {
          name: courier.NameSurname,
          totalWorkingTime: 0,
          totalBreakTime: 0,
        };
      }

      const stats = courierStats[activity.CourierId];
      const startTime = moment(activity.StartTime);
      const endTime = moment(activity.EndTime);
      const duration = endTime.valueOf() - startTime.valueOf();

      // ActivityType = 4 ise mola süresi, değilse çalışma süresi
      if (activity.ActivityType === 4) {
        stats.totalBreakTime += Math.max(0, duration);
      } else if (activity.ActivityType === 3) {
        stats.totalWorkingTime += Math.max(0, duration);
      }
    });

    // Her kurye için süreleri son haline getir
    Object.values(courierStats).forEach((stats) => {
      stats.workingTime = stats.totalWorkingTime;
      stats.breakTime = stats.totalBreakTime;

      // Gereksiz alanları temizle
      delete stats.totalWorkingTime;
      delete stats.totalBreakTime;
    });

    return { courierStats };
  };

  formatDuration = (totalMilliseconds) => {
    if (!totalMilliseconds || totalMilliseconds < 0) return "0s 0dk 0sn";

    // Milisaniyeyi saniyeye çevir
    const totalSeconds = Math.floor(totalMilliseconds / 1000);

    // Saat, dakika ve saniye hesapla
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours}s ${minutes}dk ${seconds}sn`;
  };

  render() {
    const {
      paketYoldaReportMenu,
      classes,
      paketYoldaCourierList,
      courierActivities,
      courierActivitiesLoading,
    } = this.props;

    const { tempFilter, page, rowsPerPage } = this.state;

    // Sadece geçerli kuryelerin aktivitelerini filtrele
    const filteredActivities =
      courierActivities?.filter((activity) =>
        paketYoldaCourierList?.some(
          (courier) => courier.Id === activity.CourierId
        )
      ) || [];

    if (paketYoldaReportMenu !== "CourierWorkReport") {
      return null;
    }

    const renderFilterArea = () => (
      <Grid
        container
        spacing={1}
        style={{
          borderBottom: "1px solid #424242",
          padding: "16px",
          borderRadius: 0,
          marginBottom: 5,
        }}
      >
        <Grid item md={3}>
          <FormControl fullWidth>
            <Typography
              style={{
                marginBottom: 4,
                color: "rgba(255, 255, 255, 0.7)",
                fontSize: "0.875rem",
              }}
            >
              {"Kurye Adı"}
            </Typography>
            <Select
              value={tempFilter.courierId || ""}
              onChange={this.handleCourierChange}
              style={{ width: "100%", borderRadius: 5, height: 38 }}
              input={<Input />}
            >
              <MenuItem value="">
                <em>Tümü</em>
              </MenuItem>
              {paketYoldaCourierList?.map((courier) => (
                <MenuItem key={courier.Id} value={courier.Id}>
                  {courier.NameSurname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3}>
          <Typography
            style={{
              marginBottom: 4,
              color: "rgba(255, 255, 255, 0.7)",
              fontSize: "0.875rem",
            }}
          >
            {"Başlangıç Tarihi"}
          </Typography>
          <DatePicker
            selected={tempFilter.startDate}
            onChange={this.handleChangeFilterReport("startDate")}
            dateFormat="dd.MM.yyyy HH:mm"
            showTimeSelect
            timeIntervals={15}
            timeFormat="HH:mm"
            locale="tr"
            className="inputDate"
          />
        </Grid>
        <Grid item md={3}>
          <Typography
            style={{
              marginBottom: 4,
              color: "rgba(255, 255, 255, 0.7)",
              fontSize: "0.875rem",
            }}
          >
            {"Bitiş Tarihi"}
          </Typography>
          <DatePicker
            selected={tempFilter.endDate}
            onChange={this.handleChangeFilterReport("endDate")}
            dateFormat="dd.MM.yyyy HH:mm"
            showTimeSelect
            timeIntervals={15}
            timeFormat="HH:mm"
            locale="tr"
            className="inputDate"
          />
        </Grid>
        <Grid
          item
          md={3}
          style={{
            display: "flex",
            alignItems: "flex-end",
            gap: "8px",
          }}
        >
          <Button
            fullWidth
            disabled={courierActivitiesLoading}
            onClick={() => this.handleFilterGeneral()}
            style={{
              borderRadius: 5,
              height: 37,
              padding: "6px 24px",
              marginTop: 17,
              backgroundColor: "#2196f3",
              color: "white",
            }}
            variant="contained"
            type="submit"
          >
            {"FİLTRELE"}
          </Button>
          <Button
            onClick={this.handleExcelExport}
            disabled={this.state.filterActive || !filteredActivities?.length}
            style={{
              minWidth: "40px",
              padding: "0px 25px",
              height: 37,
              backgroundColor: "#27ae60",
              borderRadius: 4,
              color: "#fafafa",
              marginTop: 15,
            }}
            variant="contained"
          >
            <Icon style={{ fontSize: 20 }}>description</Icon>
            <span style={{ marginLeft: 4, fontSize: 13 }}>Excel</span>
          </Button>
          <Button
            onClick={this.handlePdfExport}
            disabled={this.state.filterActive || !filteredActivities?.length}
            style={{
              minWidth: "40px",
              padding: "0px 25px",
              height: 37,
              backgroundColor: "#e74c3c",
              borderRadius: 4,
              color: "#fafafa",
              marginTop: 15,
            }}
            variant="contained"
          >
            <Icon style={{ fontSize: 20 }}>picture_as_pdf</Icon>
            <span style={{ marginLeft: 4, fontSize: 13 }}>PDF</span>
          </Button>
        </Grid>
      </Grid>
    );

    if (courierActivitiesLoading) {
      return (
        <Paper
          className={classes.root}
          elevation={0}
          style={{
            backgroundColor: "transparent",
            borderRadius: 0,
            height: "100%",
          }}
        >
          <div style={{ padding: 20 }}>
            {renderFilterArea()}
            <div style={{ width: "100%", textAlign: "center", paddingTop: 20 }}>
              <CircularProgress
                style={{ color: "#fafafa" }}
                thickness={4}
                className={classes.progress}
              />
            </div>
          </div>
        </Paper>
      );
    }

    const activitySummary = this.calculateActivitySummary(filteredActivities);

    // Group activities by courier
    const courierSummary = {};
    Object.entries(activitySummary.courierStats).forEach(
      ([courierId, stats]) => {
        courierSummary[courierId] = {
          name: stats.name,
          workingTime: stats.workingTime,
          breakTime: stats.breakTime,
        };
      }
    );

    return (
      <Paper
        className={classes.root}
        elevation={0}
        style={{
          backgroundColor: "transparent",
          borderRadius: 0,
          height: "100%",
        }}
      >
        <div
          style={{ height: "calc(100% - 43px)", padding: 5, overflowY: "auto" }}
        >
          {renderFilterArea()}

          <div
            style={{
              backgroundColor: "#313131",
              marginTop: 10,

              width: "100%",
              padding: 10,
              borderBottom: "1px solid rgba(209, 216, 224,.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {"Kurye Çalışma Kayıtları"}
          </div>
          {!filteredActivities || filteredActivities.length === 0 ? (
            <div style={{ textAlign: "center", color: "white" }}>
              <Typography variant="h6">
                Görüntülenecek veri bulunamadı. Lütfen filtreleri kontrol
                ediniz.
              </Typography>
            </div>
          ) : (
            <>
              {/* Simple Table */}
              <Table style={{ marginTop: 5, overflow: "hidden" }}>
                <TableHead style={{ backgroundColor: "#212121" }}>
                  <TableRow>
                    <TableCell
                      style={{
                        color: "white",
                        width: "35%",
                        borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
                        fontSize: "0.875rem",
                      }}
                    >
                      Kurye Adı
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        width: "35%",
                        borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
                        fontSize: "0.875rem",
                      }}
                    >
                      Çalışılan Süre
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        width: "30%",
                        borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
                        fontSize: "0.875rem",
                      }}
                    >
                      Mola Süresi
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(courierSummary).map((courier, index) => (
                    <TableRow
                      key={index}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#424242" : "#4f4f4f",
                      }}
                    >
                      <TableCell
                        style={{
                          color: "white",
                          padding: 5,
                          borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
                          fontSize: "0.875rem",
                        }}
                      >
                        {courier.name}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "white",
                          padding: 5,
                          borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
                          fontSize: "0.875rem",
                        }}
                      >
                        {this.formatDuration(courier.workingTime)}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "white",
                          padding: 5,
                          borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
                          fontSize: "0.875rem",
                        }}
                      >
                        {this.formatDuration(courier.breakTime)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  paketYoldaReportMenu: state.get("paketYoldaReducer").paketYoldaReportMenu,
  paketYoldaCourierList: state.get("paketYoldaReducer").paketYoldaCourierList,
  courierActivities: state
    .get("courierActivityReducer")
    .get("courierActivities")
    .toJS(),
  courierActivitiesLoading: state.get("courierActivityReducer").get("loading"),
  courierActivitiesFilter: state
    .get("courierActivityReducer")
    .get("filter")
    .toJS(),
  logReportList: state
    .get("courierActivityReducer")
    .get("courierActivities")
    .toJS(),
});

const mapDispatchToProps = {
  fetchCourierActivities,
  filterCourierActivities,
  setPaketYoldaLogReportFilterData,
  getPaketYoldaBrandList,
  getPaketYoldaBusinessList,
  getNewCourierList,
  getOrderGeneralReport,
  getPackageAdditionDetail,
  getLogReportList,
  getPaketYoldaCourierList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(CourierWorkReport)));
