import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import { PapperBlock } from 'dan-components';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import ModalInformation from '../Ortak/ModalInformation';
import Outer from '../../Templates/Outer';
import Grid from '@material-ui/core/Grid';
import Decoration from '../../Templates/Decoration';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import styles from './welcome-jss';
import jarvis from 'dan-images/jarvis.png';
import Calendar from 'react-calendar';
import "./calendar.css";
import {
    BarChart,
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    CartesianAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Label,
    PieChart, Pie, Cell,
    Sector,
    LabelList,
    Line
} from 'recharts';
import {
    createSelector
} from 'reselect';
import {
    RequestFilterHourReport, setReportPageHourPerformanceUpdate, RequestEndOfDayReport, redirectMainPage
} from '../../../redux/modules/report';
import { formDataSet } from '../../../redux/modules/addition';
import { clearCache } from '../../../redux/modules/settings';
import { getParameter, getIsLite, trMoneyConvert } from '../../../function/GeneralFunction';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import cookie from 'react-cookies';
import JaviPosHelp from '../../../components/Header/JaviPosHelp';
import { reactLocalStorage } from 'reactjs-localstorage';

export const data2 = [
    {
        name: 'Electronics',
        value: 400
    }, {
        name: 'Fashions',
        value: 300
    },
    {
        name: 'Toys',
        value: 300
    }, {
        name: 'Other',
        value: 200
    }
];

function CustomTooltipEntegration({ payload, label, active }) {
    if (active && payload != null) {
        return (
            <div style={{ backgroundColor: '#313131', borderRadius: 5, padding: 10 }}>
                <div style={{ display: 'flex', width: 300 }}>
                    <div style={{ color: 'white', width: '50%', textAlign: 'left' }}>{`Saat`}</div>
                    <div style={{ color: 'white', width: '50%', textAlign: 'right' }}>{`${label}:00`}</div>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ color: 'white', width: '50%', textAlign: 'left' }}>{`GetirYemek`}</div>
                    <div style={{ color: 'white', width: '50%', textAlign: 'right' }}>{`${trMoneyConvert(payload[0].payload.GetirYemek,2)}`}</div>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ color: 'white', width: '50%', textAlign: 'left' }}>{`TrendyolYemek`}</div>
                    <div style={{ color: 'white', width: '50%', textAlign: 'right' }}>{`${trMoneyConvert(payload[0].payload.TrendyolYemek,2)}`}</div>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ color: 'white', width: '50%', textAlign: 'left' }}>{`YemekSepeti`}</div>
                    <div style={{ color: 'white', width: '50%', textAlign: 'right' }}>{`${trMoneyConvert(payload[0].payload.YemekSepeti,2)}`}</div>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ color: 'white', width: '50%', textAlign: 'left' }}>{`MigrosYemek`}</div>
                    <div style={{ color: 'white', width: '50%', textAlign: 'right' }}>{`${trMoneyConvert(payload[0].payload.MigrosYemek,2)}`}</div>
                </div>
            </div>
        );
    }

    return null;
}

function CustomTooltip({ payload, label, active }) {
    if (active && payload != null) {
        return (
            <div style={{ backgroundColor: '#313131', borderRadius: 5, padding: 10 }}>
                <div style={{ display: 'flex', width: 200 }}>
                    <div style={{ color: 'white', width: '50%', textAlign: 'left' }}>{`Saat`}</div>
                    <div style={{ color: 'white', width: '50%', textAlign: 'right' }}>{`${label}:00`}</div>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ color: 'white', width: '50%', textAlign: 'left' }}>{`İç Satış`}</div>
                    <div style={{ color: 'white', width: '50%', textAlign: 'right' }}>{`${trMoneyConvert(payload[0].payload.NormalTotal,2)}`}</div>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ color: 'white', width: '50%', textAlign: 'left' }}>{`Paket Satışı`}</div>
                    <div style={{ color: 'white', width: '50%', textAlign: 'right' }}>{`${trMoneyConvert(payload[0].payload.PackageTotal,2)}`}</div>
                </div>
            </div>
        );
    }

    return null;
}

class MainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hourReportType: 'Normal' };
    }

    async componentDidMount() {
        await this.props.redirectMainPage(false).then(() => { })
    }

    renderColorfulLegendText(value, entry) {
        const { color } = entry;

        return <span style={{ color: 'white', fontSize: 12 }}>{value}</span>;
    }

    renderDataHourEntegration() {
        const { HourPerformanceReport } = this.props;
        if (HourPerformanceReport == null) {
            return []
        }

        var dataArray = [];
        HourPerformanceReport.map((n, i) => {
            dataArray.push(
                {
                    Hour: n.Hour,
                    YemekSepeti: n.EntegrationHours.find(x => x.EntegrationName == "YemekSepetiGlobal") != null ? n.EntegrationHours.find(x => x.EntegrationName == "YemekSepetiGlobal").Total : 0,
                    GetirYemek: n.EntegrationHours.find(x => x.EntegrationName == "GetirYemek") != null ? n.EntegrationHours.find(x => x.EntegrationName == "GetirYemek").Total : 0,
                    TrendyolYemek: n.EntegrationHours.find(x => x.EntegrationName == "TrendyolYemek") != null ? n.EntegrationHours.find(x => x.EntegrationName == "TrendyolYemek").Total : 0,
                    MigrosYemek: n.EntegrationHours.find(x => x.EntegrationName == "MigrosYemek") != null ? n.EntegrationHours.find(x => x.EntegrationName == "MigrosYemek").Total : 0,
                    PaketSatis: (n.EntegrationHours.find(x => x.EntegrationName == "Paket") != null ? n.EntegrationHours.find(x => x.EntegrationName == "Paket").Total : 0) +
                        (n.EntegrationHours.find(x => x.EntegrationName == "Call Center Paket") != null ? n.EntegrationHours.find(x => x.EntegrationName == "Call Center Paket").Total : 0) +
                        (n.EntegrationHours.find(x => x.EntegrationName == "Paket Satış") != null ? n.EntegrationHours.find(x => x.EntegrationName == "Paket Satış").Total : 0)
                });
        })
        return dataArray;
    }

    renderDataHour() {
        const { HourPerformanceReport } = this.props;
        if (HourPerformanceReport == null) {
            return []
        }

        var dataArray = [];
        HourPerformanceReport.map((n, i) => {
            dataArray.push({ Hour: n.Hour, NormalTotal: n.NormalTotal, PackageTotal: n.PackageTotal });
        })
        return dataArray;
    }

    handleChangeFilterChart = name => event => {
        this.setState({ hourReportType: (event.target.checked ? 'Entegration' : 'Normal') });
        this.props.setReportPageHourPerformanceUpdate().then(() => { })
    };

    handleChangeFilterChartRefresh = event => {
        this.props.redirectMainPage(false).then(() => { })
    };

    handleCloseApp = () => {
        cookie.remove('UserName', { path: '/' });
        cookie.save('AdminLogin', "false", { path: '/' });
        cookie.remove('RestaurantName', { path: '/' });
        cookie.remove('UserId', { path: '/' });
        cookie.remove('BrandId', { path: '/' });
        cookie.remove('TenantId', { path: '/' });
        cookie.remove('CenterBrandId', { path: '/' });
        cookie.remove('UserType', { path: '/' });
        cookie.remove('Settings', { path: '/' });
        cookie.remove('LicenceNumber', { path: '/' });
        cookie.remove('NameSurname', { path: '/' });
        cookie.remove('Authorization', { path: '/' });
        reactLocalStorage.remove('l_string');

        if (cookie.load("IsRemember") === "false") {
            cookie.remove('Email', { path: '/' });
            cookie.remove('Password', { path: '/' });
        }
        this.props.history.push('/');
    };

    handleInfo = () => {
        this.props.formDataSet("javiPosHelpVisible", true).then(() => { })
    };

    handleCloseHelpDialog = () => {
        this.props.formDataSet("javiPosHelpVisible", false).then(() => { })
    };

    render() {
        const { classes, EndOfDayReport } = this.props;

        return (
            <Outer style={{ position: 'relative' }}>
                <span style={{ display: 'flex', alignItems: 'center', color: 'white', fontSize: 18, fontWeight: 600, position: 'absolute', left: '3%', top: '3%' }}>
                    <Icon style={{ marginRight: 10, fontSize: 35 }}>{"store"}</Icon>
                    {cookie.load('RestaurantName')}
                </span>
                <span style={{ display: 'flex', alignItems: 'center', color: 'white', fontSize: 18, fontWeight: 600, position: 'absolute', right: '3%', top: '3%' }}>
                    {cookie.load('NameSurname')}
                    <Icon style={{ marginLeft: 10, fontSize: 35 }}>{"account_circle"}</Icon>
                </span>
                <JaviPosHelp classes={classes} open={this.props.javiPosHelpVisible} title={"JaviPos Canlı Destek ve Yardım"} handleCloseHelpDialog={this.handleCloseHelpDialog} />
                <Grid spacing={4} container style={{ marginTop: 30, height: '90vh', zIndex: 99, width: '90%' }}>
                    <Grid item md={4}>
                        <div style={{ display: 'block', width: '100%', height: '100%' }}>
                            <div style={{ height: '33.33%', width: '100%', paddingBottom: 8 }}>
                                <Button fullWidth onClick={() => this.props.history.push((getIsLite() ? '/app/packagepage?type=order' : (getParameter("SelfSales") ? '/app/detailpage?selfsales' : '/app')))} variant="contained" color="default" style={{ position: 'relative', height: '100%', backgroundColor: '#334899', color: 'white', borderRadius: 0, textTransform: 'capitalize' }}>
                                    <img style={{ height: 60 }} src={jarvis} />
                                    <Typography style={{ position: 'absolute', bottom: 5, left: 10, fontSize: 23, fontWeight: 600 }} className={classes.buttonMenuTitle} variant="subtitle1">{(getIsLite() ? 'Paketler' : (getParameter("SelfSales") ? 'Self Satış' : 'Satış'))}</Typography>
                                </Button>
                            </div>
                            <div style={{ height: '33.33%', width: '100%', display: 'flex', paddingBottom: 8 }}>
                                <div style={{ height: '100%', width: '50%', paddingRight: 4 }}>
                                    <Button fullWidth onClick={() => this.props.history.push('/app/settings')} variant="contained" color="default" style={{ position: 'relative', height: '100%', backgroundColor: '#4FB99F', color: 'white', borderRadius: 0, textTransform: 'capitalize' }}>
                                        <Icon style={{ fontSize: 40 }}>{"settings"}</Icon>
                                        <Typography style={{ position: 'absolute', bottom: 5, left: 10, fontSize: 16, fontWeight: 600 }} className={classes.buttonMenuTitle} variant="subtitle1">{"Kontrol Paneli"}</Typography>
                                    </Button>
                                </div>
                                <div style={{ height: '100%', width: '50%', paddingLeft: 4 }}>
                                    <Button fullWidth onClick={() => this.props.clearCache()} variant="contained" color="default" style={{ position: 'relative', height: '100%', backgroundColor: '#F2B134', color: 'white', borderRadius: 0, textTransform: 'capitalize' }}>
                                        <Icon style={{ fontSize: 35 }}>{"sync"}</Icon>
                                        <Typography style={{ position: 'absolute', bottom: 5, left: 10, fontSize: 16, fontWeight: 600 }} className={classes.buttonMenuTitle} variant="subtitle1">{"Ayarları Yükle"}</Typography>
                                    </Button>
                                </div>
                            </div>
                            <div style={{ height: '33.33%', width: '100%', display: 'flex' }}>
                                <div style={{ height: '100%', width: '50%', paddingRight: 4 }}>
                                    <Button fullWidth onClick={this.handleInfo} variant="contained" color="default" style={{ position: 'relative', height: '100%', backgroundColor: '#00ADB5', color: 'white', borderRadius: 0, textTransform: 'capitalize' }}>
                                        <Icon style={{ fontSize: 35 }}>{"info"}</Icon>
                                        <Typography style={{ position: 'absolute', bottom: 5, left: 10, fontSize: 16, fontWeight: 600 }} className={classes.buttonMenuTitle} variant="subtitle1">{"Yardım"}</Typography>
                                    </Button>
                                </div>
                                <div style={{ height: '100%', width: '50%', paddingLeft: 4 }}>
                                    <Button fullWidth onClick={this.handleCloseApp} variant="contained" color="default" style={{ position: 'relative', height: '100%', backgroundColor: '#D93E3A', color: 'white', borderRadius: 0, textTransform: 'capitalize' }}>
                                        <Icon style={{ fontSize: 35 }}>{"logout"}</Icon>
                                        <Typography style={{ position: 'absolute', bottom: 5, left: 10, fontSize: 16, fontWeight: 600 }} className={classes.buttonMenuTitle} variant="subtitle1">{"Çıkış"}</Typography>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={8}>
                        <div style={{ marginBottom: 8, display: 'flex', width: '100%', height: 'calc(40% - 4px)' }}>
                            <div style={{ marginRight: 4, borderRadius: 10, position: 'relative', backgroundColor: '#424242', height: '100%', width: '50%' }}>
                                <Icon style={{ color: 'white', fontSize: 50, position: 'absolute', left: 15, top: 10 }}>{"shopping_basket"}</Icon>
                                <div style={{ textAlign: 'center', fontSize: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', width: '100%', height: '100%' }}>
                                    {"JaviPos Market"}
                                </div>
                                <div style={{ fontWeight:600, color: 'white', fontSize: 20, position: 'absolute', right: 15, bottom: 10 }}>{"YAKINDA!"}</div>
                            </div>
                            <div style={{ marginLeft: 4, borderRadius: 10, height: '100%', width: '50%', display: 'flex' }}>
                                <div style={{ borderRadius: 10, backgroundColor: '#424242', position: 'relative', backgroundColor: '#424242', height: '100%', width: '100%' }}>
                                    <div style={{ borderRadius: 10, width: '100%', height: '100%' }}>
                                        <Icon style={{ color: 'white', fontSize: 50, position: 'absolute', left: 15, top: 10 }}>{"insights"}</Icon>
                                        <span style={{ color: 'white', fontSize: 20, position: 'absolute', right: 10, top: 10 }}>{"İstatistikler"}</span>
                                        <div style={{ textAlign: 'end', position: 'absolute', right: 10, bottom: 10, display: 'grid' }}>
                                            <span style={{ fontSize: 17, color: 'rgb(255, 160, 0)' }}>{"Açık Masa : "}{EndOfDayReport != null ? trMoneyConvert(EndOfDayReport.TableTotal,2) : 0}</span>
                                            <span style={{ fontSize: 17, color: 'rgb(255, 160, 0)' }}>{"Açık Paket : "}{EndOfDayReport != null ? trMoneyConvert(EndOfDayReport.OpenCourierTotal,2) : 0}</span>
                                            <span style={{ fontSize: 17, color: 'rgb(255, 160, 0)' }}>{"Açık Gel Al : "}{EndOfDayReport != null ? trMoneyConvert(EndOfDayReport.SelfSaleTotal,2) : 0}</span>
                                            <span style={{ marginTop: 10, fontSize: 17, color: 'white' }}>{"Gelir Toplamı : "}{EndOfDayReport != null ? trMoneyConvert(EndOfDayReport.CashReport.TotalIncome,2) : 0}</span>
                                            <span style={{ fontSize: 17, color: 'white' }}>{"Gider Toplamı : "}{EndOfDayReport != null ? trMoneyConvert(EndOfDayReport.CashReport.TotalExpense,2) : 0}</span>
                                            <span style={{ fontSize: 17, color: 'white' }}>{"Toplam Kasa : "}{EndOfDayReport != null ? trMoneyConvert(EndOfDayReport.CashReport.TotalCash,2) : 0}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ position: 'relative', marginTop: 8, borderRadius: 10, backgroundColor: '#424242', display: 'block', width: '100%', height: 'calc(60% - 4px)' }}>
                            <div style={{ color: 'white', padding: 5, display: 'flex', justifyContent: 'center' }}>{(this.state.hourReportType == "Normal" ? "Günlük Bazda Saatlik İç Satış - Paket Satış Grafiği" : "Günlük Bazda Saatlik Paket Satış Kanalı Grafiği")}</div>
                            {
                                (this.state.hourReportType == 'Normal' ?
                                    <ResponsiveContainer>
                                        <ComposedChart
                                            data={this.renderDataHour()}
                                            margin={{
                                                top: 15,
                                                right: 15, left: -20,
                                                bottom: 65
                                            }}
                                        >
                                            <defs>
                                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor={"#FFC107"} stopOpacity={1} />
                                                    <stop offset="95%" stopColor={"#FFC107"} stopOpacity={1} />
                                                </linearGradient>
                                                <linearGradient id="colorUp" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor={"#009688"} stopOpacity={1} />
                                                    <stop offset="95%" stopColor={"#009688"} stopOpacity={1} />
                                                </linearGradient>
                                            </defs>
                                            <XAxis interval={0} angle={-45} textAnchor="end" dataKey="Hour" tickLine={false} label={{ size: 12 }} />
                                            <YAxis axisLine={false} tickSize={3} tickLine={false} tick={{ stroke: 'none' }} />
                                            <CartesianGrid vertical={false} strokeDasharray="3 3" />
                                            <CartesianAxis vertical={false} />
                                            <Tooltip content={<CustomTooltip />} />
                                            <Legend verticalAlign={"bottom"} formatter={this.renderColorfulLegendText} />
                                            <Bar stackId="a" name="İç Satış" dataKey="NormalTotal" fillOpacity="1" fill="url(#colorUv)" />
                                            <Bar stackId="a" name="Paket Satışı" dataKey="PackageTotal" fillOpacity="1" fill="url(#colorUp)" />
                                        </ComposedChart>
                                    </ResponsiveContainer>
                                    :
                                    <ResponsiveContainer>
                                        <ComposedChart
                                            margin={{
                                                top: 15,
                                                right: 15, left: -20,
                                                bottom: 65
                                            }}
                                            data={this.renderDataHourEntegration()}
                                        >
                                            <defs>
                                                <linearGradient id="colorYemekSepeti" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor={"#fa0050"} stopOpacity={1} />
                                                    <stop offset="95%" stopColor={"#fa0050"} stopOpacity={1} />
                                                </linearGradient>
                                                <linearGradient id="colorTrendyolYemek" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor={"#f27a1a"} stopOpacity={1} />
                                                    <stop offset="95%" stopColor={"#f27a1a"} stopOpacity={1} />
                                                </linearGradient>
                                                <linearGradient id="colorGetirYemek" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor={"#5d3ebc"} stopOpacity={1} />
                                                    <stop offset="95%" stopColor={"#5d3ebc"} stopOpacity={1} />
                                                </linearGradient>
                                                <linearGradient id="colorGoFody" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor={"#f1b62a"} stopOpacity={1} />
                                                    <stop offset="95%" stopColor={"#f1b62a"} stopOpacity={1} />
                                                </linearGradient>
                                                <linearGradient id="colorMigrosYemek" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor={"#27ae60"} stopOpacity={1} />
                                                    <stop offset="95%" stopColor={"#27ae60"} stopOpacity={1} />
                                                </linearGradient>
                                                <linearGradient id="colorPaketSatis" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor={"#2980b9"} stopOpacity={1} />
                                                    <stop offset="95%" stopColor={"#2980b9"} stopOpacity={1} />
                                                </linearGradient>
                                            </defs>
                                            <XAxis interval={0} angle={-45} textAnchor="end" dataKey="Hour" tickLine={false} label={{ size: 12 }} />
                                            <YAxis axisLine={false} tickSize={3} tickLine={false} tick={{ stroke: 'none' }} />
                                            <CartesianGrid vertical={false} strokeDasharray="3 3" />
                                            <CartesianAxis vertical={false} />
                                            <Tooltip content={<CustomTooltipEntegration />} />
                                            <Legend verticalAlign={"bottom"} formatter={this.renderColorfulLegendText} />
                                            <Bar stackId="a" name="YemekSepeti" dataKey="YemekSepeti" fillOpacity="1" fill="url(#colorYemekSepeti)" />
                                            <Bar stackId="a" name="TrendyolYemek" dataKey="TrendyolYemek" fillOpacity="1" fill="url(#colorTrendyolYemek)" />
                                            <Bar stackId="a" name="GetirYemek" dataKey="GetirYemek" fillOpacity="1" fill="url(#colorGetirYemek)" />
                                            <Bar stackId="a" name="MigrosYemek" dataKey="MigrosYemek" fillOpacity="1" fill="url(#colorMigrosYemek)" />
                                            <Bar stackId="a" name="PaketSatis" dataKey="PaketSatis" fillOpacity="1" fill="url(#colorPaketSatis)" />
                                        </ComposedChart>
                                    </ResponsiveContainer>)
                            }
                            <div style={{ position: 'absolute', left: 10, bottom: 5 }}>
                                <IconButton style={{ backgroundColor: '#3e3e3e', borderRadius: 5, width: 35, height: 35 }} onClick={() => this.props.RequestFilterHourReport().then(() => { })}>
                                    <Icon style={{ color: 'white' }}>{"refresh"}</Icon>
                                </IconButton>
                            </div>
                            <div style={{ position: 'absolute', right: 10, bottom: 0 }}>
                                <FormControlLabel
                                    style={{ margin: 0, color: 'white' }}
                                    control={(
                                        <Switch
                                            checked={this.state.hourReportType == 'Normal' ? false : true}
                                            onChange={this.handleChangeFilterChart("")}
                                        />
                                    )}
                                    label={<Typography style={{ fontSize: 12 }}>{"Entegrasyon Bazında Oluştur"}</Typography>}
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Outer >
        );
    }
}

const valueSelector = (state, props) => state.get('reportReducer').IsReportHourPerformanceUpdate;
const value = createSelector([valueSelector],
    (value) => value);


const mapStateToProps = (state, props) => ({
    IsReportHourPerformanceUpdate: value(state, props),
    HourPerformanceReport: state.get('reportReducer').HourPerformanceReport,
    HourPerformanceFilterData: state.get('reportReducer').HourPerformanceFilterData,
    javiPosHelpVisible: state.get('additionReducer').form_data.javiPosHelpVisible,
    EndOfDayReport: state.get('reportReducer').EndOfDayReport
})

const mapDispatchToProps = {
    RequestFilterHourReport,
    setReportPageHourPerformanceUpdate,
    RequestEndOfDayReport,
    formDataSet,
    clearCache,
    redirectMainPage
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(MainPage)));
