import React from "react";
import { createSelector } from "reselect";
import cookie from "react-cookies";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { CounterTrading } from "dan-components";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  generatePdfReport,
  generateExcelReport,
} from "./services/exportServices";
import "jspdf-autotable";

import TablePagination from "@material-ui/core/TablePagination";
import {
  setPaketYoldaGeneralReportFilterData,
  getPaketYoldaBrandList,
  getPaketYoldaCourierList,
  getPaketYoldaBusinessList,
  getNewCourierList,
  getOrderGeneralReport,
  getOrderGeneralReportList,
  setOrderUnLoad,
  setExtraAmount,
  setExtraAmountVisible,
  setExtraAmountData,
} from "../../../../redux/modules/paketyolda";
import { getPackageAdditionDetail } from "../../../../redux/modules/addition";
import {
  trMoneyConvert,
  gorupOrder,
  gorupOrderColor,
} from "../../../../function/GeneralFunction";
import CircularProgress from "@material-ui/core/CircularProgress";
import DatePicker, { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr"; // the locale you want
registerLocale("tr", tr); // register it with the name you want
import "../datepicker.css";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TooltipMui from "@material-ui/core/Tooltip";
import * as XLSX from "xlsx";
import {
  BarChart,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  CartesianAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import TbPaginationActions from "../../Ortak/TbPaginationActions";
import PackageAdditionDetail from "../../PackagePage/PackageAdditionDetail";
import { createPdfTemplate } from "./services/pdfExport/pdfTemplate";

const styles = (theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

function CustomTooltip({ payload, label, active }) {
  if (active && payload != null) {
    return (
      <div style={{ backgroundColor: "#313131", borderRadius: 5, padding: 10 }}>
        <div style={{ display: "flex", width: 200 }}>
          <div
            style={{ color: "white", width: "50%", textAlign: "left" }}
          >{`Saat`}</div>
          <div
            style={{ color: "white", width: "50%", textAlign: "right" }}
          >{`${label}`}</div>
        </div>
        <div style={{ display: "flex", width: "100%" }}>
          <div
            style={{ color: "white", width: "50%", textAlign: "left" }}
          >{`Toplamı`}</div>
          <div style={{ color: "white", width: "50%", textAlign: "right" }}>{`${
            payload[0].payload.TotalCount
          }`}</div>
        </div>
        <div style={{ display: "flex", width: "100%" }}>
          <div
            style={{ color: "white", width: "50%", textAlign: "left" }}
          >{`Tutarı`}</div>
          <div
            style={{ color: "white", width: "50%", textAlign: "right" }}
          >{`${trMoneyConvert(payload[0].payload.TotalPrice, 2)}`}</div>
        </div>
      </div>
    );
  }

  return null;
}

class GeneralReport extends React.Component {
  constructor() {
    super();
    this.state = {
      filterActive: true,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.paketYoldaGeneralReportUpdate ==
      this.props.paketYoldaGeneralReportUpdate
    ) {
      return false;
    }
    return true;
  }

  async componentDidMount() {
    var PaketYoldaId = cookie.load("PaketYoldaId");
    this.props.getPaketYoldaBusinessList(PaketYoldaId).then(() => {});
    this.props.getNewCourierList().then(() => {});
  }

  handleChangeFilterReport = (name) => (event) => {
    const { paketYoldaGeneralReportfilter } = this.props;
    paketYoldaGeneralReportfilter[name] =
      name == "StartDatetime" || name == "EndDatetime"
        ? moment
            .utc(event, "MM-DD-YYYY")
            .local()
            .format("YYYY-MM-DDTHH:mm")
        : name == "IsList" || name == "IsCompare"
          ? event.target.checked
          : event.target.value;
    this.props
      .setPaketYoldaGeneralReportFilterData(paketYoldaGeneralReportfilter)
      .then(() => {});
  };

  renderDataHour() {
    const { paketYoldaGeneralReportData } = this.props;

    var dataArray = [];
    paketYoldaGeneralReportData.HourDatas.map((n, i) => {
      dataArray.push({
        Hour: n.Hour,
        TotalPrice: n.TotalPrice,
        TotalCount: n.TotalCount,
      });
    });
    return dataArray;
  }

  renderColorfulLegendText(value, entry) {
    const { color } = entry;

    return <span style={{ color: "white", fontSize: 12 }}>{value}</span>;
  }

  async handleFilterGeneral() {
    this.setState({ filterActive: false });
    const { paketYoldaGeneralReportfilter } = this.props;
    paketYoldaGeneralReportfilter.IsDay = false;
    await this.props
      .getOrderGeneralReportList(paketYoldaGeneralReportfilter)
      .then(() => {});
    await this.props.getOrderGeneralReport().then(() => {});
  }

  handleChangePage = (event, page) => {
    const { paketYoldaGeneralReportfilter } = this.props;
    paketYoldaGeneralReportfilter.page = page + 1;
    this.props
      .getOrderGeneralReportList(paketYoldaGeneralReportfilter)
      .then(() => {});
  };
  handlePdfExport = () => {
    const { paketYoldaGeneralReportData } = this.props;
    generatePdfReport(paketYoldaGeneralReportData);
  };

  handleExcelExport = () => {
    const { paketYoldaGeneralReportData } = this.props;
    generateExcelReport(paketYoldaGeneralReportData);
  };
  renderPayment() {
    const { paketYoldaGeneralReportData } = this.props;
    {
      return paketYoldaGeneralReportData.PaymentData.map((n, i) => {
        return (
          <Grid container style={{ flexDirection: "row", zIndex: 6 }}>
            <Grid item md={6} style={{ textAlign: "left", paddingBottom: 5 }}>
              <span
                style={{
                  marginLeft: 7,
                  fontSize: 13,
                  fontWeight: 500,
                  color: "white",
                  marginRight: 5,
                }}
              >
                {n.PaymentName}
              </span>
            </Grid>
            <Grid item md={6} style={{ textAlign: "right" }}>
              <span
                style={{
                  marginRight: 7,
                  fontSize: 13,
                  fontWeight: 500,
                  color: "white",
                  textTransform: "capitalize",
                }}
              >
                {trMoneyConvert(n.TotalPrice, 2)}
              </span>
            </Grid>
          </Grid>
        );
      });
    }
  }

  handleClickPopover = (event) => {
    this.props.getPackageAdditionDetail(
      event.currentTarget.id,
      event.currentTarget
    );
  };

  renderExtraAmount() {
    const { extraAmountVisible, classes, extraAmount } = this.props;

    return (
      <Dialog
        open={extraAmountVisible}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle style={{ padding: "24px 24px 5px", marginBottom: 10 }}>
          {"Adisyonun İşletme Hesabına Eklenecek Ekstra Hakediş Tutarı"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={1}>
              <Grid item md={12}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="name-simple">{"Ektra Tutar"}</InputLabel>
                  <Input
                    fullWidth
                    value={extraAmount}
                    onFocus={(event) => {
                      event.target.select();
                    }}
                    onChange={(event) =>
                      this.props
                        .setExtraAmountData(event.target.value)
                        .then(() => {})
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#e74c3c", color: "white" }}
            onClick={() =>
              this.props.setExtraAmountVisible(false).then(() => {})
            }
            color="primary"
          >
            {"İptal"}
          </Button>
          <Button
            onClick={() => this.props.setExtraAmount().then(() => {})}
            color="secondary"
            variant="contained"
            autoFocus
          >
            {"Onay"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const {
      generalReportOrderListGroup,
      generalReportOrderList,
      paketYoldaGeneralReportData,
      paketYoldaCourierList,
      paketYoldaBusinessList,
      paketYoldaGeneralReportfilter,
      paketYoldaNewReportLoading,
      paketYoldaReportMenu,
      classes,
    } = this.props;

    if (paketYoldaReportMenu != "GeneralReport") {
      return null;
    }

    if (paketYoldaNewReportLoading) {
      return (
        <div style={{ width: "100%", textAlignLast: "center", paddingTop: 20 }}>
          <CircularProgress
            style={{ color: "#fafafa" }}
            thickness={4}
            className={classes.progress}
          />
        </div>
      );
    }

    return (
      <Paper
        className={classes.root}
        elevation={0}
        style={{
          backgroundColor: "transparent",
          borderRadius: 0,
          height: "100%",
        }}
      >
        {this.renderExtraAmount()}
        <PackageAdditionDetail classes={classes} />
        <div
          style={{ height: "calc(100% - 43px)", padding: 5, overflowY: "auto" }}
        >
          <Grid
            container
            spacing={1}
            style={{
              borderBottom: "1px solid #424242",
              padding: 5,
              borderRadius: 0,
              marginBottom: 5,
            }}
          >
            <Grid item md={2}>
              <Typography
                style={{
                  marginBottom: 6,
                  padding: 0,
                  fontSize: 12,
                  fontFamily: "Quicksand,sans-serif",
                  lineHeight: 1,
                }}
                className={classes.flex}
              >
                {"Başlangıç Tarihi"}
              </Typography>
              <DatePicker
                selected={new Date(paketYoldaGeneralReportfilter.StartDatetime)}
                onChange={this.handleChangeFilterReport("StartDatetime")}
                dateFormat="dd.MM.yyyy HH:mm"
                showTimeSelect
                timeIntervals={15}
                timeFormat="HH:mm"
                locale="tr"
                className={"inputDate"}
              />
            </Grid>
            <Grid item md={2}>
              <Typography
                style={{
                  marginBottom: 6,
                  padding: 0,
                  fontSize: 12,
                  fontFamily: "Quicksand,sans-serif",
                  lineHeight: 1,
                }}
                className={classes.flex}
              >
                {"Bitiş Tarihi"}
              </Typography>
              <DatePicker
                selected={new Date(paketYoldaGeneralReportfilter.EndDatetime)}
                onChange={this.handleChangeFilterReport("EndDatetime")}
                dateFormat="dd.MM.yyyy HH:mm"
                showTimeSelect
                timeIntervals={15}
                timeFormat="HH:mm"
                locale="tr"
                className={"inputDate"}
              />
            </Grid>
            <Grid item md={2}>
              <FormControl
                fullWidth
                className={classes.formControl}
                style={{ margin: 0 }}
              >
                <Typography
                  style={{
                    marginBottom: 6,
                    padding: 0,
                    fontSize: 12,
                    fontFamily: "Quicksand,sans-serif",
                    lineHeight: 1,
                  }}
                  className={classes.flex}
                >
                  {"Restoran Seçimi"}
                </Typography>
                <Select
                  fullWidth
                  style={{ width: "100%", borderRadius: 5, height: 38 }}
                  value={paketYoldaGeneralReportfilter.JaviPosBrandId}
                  onChange={this.handleChangeFilterReport("JaviPosBrandId")}
                  inputProps={{
                    name: "age",
                    id: "age-simple",
                  }}
                >
                  <MenuItem value={"00000000-0000-0000-0000-000000000000"}>
                    {"Boş"}
                  </MenuItem>
                  {paketYoldaBusinessList
                    .sort((a, b) =>
                      a.BusinessName.localeCompare(b.BusinessName, "tr")
                    )
                    .map((n, i) => (
                      <MenuItem key={n.BrandId} value={n.BrandId}>
                        {n.BusinessName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <FormControl
                fullWidth
                className={classes.formControl}
                style={{ margin: 0 }}
              >
                <Typography
                  style={{
                    marginBottom: 6,
                    padding: 0,
                    fontSize: 12,
                    fontFamily: "Quicksand,sans-serif",
                    lineHeight: 1,
                  }}
                  className={classes.flex}
                >
                  {"Kurye Seçimi"}
                </Typography>
                <Select
                  fullWidth
                  style={{ width: "100%", borderRadius: 5, height: 38 }}
                  value={paketYoldaGeneralReportfilter.CourierId}
                  onChange={this.handleChangeFilterReport("CourierId")}
                  inputProps={{
                    name: "age",
                    id: "age-simple",
                  }}
                >
                  <MenuItem value={"00000000-0000-0000-0000-000000000000"}>
                    {"Boş"}
                  </MenuItem>
                  {paketYoldaCourierList.map((n, i) => {
                    return <MenuItem value={n.Id}>{n.NameSurname}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={1}>
              <FormControl
                fullWidth
                className={classes.formControl}
                style={{ margin: 0 }}
              >
                <Typography
                  style={{
                    marginBottom: 6,
                    padding: 0,
                    fontSize: 12,
                    fontFamily: "Quicksand,sans-serif",
                    lineHeight: 1,
                  }}
                  className={classes.flex}
                >
                  {"Durumu"}
                </Typography>
                <Select
                  fullWidth
                  style={{ width: "100%", borderRadius: 5, height: 38 }}
                  value={paketYoldaGeneralReportfilter.IsDelete}
                  onChange={this.handleChangeFilterReport("IsDelete")}
                  inputProps={{
                    name: "age",
                    id: "age-simple",
                  }}
                >
                  <MenuItem value={"Active"}>{"Aktif"}</MenuItem>
                  <MenuItem value={"Passive"}>{"Silinmiş"}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={3}
              style={{
                display: "flex",
                alignItems: "flex-end",
                gap: "8px",
              }}
            >
              <Button
                fullWidth
                disabled={paketYoldaNewReportLoading}
                onClick={() => {
                  this.handleFilterGeneral();
                }}
                style={{
                  borderRadius: 5,
                  height: 37,
                  padding: "6px 24px",
                  width: 210,
                }}
                variant="contained"
                color="secondary"
                type="submit"
              >
                {"FİLTRELE"}
              </Button>
              <Button
                onClick={this.handleExcelExport}
                disabled={this.state.filterActive}
                style={{
                  minWidth: "40px",
                  padding: "6px 12px",
                  height: 37,
                  backgroundColor: "#27ae60",
                  borderRadius: 4,
                  color: "#fafafa",
                  marginTop: 17,
                }}
                variant="contained"
              >
                <Icon style={{ fontSize: 20 }}>description</Icon>
                <span style={{ marginLeft: 4, fontSize: 13 }}>Excel</span>
              </Button>

              <Button
                onClick={this.handlePdfExport}
                disabled={this.state.filterActive}
                style={{
                  minWidth: "40px",
                  padding: "6px 12px",
                  height: 37,
                  backgroundColor: "#e74c3c",
                  borderRadius: 4,
                  color: "#fafafa",
                  marginTop: 17,
                }}
                variant="contained"
              >
                <Icon style={{ fontSize: 20 }}>picture_as_pdf</Icon>
                <span style={{ marginLeft: 4, fontSize: 13 }}>PDF</span>
              </Button>
            </Grid>
          </Grid>
          <div
            style={{
              backgroundColor: "#313131",
              marginTop: 10,
              marginBottom: 10,
              width: "100%",
              padding: 10,
              borderBottom: "1px solid rgba(209, 216, 224,.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {"Genel İstatistikler"}
          </div>
          <Grid container spacing={1}>
            <Grid item md={3}>
              <CounterTrading
                color={"#1289A7"}
                unitBefore=""
                title="Paket Sayısı"
                icon={"inventory_2"}
                position="down"
                start={0}
                end={paketYoldaGeneralReportData.OrderCount}
              />
            </Grid>
            <Grid item md={3}>
              <CounterTrading
                color={"#5758BB"}
                unitBefore=""
                unitAfter=""
                title="Ortalama Paket Tutarı"
                icon={"payments"}
                position="down"
                start={0}
                end={paketYoldaGeneralReportData.AvgAdditionPrice}
              />
            </Grid>
            <Grid item md={3}>
              <CounterTrading
                color={"rgba(96, 163, 188,1.0)"}
                unitBefore=""
                unitAfter="Dk"
                title="Ortalama Teslim Süresi"
                decimals={0}
                icon={"timer"}
                position="down"
                start={0}
                end={paketYoldaGeneralReportData.AvgDeliveredTime}
              />
            </Grid>
            <Grid item md={3}>
              <CounterTrading
                color={"#B53471"}
                unitBefore=""
                title="Toplam Paket Tutarı"
                unitAfter=""
                icon={"money"}
                position="down"
                start={0}
                end={paketYoldaGeneralReportData.TotalPrice}
              />
            </Grid>
            <Grid item md={3}>
              <CounterTrading
                color={"#7f8c8d"}
                unitBefore=""
                title="Ortalama Paket Mesafesi"
                unitAfter="Km"
                icon={"location_on"}
                position="down"
                start={0}
                end={paketYoldaGeneralReportData.AvgPacketDistance}
              />
            </Grid>
            <Grid item md={3}>
              <CounterTrading
                color={"#7f8c8d"}
                unitBefore=""
                title="Toplam Paket Mesafesi"
                unitAfter="Km"
                icon={"edit_location_alt"}
                position="down"
                start={0}
                end={paketYoldaGeneralReportData.SumPacketDistance}
              />
            </Grid>
            <Grid item md={3}>
              <CounterTrading
                color={"#16a085"}
                unitBefore=""
                title="Kurye Km Hakediş"
                unitAfter="₺"
                icon={"motorcycle"}
                position="down"
                start={0}
                end={paketYoldaGeneralReportData.P_MileageChargeCourierSum}
              />
            </Grid>
            <Grid item md={3}>
              <CounterTrading
                color={"#16a085"}
                unitBefore=""
                title="İşletme Km Maliyet"
                unitAfter="₺"
                icon={"storefront"}
                position="down"
                start={0}
                end={paketYoldaGeneralReportData.P_MileageChargeRestSum || 0}
              />
            </Grid>
          </Grid>
          <div
            style={{
              backgroundColor: "#313131",
              marginTop: 10,
              marginBottom: 10,
              width: "100%",
              padding: 10,
              borderBottom: "1px solid rgba(209, 216, 224,.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {"Ödeme Yönetime Göre Toplamlar"}
          </div>
          <Grid container style={{ flexDirection: "row", zIndex: 6 }}>
            <Grid item md={6} style={{ textAlign: "left", paddingBottom: 5 }}>
              <span
                style={{
                  marginLeft: 7,
                  fontSize: 13,
                  fontWeight: 500,
                  color: "white",
                  marginRight: 5,
                }}
              >
                {"Ödeme Yöntemi"}
              </span>
            </Grid>
            <Grid item md={6} style={{ textAlign: "right" }}>
              <span
                style={{
                  marginRight: 7,
                  fontSize: 13,
                  fontWeight: 500,
                  color: "white",
                  textTransform: "capitalize",
                }}
              >
                {"Tutar"}
              </span>
            </Grid>
          </Grid>
          {this.renderPayment()}
          <div
            style={{
              backgroundColor: "#313131",
              marginTop: 10,
              marginBottom: 10,
              width: "100%",
              padding: 10,
              borderBottom: "1px solid rgba(209, 216, 224,.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {"Kurye Listesi"}
          </div>
          <Table style={{ marginTop: 5, overflow: "hidden" }}>
            <TableHead style={{ backgroundColor: "#212121" }}>
              <TableRow>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="left"
                >
                  {"Kurye Adı"}
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="center"
                >
                  {"Paket Sayısı"}
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="center"
                >
                  {"Top. Paket Tutarı"}
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="center"
                >
                  {"Ort. Paket Tutarı"}
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="center"
                >
                  {"Top. Paket Mesafesi"}
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="center"
                >
                  {"Ort. Paket Mesafesi"}
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="center"
                >
                  {"Ort. Teslim Süresi"}
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="center"
                >
                  {"Kurye Km Hakediş"}
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="center"
                >
                  {"İşletme Km Maliyet"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paketYoldaGeneralReportData.CourierData.map((n, i) => {
                let courierData = n;

                const totalCourierCount =
                  paketYoldaGeneralReportData.CourierData.length;
                const distributedMileageCharge =
                  totalCourierCount > 0
                    ? (paketYoldaGeneralReportData.P_MileageChargeRestSum ||
                        0) / totalCourierCount
                    : 0;

                return (
                  <TableRow hover key={i}>
                    <TableCell align="left" style={{ padding: 5 }}>
                      {courierData.CourierName}
                    </TableCell>
                    <TableCell align="center" style={{ padding: 5 }}>
                      {courierData.OrderCount?.toFixed(0)}
                    </TableCell>
                    <TableCell align="center" style={{ padding: 5 }}>
                      {trMoneyConvert(courierData.TotalPackageAmount, 3)} {"₺"}
                    </TableCell>
                    <TableCell align="center" style={{ padding: 5 }}>
                      {trMoneyConvert(courierData.AvgPackageTotal, 3)} {"₺"}
                    </TableCell>
                    <TableCell align="center" style={{ padding: 5 }}>
                      {courierData.SumPacketDistance?.toFixed(3)} {"Km"}
                    </TableCell>
                    <TableCell align="center" style={{ padding: 5 }}>
                      {courierData.AvgPacketDistance?.toFixed(3)} {"Km"}
                    </TableCell>
                    <TableCell align="center" style={{ padding: 5 }}>
                      {courierData.AvgPackageDelivery?.toFixed(2)} {"Dk"}
                    </TableCell>
                    <TableCell align="center" style={{ padding: 5 }}>
                      {trMoneyConvert(
                        courierData.P_MileageChargeCourierSum || 0,
                        2
                      )}{" "}
                      {"₺"}
                    </TableCell>
                    <TableCell align="center" style={{ padding: 5 }}>
                      {trMoneyConvert(
                        courierData.P_MileageChargeRestSum ||
                          courierData.MileageChargeRest ||
                          distributedMileageCharge ||
                          0,
                        2
                      )}{" "}
                      {"₺"}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <div
            style={{
              backgroundColor: "#313131",
              marginTop: 10,
              marginBottom: 10,
              width: "100%",
              padding: 10,
              borderBottom: "1px solid rgba(209, 216, 224,.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {"Saatlik Sipariş Grafiği"}
          </div>
          <ResponsiveContainer>
            <ComposedChart
              data={this.renderDataHour()}
              margin={{
                top: 15,
                right: 15,
                left: -20,
              }}
            >
              <defs>
                <linearGradient id="colorUp" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={"#fa8231"} stopOpacity={1} />
                  <stop offset="95%" stopColor={"#fa8231"} stopOpacity={1} />
                </linearGradient>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#FFC107" stopOpacity={1} />
                  <stop offset="95%" stopColor="#FFC107" stopOpacity={1} />
                </linearGradient>
              </defs>
              <XAxis
                interval={0}
                angle={-45}
                textAnchor="end"
                dataKey="Hour"
                tickLine={false}
                label={{ size: 12 }}
              />
              <YAxis
                axisLine={false}
                tickSize={3}
                tickLine={false}
                tick={{ stroke: "none" }}
              />
              <CartesianGrid vertical={false} strokeDasharray="3 3" />
              <CartesianAxis vertical={false} />
              <Tooltip content={<CustomTooltip />} />
              <Legend
                verticalAlign={"bottom"}
                formatter={this.renderColorfulLegendText}
              />
              <Bar
                stackId="a"
                name="Toplam Adisyon Sayısı"
                dataKey="TotalCount"
                fillOpacity="0.8"
                fill="url(#colorUv)"
              />
              <Bar
                stackId="a"
                name="Toplam Adisyon Tutarı"
                dataKey="TotalPrice"
                fillOpacity="1"
                fill="url(#colorUp)"
              />
            </ComposedChart>
          </ResponsiveContainer>
          <div
            style={{
              backgroundColor: "#313131",
              marginTop: 10,
              marginBottom: 10,
              width: "100%",
              padding: 10,
              borderBottom: "1px solid rgba(209, 216, 224,.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {"Grup Sayıları"}
          </div>
          <div style={{ display: "flex" }}>
            {generalReportOrderListGroup.map((n, i) => {
              return (
                <div
                  style={{
                    marginLeft: 5,
                    display: "grid",
                    padding: 5,
                    textAlign: "center",
                    border: "1px solid #E0E0E0",
                  }}
                >
                  <span>
                    {n.Order}
                    {" grup"}
                  </span>
                  <span>
                    {n.Group}
                    {" adet"}
                  </span>
                </div>
              );
            })}
          </div>
          <div
            style={{
              backgroundColor: "#313131",
              marginTop: 10,
              marginBottom: 10,
              width: "100%",
              padding: 10,
              borderBottom: "1px solid rgba(209, 216, 224,.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {"Adisyon Listesi"}
          </div>
          <Table style={{ marginTop: 5, overflow: "hidden" }}>
            <TableHead style={{ backgroundColor: "#212121" }}>
              <TableRow>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="left"
                >
                  {"Tarih"}
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="left"
                >
                  {"No"}
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="left"
                >
                  {"Rest. Adı"}
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="left"
                >
                  {"Müş. Adı"}
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="left"
                >
                  {"Tutar"}
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="left"
                >
                  {"Kurye"}
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="left"
                >
                  {"Km"}
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="left"
                >
                  {"Rest Maliyet"}
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="left"
                >
                  {"Ekstra Tutar"}
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="left"
                >
                  {"Kurye Hakediş"}
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="left"
                >
                  {"Durumu"}
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="right"
                >
                  {"İşlem"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {generalReportOrderList != null
                ? generalReportOrderList.Results.map((n, i) => {
                    return (
                      <TableRow hover key={i}>
                        <TableCell align="left" style={{ padding: 5 }}>
                          {moment(n.CreationTime).format("DD.MM.YYYY HH:mm:ss")}
                        </TableCell>
                        <TableCell align="left" style={{ padding: 5 }}>
                          {n.AdditionId}
                          {"-"}
                          {n.AdditionOrder}
                        </TableCell>
                        <TableCell align="left" style={{ padding: 5 }}>
                          {n.RestName}
                        </TableCell>
                        <TableCell align="left" style={{ padding: 5 }}>
                          {n.CustomerName}
                        </TableCell>
                        <TableCell align="left" style={{ padding: 5 }}>
                          {trMoneyConvert(n.TotalPrice, 2)} {"₺"}
                        </TableCell>
                        <TableCell align="left" style={{ padding: 5 }}>
                          {n.CourierName}
                        </TableCell>
                        <TableCell align="left" style={{ padding: 5 }}>
                          {n.PacketDistance.toFixed(3)} {"Km"}
                        </TableCell>
                        <TableCell align="center" style={{ padding: 5 }}>
                          {trMoneyConvert(
                            n.MileageChargeRest ||
                              n.P_MileageChargeRestSum ||
                              0,
                            2
                          )}{" "}
                          {"₺"}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            padding: 5,
                            color:
                              n.ExtraAmount > 0 ? "rgba(241, 196, 15,1.0)" : "",
                          }}
                        >
                          {trMoneyConvert(n.ExtraAmount, 2)} {"₺"}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ padding: 5, justifyContent: "center" }}
                        >
                          {trMoneyConvert(n.MileageChargeCourier, 2)} {"₺"}
                        </TableCell>
                        <TableCell align="left" style={{ padding: 5 }}>
                          {n.OrderStatus == 0
                            ? "Beklemede"
                            : n.OrderStatus == 1
                              ? "Kurye Atandı"
                              : n.OrderStatus == 2
                                ? "Teslim Edildi"
                                : n.OrderStatus == 3
                                  ? "İptal Edildi"
                                  : n.OrderStatus == 4
                                    ? "Kurye Kabul Edildi"
                                    : n.OrderStatus == 5
                                      ? "Tamamlandı"
                                      : ""}
                        </TableCell>
                        <TableCell align="right" style={{ padding: 5 }}>
                          <TooltipMui title={"Adisyonu Geri Al"}>
                            <IconButton
                              style={{
                                padding: 4,
                                backgroundColor: "#3e3e3e",
                                marginRight: 5,
                              }}
                              aria-label="Adisyonu Geri Al"
                              onClick={() =>
                                confirm(
                                  "Adisyonu geri almak istediğinize emin misiniz?"
                                )
                                  ? this.props
                                      .setOrderUnLoad(n.Id)
                                      .then(() => {})
                                  : null
                              }
                            >
                              <Icon>{"redo"}</Icon>
                            </IconButton>
                          </TooltipMui>
                          <TooltipMui title={"Adisyon Detayı Görüntüle"}>
                            <IconButton
                              style={{
                                padding: 4,
                                backgroundColor: "#3e3e3e",
                                marginRight: 5,
                              }}
                              aria-label="Adisyon Detayı Görüntüle"
                              id={n.OrderId}
                              onClick={this.handleClickPopover}
                            >
                              <Icon>{"receipt_long"}</Icon>
                            </IconButton>
                          </TooltipMui>
                          <TooltipMui title={"Ekstra Tutar Ekle"}>
                            <IconButton
                              style={{
                                padding: 4,
                                backgroundColor: "#3e3e3e",
                                marginRight: 5,
                              }}
                              aria-label="Ekstra Tutar Ekle"
                              onClick={() =>
                                this.props
                                  .setExtraAmountVisible(true, n.Id)
                                  .then(() => {})
                              }
                            >
                              <Icon>{"add_card"}</Icon>
                            </IconButton>
                          </TooltipMui>
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
          {generalReportOrderList != null ? (
            <div style={{ width: "100%", display: "table" }}>
              <TablePagination
                rowsPerPageOptions={[25]}
                rowsPerPage={generalReportOrderList.PageSize}
                count={generalReportOrderList.RowCount}
                page={generalReportOrderList.CurrentPage - 1}
                onChangePage={this.handleChangePage}
                ActionsComponent={TbPaginationActions}
              />
            </div>
          ) : null}
        </div>
      </Paper>
    );
  }
}

const valueSelector = (state, props) =>
  state.get("paketYoldaReducer").paketYoldaGeneralReportUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
  paketYoldaGeneralReportUpdate: value(state, props),
  paketYoldaGeneralReportData: state.get("paketYoldaReducer")
    .paketYoldaGeneralReportData,
  paketYoldaReportMenu: state.get("paketYoldaReducer").paketYoldaReportMenu,
  paketYoldaNewReportLoading: state.get("paketYoldaReducer")
    .paketYoldaNewReportLoading,
  paketYoldaGeneralReportfilter: state.get("paketYoldaReducer")
    .paketYoldaGeneralReportfilter,

  paketYoldaBusinessList: state.get("paketYoldaReducer").paketYoldaBusinessList,
  paketYoldaCourierList: state.get("paketYoldaReducer").paketYoldaCourierList,
  generalReportOrderList: state.get("paketYoldaReducer").generalReportOrderList,
  generalReportOrderListGroup: state.get("paketYoldaReducer")
    .generalReportOrderListGroup,
  extraAmountVisible: state.get("paketYoldaReducer").extraAmountVisible,
  extraAmount: state.get("paketYoldaReducer").extraAmount,
});

const mapDispatchToProps = {
  setPaketYoldaGeneralReportFilterData,
  getPaketYoldaBrandList,
  getPaketYoldaCourierList,
  getPaketYoldaBusinessList,
  getNewCourierList,
  getOrderGeneralReport,
  getPackageAdditionDetail,
  getOrderGeneralReportList,
  setOrderUnLoad,
  setExtraAmount,
  setExtraAmountVisible,
  setExtraAmountData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(GeneralReport)));
