import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import defaultBanner from "dan-images/QrMenu/banner-default.png";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    marginBottom: theme.spacing(6),
  },
  heroBanner: {
    position: "relative",
    height: "230px",
    marginTop: 30,
    overflow: "hidden",
    borderRadius: 13,
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: 13,
    },
  },
  slideContainer: {
    display: "flex",
    transition: "transform 0.5s ease-in-out",
    height: "100%",
  },
  slide: {
    minWidth: "100%",
    position: "relative",
  },
  dotsContainer: {
    position: "absolute",
    bottom: theme.spacing(-4),
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    zIndex: 1,
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: "50%",
    backgroundColor: "#ccc",
    cursor: "pointer",
    border: "none",
    padding: 0,
    transition: "background-color 0.3s ease",
    "&.active": {
      backgroundColor: "#ff0000",
    },
  },
}));

const HeroBanner = ({ menudata }) => {
  const classes = useStyles();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [startX, setStartX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [dragX, setDragX] = useState(0);

  const banners =
    menudata?.bannerList?.length > 0
      ? menudata.bannerList
      : [{ ImageUrl: defaultBanner }];

  useEffect(
    () => {
      const timer = setInterval(() => {
        if (!isDragging) {
          setCurrentSlide((prev) => (prev + 1) % banners.length);
        }
      }, 5000);
      return () => clearInterval(timer);
    },
    [isDragging, banners.length]
  );

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
    setIsDragging(true);
    setDragX(0);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const currentX = e.touches[0].clientX;
    const diff = currentX - startX;
    setDragX(diff);
  };

  const handleTouchEnd = () => {
    if (!isDragging) return;

    const threshold = window.innerWidth * 0.2;

    if (Math.abs(dragX) > threshold) {
      if (dragX > 0 && currentSlide > 0) {
        setCurrentSlide(currentSlide - 1);
      } else if (dragX < 0 && currentSlide < banners.length - 1) {
        setCurrentSlide(currentSlide + 1);
      }
    }

    setIsDragging(false);
    setDragX(0);
  };

  const getTransform = () => {
    const baseTransform = -currentSlide * 100;
    const dragPercent = (dragX / window.innerWidth) * 100;
    return `translateX(${baseTransform + dragPercent}%)`;
  };

  return (
    <div className={classes.root}>
      <div className={classes.heroBanner}>
        <div
          className={classes.slideContainer}
          style={{
            transform: getTransform(),
            transition: isDragging ? "none" : "transform 0.5s ease-in-out",
          }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {banners.map((image, index) => (
            <div key={index} className={classes.slide}>
              <img src={image.ImageUrl} alt={`Banner ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
      <div className={classes.dotsContainer}>
        {banners.map((_, index) => (
          <button
            key={index}
            className={`${classes.dot} ${
              currentSlide === index ? "active" : ""
            }`}
            onClick={() => setCurrentSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default HeroBanner;
