import React from 'react';
import {
    createSelector
} from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import logo from 'dan-images/logo.png';
import colorfull from 'dan-api/palette/colorfull';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';

import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import DatePicker, { registerLocale } from 'react-datepicker';
import '../../SettingPage/datepicker.css';
import tr from 'date-fns/locale/tr';
import 'leaflet/dist/leaflet.css';
import cookie from 'react-cookies';
import Select from '@material-ui/core/Select';
import MapClusterReport from '../../SettingPage/ReportPage/Maps/MapClusterReport';
import {
    getProductGraffic, setFranchiseReportProductSalesListFilter, setFranchiseReportBrandSelected
} from '../../../../redux/modules/centerbrand';
import { getParameter } from '../../../../function/GeneralFunction';
registerLocale('tr', tr);
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
    nested: {
        paddingLeft: theme.spacing(4),
    }
});

class ProductSalesList extends React.Component {
    constructor() {
        super();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.franchiseReportProductListUpdate == this.props.franchiseReportProductListUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {

    }

    handleChangeFilterProductSalesList = name => event => {
        const { filterReportProductList } = this.props;
        filterReportProductList[name] = (name == "StartDatetime" || name == "EndDatetime" ? moment.utc(event, 'MM-DD-YYYY').local().format("YYYY-MM-DD") : event);
        this.props.setFranchiseReportProductSalesListFilter(filterReportProductList).then(() => { });
    };

    handleChange = async (event) => {
        this.props.setFranchiseReportBrandSelected(event.target.value).then(() => { });
    };

    render() {
        const {
            franchiseReportName, franchiseReportProductListData, filterReportProductList, isCenterReportLoading, classes,
            franchiseReportBrandSelected, centerBrandData
        } = this.props;

        if (franchiseReportName != 'ProductSalesReport') {
            return null;
        }

        return (
            <Paper className={classes.root} elevation={0} style={{ height: '100%', borderRadius: 0, overflowX: 'hidden', overflowY: 'auto' }}>
                <div style={{
                    backgroundColor: '#424242', padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'
                }}
                >
                    {"Genel ve Şube Bazında Ürün Satış Raporu"}
                </div>
                <Grid container spacing={1} style={{ padding: 10 }}>
                    <Grid item md={3}>
                        <Typography
                            style={{
                                marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1, textAlign: 'left'
                            }}
                            className={classes.flex}
                        >
                            {"Başlangıç Tarihi"}
                        </Typography>
                        <DatePicker
                            selected={new Date(filterReportProductList.StartDatetime)}
                            onChange={this.handleChangeFilterProductSalesList('StartDatetime')}
                            dateFormat="dd.MM.yyyy"
                            locale="tr"
                            className="inputDate"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Typography
                            style={{
                                marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1, textAlign: 'left'
                            }}
                            className={classes.flex}
                        >
                            {"Bitiş Tarihi"}
                        </Typography>
                        <DatePicker
                            selected={new Date(filterReportProductList.EndDatetime)}
                            onChange={this.handleChangeFilterProductSalesList('EndDatetime')}
                            dateFormat="dd.MM.yyyy"
                            locale="tr"
                            className="inputDate"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Typography
                            style={{
                                marginBottom: 6, padding: 0, fontSize: 12, fontFamily: 'Quicksand,sans-serif', lineHeight: 1, textAlign: 'left'
                            }}
                            className={classes.flex}
                        >
                            {"Şube Seçimi"}
                        </Typography>
                        <Select
                            fullWidth
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            style={{ height: 38 }}
                            value={franchiseReportBrandSelected}
                            onChange={this.handleChange}
                        >
                            <MenuItem value={"00000000-0000-0000-0000-000000000000"}>
                                <ListItemText primary={"Tüm Şubeler"} />
                            </MenuItem>
                            {centerBrandData.map((n, i) => (
                                <MenuItem lat={n.Latitude} lng={n.Longitude} key={i} value={n.Id}>
                                    <ListItemText primary={n.Name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item md={3}>
                        <Button fullWidth disabled={isCenterReportLoading} onClick={() => this.props.getProductGraffic().then(() => { })} style={{ marginTop: 18, borderRadius: 5, height: 37, padding: '6px 24px' }} size="large" variant="contained" color="secondary" type="submit">
                            {'FİLTRELE'}
                        </Button>
                    </Grid>
                </Grid>
                {(isCenterReportLoading ? <div style={{ width: '100%', textAlign: 'center', marginBottom: 15 }}><CircularProgress disableShrink thickness={4} size={40} /></div> : null)}
                <div style={{
                    padding: 10, width: '100%', height: '100%'
                }}
                >
                    <Table className={classNames(classes.table, classes.bordered)} style={{ marginTop: 10 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell align='left'>{"Ürün Adı"}</TableCell>
                                <TableCell align='center'>{"Miktarı"}</TableCell>
                                <TableCell align='center'>{"Opsiyon Miktarı"}</TableCell>
                                <TableCell align='center'>{"Toplam Miktar"}</TableCell>
                                <TableCell align='right'>{"Tutarı"}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                franchiseReportProductListData.map((n, i) => (
                                    <TableRow hover key={i}>
                                        <TableCell align='left'>{n.StockName}</TableCell>
                                        <TableCell align='center'>{n.TotalCount}</TableCell>
                                        <TableCell align='center'>{n.SubTotalCount}</TableCell>
                                        <TableCell align='center'>{(n.SubTotalCount + n.TotalCount).toFixed(0)}</TableCell>
                                        <TableCell align='right'>{n.TotalAmount.toFixed(2)}</TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </div>
            </Paper>
        );
    }
}

const valueSelector = (state, props) => state.get('centerBrandReducer').franchiseReportProductListUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    franchiseReportProductListUpdate: value(state, props),
    franchiseReportName: state.get('centerBrandReducer').franchiseReportName,
    isCenterReportLoading: state.get('centerBrandReducer').isCenterReportLoading,
    filterReportProductList: state.get('centerBrandReducer').filterReportProductList,
    franchiseReportProductListData: state.get('centerBrandReducer').franchiseReportProductListData,
    centerBrandData: state.get('centerBrandReducer').centerBrandData,
    franchiseReportBrandSelected: state.get('centerBrandReducer').franchiseReportBrandSelected
});

const mapDispatchToProps = {
    getProductGraffic,
    setFranchiseReportProductSalesListFilter,
    setFranchiseReportBrandSelected
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(ProductSalesList)));
