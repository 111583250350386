import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { trMoneyConvert } from "../../../../../../function/GeneralFunction";
import javiKuryeRed from "dan-images/javikurye.png";

//  PDF Başlık ve Üstbilgi
const generateHeader = (doc, title, totalRecords) => {
  doc.setFont("helvetica");

  doc.addImage(javiKuryeRed, "PNG", 14, 10, 60, 15);

  doc.setDrawColor(0);
  doc.setLineWidth(0.5);
  doc.line(14, 30, 280, 30);

  doc.setFontSize(16);
  doc.setFont("helvetica", "bold");
  doc.text(title, doc.internal.pageSize.width / 2, 20, { align: "center" });

  doc.setFontSize(10);
  doc.setFont("helvetica", "normal");
  const today = new Date().toLocaleDateString("tr-TR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  // Üstbilgi Kutusu
  doc.rect(14, 35, 266, 15);
  doc.line(93, 35, 93, 50);
  doc.line(172, 35, 172, 50);

  doc.setFontSize(8);
  doc.text("RAPOR TARİHİ:", 16, 42);
  doc.text("TOPLAM KAYIT:", 95, 42);
  doc.text("SAYFA:", 174, 42);

  doc.setFont("helvetica", "bold");
  doc.text(today, 16, 47);
  doc.text(totalRecords.toString() || "0", 95, 47);
  doc.text("1", 174, 47);
};

const turkishCharMap = {
  ı: "i",
  İ: "I",
  ğ: "g",
  Ğ: "G",
  ü: "u",
  Ü: "U",
  ş: "s",
  Ş: "S",
  ö: "o",
  Ö: "O",
  ç: "c",
  Ç: "C",
};

const convertTurkishChars = (text) => {
  return text.replace(
    /[ıİğĞüÜşŞöÖçÇ]/g,
    (match) => turkishCharMap[match] || match
  );
};

//  Tablo Oluşturma
const generateTable = (doc, columns, rows, startY = 55) => {
  doc.autoTable({
    columns,
    body: rows,
    startY,
    margin: { left: 14, right: 14 },
    theme: "grid",
    styles: {
      font: "helvetica",
      fontSize: 8,
      cellPadding: 3,
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
    },
    headStyles: {
      fillColor: [220, 220, 220],
      textColor: [0, 0, 0],
      fontStyle: "bold",
      halign: "center",
    },
    didParseCell: function(data) {
      // Hücre içeriğini Türkçe karakter dönüşümü ile güncelle
      if (typeof data.cell.text === "string") {
        data.cell.text = convertTurkishChars(data.cell.text);
      } else if (Array.isArray(data.cell.text)) {
        data.cell.text = data.cell.text.map(
          (text) =>
            typeof text === "string" ? convertTurkishChars(text) : text
        );
      }
    },
  });

  return doc.lastAutoTable.finalY;
};

//  Altbilgi
const generateFooter = (doc, finalY) => {
  doc.setFontSize(8);
  doc.setFont("helvetica", "italic");
  const footerText = convertTurkishChars(
    "Bu rapor JaviKurye sistemi tarafından otomatik olarak oluşturulmuştur."
  );
  doc.text(footerText, 14, finalY + 10);
};

//  Kurye Raporu PDF
export const createPdfTemplate = (data) => {
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: "a4",
    putOnlyUsedFonts: true,
    floatPrecision: 16,
  });

  generateHeader(
    doc,
    convertTurkishChars("HESAP RAPORU"),
    data?.courierData?.length || 0
  );

  const columns = [
    { header: convertTurkishChars("KURYE ADI"), dataKey: "courierName" },
    { header: convertTurkishChars("PAKET SAYISI"), dataKey: "orderCount" },
    { header: convertTurkishChars("TOPLAM TUTAR"), dataKey: "totalAmount" },
    { header: convertTurkishChars("ORT. TUTAR"), dataKey: "avgAmount" },
    { header: convertTurkishChars("TOP. MESAFE"), dataKey: "totalDistance" },
    { header: convertTurkishChars("ORT. MESAFE"), dataKey: "avgDistance" },
    { header: convertTurkishChars("ORT. SÜRE"), dataKey: "avgTime" },
    { header: convertTurkishChars("KM HAKEDİŞ"), dataKey: "mileageCharge" },
    {
      header: convertTurkishChars("İŞLETME KM MALİYETİ"),
      dataKey: "mileageChargeRest",
    },
  ];

  // Toplamları hesapla
  const totals = data.courierData.reduce(
    (acc, courier) => ({
      orderCount: acc.orderCount + courier.OrderCount,
      amount: acc.amount + courier.TotalPackageAmount,
      mileageCharge: acc.mileageCharge + courier.P_MileageChargeCourierSum,
      totalDistance: acc.totalDistance + courier.SumPacketDistance,
    }),
    { orderCount: 0, amount: 0, mileageCharge: 0, totalDistance: 0 }
  );

  // Her kurye için satır oluştur
  const rows = data.courierData.map((courier) => ({
    courierName: convertTurkishChars(courier.CourierName || ""),
    orderCount: courier.OrderCount.toFixed(0),
    totalAmount: `${trMoneyConvert(courier.TotalPackageAmount, 3)} TL`,
    avgAmount: `${trMoneyConvert(courier.AvgPackageTotal, 3)} TL`,
    totalDistance: `${courier.SumPacketDistance.toFixed(3)} Km`,
    avgDistance: `${courier.AvgPacketDistance.toFixed(3)} Km`,
    avgTime: `${courier.AvgPackageDelivery.toFixed(2)} Dk`,
    mileageCharge: `${trMoneyConvert(courier.P_MileageChargeCourierSum, 2)} TL`,
    mileageChargeRest: `${trMoneyConvert(courier.mileageCost, 2)} TL`,
  }));

  // Toplam satırı
  rows.push({
    courierName: convertTurkishChars("Toplam"),
    orderCount: totals.orderCount.toFixed(0),
    totalAmount: `${trMoneyConvert(totals.amount, 3)} TL`,
    avgAmount: "",
    totalDistance: `${totals.totalDistance.toFixed(3)} Km`,
    avgDistance: "",
    avgTime: "",
    mileageCharge: `${trMoneyConvert(totals.mileageCharge, 2)} TL`,
    mileageChargeRest: `${trMoneyConvert(data.P_MileageChargeRestSum, 2)} TL`,
  });

  const finalY = generateTable(doc, columns, rows);
  generateFooter(doc, finalY);

  return doc;
};

//  Log Raporu PDF
export const createLogReport = (data) => {
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: "a4",
    putOnlyUsedFonts: true,
    floatPrecision: 16,
  });

  generateHeader(
    doc,
    convertTurkishChars("KURYE AKTİVİTE RAPORU"),
    data?.Results?.length || 0
  );

  const columns = [
    { header: convertTurkishChars("BAŞLANGIÇ TARİHİ"), dataKey: "startDate" },
    { header: convertTurkishChars("BİTİŞ TARİHİ"), dataKey: "endDate" },
    { header: convertTurkishChars("SÜRE"), dataKey: "duration" },
    { header: convertTurkishChars("KURYE ADI"), dataKey: "user" },
    { header: convertTurkishChars("AKTİVİTE DURUMU"), dataKey: "operation" },
  ];

  const calculateDuration = (startDate, endDate) => {
    if (!startDate || !endDate) return "-";

    const start = moment(startDate);
    const end = moment(endDate);
    const duration = moment.duration(end.diff(start));

    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${hours}s ${minutes}dk ${seconds}sn`;
  };

  const rows = (data?.Results || []).map((item) => ({
    startDate: moment(item.StartDate).format("DD.MM.YYYY HH:mm:ss"),
    endDate: item.EndDate
      ? moment(item.EndDate).format("DD.MM.YYYY HH:mm:ss")
      : "-",
    duration: calculateDuration(item.StartDate, item.EndDate),
    user: convertTurkishChars(item.Username || ""),
    operation: convertTurkishChars(item.Operation || ""),
  }));

  const finalY = generateTable(doc, columns, rows);
  generateFooter(doc, finalY);

  return doc;
};

// Kurye Çalışma Raporu PDF
export const createCourierActivityPdfReport = (data) => {
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: "a4",
    putOnlyUsedFonts: true,
    floatPrecision: 16,
  });

  generateHeader(
    doc,
    convertTurkishChars("KURYE ÇALIŞMA RAPORU"),
    data?.Results?.length || 0
  );

  const columns = [
    { header: convertTurkishChars("KURYE ADI"), dataKey: "CourierName" },
    { header: convertTurkishChars("ÇALIŞILAN SÜRE"), dataKey: "WorkingTime" },
    { header: convertTurkishChars("MOLA SÜRESİ"), dataKey: "BreakTime" },
  ];

  const rows = (data?.Results || []).map((item) => ({
    CourierName: convertTurkishChars(item.CourierName || ""),
    WorkingTime: item.WorkingTime || "0s 0dk 0sn",
    BreakTime: item.BreakTime || "0s 0dk 0sn",
  }));

  const finalY = generateTable(doc, columns, rows);
  generateFooter(doc, finalY);

  return doc;
};
