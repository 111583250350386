import {
  createPdfTemplate,
  createLogReport,
  createCourierActivityPdfReport,
} from "./pdfExport/pdfTemplate";
import {
  createExcelTemplate,
  createLogExcelReport,
  createExcelWorkTemplate,
} from "./excelExport/excelTemplate";
import XLSX from "xlsx";

// Kurye raporu için veri formatlama
const formatReportData = (rawData) => {
  // Veriyi hazırla
  const mileageCost = Number(rawData.P_MileageChargeRestSum) || 0;
  const courierCount = rawData.CourierData?.length || 1;
  const perCourierCost = mileageCost / courierCount;

  return {
    ...rawData,
    P_MileageChargeRestSum: mileageCost,
    courierData: (rawData.CourierData || []).map((courier) => ({
      ...courier,
      OrderCount: Number(courier.OrderCount) || 0,
      TotalPackageAmount: Number(courier.TotalPackageAmount) || 0,
      AvgPackageTotal: Number(courier.AvgPackageTotal) || 0,
      SumPacketDistance: Number(courier.SumPacketDistance) || 0,
      AvgPacketDistance: Number(courier.AvgPacketDistance) || 0,
      AvgPackageDelivery: Number(courier.AvgPackageDelivery) || 0,
      P_MileageChargeCourierSum: Number(courier.P_MileageChargeCourierSum) || 0,
      mileageCost: perCourierCost, // Her kurye için maliyet değeri
    })),
  };
};

export const formatExcelReportData = (rawData) => {
  // Km başına maliyet değerini doğru şekilde al
  const businessKmPrice =
    Number(rawData.businessSettings?.businessKmPrice) || 0;

  console.log("Debug - Format Data:", {
    originalPrice: rawData.businessSettings?.businessKmPrice,
    formattedPrice: businessKmPrice,
  });

  return {
    ...rawData,
    P_MileageChargeRestSum: Number(rawData.P_MileageChargeRestSum) || 0,
    businessSettings: {
      ...rawData.businessSettings,
      businessKmPrice: businessKmPrice,
    },
    courierData: (rawData.CourierData || []).map((courier) => {
      const distance = Number(courier.SumPacketDistance) || 0;
      const businessKmCost = distance * businessKmPrice;

      return {
        ...courier,
        CourierName: courier.CourierName || "Bilinmeyen Kurye",
        OrderCount: Number(courier.OrderCount) || 0,
        TotalPackageAmount: Number(courier.TotalPackageAmount) || 0,
        AvgPackageTotal: Number(courier.AvgPackageTotal) || 0,
        SumPacketDistance: distance,
        AvgPacketDistance: Number(courier.AvgPacketDistance) || 0,
        AvgPackageDelivery: Number(courier.AvgPackageDelivery) || 0,
        P_MileageChargeCourierSum:
          Number(courier.P_MileageChargeCourierSum) || 0,
        mileageCost: businessKmCost,
      };
    }),
  };
};

// Log raporu için veri formatlama
export const formatLogData = (data) => {
  return {
    Results: data.Results,
    RowCount: data.RowCount,
    PageSize: data.PageSize,
    CurrentPage: data.CurrentPage,
  };
};

// Kurye çalışma verilerini formatlama
export const formatCourierWorkData = (reportData) => {
  if (!reportData?.CourierData) {
    return {
      Results: [],
    };
  }

  // Kuryelere göre süreleri hesapla
  const courierStats = {};
  reportData.CourierData.forEach((activity) => {
    if (!courierStats[activity.CourierId]) {
      courierStats[activity.CourierId] = {
        name: activity.CourierName || "Bilinmeyen Kurye",
        workingTime: 0,
        breakTime: 0,
      };
    }

    const stats = courierStats[activity.CourierId];
    const startTime = new Date(activity.StartTime).getTime();
    const endTime = activity.EndTime
      ? new Date(activity.EndTime).getTime()
      : new Date().getTime();
    const duration = endTime - startTime;

    if (activity.ActivityType === 4) {
      // Mola süresi
      stats.breakTime += Math.max(0, duration);
    } else if (activity.ActivityType === 3) {
      // Çalışma süresi
      stats.workingTime += Math.max(0, duration);
    }
  });

  // Süreleri formatla
  const formatDuration = (ms) => {
    if (!ms || ms < 0) return "0s 0dk 0sn";
    const totalSeconds = Math.floor(ms / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}s ${minutes}dk ${seconds}sn`;
  };

  // Sonuçları hazırla
  return {
    Results: Object.entries(courierStats).map(([courierId, stats]) => ({
      CourierName: stats.name,
      WorkingTime: formatDuration(stats.workingTime),
      BreakTime: formatDuration(stats.breakTime),
      RawWorkingTime: stats.workingTime,
      RawBreakTime: stats.breakTime,
    })),
  };
};

// Hesap Raporları PDF raporu oluşturma
export const generatePdfReport = (reportData) => {
  const formattedData = formatReportData(reportData);
  const doc = createPdfTemplate(formattedData);
  const fileName = `Hesap_Raporu_${new Date()
    .toLocaleDateString("tr-TR")
    .replace(/\./g, "-")}.pdf`;
  doc.save(fileName);
};

// Hesap Raproları raporu oluşturma
export const generateExcelReport = (reportData) => {
  // Veriyi hazırla
  const formattedData = {
    ...reportData,
    businessSettings: {
      ...reportData.businessSettings,

      businessKmPrice:
        Number(reportData.businessSettings?.businessKmPrice) || 0,
    },
    courierData: (reportData.CourierData || []).map((courier) => ({
      ...courier,

      OrderCount: Number(courier.OrderCount) || 0,
      TotalPackageAmount: Number(courier.TotalPackageAmount) || 0,
      AvgPackageTotal: Number(courier.AvgPackageTotal) || 0,
      SumPacketDistance: Number(courier.SumPacketDistance) || 0,
      AvgPacketDistance: Number(courier.AvgPacketDistance) || 0,
      AvgPackageDelivery: Number(courier.AvgPackageDelivery) || 0,
      P_MileageChargeCourierSum: Number(courier.P_MileageChargeCourierSum) || 0,
    })),
  };

  console.log("Debug - Excel Report Data:", {
    businessKmPrice: formattedData.businessSettings.businessKmPrice,
    sampleCourier: formattedData.courierData[0],
  });

  const workbook = createExcelWorkTemplate(formattedData);
  const fileName = `Hesap_Raporu_${new Date()
    .toLocaleDateString("tr-TR")
    .replace(/\./g, "-")}.xlsx`;
  XLSX.writeFile(workbook, fileName);
};

// Log PDF raporu oluşturma
export const generateLogPdfReport = (logData) => {
  const formattedData = formatLogData(logData);
  const doc = createLogReport(formattedData);
  const fileName = `Log_Raporu_${new Date()
    .toLocaleDateString("tr-TR")
    .replace(/\./g, "-")}.pdf`;
  doc.save(fileName);
};

// Log Excel raporu oluşturma
export const generateLogExcelReport = (logData) => {
  const formattedData = formatLogData(logData);
  const workbook = createLogExcelReport(formattedData);
  const fileName = `Log_Raporu_${new Date()
    .toLocaleDateString("tr-TR")
    .replace(/\./g, "-")}.xlsx`;
  XLSX.writeFile(workbook, fileName);
};

// Kurye aktivite verilerini formatlama
export const formatCourierActivityData = (activities) => {
  return {
    Results: activities.map((activity) => ({
      StartDate: activity.StartTime,
      EndDate: activity.EndTime || null,
      LogText: `${
        activity.ActivityType === 1
          ? "Çalışıyor"
          : activity.ActivityType === 2
            ? "Molada"
            : activity.ActivityType === 3
              ? "Çevrimdışı"
              : activity.ActivityType === 4
                ? "Mola Bitti"
                : "Bilinmiyor"
      }`,
      Username: activity.CourierName || "Bilinmeyen Kurye",
      Operation:
        activity.ActivityType === 1
          ? "Çalışma Başlangıcı"
          : activity.ActivityType === 2
            ? "Mola Başlangıcı"
            : activity.ActivityType === 3
              ? "Çevrimdışı"
              : activity.ActivityType === 4
                ? "Mola Bitişi"
                : "Bilinmiyor",
    })),
  };
};

// Kurye aktivite PDF raporu oluşturma
export const generateLogCourierPdfReport = (courierData) => {
  const formattedData = formatCourierActivityData(courierData);
  const doc = createLogReport(formattedData);
  const fileName = `Kurye_Aktivite_Raporu_${new Date()
    .toLocaleDateString("tr-TR")
    .replace(/\./g, "-")}.pdf`;
  doc.save(fileName);
};

// Kurye aktivite Excel raporu oluşturma
export const generateLogCourierExcelReport = (courierData) => {
  const formattedData = formatCourierActivityData(courierData);
  const workbook = createLogExcelReport(formattedData);
  const fileName = `Kurye_Aktivite_Raporu_${new Date()
    .toLocaleDateString("tr-TR")
    .replace(/\./g, "-")}.xlsx`;
  XLSX.writeFile(workbook, fileName);
};

// Kurye Çalışma Raporu  PDF raporu oluşturma
export const generateCourierWorkPdfReport = (reportData) => {
  const formattedData = formatCourierWorkData(reportData);
  const doc = createCourierActivityPdfReport(formattedData);
  const fileName = `Kurye_Calisma_Raporu_${new Date()
    .toLocaleDateString("tr-TR")
    .replace(/\./g, "-")}.pdf`;
  doc.save(fileName);
};

// Kurye Çalışma Raporu Excel raporu oluşturma
export const generateCourierWorkExcelReport = (reportData) => {
  const formattedData = formatCourierWorkData(reportData);
  const workbook = createExcelTemplate(formattedData);
  const fileName = `Kurye_Calisma_Raporu_${new Date()
    .toLocaleDateString("tr-TR")
    .replace(/\./g, "-")}.xlsx`;
  XLSX.writeFile(workbook, fileName);
};
