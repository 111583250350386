import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../Settings/settings-jss';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import Type from 'dan-styles/Typography.scss';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { CirclePicker } from 'react-color';
import ButtonBase from '@material-ui/core/ButtonBase';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import NumberFormat from 'react-number-format';
import IconButton from '@material-ui/core/IconButton';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Field } from 'redux-form/immutable';
import ModalInformation from '../../Ortak/ModalInformation';
import AlertNotification from '../../Ortak/AlertNotification';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  formDataSetStock, setProduct, getStockGroup, getStock, setProductCategory, stockClear,
  setCategoryListVisible, getPrinterGroup, setPrinterGroupListVisible, getProductList, getProductCopy,
  getUnitRecipeControl, setProductPropertyListVisible, setSaveImageProduct, setModalStock
} from '../../../../redux/modules/stock';
import { CapitalizeText, getLicenceModule } from '../../../../function/GeneralFunction';
const uuidv4 = require('uuid/v4');
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function getSteps() {
  return ['Genel Ürün Bilgileri', 'Ürün Özellikleri', 'Menü Oluşturma', 'Önizleme'];
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      decimalScale={2}
      fixedDecimalScale={true}
      autocomplete="off"
      allowNegative={false}
      decimalSeparator=","
      thousandSeparator="."
      prefix="₺"
    />
  );
}

class ProductAdd extends React.Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    await this.props.getStock(false).then(() => { });
    await this.props.getStockGroup(false).then(() => { });
    await this.props.getPrinterGroup().then(() => { });
  }

  handleNext = () => {
    const { activeStep, data } = this.props.stockReducer;
    let { skipped } = this.props.stockReducer;

    if (activeStep == 0) {
      let warning = false;
      let warning_text = "";
      if (data.Category.length == 0) {
        warning = true;
        warning_text = "Ürüne kategori seçmelisiniz.";
      } else if (data.ProductName.length == 0) {
        warning = true;
        warning_text = "Ürüne isim girmelisiniz.";
      } else if (data.Unit.filter(x => x.IsDelete == false).length == 0) {
        warning = true;
        warning_text = "Ürüne Birim tanımlaması yapmanız gerekmektedir.";
      } else if (data.IsScales && data.Unit.length > 1) {
        warning = true;
        warning_text = "Tartılı ürünlerde 1 birim tanımlanabilir.";
      } else if (data.Unit[0].UnitMultiplier > 1 && data.Unit.length == 1) {
        warning = true;
        warning_text = "Ürün birimine çarpan girmeniz için birden fazla birim oluşturmalısız!";
      }
      if (warning) {
        this.props.formDataSetStock("modalOpen", true).then(() => { })
        this.props.formDataSetStock("modalTitle", "Uyarı").then(() => { })
        this.props.formDataSetStock("modalText", warning_text).then(() => { })
        return;
      }
    } else if (activeStep == 1) {
      let warning = false;
      let warning_text = "";

    } else if (activeStep == 2) {
      let warning = false;
      let warning_text = "";

    }
    else if (activeStep == 3) {
      this.props.setProduct().then(() => {
        this.props.getProductList(this.props.filterDataProduct).then(() => { });
      });
    }

    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    this.props.formDataSetStock("activeStep", activeStep + 1).then(() => { })
    this.props.formDataSetStock("skipped", skipped).then(() => { })
  };

  handleBack = () => {
    const { activeStep } = this.props.stockReducer;
    this.props.formDataSetStock("activeStep", activeStep - 1).then(() => { })
  };

  handleSkip = () => {
    const { activeStep, skipped } = this.props.stockReducer;
    if (!this.isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }
    const skippedConst = new Set(skipped.values());
    skipped.add(activeStep);
    this.props.formDataSetStock("activeStep", activeStep + 1).then(() => { })
    this.props.formDataSetStock("skipped", skippedConst).then(() => { })
  };

  handleReset = () => {
    this.props.stockClear().then(() => {
      this.props.formDataSetStock("activeStep", 0).then(() => { })
    })
  };

  handleChangeUnit = name => event => {
    this.props.stockReducer.unit[name] = (name == "IsVisible" ? event.target.checked : (name == "UnitName" ? CapitalizeText(event.target.value) :
      (name == "Price" || name == "PackagePrice" || name == "Cost" ? parseFloat(event.target.value) : event.target.value)));
    this.props.formDataSetStock("unit", this.props.stockReducer.unit).then(() => { })
  };

  handleChange = name => event => {
    if (name == "ProductName") {
      event.target.value = CapitalizeText(event.target.value);
    }
    this.props.stockReducer.data[name] = (name == "IsStockTracking" || name == "IsFavorite" || name == "IsScales" || name == "IsList" ? event.target.checked : (name == "Color" ? event.hex : event.target.value));
    this.props.formDataSetStock("data", this.props.stockReducer.data).then(() => { })
  };

  isStepOptional = step => (step === 1 || step === 2);

  UnitAdd() {
    const { unit } = this.props.stockReducer;
    if (unit.UnitName.length == 0) {
      this.props.formDataSetStock("modalOpen", true).then(() => { })
      this.props.formDataSetStock("modalTitle", "Uyarı").then(() => { })
      this.props.formDataSetStock("modalText", "Birime İsim girmelisiniz.").then(() => { })
      return;
    }

    var order = this.props.stockReducer.unit.Order + 1;
    this.props.stockReducer.unit.Id = uuidv4();
    this.props.stockReducer.data.Unit.push(this.props.stockReducer.unit);
    this.props.formDataSetStock("unit", { "Id": "", "UnitName": "", "Price": 0, "Cost": 0, "PackagePrice": 0, "Order": order, "IsDelete": false, "UnitMultiplier": 1, "IsVisible": true }).then(() => { })
  }

  async UnitDelete(Id) {
    const { data } = this.props.stockReducer;
    await this.props.getUnitRecipeControl(Id, data).then(() => { })
  }

  handleChangeProperty = (name, property_id) => event => {
    this.props.stockReducer.data.Property.find(x => x.Id == property_id)[name] = event.target.value;
    this.props.formDataSetStock("data", this.props.stockReducer.data).then(() => { })
  };


  handleChangeMenu = (name, property_id) => event => {
    this.props.stockReducer.data.Menu.find(x => x.Id == property_id)[name] = (name == "IsMenuVisible" ? event.target.checked : event.target.value);
    this.props.formDataSetStock("data", this.props.stockReducer.data).then(() => { })
  };

  handleChangePropertyItem = (name, property_id, item_id) => event => {
    this.props.stockReducer.data.Property.find(x => x.Id == property_id).Item.find(x => x.Id == item_id)[name] = (name == "Default" || name == "IsList" ? event.target.checked : (name == "Price" ? parseFloat(event.target.value) : CapitalizeText(event.target.value)));
    this.props.formDataSetStock("data", this.props.stockReducer.data).then(() => { })
  };

  handleChangeUnitPrice = (item_id) => event => {
    this.props.stockReducer.data.Unit.find(x => x.Id == item_id).Price = parseFloat(event.target.value);
    this.props.formDataSetStock("data", this.props.stockReducer.data).then(() => { })
  };

  handleChangeUnitPricePackage = (item_id) => event => {
    this.props.stockReducer.data.Unit.find(x => x.Id == item_id).PackagePrice = parseFloat(event.target.value);
    this.props.formDataSetStock("data", this.props.stockReducer.data).then(() => { })
  };

  handleChangeUnitCost = (item_id) => event => {
    this.props.stockReducer.data.Unit.find(x => x.Id == item_id).Cost = parseFloat(event.target.value);
    this.props.formDataSetStock("data", this.props.stockReducer.data).then(() => { })
  };

  handleChangeUnitIsVisible = (item_id) => event => {
    this.props.stockReducer.data.Unit.find(x => x.Id == item_id).IsVisible = event.target.checked;
    this.props.formDataSetStock("data", this.props.stockReducer.data).then(() => { })
  };

  handleChangeUnitMultiplier = (item_id) => event => {
    this.props.stockReducer.data.Unit.find(x => x.Id == item_id).UnitMultiplier = event.target.value;
    this.props.formDataSetStock("data", this.props.stockReducer.data).then(() => { })
  };

  handleChangeMenuItem = (name, menu_id, item_id) => event => {
    this.props.stockReducer.data.Menu.find(x => x.Id == menu_id).Item.find(x => x.Id == item_id)[name] = (name == "Default" || name == "IsList" ? event.target.checked : (name == "Price" ? parseFloat(event.target.value) : CapitalizeText(event.target.value)));
    this.props.formDataSetStock("data", this.props.stockReducer.data).then(() => { })
  };

  clickMenuUpdate(MenuId) {
    this.props.stockReducer.data.Menu.find(x => x.Id == MenuId).IsCompulsory = !this.props.stockReducer.data.Menu.find(x => x.Id == MenuId).IsCompulsory;
    this.props.formDataSetStock("data", this.props.stockReducer.data).then(() => { });
  }

  compare(a, b) {
    const genreA = a.Order;
    const genreB = b.Order;

    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
  }

  PropertyDelete(Id) {
    this.props.stockReducer.data.Property.filter(x => x.Id == Id)[0].IsDelete = true;

    var item = this.props.stockReducer.data.Property.find(x => x.Id == Id).Item;
    if (item != null) {
      item.map((n, i) => {
        n.IsDelete = true;
      })
    }

    this.props.formDataSetStock("data", this.props.stockReducer.data).then(() => { })
  }

  MenuDelete(Id) {
    this.props.stockReducer.data.Menu.filter(x => x.Id == Id)[0].IsDelete = true;
    var item = this.props.stockReducer.data.Menu.find(x => x.Id == Id).Item;
    if (item != null) {
      item.map((n, i) => {
        n.IsDelete = true;
      })
    }
    this.props.formDataSetStock("data", this.props.stockReducer.data).then(() => { })
  }

  addDefaultSrc(ev) {
    ev.target.src = 'https://cdn.javipos.com/broken.png';
  }

  onChangeImageFile = event => {
    const { data } = this.props.stockReducer;

    var Id = event.target.id;
    var dataFile = event.target.files[0];

    const fileExtension = dataFile.name.split(".").at(-1);
    const allowedFileTypes = ["png"];
    if (!allowedFileTypes.includes(fileExtension)) {
      this.props.setModalStock("Sadece .png resim formatı desteklenmektedir.").then(() => { })
      return;
    }

    var filesize = ((dataFile.size / 1024) / 1024).toFixed(4); // MB
    if (filesize > 2) {
      this.props.setModalStock("Görsel dosya boyutu maksimum 2mb olabilir").then(() => { })
      return;
    }
    if (!data.IsUpdate) {
      this.props.setModalStock("Önce ürünü kayıt etmeniz gerekmektedir.").then(() => { })
      return;
    }
    this.props.setSaveImageProduct(dataFile, Id, 0, 250, 250).then(() => { });
  };

  getStepContent(step, classes, single) {
    const { age, checkedA, data, unit, openProperty, property, menu, openMenu, categoryList, printerGroupList } = this.props.stockReducer;

    switch (step) {
      case 0:
        return (
          <div>
            <Grid container spacing={1} style={{ width: '100%' }}>
              <Grid item sm={5} xs={5} md={5}>
                <Paper className={classes.root} elevation={4}>
                  <Typography style={{ fontSize: '1.2rem', fontWeight: 400, lineHeight: '1.5' }} component="h3">
                    {"Ürün İşlemleri"}
                  </Typography>
                  <Typography style={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '12px', marginBottom: 10, fontWeight: 400 }} component="p">
                    {"Ürün Bilgileri ve Detayları"}
                  </Typography>
                  <Grid container spacing={1} style={{ borderTop: '1px solid #636363' }}>
                    <Grid item md={5}>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-helper">{"Kategori"}</InputLabel>
                        <Select
                          value={data.Category}
                          onChange={this.handleChange("Category")}
                          input={<Input name="Category" id="age-helper" />}
                        >
                          <MenuItem value="">
                            <em>{"Seçiniz"}</em>
                          </MenuItem>
                          {
                            categoryList.map(n => ([
                              <MenuItem key={n.Id} value={n.Id}>{n.Name}</MenuItem>
                            ]))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={1}>
                      <Button fullWidth onClick={() => this.props.setCategoryListVisible(true).then(() => { })} className={classes.rootButton} style={{ height: 44, backgroundColor: '#009688', minWidth: '100%', marginLeft: 9, marginTop: 10 }}>
                        <Icon>{"add"}</Icon>
                      </Button>
                    </Grid>
                    <Grid item md={5}>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-helper">{"Yazdırma Grubu"}</InputLabel>
                        <Select
                          value={data.PrinterGroup}
                          onChange={this.handleChange("PrinterGroup")}
                          input={<Input name="PrinterGroup" id="age-helper" />}
                        >
                          <MenuItem value="00000000-0000-0000-0000-000000000000">
                            <em>{"Seçiniz"}</em>
                          </MenuItem>
                          {
                            printerGroupList.map(n => ([
                              <MenuItem key={n.Id} value={n.Id}>{n.GroupName}</MenuItem>
                            ]))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={1}>
                      <Button fullWidth onClick={() => this.props.setPrinterGroupListVisible(true).then(() => { })} className={classes.rootButton} style={{ height: 44, backgroundColor: '#FF9800', minWidth: '100%', marginLeft: 9, marginTop: 10 }}>
                        <Icon>{"add"}</Icon>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                      <label htmlFor={data.Id}>
                        <Tooltip title="Resim boyutu 250px*250px olarak otomatik boyutlandırılacaktır. Yüklemek istediğiniz resim çalışmalarını bu boyutta yapabilirsiniz!">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            style={{ backgroundColor: 'transparent' }}
                          >{<Icon>{"add_a_photo"}</Icon>}
                          </Button>
                        </Tooltip>
                      </label>
                      <input id={data.Id} style={{ display: "none" }} type="file" onChange={this.onChangeImageFile} />
                      <img onError={this.addDefaultSrc} style={{ height: 30 }} src={data.ImageUrl} />
                    </Grid>
                    <Grid item md={10}>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="name-simple">Ürün Adı</InputLabel>
                        <Input id="ProductName" defaultValue={data.ProductName} onChange={this.handleChange("ProductName")} />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item md={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="name-simple">{"Açıklama"}</InputLabel>
                      <Input id="Barcode" defaultValue={data.Explanation} onChange={this.handleChange("Explanation")} />
                    </FormControl>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item md={3}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="name-simple">{"Sıralama"}</InputLabel>
                        <Input
                          id="Order"
                          defaultValue={data.Order || 1}
                          onFocus={event => { event.target.select() }}
                          onChange={this.handleChange('Order')}
                          type="number"
                          fullWidth
                          inputProps={{
                            min: 0
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="name-simple">{"Barkodu"}</InputLabel>
                        <Input id="Barcode" defaultValue={data.Barcode} onChange={this.handleChange("Barcode")} />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item md={3}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="name-simple">{"Hazırlanma Süresi"}</InputLabel>
                        <Input
                          id="Order"
                          defaultValue={data.PreparationTime || 0}
                          onFocus={event => { event.target.select() }}
                          onChange={this.handleChange('PreparationTime')}
                          type="number"
                          fullWidth
                          inputProps={{
                            min: 0,
                            max: 100,
                            step: 1
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={3}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="name-simple">{"Pişirme Süresi"}</InputLabel>
                        <Input
                          id="Order"
                          defaultValue={data.CookingTime || 0}
                          onFocus={event => { event.target.select() }}
                          onChange={this.handleChange('CookingTime')}
                          type="number"
                          fullWidth
                          inputProps={{
                            min: 0
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={3}>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="name-simple">Kdv'si</InputLabel>
                        <Input id="Kdv" type="number" value={data.Kdv || 10} onChange={this.handleChange("Kdv")} />
                      </FormControl>
                    </Grid>
                    <Grid item md={3}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="name-simple">{"Kalori"}</InputLabel>
                        <Input
                          id="Calori"
                          defaultValue={data.Calori || 1}
                          onFocus={event => { event.target.select() }}
                          onChange={this.handleChange('Calori')}
                          type="number"
                          fullWidth
                          inputProps={{
                            min: 0
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={3}>
                      <Tooltip title="Satışta Favori Listesine Ekler">
                        <FormControlLabel
                          style={{ marginLeft: 0 }}
                          control={(
                            <Switch
                              checked={data.IsFavorite}
                              onChange={this.handleChange('IsFavorite')}
                              value="checkedA"
                            />
                          )}
                          label="Favoriler"
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item md={3}>
                      <Tooltip title="Ürünü Satış Ekranında Gösterir">
                        <FormControlLabel
                          style={{ marginLeft: 0 }}
                          control={(
                            <Switch
                              checked={data.IsList}
                              onChange={this.handleChange('IsList')}
                              value="checkedA"
                            />
                          )}
                          label="Satışta"
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item md={3}>
                      <Tooltip title="Ürünü Terazi Ürünü Olarak İşaretler">
                        <FormControlLabel
                          style={{ marginLeft: 0 }}
                          control={(
                            <Switch
                              checked={data.IsScales}
                              onChange={this.handleChange('IsScales')}
                              value="checkedA"
                            />
                          )}
                          label="Terazi"
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item md={3}>
                      <Tooltip title="Ürün Satıldığında Stok Takibi Yapılmasını Sağlar">
                        <FormControlLabel
                          style={{ marginLeft: 0 }}
                          control={(
                            <Switch
                              checked={data.IsStockTracking}
                              onChange={this.handleChange('IsStockTracking')}
                              value="checkedA"
                            />
                          )}
                          label="Stok Takip"
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid item md={12}>
                    <CirclePicker
                      width="100%"
                      name='color'
                      colors={["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#009688", "#FF5722", "#FF9800", "#795548", "#CDDC39", "#8BC34A"]}
                      onChange={this.handleChange("Color")}
                      color={data.Color}
                    />
                  </Grid>
                </Paper>
              </Grid>
              <Grid item sm={7} xs={7} md={7}>
                <Paper className={classes.root} elevation={4}>
                  <Typography style={{ fontSize: '1.2rem', fontWeight: 400, lineHeight: '1.5' }} component="h3">
                    {"Birimler"}
                  </Typography>
                  <Typography style={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '12px', marginBottom: 10, fontWeight: 400 }} component="p">
                    {"(Adet, Porsiyon, Küçük, Orta, Büyük v.b.)"}
                  </Typography>
                  <Grid container spacing={1} style={{ borderBottom: '1px solid #636363', borderTop: '1px solid #636363', width: 'calc(100% + 0px)', marginBottom: 10 }}>
                    <Grid item md={3}>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="name-simple">{"Birim Adı"}</InputLabel>
                        <Input id="name-simple" defaultValue={unit.UnitName} onChange={this.handleChangeUnit("UnitName")} />
                      </FormControl>
                    </Grid>
                    <Grid item md={3}>
                      <TextField
                        className={classes.formControl}
                        label="Fiyat"
                        value={unit.Price}
                        onFocus={event => { event.target.select() }}
                        onChange={this.handleChangeUnit('Price')}
                        id="formatted-numberformat-input"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <TextField
                        className={classes.formControl}
                        label="Paket Fiyatı"
                        value={unit.PackagePrice}
                        onFocus={event => { event.target.select() }}
                        onChange={this.handleChangeUnit('PackagePrice')}
                        id="formatted-numberformat-input"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <TextField
                        className={classes.formControl}
                        label="Maliyet"
                        style={{ display: (window.location.pathname == "/centerbrand" ? 'none' : '') }}
                        value={unit.Cost}
                        onChange={this.handleChangeUnit('Cost')}
                        onFocus={event => { event.target.select() }}
                        id="formatted-numberformat-input"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                    </Grid>
                    <Grid item md={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Tooltip title="Ürün Satıldığında Stok Takibi Yapılmasını Sağlar">
                        <FormControlLabel
                          style={{ marginLeft: 0 }}
                          control={(
                            <Switch
                              checked={unit.IsVisible}
                              onChange={this.handleChangeUnit('IsVisible')}
                              value="checkedA"
                            />
                          )}
                          label="Birim Satışta Görünsün"
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item md={3}>
                      <Input
                        type="number"
                        id="standard-adornment-weight"
                        onFocus={event => { event.target.select() }}
                        value={unit.UnitMultiplier}
                        onChange={this.handleChangeUnit("UnitMultiplier")}
                        endAdornment={<InputAdornment style={{ marginRight: 10 }} position="end">{"Birim Çarpan"}</InputAdornment>}
                        aria-describedby="standard-weight-helper-text"
                        fullWidth
                        inputProps={{
                          min: 1
                        }}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <Input
                        type="number"
                        id="standard-adornment-weight"
                        onFocus={event => { event.target.select() }}
                        value={unit.Order}
                        onChange={this.handleChangeUnit("Order")}
                        endAdornment={<InputAdornment style={{ marginRight: 10 }} position="end">{"Sıra"}</InputAdornment>}
                        aria-describedby="standard-weight-helper-text"
                        fullWidth
                        inputProps={{
                          min: 1
                        }}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.UnitAdd()} className={classes.rootButton} style={{ marginTop: 0, height: 36, backgroundColor: '#009688', fontSize: 20 }}>
                        <Icon>{"add"}</Icon>
                        {"Ekle"}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item md={12}>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow style={{ backgroundColor: '#424242' }}>
                          <TableCell align="left">{"Görünsün"}</TableCell>
                          <TableCell align="left">{"Birimi"}</TableCell>
                          <TableCell align="center">{"Fiyatı"}</TableCell>
                          <TableCell align="center">{"Paket Fiyatı"}</TableCell>
                          <TableCell align="center">{"Maliyet"}</TableCell>
                          <TableCell align="center">{"Çarpan"}</TableCell>
                          <TableCell align="center">{"Sıralama"}</TableCell>
                          <TableCell align="right">{"İşlem"}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.Unit.filter(x => x.IsDelete == false).map(n => ([
                          <TableRow key={n.Id}>
                            <TableCell align="left" style={{ width: '7.5%', padding: '0.50em' }}>
                              <FormControlLabel
                                style={{ marginLeft: 0 }}
                                control={(
                                  <Switch
                                    checked={n.IsVisible}
                                    onChange={this.handleChangeUnitIsVisible(n.Id)}
                                    value="checkedA"
                                  />
                                )}
                                label=""
                              />
                            </TableCell>
                            <TableCell align="left" style={{ width: '22.5%', padding: '0.50em' }}>{n.UnitName}</TableCell>
                            <TableCell align="center" style={{ width: '12.5%', padding: '0.50em' }}>
                              <TextField
                                fullWidth
                                value={n.Price}
                                onChange={this.handleChangeUnitPrice(n.Id)}
                                id="price_unit"
                                onFocus={event => { event.target.select() }}
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                              />
                            </TableCell>
                            <TableCell align="center" style={{ width: '12.5%', padding: '0.50em' }}>
                              <TextField
                                fullWidth
                                value={n.PackagePrice}
                                onFocus={event => { event.target.select() }}
                                onChange={this.handleChangeUnitPricePackage(n.Id)}
                                id="price_package_unit"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                              />
                            </TableCell>
                            <TableCell align="center" style={{ width: '12.5%', padding: '0.50em' }}>
                              <TextField
                                fullWidth
                                value={n.Cost}
                                onFocus={event => { event.target.select() }}
                                onChange={this.handleChangeUnitCost(n.Id)}
                                id="cost_unit"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                              />
                            </TableCell>
                            <TableCell align="center" style={{ width: '12.5%', padding: '0.50em' }}>
                              <Input
                                type="number"
                                id="standard-adornment-weight"
                                onFocus={event => { event.target.select() }}
                                value={n.UnitMultiplier}
                                onChange={this.handleChangeUnitMultiplier(n.Id)}
                                aria-describedby="standard-weight-helper-text"
                                fullWidth
                                inputProps={{
                                  min: 1
                                }}
                              />
                            </TableCell>
                            <TableCell align="center" style={{ width: '10%', padding: '0.50em' }}>{n.Order}</TableCell>
                            <TableCell align="right" style={{ width: '10%', padding: '0.50em' }}>
                              <IconButton style={{ backgroundColor: '#3e3e3e' }} aria-label="Delete" onClick={() => this.UnitDelete(n.Id)}>
                                <Icon>{"delete"}</Icon>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ]))}
                      </TableBody>
                    </Table>
                  </Grid>
                </Paper>
                <Paper style={{ marginTop: 5 }} className={classes.root} elevation={4}>
                  <Typography style={{ fontSize: '1.2rem', fontWeight: 400, lineHeight: '1.5' }} component="h3">
                    {"Franchise Sistemi Otomatik Sipariş Oluşturma"}
                  </Typography>
                  <Typography style={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '12px', marginBottom: 10, fontWeight: 400 }} component="p">
                    {"Merkeze Sipariş biriminden sipariş miktarı kadar kritik stok miktarının altına düştüğünde otomatik sipariş oluşturulur."}
                  </Typography>
                  <Grid container spacing={1} style={{ borderBottom: '1px solid #636363', borderTop: '1px solid #636363', width: 'calc(100% + 0px)', marginBottom: 10 }}>
                    <Grid item md={4}>
                      <Tooltip title="Adisyon kapatıldığında kritik stok miktarı girilmiş ise ve bu stok kritik seviyenin altınaysa uyarı alırsınız!">
                        <FormControl className={classes.formControl} fullWidth>
                          <InputLabel htmlFor="name-simple">{"Kritik Stok Miktarı"}</InputLabel>
                          <Input
                            id="CriticalStockQuantity"
                            defaultValue={data.CriticalStockQuantity || 0}
                            onFocus={event => { event.target.select() }}
                            onChange={this.handleChange('CriticalStockQuantity')}
                            type="number"
                            fullWidth
                            inputProps={{
                              min: 0
                            }}
                          />
                        </FormControl>
                      </Tooltip>
                    </Grid>
                    <Grid item md={4}>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-helper">{"Hareket Birimi"}</InputLabel>
                        <Select
                          value={data.CriticalUnitId}
                          onChange={this.handleChange("CriticalUnitId")}
                          input={<Input name="Category" id="age-helper" />}
                        >
                          <MenuItem value="00000000-0000-0000-0000-000000000000">
                            <em>{"Seçiniz"}</em>
                          </MenuItem>
                          {
                            data.Unit.filter(x => x.IsDelete == false).map(n => ([
                              <MenuItem key={n.Id} value={n.Id}>{n.UnitName}</MenuItem>
                            ]))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={4}>
                      <Tooltip title="Franchise merkezine bu üründen otomatik kaç adet sipariş geçileceğini belirten miktardır.">
                        <FormControl className={classes.formControl} fullWidth>
                          <InputLabel htmlFor="name-simple">{"Sipariş Miktarı"}</InputLabel>
                          <Input
                            id="CriticalOrderQuantity"
                            defaultValue={data.CriticalOrderQuantity || 0}
                            onFocus={event => { event.target.select() }}
                            onChange={this.handleChange('CriticalOrderQuantity')}
                            type="number"
                            fullWidth
                            inputProps={{
                              min: 0
                            }}
                          />
                        </FormControl>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        );
      case 1:
        return (
          <div>
            <Grid container spacing={1}>
              <Grid item md={12}>
                <Grid item md={2}>
                  <Button disabled={data.IsScales} onClick={() => this.props.setProductPropertyListVisible(true, "property").then(() => { })} variant="contained" size="medium" color="secondary" style={{ borderRadius: 3, textTransform: 'none' }}>
                    <Icon style={{ marginRight: 6 }}>{"playlist_add"}</Icon>
                    {"Ürün Özellik Grubu Ekle"}
                  </Button>
                </Grid>
              </Grid>
              <Grid item md={12} style={{ padding: 0, marginBottom: 60 }}>
                {data.Property.sort(this.compare).filter(x => x.IsDelete == false).map((n, i) => ([
                  <Paper key={i} className={classes.root} style={{ margin: 5, borderRadius: 5 }} elevation={4}>
                    <Grid container spacing={1}>
                      <Grid item md={1} style={{ alignSelf: 'center', display: 'flex' }}>
                        <FormControl className={classes.formControl}>
                          <InputLabel htmlFor="name-simple">{"Sıra"}</InputLabel>
                          <Input id="PropertyOrder" type="number" value={n.Order} onChange={this.handleChangeProperty("Order", n.Id)} />
                        </FormControl>
                      </Grid>
                      <Grid item md={9} style={{ justifyContent: 'center', alignSelf: 'center', display: 'flex' }}>
                        <Typography style={{ fontSize: 18 }} component="h3">
                          {n.Name}
                        </Typography>
                        <Typography className={Type.textSuccess} style={{ fontSize: 18, marginLeft: 10 }} component="h3">
                          {(n.Type == 1 ? <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #636363', padding: 2 }}><Icon style={{ color: '#388E3C' }}>{"radio_button_checked"}</Icon> <span style={{ color: 'white', fontSize: 15, marginLeft: 5 }}>{"Tekli Seçim"}</span></div> : <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #636363', padding: 2 }}><Icon style={{ color: '#388E3C' }}>{"check_box"}</Icon> <span style={{ color: 'white', fontSize: 15, marginLeft: 5 }}>{"Çoklu Seçim"}</span></div>)}
                        </Typography>
                      </Grid>
                      <Grid item md={2} style={{ textAlign: 'right' }}>
                        <Button fullWidth onClick={() => this.PropertyDelete(n.Id)} variant="contained" size="medium" color="default" style={{ borderRadius: 3, textTransform: 'none', color: 'white', backgroundColor: '#D32F2F', width: '100%' }}>
                          <Icon style={{ marginRight: 6 }}>{"clear"}</Icon>
                          {"Grubu Sil"}
                        </Button>
                      </Grid>
                      <Grid item md={12}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell padding="dense">{"İsim"}</TableCell>
                              <TableCell align="dense">{"Fiyatı"}</TableCell>
                              <TableCell align="dense">{"Varsayılan"}</TableCell>
                              <TableCell align="dense">{"Listede Göster"}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {n.Item.filter(x => x.IsDelete == false).map((x, i) => ([
                              <TableRow key={x.Id}>
                                <TableCell padding="dense">
                                  <Input fullWidth placeholder="Örn: Ketçap, Susamlı Ekmek, Acısız vb" id="name_propertyitem" defaultValue={x.Name} onChange={this.handleChangePropertyItem("Name", n.Id, x.Id)} />
                                </TableCell>
                                <TableCell align="dense">
                                  <TextField
                                    fullWidth
                                    defaultValue={x.Price}
                                    value={x.Price}
                                    onFocus={event => { event.target.select() }}
                                    onChange={this.handleChangePropertyItem("Price", n.Id, x.Id)}
                                    id="price_propertyitem"
                                    InputProps={{
                                      inputComponent: NumberFormatCustom,
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="dense">
                                  <FormControlLabel
                                    style={{ marginLeft: 0 }}
                                    control={(
                                      <Switch
                                        checked={x.Default}
                                        onChange={this.handleChangePropertyItem("Default", n.Id, x.Id)}
                                        value="checkedA"
                                      />
                                    )}
                                  />
                                </TableCell>
                                <TableCell align="dense">
                                  <FormControlLabel
                                    style={{ marginLeft: 0 }}
                                    control={(
                                      <Switch
                                        checked={x.IsList}
                                        onChange={this.handleChangePropertyItem("IsList", n.Id, x.Id)}
                                        value="checkedA"
                                      />
                                    )}
                                  />
                                </TableCell>
                              </TableRow>
                            ]))}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </Paper>
                ]))}
              </Grid>
            </Grid>
          </div>
        );
      case 2:
        return (
          <div>
            <Grid container spacing={1}>
              <Grid item md={12}>
                <Grid item md={2}>
                  <Button disabled={data.IsScales} onClick={() => this.props.setProductPropertyListVisible(true, "menu").then(() => { })} variant="contained" size="medium" color="secondary" style={{ borderRadius: 3, textTransform: 'none' }}>
                    <Icon style={{ marginRight: 6 }}>{"fastfood"}</Icon>
                    {"Ürün Menü Grubu Ekle"}
                  </Button>
                </Grid>
              </Grid>
              <Grid item md={12} style={{ padding: 0, marginBottom: 60 }}>
                {data.Menu.sort(this.compare).filter(x => x.IsDelete == false).map((n, i) => ([
                  <Paper key={i} className={classes.root} style={{ margin: 5, borderRadius: 5 }} elevation={4}>
                    <Grid container spacing={1}>
                      <Grid item md={1} style={{ alignSelf: 'center', display: 'flex' }}>
                        <FormControl className={classes.formControl}>
                          <InputLabel htmlFor="name-simple">{"Sıra"}</InputLabel>
                          <Input id="PropertyOrder" type="number" value={n.Order} onChange={this.handleChangeMenu("Order", n.Id)} />
                        </FormControl>
                      </Grid>
                      <Grid item md={2} style={{ alignSelf: 'center', display: 'flex' }}>
                        <FormControlLabel
                          style={{ marginLeft: 0 }}
                          control={(
                            <Switch
                              checked={n.IsMenuVisible}
                              onChange={this.handleChangeMenu("IsMenuVisible", n.Id)}
                              value="checkedA"
                            />
                          )}
                          label={"Grup Menüsünde Alt Opsiyonunu Göster"}
                        />
                      </Grid>
                      <Grid item md={7} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        <Typography style={{ fontSize: 18 }} component="h3">
                          {n.Name}
                        </Typography>
                      </Grid>
                      <Grid item md={2} style={{ textAlign: 'right' }}>
                        <Button fullWidth onClick={() => this.MenuDelete(n.Id)} variant="contained" size="medium" color="default" style={{ borderRadius: 3, textTransform: 'none', color: 'white', backgroundColor: '#D32F2F', width: '100%' }}>
                          <Icon style={{ marginRight: 6 }}>{"clear"}</Icon>
                          {"Grubu Sil"}
                        </Button>
                      </Grid>
                      <Grid item md={12}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell padding="dense">{"Ürün"}</TableCell>
                              <TableCell align="dense">{"Fiyatı"}</TableCell>
                              <TableCell align="dense">{"Varsayılan"}</TableCell>
                              <TableCell align="dense">{"Görünsün"}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {n.Item.filter(x => x.IsDelete == false).map((x, i) => ([
                              <TableRow key={x.Id}>
                                <TableCell padding="dense">
                                  <Input disabled fullWidth placeholder="" id={x.StockId} value={x.Name} />
                                </TableCell>
                                <TableCell align="dense">
                                  <TextField
                                    fullWidth
                                    value={x.Price}
                                    onFocus={event => { event.target.select() }}
                                    onChange={this.handleChangeMenuItem("Price", n.Id, x.Id)}
                                    id="price_propertyitem"
                                    InputProps={{
                                      inputComponent: NumberFormatCustom,
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="dense">
                                  <FormControlLabel
                                    style={{ marginLeft: 0 }}
                                    control={(
                                      <Switch
                                        checked={x.Default}
                                        onChange={this.handleChangeMenuItem("Default", n.Id, x.Id)}
                                        value="checkedA"
                                      />
                                    )}
                                  />
                                </TableCell>
                                <TableCell align="dense">
                                  <FormControlLabel
                                    style={{ marginLeft: 0 }}
                                    control={(
                                      <Switch
                                        checked={x.IsList}
                                        onChange={this.handleChangeMenuItem("IsList", n.Id, x.Id)}
                                        value="checkedA"
                                      />
                                    )}
                                  />
                                </TableCell>
                              </TableRow>
                            ]))}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </Paper>
                ]))}
              </Grid>
            </Grid>
          </div>
        );
      case 3:
        return 'Önizleme';
      default:
        return 'Unknown step..';
    }
  }

  isStepSkipped(step) {
    const { skipped } = this.props.stockReducer;
    return skipped.has(step);
  }

  modalClose = () => {
    this.props.formDataSetStock("modalOpen", false).then(() => { })
  };

  render() {
    const {
      classes,
      open,
      handleClose,
      title,
      productAddSaveLoading,
      productAddSaveText
    } = this.props;
    const steps = getSteps();
    const { activeStep, altLabel, single } = this.props.stockReducer;

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div name={"paymentLoading"} style={{ display: (productAddSaveLoading ? 'grid' : 'none'), backgroundColor: 'rgba(48,48,48,.5)', position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, zIndex: 9999, alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ display: 'grid' }}>
            <CircularProgress size={100} style={{ color: 'rgb(243, 156, 18)', backgroundColor: 'transparent', margin: 'auto' }} thickness={4} className={classes.progress} />
            <span style={{ color: 'white', fontSize: 20 }}>{productAddSaveText}</span>
          </div>
        </div>
        <ModalInformation event={this.modalClose} open={this.props.stockReducer.modalOpen} title={this.props.stockReducer.modalTitle} text={this.props.stockReducer.modalText} />
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Button color="inherit" onClick={handleClose}>
              {"Geri"}
            </Button>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.detailWrap} style={{ backgroundColor: 'rgb(48, 48, 48)' }}>
          <Divider />
          <Stepper activeStep={activeStep} alternativeLabel={altLabel} style={{ padding: 10, backgroundColor: 'transparent' }}>
            {steps.map((label, index) => {
              const props = {};
              const labelProps = {};
              if (this.isStepOptional(index)) {
                labelProps.optional = <Typography className={altLabel ? Type.textCenter : ''} variant="caption">Opsiyonel</Typography>;
              }
              if (this.isStepSkipped(index)) {
                props.completed = false;
              }
              return (
                <Step key={label} {...props}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Divider />
          <div>
            {activeStep === steps.length ? (
              <Paper style={{ flex: 1, height: '100%', textAlign: 'center' }} className={classes.root} elevation={4}>
                <Typography className={classes.instructions} style={{ fontSize: 20 }}>
                  {"Ürün Başarıyla Kaydedildi"}
                </Typography>
                <Button style={{ marginTop: 20 }} color="primary" variant="outlined" size="large" onClick={this.handleReset} className={classes.stepper_button}>
                  {"Yeni Ürün Kaydet"}
                </Button>
                <Button style={{ marginTop: 20 }} color="secondary" variant="outlined" size="large" onClick={() => this.props.getProductCopy(this.props.stockReducer.data.Id).then(() => { })} className={classes.stepper_button}>
                  {"Bu Ürünü Kopyala"}
                </Button>
              </Paper>
            ) : (
              <div style={{ backgroundColor: '#303030', padding: 5, height: '100%' }}>
                {this.getStepContent(activeStep, classes, single)}
                <div style={{ bottom: 20, position: 'fixed', right: 20 }}>
                  <Button
                    disabled={activeStep === 0}
                    onClick={this.handleBack}
                    className={classes.stepper_button}
                  >
                    {"Geri"}
                  </Button>
                  {this.isStepOptional(activeStep) && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleSkip}
                      className={classes.stepper_button}
                    >
                      {"Atla"}
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleNext}
                    className={classes.stepper_button}
                  >
                    {activeStep === steps.length - 1 ? 'Ürünü Kaydet' : 'İleri'}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    );
  }
}

ProductAdd.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    stockReducer: state.get('stockReducer').form_data,
    filterDataProduct: state.get('stockReducer').filterDataProduct,
    productAddSaveLoading: state.get('stockReducer').productAddSaveLoading,
    productAddSaveText: state.get('stockReducer').productAddSaveText
  };
};

const mapDispatchToProps = {
  formDataSetStock,
  setProduct,
  getStockGroup,
  getStock,
  getPrinterGroup,
  setProductCategory,
  stockClear,
  setCategoryListVisible,
  setPrinterGroupListVisible,
  getProductList,
  getProductCopy,
  getUnitRecipeControl,
  setProductPropertyListVisible,
  setSaveImageProduct,
  setModalStock
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(ProductAdd)));
