import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import { PapperBlock } from 'dan-components';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import ModalInformation from '../Ortak/ModalInformation';
import Outer from '../../Templates/Outer';
import Grid from '@material-ui/core/Grid';
import Decoration from '../../Templates/Decoration';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import styles from './track-style-jss';
import {
    setPaketYoldaCourierHubConnection, setPaketYoldaOrderHubConnection,
    getOrderList, setPaketYoldaCourierVisible, modalClosePaketYolda, setPaketYoldaPanelPageUpdate,
    getPaketYoldaBusinessList, setPaketYoldaCourierAddVisible,
    setPaketYoldaBrandListVisible, setRegionListClear, getRegionList, getNewCourierList,
    setPaketYoldaOrderUpdate
} from '../../../redux/modules/paketyolda';
import { setSoundControl } from '../../../redux/modules/settings';
import JaviPosMarker from 'dan-images/javiposmarker.png';
import JaviPosMarkerRed from 'dan-images/javiposmarkerred.png';
import cookie from 'react-cookies';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import PaketYoldaMap from './Maps/PaketYoldaMap'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { soundPlay, notificationShow, getPaketYolda } from '../../../function/GeneralFunction';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {
    createSelector
} from 'reselect';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PaketYoldaIntro from 'dan-images/paketyolda_intro.png';
import Ionicon from 'react-ionicons';
import PaketYoldaCourierAdd from './PaketYoldaCourierAdd';
import javikurye from 'dan-images/javikurye.png';
import PaketYoldaOrderList from './PaketYoldaOrderList';
import PaketYoldaCourierList from './PaketYoldaCourierList';
import PaketYoldaBrandList from './PaketYoldaBrandList';
import PackageAdditionDetail from '../PackagePage/PackageAdditionDetail';
import * as workerTimers from "worker-timers";
import InputLabel from '@material-ui/core/InputLabel';
import PaketYoldaUserList from './PaketYoldaUserList';
import PaketYoldaCounter from './Maps/PaketYoldaCounter';
const elem = document.documentElement;
import Fab from '@material-ui/core/Fab';
import Slide from '@material-ui/core/Slide';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import ScrollContainer from 'react-indiana-drag-scroll'

class PaketYoldaPanelPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { show: false, fullScreen: false, scroollTop: false };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.IsPaketYoldaPanelUpdate == this.props.IsPaketYoldaPanelUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {
        const { paketYoldaCourierHubConnection, paketYoldaOrderHubConnection } = this.props;
        if (paketYoldaCourierHubConnection == null) {
            await this.props.setPaketYoldaCourierHubConnection().then(() => { });
        }

        if (paketYoldaOrderHubConnection == null) {
            await this.props.setPaketYoldaOrderHubConnection().then(() => { });
        }

        await this.props.getOrderList(0).then(() => { });
        await this.props.getNewCourierList().then(() => { });
        await this.props.getPaketYoldaBusinessList(cookie.load("PaketYoldaId")).then(() => { });
    }

    openFullScreen = () => {
        this.setState({ fullScreen: true });
        this.props.setPaketYoldaPanelPageUpdate().then(() => { })
    };

    closeFullScreen = () => {
        this.setState({ fullScreen: false });
        this.props.setPaketYoldaPanelPageUpdate().then(() => { })
    };

    _handleGetRegionList = () => {
        const { paketYoldaRegionListData } = this.props;
        if (paketYoldaRegionListData != null) {
            this.props.setRegionListClear().then(() => { })
        } else {
            this.props.getRegionList().then(() => { })
        }
    };




    modalClose = () => {
        this.props.modalClosePaketYolda().then(() => { })
    };

    handleSoundControl = () => {
        this.props.setSoundControl(false).then(() => { })
        var list = [
            {
                ResponseData: "Ses testi yapılıyor. Lütfen uyarı sesini dikkate almayınız!",
                IsStatus: true
            }
        ]
        notificationShow(list, 3000);
        soundPlay("play")
        workerTimers.setTimeout(() => {
            soundPlay("stop")
        }, 2000);
        this.props.setPaketYoldaPanelPageUpdate().then(() => { })
    }

    renderSoundControl() {
        return (
            <div style={{ display: (this.props.IsSoundControl ? '' : 'none'), zIndex: 9999, position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.5)', bottom: 0, left: 0, right: 0, top: 0 }}>
                <div style={{ position: 'fixed', width: 400, backgroundColor: 'white', zIndex: 9999, padding: 20, borderRadius: 10, textAlign: 'center', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <Icon style={{ fontSize: 50, color: '#424242' }}>{"notifications_active"}</Icon>
                    <div style={{ display: 'grid' }}>
                        <span style={{ color: '#424242', fontWeight: 500, marginTop: 5 }}>{"Uygulamanın ses çıkardığından emin olun."}</span>
                    </div>
                    <div style={{ display: 'flex', marginTop: 20 }}>
                        <Button
                            onClick={this.handleSoundControl}
                            variant="contained"
                            fullWidth
                            style={{ textTransform: 'capitalize', backgroundColor: '#27ae60', color: 'white', borderRadius: 3, width: '100%', margin: 3 }}
                        >
                            {"Ses Testi Yap"}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    handleScroll = (event) => {
        this.setState({ scroollTop: (event.currentTarget.scrollTop > 300 ? true : false) });
        this.props.setPaketYoldaPanelPageUpdate().then(() => { })
    };

    returnSocketState(paketYoldaOrderHubConnection) {
        var state = { stateColor: "", stateText: "" };

        if (paketYoldaOrderHubConnection == null) {
            state.stateColor = "#e74c3c";
            state.stateText = "Bağlantı Yok (Sayfayı Yenile)";
        } else if (paketYoldaOrderHubConnection._connectionState == "Connected") {
            state.stateColor = "#16a085";
            state.stateText = "Bağlandı";
        } else if (paketYoldaOrderHubConnection._connectionState == "Connecting") {
            state.stateColor = "#1abc9c";
            state.stateText = "Bağlanıyor";
        } else if (paketYoldaOrderHubConnection._connectionState == "Disconnected") {
            state.stateColor = "#e74c3c";
            state.stateText = "Bağlı Değil (Sayfayı Yenile)";
        } else if (paketYoldaOrderHubConnection._connectionState == "Reconnecting") {
            state.stateColor = "#f39c12";
            state.stateText = "Yeniden Bağlanıyor";
        }

        return state;
    }

    containerScrollProduct = React.createRef();

    render() {
        const { paketYoldaOrderHubConnection, paketYoldaRegionListData, paketYoldaOrderStatus, classes, paketYoldaBusinessList, modalOpen, modalText, modalTitle, paketYoldaCourierTrack, paketYoldaOrderList, paketYoldaCourierListUpdate, paketYoldaOrderListUpdate } = this.props;
        const { fullScreen, show } = this.state;

        return (
            <div style={{ position: 'relative', backgroundColor: '#303030', overflow: 'hidden' }}>
                <audio autoplay="true" muted="true" loop="true" id="audio">
                    <source src={"https://cdn.javipos.com/paketyolda.mp3"} type="audio/mp3" />
                </audio>
                {modalOpen ? <ModalInformation event={this.modalClose} open={modalOpen} title={modalTitle} text={modalText} /> : null}
                <PaketYoldaCourierList />
                <PaketYoldaCourierAdd />
                <PaketYoldaUserList />
                <PaketYoldaBrandList />
                <PackageAdditionDetail classes={classes} />
                <ReactNotification />
                {this.renderSoundControl()}
                <Grid
                    container
                    direction={"row"}
                    alignItems="center"
                    spacing={1}
                    style={{ margin: 0, height: '100vh', maxWidth: '100%', zIndex: 99, padding: 0, borderRadius: 5, overflow: 'hidden' }}
                >
                    <Grid item md={12} style={{ height: (fullScreen ? '93%' : '40%'), position: 'relative' }}>
                        <div style={{ marginTop: 5, height: '100%', width: '100%', position: 'relative' }}>
                            <PaketYoldaMap fullScreen={fullScreen} PolygonJson={paketYoldaRegionListData} BrandList={paketYoldaBusinessList} IsUpdateOrder={paketYoldaOrderListUpdate} IsUpdateCourier={paketYoldaCourierListUpdate} courier={paketYoldaCourierTrack} order={paketYoldaOrderList.filter(x => x.OrderStatus == paketYoldaOrderStatus)} />
                            <span style={{ borderRadius: 3, zIndex: 999, right: 5, bottom: 5, padding: 3, fontSize: 13, position: 'absolute', backgroundColor: '#424242', color: 'white', fontWeight: 500, padding: 3 }}>
                                {"Listelenen Paket Sayısı: "} {paketYoldaOrderList.filter(x => x.OrderStatus == paketYoldaOrderStatus).length}
                            </span>
                            <span style={{ borderRadius: 3, zIndex: 999, right: 5, bottom: 36, padding: 3, fontSize: 13, position: 'absolute', backgroundColor: this.returnSocketState(paketYoldaOrderHubConnection).stateColor, color: 'white', fontWeight: 500, padding: 3 }}>
                                {"Soket Bağlantısı: "} {this.returnSocketState(paketYoldaOrderHubConnection).stateText}
                            </span>
                            {(getPaketYolda("IsPendingPayment") ?
                                <span style={{ borderRadius: 3, zIndex: 999, left: 50, top: 10, padding: 3, fontSize: 11, position: 'absolute', backgroundColor: '#424242', color: 'white', fontWeight: 500, padding: 3 }}>
                                    {"Son Ödeme Tarihi: "} {moment(getPaketYolda("FinalyPaymentDate")).format('DD.MM.YYYY HH:mm')} {"Sistem otomatik olarak kapatılacaktır!"}
                                </span> : null)}
                            <img style={{ zIndex: 999, position: 'absolute', left: 10, bottom: 10, height: 30 }} src={javikurye} />
                            <Button onClick={() => this.props.history.push('/paketyoldamainpage')} variant={"outlined"} style={{ right: 5, top: 5, position: 'absolute', minWidth: 50, maxWidth: 40, minHeight: 50, marginTop: 3, borderRadius: 5, backgroundColor: '#16a085', color: 'white', minHeight: 50 }}>
                                <Icon style={{ fontSize: 23 }}>{"menu"}</Icon>
                            </Button>
                            <Button onClick={this._handleGetRegionList} variant={"outlined"} style={{ right: 5, top: 60, position: 'absolute', minWidth: 50, maxWidth: 40, minHeight: 50, marginTop: 3, borderRadius: 5, backgroundColor: '#f7b731', color: 'white', minHeight: 50 }}>
                                <Icon style={{ fontSize: 23 }}>{"explore"}</Icon>
                            </Button>
                            <PaketYoldaCounter />
                            <Button onClick={(fullScreen ? this.closeFullScreen : this.openFullScreen)} variant={"outlined"} style={{ right: 60, top: 118, position: 'absolute', minWidth: 50, maxWidth: 40, minHeight: 50, borderRadius: 5, backgroundColor: '#7f8c8d', color: 'white', minHeight: 50 }}>
                                <Icon style={{ fontSize: 23 }}>{(fullScreen ? "close_fullscreen" : "open_in_full")}</Icon>
                            </Button>
                        </div>
                    </Grid>
                    <Grid item md={12} style={{ height: '60%', position: 'relative' }}>
                        <div style={{ width: '100%', display: 'block', overflow: 'hidden', height: '100%' }}>
                            <div style={{ zIndex: 999999, backgroundColor: '#313131', zIndex: 9999, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 5 }}>
                                <Button size="small" onClick={() => this.props.getOrderList(0).then(() => { })} color="primary" variant={"contained"} style={{ display: 'flex', justifyContent: 'space-between', height: 25, width: '100%', backgroundColor: '#2d98da', textTransform: 'capitalize', borderRadius: 0, marginRight: 10 }}>
                                    <Icon style={{ marginRight: 5, fontSize: 22, textAlign: 'center' }}>{"room_service"}</Icon>
                                    {"Bekleyen"}
                                    <Icon style={{ marginRight: 5, fontSize: 22, textAlign: 'center' }}>{(paketYoldaOrderStatus == 0 ? "check" : "")}</Icon>
                                </Button>
                                <Button size="small" onClick={() => this.props.getOrderList(1).then(() => { })} color="secondary" variant={"contained"} style={{ display: 'flex', justifyContent: 'space-between', height: 25, width: '100%', backgroundColor: '#218c74', textTransform: 'capitalize', borderRadius: 0, marginRight: 10 }}>
                                    <Icon style={{ marginRight: 5, fontSize: 22, textAlign: 'center' }}>{"remember_me"}</Icon>
                                    {"Atanan"}
                                    <Icon style={{ marginRight: 5, fontSize: 22, textAlign: 'center' }}>{(paketYoldaOrderStatus == 1 ? "check" : "")}</Icon>
                                </Button>
                                <Button size="small" onClick={() => this.props.getOrderList(4).then(() => { })} color="secondary" variant={"contained"} style={{ display: 'flex', justifyContent: 'space-between', height: 25, width: '100%', backgroundColor: '#f7b731', textTransform: 'capitalize', borderRadius: 0, marginRight: 10 }}>
                                    <Icon style={{ marginRight: 5, fontSize: 22, textAlign: 'center' }}>{"motorcycle"}</Icon>
                                    {"Yolda"}
                                    <Icon style={{ marginRight: 5, fontSize: 22, textAlign: 'center' }}>{(paketYoldaOrderStatus == 4 ? "check" : "")}</Icon>
                                </Button>
                                <Button size="small" onClick={() => this.props.getOrderList(3).then(() => { })} color="secondary" variant={"contained"} style={{ display: 'flex', justifyContent: 'space-between', height: 25, width: '100%', backgroundColor: '#e74c3c', textTransform: 'capitalize', borderRadius: 0, marginRight: 10 }}>
                                    <Icon style={{ marginRight: 5, fontSize: 22, textAlign: 'center' }}>{"cancel"}</Icon>
                                    {"İptal"}
                                    <Icon style={{ marginRight: 5, fontSize: 22, textAlign: 'center' }}>{(paketYoldaOrderStatus == 3 ? "check" : "")}</Icon>
                                </Button>
                                <Button size="small" onClick={() => this.props.setPaketYoldaCourierVisible(true).then(() => { })} color="default" variant={"contained"} style={{ display: 'flex', justifyContent: 'space-between', height: 25, width: '50%', color: 'white', backgroundColor: '#4b6584', textTransform: 'capitalize', borderRadius: 0 }}>
                                    <Icon style={{ marginRight: 5, fontSize: 22, textAlign: 'center' }}>{"sports_motorsports"}</Icon>
                                    {"Atama"}
                                    <Icon style={{ marginRight: 5, fontSize: 22, textAlign: 'center' }}>{""}</Icon>
                                </Button>
                            </div>
                            <PaketYoldaOrderList />
                        </div>
                    </Grid>
                </Grid >
            </div >
        );
    }
}

const valueSelector = (state, props) => state.get('paketYoldaReducer').IsPaketYoldaPanelUpdate;
const value = createSelector([valueSelector],
    (value) => value);

const mapStateToProps = (state, props) => ({
    IsPaketYoldaPanelUpdate: value(state, props),
    paketYoldaCourierHubConnection: state.get('paketYoldaReducer').paketYoldaCourierHubConnection,
    paketYoldaCourierTrack: state.get('paketYoldaReducer').paketYoldaCourierTrack,
    paketYoldaCourierListUpdate: state.get('paketYoldaReducer').paketYoldaCourierListUpdate,
    paketYoldaOrderHubConnection: state.get('paketYoldaReducer').paketYoldaOrderHubConnection,
    paketYoldaOrderList: state.get('paketYoldaReducer').paketYoldaOrderList,
    paketYoldaOrderListUpdate: state.get('paketYoldaReducer').paketYoldaOrderListUpdate,
    modalOpen: state.get('paketYoldaReducer').form_data.modalOpen,
    modalText: state.get('paketYoldaReducer').form_data.modalText,
    modalTitle: state.get('paketYoldaReducer').form_data.modalTitle,
    IsSoundControl: state.get('settingsReducer').IsSoundControl,
    paketYoldaBusinessList: state.get('paketYoldaReducer').paketYoldaBusinessList,
    paketYoldaRegionListData: state.get('paketYoldaReducer').paketYoldaRegionListData,

    paketYoldaOrderStatus: state.get('paketYoldaReducer').paketYoldaOrderStatus,
})

const mapDispatchToProps = {
    setPaketYoldaCourierHubConnection,
    setPaketYoldaOrderHubConnection,
    getOrderList,
    setPaketYoldaCourierVisible,
    modalClosePaketYolda,
    setPaketYoldaPanelPageUpdate,
    getPaketYoldaBusinessList,
    getNewCourierList,
    setPaketYoldaCourierAddVisible,
    setSoundControl,
    setPaketYoldaBrandListVisible,
    getRegionList,
    setRegionListClear,
    setPaketYoldaOrderUpdate
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(PaketYoldaPanelPage)));
