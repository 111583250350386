import React from "react";
import { createSelector } from "reselect";
import cookie from "react-cookies";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { CounterTrading } from "dan-components";
import logo from "dan-images/logo.png";
import colorfull from "dan-api/palette/colorfull";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import messageStyles from "dan-styles/Messages.scss";
import Popover from "@material-ui/core/Popover";
import { EmptyData } from "dan-components";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TablePagination from "@material-ui/core/TablePagination";
import {
  setPaketYoldaLogReportFilterData,
  getPaketYoldaBrandList,
  getPaketYoldaCourierList,
  getPaketYoldaBusinessList,
  getNewCourierList,
  getOrderGeneralReport,
  getLogReportList,
} from "../../../../redux/modules/paketyolda";
import { getPackageAdditionDetail } from "../../../../redux/modules/addition";
import { getPaketYolda } from "../../../../function/GeneralFunction";
import CircularProgress from "@material-ui/core/CircularProgress";
import DatePicker, { registerLocale } from "react-datepicker";
import "../datepicker.css";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TooltipMui from "@material-ui/core/Tooltip";
import TbPaginationActions from "../../Ortak/TbPaginationActions";
import MapClusterReport from "../../SettingPage/Report/SubReportPage/Maps/MapClusterReport";
import PackageAdditionDetail from "../../PackagePage/PackageAdditionDetail";
import {
  generateLogPdfReport,
  generateLogExcelReport,
} from "./services/exportServices";

const styles = (theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

class LogReport extends React.Component {
  constructor() {
    super();
    this.state = {
      filterActive: true,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.paketYoldaLogReportUpdate ==
      this.props.paketYoldaLogReportUpdate
    ) {
      return false;
    }
    return true;
  }

  async componentDidMount() {}

  handleChangeFilterReport = (name) => (event) => {
    const { paketYoldaLogReportfilter } = this.props;
    paketYoldaLogReportfilter[name] =
      name == "StartDatetime" || name == "EndDatetime"
        ? moment
            .utc(event, "MM-DD-YYYY")
            .local()
            .format("YYYY-MM-DDTHH:mm")
        : event.target.value;
    this.props
      .setPaketYoldaLogReportFilterData(paketYoldaLogReportfilter)
      .then(() => {});
  };

  handleClickPopover = (event) => {
    this.props.getPackageAdditionDetail(
      event.currentTarget.id,
      event.currentTarget
    );
  };

  async handleFilterGeneral() {
    this.setState({ filterActive: false });
    const { paketYoldaLogReportfilter } = this.props;
    await this.props.getLogReportList(paketYoldaLogReportfilter).then(() => {});
  }

  handlePdfExport = () => {
    const { logReportList } = this.props;
    if (logReportList) {
      generateLogPdfReport(logReportList);
    }
  };

  handleExcelExport = () => {
    const { logReportList } = this.props;
    if (logReportList) {
      generateLogExcelReport(logReportList);
    }
  };

  render() {
    const {
      logReportList,
      paketYoldaLogReportfilter,
      paketYoldaNewReportLoading,
      paketYoldaReportMenu,
      classes,
    } = this.props;

    if (paketYoldaReportMenu != "LogReport") {
      return null;
    }

    if (paketYoldaNewReportLoading) {
      return (
        <div style={{ width: "100%", textAlignLast: "center", paddingTop: 20 }}>
          <CircularProgress
            style={{ color: "#fafafa" }}
            thickness={4}
            className={classes.progress}
          />
        </div>
      );
    }

    return (
      <Paper
        className={classes.root}
        elevation={0}
        style={{
          backgroundColor: "transparent",
          borderRadius: 0,
          height: "100%",
        }}
      >
        <PackageAdditionDetail classes={classes} />
        <div
          style={{ height: "calc(100% - 43px)", padding: 5, overflowY: "auto" }}
        >
          <Grid
            container
            spacing={1}
            style={{
              borderBottom: "1px solid #424242",
              padding: 5,
              borderRadius: 0,
              marginBottom: 5,
            }}
          >
            <Grid item md={5}>
              <Typography
                style={{
                  marginBottom: 6,
                  padding: 0,
                  fontSize: 12,
                  fontFamily: "Quicksand,sans-serif",
                  lineHeight: 1,
                }}
                className={classes.flex}
              >
                {"Başlangıç Tarihi"}
              </Typography>
              <DatePicker
                selected={new Date(paketYoldaLogReportfilter.StartDatetime)}
                onChange={this.handleChangeFilterReport("StartDatetime")}
                dateFormat="dd.MM.yyyy HH:mm"
                showTimeSelect
                timeIntervals={15}
                timeFormat="HH:mm"
                locale="tr"
                className={"inputDate"}
              />
            </Grid>
            <Grid item md={4}>
              <Typography
                style={{
                  marginBottom: 6,
                  padding: 0,
                  fontSize: 12,
                  fontFamily: "Quicksand,sans-serif",
                  lineHeight: 1,
                }}
                className={classes.flex}
              >
                {"Bitiş Tarihi"}
              </Typography>
              <DatePicker
                selected={new Date(paketYoldaLogReportfilter.EndDatetime)}
                onChange={this.handleChangeFilterReport("EndDatetime")}
                dateFormat="dd.MM.yyyy HH:mm"
                showTimeSelect
                timeIntervals={15}
                timeFormat="HH:mm"
                locale="tr"
                className={"inputDate"}
              />
            </Grid>
            <Grid
              item
              md={3}
              style={{
                display: "flex",
                alignItems: "flex-end",
                gap: "8px",
              }}
            >
              <Button
                fullWidth
                disabled={paketYoldaNewReportLoading}
                onClick={() => this.handleFilterGeneral()}
                style={{
                  borderRadius: 5,
                  height: 37,
                  padding: "6px 24px",
                  marginTop: 17,
                }}
                variant="contained"
                color="secondary"
                type="submit"
              >
                {"FİLTRELE"}
              </Button>
              <Button
                onClick={this.handleExcelExport}
                disabled={this.state.filterActive}
                style={{
                  minWidth: "40px",
                  padding: "0px 25px",
                  height: 37,
                  backgroundColor: "#27ae60",
                  borderRadius: 4,
                  color: "#fafafa",
                  marginTop: 17,
                }}
                variant="contained"
              >
                <Icon style={{ fontSize: 20 }}>description</Icon>
                <span style={{ marginLeft: 4, fontSize: 13 }}>Excel</span>
              </Button>

              <Button
                onClick={this.handlePdfExport}
                disabled={this.state.filterActive}
                style={{
                  minWidth: "40px",
                  padding: "0px 25px",
                  height: 37,
                  backgroundColor: "#e74c3c",
                  borderRadius: 4,
                  color: "#fafafa",
                  marginTop: 17,
                }}
                variant="contained"
              >
                <Icon style={{ fontSize: 20 }}>picture_as_pdf</Icon>
                <span style={{ marginLeft: 4, fontSize: 13 }}>PDF</span>
              </Button>
            </Grid>
          </Grid>
          <div
            style={{
              backgroundColor: "#313131",
              marginTop: 10,
              marginBottom: 10,
              width: "100%",
              padding: 10,
              borderBottom: "1px solid rgba(209, 216, 224,.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {"İşlem Log Kayıtları"}
          </div>
          <Table style={{ marginTop: 5, overflow: "hidden" }}>
            <TableHead style={{ backgroundColor: "#212121" }}>
              <TableRow>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="left"
                >
                  {"Tarih"}
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="left"
                >
                  {"İşlem Detayı"}
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="left"
                >
                  {"Kullanıcı"}
                </TableCell>
                <TableCell
                  style={{ color: "white", fontSize: 13 }}
                  align="right"
                >
                  {"İşlem"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logReportList != null
                ? logReportList.Results.map((n, i) => {
                    return (
                      <TableRow hover key={i}>
                        <TableCell align="left" style={{ padding: 5 }}>
                          {moment(n.CreationTime).format("DD.MM.YYYY HH:mm:ss")}
                        </TableCell>
                        <TableCell align="left" style={{ padding: 5 }}>
                          {n.LogText}
                        </TableCell>
                        <TableCell align="left" style={{ padding: 5 }}>
                          {n.Username}
                        </TableCell>
                        <TableCell align="right" style={{ padding: 5 }}>
                          <TooltipMui title={"Adisyon Detayı Görüntüle"}>
                            <IconButton
                              style={{
                                padding: 4,
                                backgroundColor: "#3e3e3e",
                                marginRight: 5,
                              }}
                              aria-label="Adisyon Detayı Görüntüle"
                              id={n.OrderId}
                              onClick={this.handleClickPopover}
                            >
                              <Icon>{"receipt_long"}</Icon>
                            </IconButton>
                          </TooltipMui>
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
          {logReportList != null ? (
            <div style={{ width: "100%", display: "table" }}>
              <TablePagination
                rowsPerPageOptions={[25]}
                rowsPerPage={logReportList.PageSize}
                count={logReportList.RowCount}
                page={logReportList.CurrentPage - 1}
                onChangePage={this.handleChangePage}
                ActionsComponent={TbPaginationActions}
              />
            </div>
          ) : null}
        </div>
      </Paper>
    );
  }
}

const valueSelector = (state, props) =>
  state.get("paketYoldaReducer").paketYoldaLogReportUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
  paketYoldaLogReportUpdate: value(state, props),
  paketYoldaReportMenu: state.get("paketYoldaReducer").paketYoldaReportMenu,
  paketYoldaNewReportLoading: state.get("paketYoldaReducer")
    .paketYoldaNewReportLoading,
  paketYoldaLogReportfilter: state.get("paketYoldaReducer")
    .paketYoldaLogReportfilter,

  logReportList: state.get("paketYoldaReducer").logReportList,
});

const mapDispatchToProps = {
  setPaketYoldaLogReportFilterData,
  getPaketYoldaBrandList,
  getPaketYoldaCourierList,
  getPaketYoldaBusinessList,
  getNewCourierList,
  getOrderGeneralReport,
  getPackageAdditionDetail,
  getLogReportList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(LogReport)));
