import React from 'react';
import {
    createSelector
} from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { CounterTrading } from 'dan-components';
import logo from 'dan-images/logo.png';
import colorfull from 'dan-api/palette/colorfull';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import messageStyles from 'dan-styles/Messages.scss';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import { EmptyData } from 'dan-components';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TablePagination from '@material-ui/core/TablePagination';
import {
    setPaketYoldaGeneralSettingsData, setSettings
} from '../../../../redux/modules/paketyolda';
import { getPaketYolda } from '../../../../function/GeneralFunction';
import CircularProgress from '@material-ui/core/CircularProgress';
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);
import DatePicker, { registerLocale } from "react-datepicker";
import { setRawCookie } from 'react-cookies';
import cookie from 'react-cookies';

const styles = theme => ({
    nested: {
        paddingLeft: theme.spacing(4),
    }
});

class IsyerimPosKurye extends React.Component {
    constructor() {
        super();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.paketYoldaGeneralSettingsUpdate == this.props.paketYoldaGeneralSettingsUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {

    }

    handleChangePaketYoldaGeneral = (Name) => (event) => {
        const { paketYoldaGeneralSettingsData } = this.props;
        paketYoldaGeneralSettingsData[Name] = event.target.value;
        this.props.setPaketYoldaGeneralSettingsData(paketYoldaGeneralSettingsData).then(() => { })
    };

    render() {
        const { paketYoldaGeneralSettingsData, paketYoldaGeneralSettingsLoading, paketYoldaSettingsMenu, classes } = this.props;

        if (paketYoldaSettingsMenu != "JaviKuryeIsyerimPos") {
            return null;
        }

        if (paketYoldaGeneralSettingsLoading) {
            return <div style={{ width: '100%', textAlignLast: 'center', paddingTop: 20 }}>
                <CircularProgress
                    style={{ color: '#fafafa' }}
                    thickness={4}
                    className={classes.progress}
                />
            </div>
        }

        return (
            <Paper className={classes.root} elevation={0} style={{ backgroundColor: 'transparent', borderRadius: 0, height: '100%' }}>

                <div style={{ height: 'calc(100% - 43px)', padding: 5, overflowY: 'auto' }}>
                    <div style={{ backgroundColor: 'rgb(49, 49, 49)', padding: 5 }}>
                        <div style={{ marginBottom: 10, width: '100%', padding: 10, borderBottom: '1px solid rgba(209, 216, 224,.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {"Entegrasyon Ayarları"}
                        </div>
                        <div style={{ fontSize: 15, display: 'grid', textAlign: 'center', alignItems: 'center', backgroundColor: '#d1d8e0', color: '#424242', fontWeight: 600 }}>
                            <div style={{ width: '100%', fontSize: 14 }}>{"İşyerim CepPos ile kuryelerinizin tahsilat süreçlerini kolaylaştırın. Düşük komisyon oranları ve cihaz bağımsız çalışma avantajı ile hızlı ve kolay şekilde ödeme alın!"}</div>
                        </div>
                        <div style={{ display: 'flex', padding: 10, borderBottom: '1px solid rgb(99, 99, 99)' }}>
                            <div style={{ fontSize:12, textAlign: 'left', width: '60%', alignContent:'center' }}>{"Api Key"}</div>
                            <div style={{ fontSize: 12, textAlign: 'right', width: '40%' }}>
                                <Input
                                    type="text"
                                    id="standard-adornment-weight"
                                    onFocus={event => { event.target.select() }}
                                    value={paketYoldaGeneralSettingsData.ApiKey}
                                    onChange={this.handleChangePaketYoldaGeneral("ApiKey")}
                                    aria-describedby="standard-weight-helper-text"
                                    fullWidth
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', padding: 10, borderBottom: '1px solid rgb(99, 99, 99)' }}>
                        <div style={{ fontSize:12, textAlign: 'left', width: '60%', alignContent:'center' }}>{"Merchant Id"}</div>
                            <div style={{ fontSize: 12, textAlign: 'right', width: '40%' }}>
                                <Input
                                    type="text"
                                    id="standard-adornment-weight"
                                    onFocus={event => { event.target.select() }}
                                    value={paketYoldaGeneralSettingsData.MerchantId}
                                    onChange={this.handleChangePaketYoldaGeneral("MerchantId")}
                                    aria-describedby="standard-weight-helper-text"
                                    fullWidth
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', padding: 10, borderBottom: '1px solid rgb(99, 99, 99)' }}>
                        <div style={{ fontSize:12, textAlign: 'left', width: '60%', alignContent:'center' }}>{"Kullanıcı Id"}</div>
                            <div style={{ fontSize: 12, textAlign: 'right', width: '40%' }}>
                                <Input
                                    type="text"
                                    id="standard-adornment-weight"
                                    onFocus={event => { event.target.select() }}
                                    value={paketYoldaGeneralSettingsData.UserId}
                                    onChange={this.handleChangePaketYoldaGeneral("UserId")}
                                    aria-describedby="standard-weight-helper-text"
                                    fullWidth
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ backgroundColor: 'rgb(66, 66, 66)', width: '100%', padding: 5, textAlign: 'right' }}>
                    <Button onClick={() => this.props.setSettings().then(() => { })} variant="contained" color="secondary">
                        {"Kaydet"}
                    </Button>
                </div>
            </Paper>
        )
    }
}

const valueSelector = (state, props) => state.get('paketYoldaReducer').paketYoldaGeneralSettingsUpdate;
const value = createSelector([valueSelector],
    (value) => value);


const mapStateToProps = (state, props) => ({
    paketYoldaGeneralSettingsUpdate: value(state, props),
    paketYoldaSettingsMenu: state.get('paketYoldaReducer').paketYoldaSettingsMenu,
    paketYoldaGeneralSettingsLoading: state.get('paketYoldaReducer').paketYoldaGeneralSettingsLoading,
    paketYoldaGeneralSettingsData: state.get('paketYoldaReducer').paketYoldaGeneralSettingsData
})

const mapDispatchToProps = {
    setPaketYoldaGeneralSettingsData,
    setSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(IsyerimPosKurye)));
