import * as XLSX from "xlsx";
import { trMoneyConvert } from "../../../../../../function/GeneralFunction";
import moment from "moment";

// Excel raporları için özel veri formatlama fonksiyonu
export const formatExcelReportData = (rawData) => {
  const businessKmPrice =
    Number(rawData.businessSettings?.businessKmPrice) || 0;

  return {
    ...rawData,
    P_MileageChargeRestSum: Number(rawData.P_MileageChargeRestSum) || 0,
    businessSettings: {
      ...rawData.businessSettings,
      businessKmPrice: businessKmPrice,
    },
    courierData: (rawData.CourierData || []).map((courier) => {
      // Kurye için gerekli değerleri hazırla
      const distance = Number(courier.SumPacketDistance) || 0;

      return {
        ...courier,
        CourierName: courier.CourierName || "Bilinmeyen Kurye",
        OrderCount: Number(courier.OrderCount) || 0,
        TotalPackageAmount: Number(courier.TotalPackageAmount) || 0,
        AvgPackageTotal: Number(courier.AvgPackageTotal) || 0,
        SumPacketDistance: distance,
        AvgPacketDistance: Number(courier.AvgPacketDistance) || 0,
        AvgPackageDelivery: Number(courier.AvgPackageDelivery) || 0,
        P_MileageChargeCourierSum:
          Number(courier.P_MileageChargeCourierSum) || 0,
      };
    }),
  };
};

const createExcelReport = (data, reportType) => {
  let excelData = [];
  let sheetName = "";
  let columns = [];

  if (reportType === "courier") {
    sheetName = "Kurye Raporu";
    columns = [
      { key: "Kurye Adı", width: 20 },
      { key: "Çalışılan Süre", width: 20 },
      { key: "Mola Süresi", width: 20 },
    ];

    excelData = data.Results.map((item) => ({
      "Kurye Adı": item.CourierName,
      "Çalışılan Süre": item.WorkingTime,
      "Mola Süresi": item.BreakTime,
    }));
  } else if (reportType === "log") {
    sheetName = "Kurye Aktivite Raporu";
    columns = [
      { key: "Başlangıç Tarihi", width: 25 },
      { key: "Bitiş Tarihi", width: 25 },
      { key: "Süre", width: 20 },
      { key: "Kurye", width: 30 },
      { key: "İşlem", width: 20 },
    ];

    const calculateDuration = (startDate, endDate) => {
      if (!startDate || !endDate) return "-";

      const start = moment(startDate);
      const end = moment(endDate);
      const duration = moment.duration(end.diff(start));

      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      return `${hours}s ${minutes}dk ${seconds}sn`;
    };

    excelData = data.Results.length
      ? data.Results.map((item) => ({
          "Başlangıç Tarihi": moment(item.StartDate).format(
            "DD.MM.YYYY HH:mm:ss"
          ),
          "Bitiş Tarihi": item.EndDate
            ? moment(item.EndDate).format("DD.MM.YYYY HH:mm:ss")
            : "-",
          Süre: calculateDuration(item.StartDate, item.EndDate),
          Kurye: item.Username || "",
          İşlem: item.Operation || "",
        }))
      : [
          {
            "Başlangıç Tarihi": "Veri bulunamadı",
            "Bitiş Tarihi": "",
            Süre: "",
            Kurye: "",
            İşlem: "",
          },
        ];
  } else if (reportType === "work") {
    sheetName = "Hesap Raporu";
    columns = [
      { key: "Kurye Adı", width: 30 },
      { key: "Paket Sayısı", width: 15 },
      { key: "Top. Paket Tutarı", width: 20 },
      { key: "Ort. Paket Tutarı", width: 20 },
      { key: "Top. Paket Mesafesi", width: 20 },
      { key: "Ort. Paket Mesafesi", width: 20 },
      { key: "Ort. Teslim Süresi", width: 20 },
      { key: "Kurye Km Hakediş", width: 20 },
      { key: "İşletme Km Maliyet", width: 20 },
    ];

    // Toplam kurye sayısını hesapla
    const totalCourierCount = data.courierData.length;
    // Toplam işletme km maliyetini al
    const totalMileageChargeRest = Number(data.P_MileageChargeRestSum) || 0;
    // Kurye başına düşen maliyeti hesapla
    const distributedMileageCharge =
      totalCourierCount > 0 ? totalMileageChargeRest / totalCourierCount : 0;

    const courierRows = data.courierData.map((item) => {
      const hakedis = Number(item.P_MileageChargeCourierSum) || 0;
      const mileageChargeRest =
        Number(item.P_MileageChargeRestSum) ||
        Number(item.MileageChargeRest) ||
        distributedMileageCharge ||
        0;

      return {
        "Kurye Adı": item.CourierName || "Bilinmeyen Kurye",
        "Paket Sayısı": (item.OrderCount || 0).toString(),
        "Top. Paket Tutarı": `${trMoneyConvert(
          item.TotalPackageAmount || 0,
          3
        )} TL`,
        "Ort. Paket Tutarı": `${trMoneyConvert(
          item.AvgPackageTotal || 0,
          3
        )} TL`,
        "Top. Paket Mesafesi": `${(item.SumPacketDistance || 0).toFixed(3)} Km`,
        "Ort. Paket Mesafesi": `${(item.AvgPacketDistance || 0).toFixed(3)} Km`,
        "Ort. Teslim Süresi": `${(item.AvgPackageDelivery || 0).toFixed(2)} Dk`,
        "Kurye Km Hakediş": `${trMoneyConvert(hakedis, 2)} TL`,
        "İşletme Km Maliyet": `${trMoneyConvert(mileageChargeRest, 2)} TL`,
      };
    });

    // Toplam satırı için değerleri hesapla
    const totals = data.courierData.reduce(
      (acc, item) => ({
        orderCount: acc.orderCount + (Number(item.OrderCount) || 0),
        totalAmount: acc.totalAmount + (Number(item.TotalPackageAmount) || 0),
        totalDistance:
          acc.totalDistance + (Number(item.SumPacketDistance) || 0),
        totalHakedis:
          acc.totalHakedis + (Number(item.P_MileageChargeCourierSum) || 0),
      }),
      { orderCount: 0, totalAmount: 0, totalDistance: 0, totalHakedis: 0 }
    );

    // Toplam satırı
    const totalRow = {
      "Kurye Adı": "Toplam",
      "Paket Sayısı": totals.orderCount.toString(),
      "Top. Paket Tutarı": `${trMoneyConvert(totals.totalAmount, 3)} TL`,
      "Ort. Paket Tutarı": "",
      "Top. Paket Mesafesi": `${totals.totalDistance.toFixed(3)} Km`,
      "Ort. Paket Mesafesi": "",
      "Ort. Teslim Süresi": "",
      "Kurye Km Hakediş": `${trMoneyConvert(totals.totalHakedis, 2)} TL`,
      "İşletme Km Maliyet": `${trMoneyConvert(totalMileageChargeRest, 2)} TL`,
    };

    excelData = [...courierRows, totalRow];
  }

  // Veri yoksa varsayılan değerler
  if (!excelData || excelData.length === 0) {
    if (reportType === "work") {
      excelData = [
        {
          "Kurye Adı": "Veri bulunamadı",
          "Paket Sayısı": "0",
          "Top. Paket Tutarı": "0,00 TL",
          "Ort. Paket Tutarı": "0,00 TL",
          "Top. Paket Mesafesi": "0,000 Km",
          "Ort. Paket Mesafesi": "0,000 Km",
          "Ort. Teslim Süresi": "0,00 Dk",
          "Kurye Km Hakediş": "0,00 TL",
          "İşletme Km Maliyet": "0,00 TL",
        },
      ];
    } else if (reportType === "log") {
      excelData = [
        {
          "Başlangıç Tarihi": "Veri bulunamadı",
          "Bitiş Tarihi": "",
          Süre: "",
          Kurye: "",
          İşlem: "",
        },
      ];
    } else {
      excelData = [
        {
          "Kurye Adı": "Veri bulunamadı",
          "Çalışılan Süre": "0s 0dk 0sn",
          "Mola Süresi": "0s 0dk 0sn",
        },
      ];
    }
  }

  const worksheet = XLSX.utils.json_to_sheet(excelData, { bookType: "xlsx" });
  worksheet["!cols"] = columns.map((col) => ({ wch: col.width }));

  // Başlık stilini ayarla
  const headerStyle = {
    font: { bold: true, name: "Arial" },
    fill: { fgColor: { rgb: "CCCCCC" } },
    alignment: { horizontal: "center" },
  };

  // Başlık stilini uygula
  if (excelData[0]) {
    Object.keys(excelData[0]).forEach((_, index) => {
      const cellRef = XLSX.utils.encode_cell({ r: 0, c: index });
      if (!worksheet[cellRef].s) worksheet[cellRef].s = {};
      worksheet[cellRef].s = headerStyle;
    });
  }

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

  workbook.Workbook = {
    Views: [{ RTL: false }],
  };

  return workbook;
};

export const createExcelTemplate = (data) => createExcelReport(data, "courier");

export const createExcelWorkTemplate = (data) =>
  createExcelReport(data, "work");

export const createLogExcelReport = (data) => createExcelReport(data, "log");
export const createCourierExcelTemplate = (data) =>
  createExcelReport(data, "courier");
