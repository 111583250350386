import axios from 'axios';
var base64 = require('base-64');
const uuidv4 = require('uuid/v4');
import cookie from 'react-cookies';
import brand from 'dan-api/dummy/brand';
import {
  setModal,
} from './settings';
import {
  setReportPageName
} from './report';
import { MakeRandomKey, getCenterDate } from '../../function/GeneralFunction';
import moment from 'moment';

export const CENTERBRAND_START = 'centerbrand_start';
export const CENTERBRAND_STOP = 'centerbrand_stop';
export const CENTERBRAND_ERROR = 'centerbrand_error';
export const CENTER_GLOBAL_FORM_DATA_SET = 'center_global_form_data_set';
export const CENTERBRAND_MODALCLOSE = 'centerbrand_modalclose';

export const CENTER_SETTING_VISIBLE = 'center_setting_visible';
export const CENTER_SETTING_DATA = 'center_setting_data';

export const CENTER_BRAND_VISIBLE = 'center_brand_visible';
export const CENTER_BRAND_DATA = 'center_brand_data';

export const SET_CENTER_BRAND_ADD_VISIBLE = 'set_center_brand_add_visible';
export const SET_CENTER_BRAND_ADD_LOADING = 'set_center_brand_add_loading';
export const SET_CENTER_BRAND_ADD_DATA = 'set_center_brand_add_data';

export const CENTER_USER_VISIBLE = 'center_user_visible';
export const CENTER_USER_DATA = 'center_user_data';

export const SET_CENTER_USER_ADD_VISIBLE = 'set_center_user_add_visible';
export const SET_CENTER_USER_ADD_LOADING = 'set_center_user_add_loading';
export const SET_CENTER_USER_ADD_DATA = 'set_center_user_add_data';

export const CENTER_REGION_LIST_VISIBLE = 'center_region_list_visible';
export const CENTER_REGION_LIST_UPDATE = 'center_region_list_update';
export const CENTER_REGION_LIST_DATA = 'center_region_list_data';

export const CENTER_AUTHORITY_LIST_VISIBLE = 'center_authority_list_visible';
export const CENTER_AUTHORITY_LIST_DATA = 'center_authority_list_data';

export const CENTER_PRODUCT_LIST_VISIBLE = 'center_product_list_visible';
export const CENTER_PRODUCT_LIST_DATA = 'center_product_list_data';

export const FRANCHISE_ORDER_LIST_VISIBLE = 'franchise_order_list_visible';
export const FRANCHISE_ORDER_LIST_DATA = 'franchise_order_list_data';

export const FRANCHISE_ORDER_ADD_LIST_VISIBLE = 'franchise_order_add_list_visible';
export const FRANCHISE_ORDER_ADD_LIST_CLEAR = 'franchise_order_add_list_clear';
export const FRANCHISE_ORDER_ADD_LIST_DATA = 'franchise_order_add_list_data';
export const FRANCHISE_ORDER_ADD_LIST_DATAS = 'franchise_order_add_list_datas';

export const FRANCHISE_MAIN_REPORT_DATA = 'franchise_main_report_data';

export const FRANCHISE_REPORT_VISIBLE = 'franchise_report_visible';
export const FRANCHISE_REPORT_NAME = 'franchise_report_name';
export const FRANCHISE_REPORT_DATA = 'franchise_report_data';
export const FRANCHISE_REPORT_UPDATE = 'franchise_report_update';
export const FRANCHISE_REPORT_LOADING = 'franchise_report_loading';

export const FRANCHISE_REPORT_BRAND_SELECTED = 'franchise_report_brand_selected';

export const FRANCHISE_REPORT_ORDERHEAT_DATA = 'franchise_report_orderheat_data';
export const FRANCHISE_REPORT_ORDERHEAT_FILTER = 'franchise_report_orderheat_filter';

export const FRANCHISE_REPORT_GRAFFIC_FILTER = 'franchise_report_graffic_filter';

export const FRANCHISE_REPORT_GENERALTOTAL_DATA = 'franchise_report_generaltotal_data';

export const FRANCHISE_REPORT_PRODUCTLIST_FILTER = 'franchise_report_productlist_filter';
export const FRANCHISE_REPORT_PRODUCTLIST_DATA = 'franchise_report_productlist_data';

export const FRANCHISE_REPORT_ENTEGRATION_DATA = 'franchise_report_entegration_data';
export const FRANCHISE_REPORT_ADDITIONLIST_UPDATE = 'franchise_report_additionlist_update';

export const FRANCHISE_REPORT_LOGS_DATA = 'franchise_report_logs_data';
export const FRANCHISE_REPORT_LOGS_FILTER = 'franchise_report_logs_filter';

export const FRANCHISE_REPORT_ENTEGRATION_LIST = 'franchise_report_entegration_list';


export const FRANCHISE_REPORT_REVIEWS_DATA = 'franchise_report_reviews_data';
export const FRANCHISE_REPORT_REVIEWS_UPDATE = 'franchise_report_reviews_update';
export const FRANCHISE_REPORT_REVIEWS_FILTER = 'franchise_report_reviews_filter';

export const FRANCHISE_JAVI_SADAKAT_VISIBLE = 'franchise_javi_sadakat_visible';
export const FRANCHISE_JAVI_SADAKAT_DATA = 'franchise_javi_sadakat_data';
export const FRANCHISE_JAVI_SADAKAT_LOADING = 'franchise_javi_sadakat_loading';

export const SET_CAMPAIGN_UPDATE = 'set_campaign_update';
export const SET_CAMPAIGN_LIST_DATA = 'set_campaign_list_data';

export const FRANCHISE_JAVI_REGION_VISIBLE = 'franchise_javi_region_visible';
export const FRANCHISE_JAVI_REGION_UPDATE = 'franchise_javi_region_update';
export const FRANCHISE_JAVI_REGION_LOADING = "franchise_javi_region_loading";
export const FRANCHISE_JAVI_REGION_DATA = "franchise_javi_region_loading";

export const FRANCHISE_JAVI_REGION_SEARCH_DATA = "franchise_javi_region_search_data";
export const FRANCHISE_JAVI_REGION_SEARCH_TEXT = "franchise_javi_region_search_text";

export const SET_NOTIFICATION_DATA = "set_notification_data";

let initialState = {
  form_data: {
    modalOpen: false,
    modalTitle: "",
    modalText: "",
    userAddModal: false,
    userAddModalTitle: "",
    brandListModal: false,
    brandListModalTitle: "",
    BrandList: [],
    LimitList: [],
    BrandId: "",
    BrandText: "",
    IlList: [],
    IlceList: [],
    MahalleList: [],
    SokakList: [],
    IlId: "",
    IlText: "",
    IlceId: "",
    IlceText: "",
    MahalleId: "",
    MahalleText: "",
    Limit: 0,
    CenterProductList: [],
    IsBrandAddModalVisible: false,
    BrandAddData: {
      BrandTitleName: "",
      BrandEmail: "",
      BrandName: "",
      BrandSurname: "",
      BrandPhone: "",
      BrandPassword: "",
      TenantId: "",
      CenterBrandId: "",
      Language: "",
      BusinessType: -1,
      IsPackage: false
    },
    BrandAddButtonVisible: false
  },

  centerSettingVisible: false,
  centerSettingData: {
    CenterBrandId: "",
    ReportStartDate: new Date(),
    ReportEndDate: new Date(),
    IsFranchise: false
  },
  centerSettingUpdate: false,

  centerBrandVisible: false,
  centerBrandData: [],
  centerBrandUpdate: false,

  centerBrandAddVisible: false,
  centerBrandAddUpdate: false,
  centerBrandAddLoading: false,
  centerBrandAddData: {
    Id: "00000000-0000-0000-0000-000000000000",
    CenterBrandId: cookie.load('CenterBrandId'),
    TenantId: cookie.load('TenantId'),
    Name: "",
    Latitude: "",
    Longitude: "",
    MobilePhone: "",
    Email: "",
    CreationDatetime: "",
    IsStatus: true,
    Language: "tr",
    Password: "",
    TurnoverShare: 0
  },

  centerUserVisible: false,
  centerUserData: [],
  centerUserUpdate: false,

  centerUserAddVisible: false,
  centerUserAddUpdate: false,
  centerUserAddLoading: false,
  centerUserAddData: {
    Id: "00000000-0000-0000-0000-000000000000",
    CenterBrandId: cookie.load('CenterBrandId'),
    TenantId: cookie.load('TenantId'),
    Name: "",
    Username: "",
    Password: "",
    Phone: "",
    IsStatus: true,
    UserType: 6
  },

  centerRegionVisible: false,
  centerRegionData: [],
  centerRegionUpdate: false,

  centerAuthorityVisible: false,
  centerAuthorityUpdate: false,
  centerAuthorityData: null,

  centerProductListVisible: false,
  centerProductListUpdate: false,
  centerProductListData: [],

  franchiseOrderListVisible: false,
  franchiseOrderListUpdate: false,
  franchiseOrderListData: [],

  franchiseOrderAddVisible: false,
  franchiseOrderAddUpdate: false,
  franchiseOrderAddData: {
    Id: "00000000-0000-0000-0000-000000000000",
    CreationDatetime: new Date(),
    StockId: "00000000-0000-0000-0000-000000000000",
    UnitId: "00000000-0000-0000-0000-000000000000",
    Quantity: 1,
    OrderNo: MakeRandomKey(8),
    IsDelete: false,
    FranchiseOrderType: 0,
    TenantId: "00000000-0000-0000-0000-000000000000",
    BrandId: "00000000-0000-0000-0000-000000000000",
    CenterBrandId: "00000000-0000-0000-0000-000000000000"
  },
  franchiseOrderAddDataList: [],
  franchiseMainReportData: [],

  franchiseReportVisible: false,
  franchiseReportUpdate: false,
  franchiseReportName: "",
  franchiseReportLoading: false,
  franchiseReportData: [],
  franchiseReportBrandSelected: "00000000-0000-0000-0000-000000000000",

  filterOrderHeat: {
    BrandId: "00000000-0000-0000-0000-000000000000",
    StartDatetime: "",
    EndDatetime: "",
    TableType: "All",
    EntegrationId: ""
  },
  isOrderHeatUpdate: false,
  isCenterReportLoading: false,
  franchiseReportOrderHeatData: [],
  franchiseReportReviewsData: [],
  franchiseReportGrafficFilter: {
    BrandId: "00000000-0000-0000-0000-000000000000",
    TenantId: "00000000-0000-0000-0000-000000000000",
    Datetime: new Date(),
    GrafficType: 1,
    IsAll: false
  },
  franchiseReportGrafficUpdate: false,

  franchiseReportGeneralTotalUpdate: false,
  franchiseReportGeneralTotalData: [],

  filterReportProductList: {
    BrandId: "00000000-0000-0000-0000-000000000000",
    CenterBrandId: cookie.load("CenterBrandId"),
    TenantId: cookie.load("TenantId"),
    StartDatetime: "",
    EndDatetime: ""
  },
  franchiseReportProductListUpdate: false,
  franchiseReportProductListData: [],

  franchiseReportEntegrationUpdate: false,
  franchiseReportEntegrationData: {
    generalEntegrationReportData: [],
    generalEntegrationReportDataBrand: []
  },

  franchiseReportAdditionListUpdate: false,

  franchiseReportLogsFilter: {
    BrandId: "00000000-0000-0000-0000-000000000000",
    Page: 1,
    StartDatetime: "",
    EndDatetime: "",
    LogType: 1,
    ProcessType: 0
  },
  franchiseReportLogsUpdate: false,
  franchiseReportLogsList: {
    Results: [],
    PageSize: 0,
    RowCount: 0,
    CurrentPage: 0
  },
  franchiseBrandEntegrationList: null,
  filterReviews: {
    StartDatetime: "",
    EndDatetime: "",
    Type: "",
    EntegrationId: ""
  },
  isReviewsUpdate: false,
  franchiseSadakatLoading: false,
  franchiseSadakatVisible: false,
  franchiseSadakatUpdate: false,
  franchiseSadakatData: {
    IsApp: false,
    PrimaryColor: "#ffa801",
    SecondaryColor: "#485460",
    WorkingHours: [],
    BrandList: [],
    TenantId: "",
    CenterBrandId: "",
    WellcomeCam: {
      IsStatus: false,
      DiscountRate: 0,
      ImageUrl: ""
    },
    FriendsInvite: {
      IsStatus: false,
      DiscountAmount: 0,
      ImageUrl: ""
    },
    Wallet: {
      Explanation: "",
      WalletRatio: 0,
      ImageUrl: ""
    },
    ApiKey: "",
    MerchantId: "",
    UserId: "",
    WalletLimit: 200
  },
  IsCampaignVisible: false,
  CampaignListData: [],
  DiscountProduct: {
    "IsStatus": 1,
    "SalesType": 0,
    "CampaignType": 0,
    "ProductsAdded": [{
      "ProductId": '00000000-0000-0000-0000-000000000000',
      "ProductName": ""
    }],
    "Code": "",
    "IsDelete": false,
    "StartDate": moment.utc(new Date(), 'MM-DD-YYYYTHH:mm').local().format("YYYY-MM-DDTHH:mm"),
    "EndDate": moment.utc(new Date(), 'MM-DD-YYYYTHH:mm').local().format("YYYY-MM-DDTHH:mm"),
    "ProductsToBeAdded": [{
      "ProductId": '00000000-0000-0000-0000-000000000000',
      "ProductName": "",
      "DiscountRate": 0
    }],
    "BrandId": cookie.load('BrandId'),
    "TenantId": cookie.load('TenantId'),
    "ImageUrl": ""
  },
  GeneralDiscount: {
    "IsStatus": 1,
    "SalesType": 0,
    "CampaignType": 2,
    "ProductsAdded": [{
      "ProductId": '00000000-0000-0000-0000-000000000000',
      "ProductName": ""
    }],
    "Code": "",
    "IsDelete": false,
    "StartDate": moment.utc(new Date(), 'MM-DD-YYYYTHH:mm').local().format("YYYY-MM-DDTHH:mm"),
    "EndDate": moment.utc(new Date(), 'MM-DD-YYYYTHH:mm').local().format("YYYY-MM-DDTHH:mm"),
    "ProductsToBeAdded": [{
      "ProductId": '00000000-0000-0000-0000-000000000000',
      "ProductName": "",
      "DiscountRate": 0
    }],
    "BrandId": cookie.load('BrandId'),
    "TenantId": cookie.load('TenantId'),
    "ImageUrl": ""
  },
  franchiseRegionVisible: false,
  franchiseRegionBrandId: "",
  franchiseRegionUpdate: false,
  franchiseRegionLoading: false,
  franchiseRegionData: [],
  regionSearchText: "",
  regionSearchData: [],
  sendNotificationData: {
    "CenterBrandId": cookie.load('CenterBrandId'),
    "TenantId": cookie.load('TenantId'),
    "title": "",
    "body": ""
  }
}

export async function setSaveImageApp(dataFile, type, width, height, id) {
  return async (dispatch, getState) => {
    dispatch({ type: FRANCHISE_JAVI_SADAKAT_LOADING, payload: true });

    const apiBaseUrl = brand.urlSocket + 'api/ThirdParty/ImageSave';
    const f = new FormData();
    f.append('files', dataFile);
    f.append('BrandId', cookie.load('BrandId'));
    f.append('CenterBrandId', cookie.load('CenterBrandId'));
    f.append('ProductId', (type == 5 ? id : '00000000-0000-0000-0000-000000000000'));
    f.append('type', type);
    f.append('width', width);
    f.append('height', height);

    await axios.post(apiBaseUrl, f, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      }
    }).then((response) => {
      if (response.data.isStatus) {
        if (type == 5) {
          dispatch(getCampaign());
        } else if (type == 6 || type == 7 || type == 8) {
          dispatch(getJaviAppSettings());
        }
      }
    })
      .catch((error) => {

      });
    dispatch({ type: FRANCHISE_JAVI_SADAKAT_LOADING, payload: false });
  };
}

export async function setFranchiseLogFilter(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: FRANCHISE_REPORT_LOGS_FILTER,
      payload: data
    });
  };
}

export async function getEntegrationCacheList(BrandId) {
  return async (dispatch, getState) => {
    let aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/ThirdParty/getEntegrationCacheList?BrandId=' + BrandId,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({
            type: FRANCHISE_REPORT_ENTEGRATION_LIST,
            payload: json
          });
        }
      })
      .catch(function (error) {
        alert(error);
      });
    dispatch({ type: FRANCHISE_REPORT_LOADING, payload: false });
  };
}

export async function getLogs(page) {
  return async (dispatch, getState) => {
    const { franchiseReportLogsFilter, franchiseReportBrandSelected } = getState().get('centerBrandReducer');
    dispatch({ type: FRANCHISE_REPORT_LOADING, payload: true });
    franchiseReportLogsFilter.Page = page;
    franchiseReportLogsFilter.BrandId = franchiseReportBrandSelected;
    if (franchiseReportBrandSelected == "00000000-0000-0000-0000-000000000000") {
      dispatch(setModalCenterBrand("Şube Seçimi yapmanız gerekmektedir."));
      return;
    }
    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Settings/getLogs',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: franchiseReportLogsFilter,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch({
            type: FRANCHISE_REPORT_LOGS_DATA,
            payload: json
          });
        }
      })
      .catch(function (error) {
        alert(error);
      });
    dispatch({ type: FRANCHISE_REPORT_LOADING, payload: false });
  };
}

export async function getReportEntegration() {
  return async (dispatch, getState) => {
    const { filterReportProductList, franchiseReportBrandSelected } = getState().get('centerBrandReducer');
    filterReportProductList.BrandId = franchiseReportBrandSelected;
    filterReportProductList.TenantId = cookie.load("TenantId");

    dispatch({ type: FRANCHISE_REPORT_LOADING, payload: true });
    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/CenterBrand/getReportEntegration',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data: filterReportProductList
    })
      .then((response) => {
        if (response.data.isStatus) {
          var jsonData = JSON.parse(response.data.responseData);
          dispatch({
            type: FRANCHISE_REPORT_ENTEGRATION_DATA,
            payload: jsonData
          });
        } else {
          dispatch(setModal(response.data.responseData));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    dispatch({ type: FRANCHISE_REPORT_LOADING, payload: false });
  };
}

export async function getProductGraffic() {
  return async (dispatch, getState) => {
    const { filterReportProductList, franchiseReportBrandSelected } = getState().get('centerBrandReducer');
    filterReportProductList.BrandId = franchiseReportBrandSelected;
    filterReportProductList.TenantId = cookie.load("TenantId");

    dispatch({ type: FRANCHISE_REPORT_LOADING, payload: true });
    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/CenterBrand/getProductGraffic',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data: filterReportProductList
    })
      .then((response) => {
        if (response.data.isStatus) {
          var jsonData = JSON.parse(response.data.responseData);
          dispatch({
            type: FRANCHISE_REPORT_PRODUCTLIST_DATA,
            payload: jsonData
          });
        } else {
          dispatch(setModal(response.data.responseData));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    dispatch({ type: FRANCHISE_REPORT_LOADING, payload: false });
  };
}

export async function getGeneralTotalReport() {
  return async (dispatch, getState) => {
    const { filterOrderHeat, franchiseReportBrandSelected } = getState().get('centerBrandReducer');
    filterOrderHeat.BrandId = franchiseReportBrandSelected;
    filterOrderHeat.TenantId = cookie.load("TenantId");

    dispatch({ type: FRANCHISE_REPORT_LOADING, payload: true });
    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/CenterBrand/getGeneralTotalReport',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data: filterOrderHeat
    })
      .then((response) => {
        if (response.data.isStatus) {
          var jsonData = JSON.parse(response.data.responseData);
          dispatch({
            type: FRANCHISE_REPORT_GENERALTOTAL_DATA,
            payload: jsonData
          });
        } else {
          dispatch(setModal(response.data.responseData));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    dispatch({ type: FRANCHISE_REPORT_LOADING, payload: false });
  };
}

export async function getOrderHeat() {
  return async (dispatch, getState) => {
    const { filterOrderHeat, franchiseReportBrandSelected } = getState().get('centerBrandReducer');
    filterOrderHeat.BrandId = franchiseReportBrandSelected;
    dispatch({ type: FRANCHISE_REPORT_LOADING, payload: true });
    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/CenterBrand/getOrderHeat',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data: filterOrderHeat
    })
      .then((response) => {
        if (response.data.isStatus) {
          var jsonData = JSON.parse(response.data.responseData);
          dispatch({
            type: FRANCHISE_REPORT_ORDERHEAT_DATA,
            payload: jsonData
          });
        } else {
          dispatch(setModal(response.data.responseData));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    dispatch({ type: FRANCHISE_REPORT_LOADING, payload: false });
  };
}

export async function filterReviewsDownload() {
  return async (dispatch, getState) => {
    const { franchiseReportReviewsData } = getState().get('centerBrandReducer');

    axios({
      url: brand.urlSocket + 'api/CenterBrand/getReviewsExcel',
      method: 'POST',
      responseType: 'blob',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: franchiseReportReviewsData,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'PuanYorumlar.xlsx');
      document.body.appendChild(link);
      link.click();
    });
  };
}

export async function getReviews() {
  return async (dispatch, getState) => {
    const { filterReviews, franchiseReportBrandSelected } = getState().get('centerBrandReducer');
    filterReviews.BrandId = franchiseReportBrandSelected;
    dispatch({ type: FRANCHISE_REPORT_LOADING, payload: true });

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/CenterBrand/getReviews',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data: filterReviews
    })
      .then((response) => {
        if (response.data.isStatus) {
          var jsonData = JSON.parse(response.data.responseData);
          dispatch({
            type: FRANCHISE_REPORT_REVIEWS_DATA,
            payload: jsonData
          });
        } else {
          dispatch(setModal(response.data.responseData));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    dispatch({ type: FRANCHISE_REPORT_LOADING, payload: false });
  };
}

export async function getDailyMonthlyGrafficReport() {
  return async (dispatch, getState) => {
    const { franchiseReportGrafficFilter, franchiseReportBrandSelected } = getState().get('centerBrandReducer');
    var filter = JSON.parse(JSON.stringify(franchiseReportGrafficFilter));

    filter.TenantId = cookie.load("TenantId");
    filter.BrandId = franchiseReportBrandSelected;
    filter.IsAll = franchiseReportBrandSelected == "00000000-0000-0000-0000-000000000000" ? true : false;

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/CenterBrand/getMainPageReport',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data: filter
    })
      .then((response) => {

        if (response.data.isStatus) {
          var jsonData = JSON.parse(response.data.responseData);
          dispatch(setFranchiseMainReportData(jsonData));
        } else {
          dispatch(setModal(response.data.responseData));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function getMainPageReport() {
  return async (dispatch, getState) => {
    const { franchiseReportGrafficFilter } = getState().get('centerBrandReducer');
    var filter = JSON.parse(JSON.stringify(franchiseReportGrafficFilter));
    filter.TenantId = cookie.load("TenantId");
    filter.BrandId = "00000000-0000-0000-0000-000000000000";
    filter.GrafficType = 0;
    filter.IsAll = true;

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/CenterBrand/getMainPageReport',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data: filter
    })
      .then((response) => {
        if (response.data.isStatus) {
          var jsonData = JSON.parse(response.data.responseData);
          dispatch(setFranchiseMainReportData(jsonData));
        } else {
          dispatch(setModal(response.data.responseData));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function getFranchiseOrderList() {
  return async (dispatch, getState) => {
    var BrandId = (window.location.pathname == "/centerbrand" ? "00000000-0000-0000-0000-000000000000" : cookie.load("BrandId"));
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/CenterBrand/getFranchiseOrderList?CenterBrandId=' + cookie.load("CenterBrandId") + '&BrandId=' + BrandId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          var jsonData = JSON.parse(response.data.responseData);
          dispatch(setFranchiseOrderListData(jsonData));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function setFranchiseOrderStock(Id, typeData = "") {
  return async (dispatch, getState) => {
    var type = (typeData == "" ? (window.location.pathname == "/centerbrand" ? 1 : 2) : 3);
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/CenterBrand/setFranchiseOrderStock?OrderNo=' + Id + '&type=' + type,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch(getFranchiseOrderList());
        }
        dispatch(setModal(response.data.responseData));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function setFranchiseOrder() {
  return async (dispatch, getState) => {
    const { franchiseOrderAddDataList } = getState().get('centerBrandReducer');
    franchiseOrderAddDataList.map((n, i) => {
      n.BrandId = cookie.load("BrandId");
      n.TenantId = cookie.load("TenantId");
      n.CenterBrandId = cookie.load("CenterBrandId");
    });

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/CenterBrand/setFranchiseOrder',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data: franchiseOrderAddDataList
    })
      .then((response) => {
        if (response.data.isStatus) {
          dispatch({
            type: FRANCHISE_ORDER_ADD_LIST_CLEAR
          });
          dispatch(getFranchiseOrderList());
        } else {
          dispatch(setModal(response.data.responseData));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function setBrandSettings(BrandId) {
  return async (dispatch, getState) => {
    const { centerAuthorityData } = getState().get('centerBrandReducer');
    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/Settings/setSettings',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data: centerAuthorityData,
    })
      .then((response) => {
        dispatch(setModalCenterBrand("Yetkilendirme kayıt edildi."));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export async function setCenterAuthorityData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: CENTER_AUTHORITY_LIST_DATA,
      payload: data
    });
  };
}

export async function getBrandSettings(BrandId, TenantId) {
  return async (dispatch, getState) => {
    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/Settings/getSettings?TenantId=' + TenantId + '&BrandId=' + BrandId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const json = JSON.parse(response.data.responseData);
          json.BrandId = BrandId;
          dispatch(setCenterAuthorityData(json));
        }
      })
      .catch((error) => {
      });
  }
}

export async function setCenterAuthorityVisible(visible, BrandId = "", TenantId = "") {
  return async (dispatch, getState) => {
    if (visible) {
      await dispatch(getBrandSettings(BrandId, TenantId));
    }
    dispatch({
      type: CENTER_AUTHORITY_LIST_VISIBLE,
      payload: visible
    });
  };
}

export async function setLimitAdd() {
  return async (dispatch, getState) => {
    const { MahalleId, MahalleText, Limit, BrandId } = getState().get('centerBrandReducer').form_data;
    let limitdata = {
      BrandId: BrandId,
      MahalleAdi: MahalleText,
      MahalleKodu: MahalleId,
      PackageLimit: Limit,
      TenantId: cookie.load('TenantId')
    };
    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/CenterBrand/setLimit',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: limitdata
    })
      .then(function (response) {
        if (response.data.isStatus) {
          dispatch(formDataSetCenterBrand("IlceId", ""));
          dispatch(formDataSetCenterBrand("MahalleId", ""));
          dispatch(formDataSetCenterBrand("Limit", 0));

          dispatch(getLimitList(BrandId));

          dispatch(setModalCenterBrand(response.data.responseData));
        }
      })
      .catch(function (error) {

      });
  }
}

export async function setLimit(data) {
  return async (dispatch, getState) => {
    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/CenterBrand/setLimit',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: data
    })
      .then(function (response) {
        dispatch(setModalCenterBrand(response.data.responseData));
      })
      .catch(function (error) {

      });
  }
}

export async function deleteLimit(Id, BrandId) {
  return async (dispatch, getState) => {

    let aa = await axios({
      method: 'DELETE',
      url: brand.urlSocket + 'api/CenterBrand/deleteLimit?Id=' + Id,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        dispatch(getLimitList(BrandId));
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function setCenterRegionVisible(Id = "", visible) {
  return async (dispatch, getState) => {
    if (visible) {
      await dispatch(getLimitList(Id));
    }
    dispatch({
      type: CENTER_REGION_LIST_VISIBLE,
      payload: visible,
      brandid: Id
    });
  };
}

export async function setCenterRegionData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: CENTER_REGION_LIST_DATA,
      payload: data
    });
  };
}

export async function getLimitList(Id) {
  return async (dispatch, getState) => {
    let aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/CenterBrand/getLimitList?Id=' + Id,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var jsonData = JSON.parse(response.data.responseData);
          dispatch(setCenterRegionData(jsonData));
        }
      })
      .catch(function (error) {

      });
  }
}

export async function formDataSetCenterBrand(key, val) {
  return async (dispatch, getState) => {
    dispatch({
      type: CENTER_GLOBAL_FORM_DATA_SET,
      payload: { key, val }
    });
  };
}

export async function setModalCenterBrand(text) {
  return async (dispatch, getState) => {
    dispatch({
      type: CENTERBRAND_ERROR,
      payload: text
    });
  };
}

export async function modalClose() {
  return async (dispatch, getState) => {
    dispatch({ type: CENTERBRAND_MODALCLOSE });
  }
}


export async function getCenterSettings() {
  return async (dispatch, getState) => {
    let aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/CenterBrand/getCenterSettings?CenterBrandId=' + cookie.load('CenterBrandId'),
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch(setCenterSettingsData(json));
        } else {
          dispatch(setModalCenterBrand(response.data.responseData));
        }
      })
      .catch(function (error) {

      });
  }
}

export async function setCenterSettings() {
  return async (dispatch, getState) => {
    const { centerSettingData } = getState().get('centerBrandReducer');
    centerSettingData.CenterBrandId = cookie.load('CenterBrandId');
    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/CenterBrand/setCenterSettings',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: centerSettingData
    })
      .then(function (response) {
        dispatch(setModalCenterBrand(response.data.responseData));
      })
      .catch(function (error) {

      });
  }
}

export async function getBrandList() {
  return async (dispatch, getState) => {
    let aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/CenterBrand/getBrandList?CenterBrandId=' + cookie.load('CenterBrandId'),
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var jsonData = JSON.parse(response.data.responseData);
          dispatch({
            type: CENTER_BRAND_DATA,
            payload: jsonData
          });
        }
      })
      .catch(function (error) {

      });
  }
}

export async function setCenterUserVisible(visible) {
  return async (dispatch, getState) => {
    if (visible) {
      await dispatch(getUserList());
    }
    dispatch({
      type: CENTER_USER_VISIBLE,
      payload: visible
    });
  };
}

export async function setCenterUserAddData(data, IsVisible = false) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_CENTER_USER_ADD_DATA,
      payload: data,
      isvisible: IsVisible
    });
  };
}

export async function getUser(Id) {
  return async (dispatch, getState) => {
    dispatch({ type: SET_CENTER_USER_ADD_LOADING, payload: true });

    let aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/CenterBrand/getUser?Id=' + Id,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch(setCenterUserAddData(json, true));
        }
      })
      .catch(function (error) {
        alert(error);
      });
    dispatch({ type: SET_CENTER_USER_ADD_LOADING, payload: false });
  };
}

export async function deleteUser(Id) {
  return async (dispatch, getState) => {
    dispatch({ type: SET_CENTER_USER_ADD_LOADING, payload: true });

    let aa = await axios({
      method: 'DELETE',
      url: brand.urlSocket + 'api/CenterBrand/deleteUser?Id=' + Id,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        dispatch(getUserList());
        dispatch(setModalCenterBrand(response.data.responseData));
      })
      .catch(function (error) {
        alert(error);
      });
    dispatch({ type: SET_CENTER_USER_ADD_LOADING, payload: false });
  };
}

export async function setCenterUserAddVisible(visible) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_CENTER_USER_ADD_VISIBLE,
      payload: visible
    });
  };
}

export async function setUserUpdate() {
  return async (dispatch, getState) => {
    dispatch({ type: SET_CENTER_BRAND_ADD_LOADING, payload: true });
    const { centerUserAddData } = getState().get('centerBrandReducer');

    axios({
      method: 'POST',
      url: brand.urlSocket + 'api/CenterBrand/setUserUpdate',
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
      data: centerUserAddData,
    })
      .then(function (response) {
        if (response.data.isStatus) {
          dispatch(getUserList());
          dispatch(setCenterUserAddVisible(false));
        }
      })
      .catch(function (error) {
      });
    dispatch({ type: SET_CENTER_BRAND_ADD_LOADING, payload: false });
  }
}

export async function getUserList() {
  return async (dispatch, getState) => {
    let aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/CenterBrand/getUserList?CenterBrandId=' + cookie.load('CenterBrandId'),
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var jsonData = JSON.parse(response.data.responseData);
          dispatch({
            type: CENTER_USER_DATA,
            payload: jsonData
          });
        }
      })
      .catch(function (error) {

      });
  }
}

export async function setCenterSettingsVisible(visible) {
  return async (dispatch, getState) => {
    if (visible) {
      await dispatch(getCenterSettings());
    }
    dispatch({
      type: CENTER_SETTING_VISIBLE,
      payload: visible
    });
  };
}

export async function setCenterSettingsData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: CENTER_SETTING_DATA,
      payload: data
    });
  };
}

export async function setCenterBrandVisible(visible) {
  return async (dispatch, getState) => {
    if (visible) {
      await dispatch(getBrandList());
    }
    dispatch({
      type: CENTER_BRAND_VISIBLE,
      payload: visible
    });
  };
}

export async function getBrand(Id) {
  return async (dispatch, getState) => {
    dispatch({ type: SET_CENTER_BRAND_ADD_LOADING, payload: true });

    let aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/CenterBrand/getBrand?Id=' + Id,
      dataType: 'json',
      headers: {
        'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
      },
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch(setCenterBrandAddData(json, true));
        }
      })
      .catch(function (error) {
        alert(error);
      });
    dispatch({ type: SET_CENTER_BRAND_ADD_LOADING, payload: false });
  };
}

export async function setCenterBrandAddData(data, IsVisible = false) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_CENTER_BRAND_ADD_DATA,
      payload: data,
      isvisible: IsVisible
    });
  };
}

export async function setCenterBrandAddVisible(visible) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_CENTER_BRAND_ADD_VISIBLE,
      payload: visible
    });
  };
}

export async function setBrandUpdateOrAdd() {
  return async (dispatch, getState) => {
    dispatch({ type: SET_CENTER_BRAND_ADD_LOADING, payload: true });
    const { centerBrandAddData } = getState().get('centerBrandReducer');

    if (centerBrandAddData.Id == "00000000-0000-0000-0000-000000000000") {
      centerBrandAddData["Language"] = cookie.load('Lang') || 'tr';

      centerBrandAddData.CreationDatetime = new Date();
      axios({
        method: 'POST',
        url: brand.urlSocket + 'api/CenterBrand/setBrandAdd',
        dataType: 'json',
        headers: {
          'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*"),
        },
        data: centerBrandAddData,
      })
        .then(function (response) {
          if (response.data.isStatus) {
            dispatch(setModalCenterBrand(response.data.responseData));
            dispatch(getBrandList());
            dispatch(setCenterBrandAddVisible(false));
          } else {
            dispatch(setModalCenterBrand(response.data.responseData));
          }
        })
        .catch(function (error) {
          dispatch(setModalCenterBrand(error));
        });
    } else {
      axios({
        method: 'POST',
        url: brand.urlSocket + 'api/CenterBrand/setBrandUpdate',
        dataType: 'json',
        headers: {
          'Authorization': 'Basic ' + base64.encode("JarvisPos" + ":" + "Shark123*")
        },
        data: centerBrandAddData,
      })
        .then(function (response) {
          if (response.data.isStatus) {
            dispatch(getBrandList());
            dispatch(setCenterBrandAddVisible(false));
          }
        })
        .catch(function (error) {
        });
    }

    dispatch({ type: SET_CENTER_BRAND_ADD_LOADING, payload: false });
  }
}

export async function getProductList(Id) {
  return async (dispatch, getState) => {
    dispatch({ type: SET_CENTER_BRAND_ADD_LOADING, payload: true });

    let aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/CenterBrand/getCenterStock?CenterBrandId=' + cookie.load('CenterBrandId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      }
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch(setCenterProductListData(json));
        }
      })
      .catch(function (error) {
        alert(error);
      });
    dispatch({ type: SET_CENTER_BRAND_ADD_LOADING, payload: false });
  };
}

export async function setCenterProductListData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: CENTER_PRODUCT_LIST_DATA,
      payload: data
    });
  };
}

export async function setCenterProductListVisible(visible) {
  return async (dispatch, getState) => {
    if (visible) {
      await dispatch(getProductList());
    }
    dispatch({
      type: CENTER_PRODUCT_LIST_VISIBLE,
      payload: visible
    });
  };
}

export async function setCenterRegionUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: CENTER_REGION_LIST_UPDATE
    });
  };
}

export async function setFranchiseOrderListData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: FRANCHISE_ORDER_LIST_DATA,
      payload: data
    });
  };
}

export async function setFranchiseOrderListVisible(visible) {
  return async (dispatch, getState) => {
    if (visible) {
      await dispatch(getFranchiseOrderList());
    }
    dispatch({
      type: FRANCHISE_ORDER_LIST_VISIBLE,
      payload: visible
    });
  };
}

export async function setFranchiseOrderAddVisible(visible) {
  return async (dispatch, getState) => {
    dispatch({
      type: FRANCHISE_ORDER_ADD_LIST_VISIBLE,
      payload: visible
    });
  };
}

export async function setFranchiseOrderAddData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: FRANCHISE_ORDER_ADD_LIST_DATA,
      payload: data
    });
  };
}

export async function setFranchiseOrderListAddData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: FRANCHISE_ORDER_ADD_LIST_DATAS,
      payload: data
    });
  };
}

export async function setFranchiseMainReportData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: FRANCHISE_MAIN_REPORT_DATA,
      payload: data
    });
  };
}

export async function setFranchiseSadakatVisible(visible) {
  return async (dispatch, getState) => {
    if (visible) {
      await dispatch(getJaviAppSettings());
      await dispatch(getCampaign());
    }
    dispatch({
      type: FRANCHISE_JAVI_SADAKAT_VISIBLE,
      payload: visible
    });
  };
}

export async function getJaviAppSettings() {
  return async (dispatch, getState) => {

    let aa = await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/CenterBrand/getJaviAppSettings?CenterBrandId=' + cookie.load('CenterBrandId'),
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      }
    })
      .then(function (response) {
        if (response.data.isStatus) {
          var json = JSON.parse(response.data.responseData);
          dispatch(setFranchiseSadakatData(json));
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function setJaviAppSettings() {
  return async (dispatch, getState) => {
    const { franchiseSadakatData } = getState().get('centerBrandReducer');

    franchiseSadakatData.CenterBrandId = cookie.load("CenterBrandId");
    franchiseSadakatData.TenantId = cookie.load("TenantId");

    let aa = await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/CenterBrand/setJaviAppSettings',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*')
      },
      data: franchiseSadakatData
    })
      .then(function (response) {
        dispatch(setModalCenterBrand(response.data.responseData));
      })
      .catch(function (error) {
        alert(error);
      });
  };
}

export async function setFranchiseSadakatData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: FRANCHISE_JAVI_SADAKAT_DATA,
      payload: data
    });
  };
}

export async function setFranchiseRaportVisible(visible) {
  return async (dispatch, getState) => {
    dispatch({
      type: FRANCHISE_REPORT_VISIBLE,
      payload: visible
    });
  };
}

export async function setFranchiseReportName(name) {
  return async (dispatch, getState) => {
    if (name == "AdditionListReport") {
      await dispatch(setReportPageName("AdditionReport"));
    }
    dispatch({
      type: FRANCHISE_REPORT_NAME,
      payload: name
    });
  };
}

export async function setFranchiseReportBrandSelected(value) {
  return async (dispatch, getState) => {
    dispatch({
      type: FRANCHISE_REPORT_BRAND_SELECTED,
      payload: value
    });
  };
}

export async function setFranchiseReportOrderHeatFilter(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: FRANCHISE_REPORT_ORDERHEAT_FILTER,
      payload: data
    });
  };
}

export async function setFranchiseReportReviewsUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: FRANCHISE_REPORT_REVIEWS_UPDATE
    });
  };
}

export async function setFranchiseReportReviewsFilter(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: FRANCHISE_REPORT_REVIEWS_FILTER,
      payload: data
    });
  };
}

export async function setFranchiseReportGrafficFilter(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: FRANCHISE_REPORT_GRAFFIC_FILTER,
      payload: data
    });
  };
}

export async function setFranchiseReportProductSalesListFilter(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: FRANCHISE_REPORT_PRODUCTLIST_FILTER,
      payload: data
    });
  };
}

export async function setFranchiseReportAdditionListUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: FRANCHISE_REPORT_ADDITIONLIST_UPDATE
    });
  };
}

export async function setCampaignUpdate() {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_CAMPAIGN_UPDATE
    });
  };
}

export async function setCampaignListData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_CAMPAIGN_LIST_DATA,
      payload: data
    });
  };
}

export async function setCampaign() {
  return async (dispatch, getState) => {

    const { CampaignListData } = getState().get('centerBrandReducer');
    var textModal = "";

    CampaignListData.map((n, i) => {
      if (n.CampaignType == 0) {
        if (n.ProductsToBeAdded[0].ProductId == '00000000-0000-0000-0000-000000000000') {
          textModal = "Kampanyada ürün seçmeniz gerekmektedir.";
        } else if (n.ProductsToBeAdded[0].DiscountRate == 0) {
          textModal = "Kampanyada seçili ürüne Oransal indirim girmelisiniz!";
        } else if (n.StartDate > n.EndDate) {
          textModal = "Kampanya bitiş tarihi başlangıç tarihinden küçük olamaz!";
        }
      } else if (n.CampaignType == 2) {
        if (n.ProductsToBeAdded[0].DiscountRate == 0) {
          textModal = "Kampanyaya oransal indirim girmelisiniz!";
        } else if (n.StartDate > n.EndDate) {
          textModal = "Kampanya bitiş tarihi başlangıç tarihinden küçük olamaz!";
        }
      } else if (n.CampaignType == 3) {
        if (n.ProductsToBeAdded.length == 0) {
          textModal = "Kampanyaya sepet ürünü eklemelisiniz!";
        } else if (n.ProductsAdded.length == 0) {
          textModal = "Kampanyaya sepete eklenebilecek ürün eklemelisiniz!";
        }
      }
    })

    if (textModal.length > 0) {
      dispatch(setModalCenterBrand(textModal));
      return;
    }

    CampaignListData.map((n, i) => {
      if (n.CampaignType == 1) {
        n.ProductsToBeAdded.map((x, s) => {
          x["DiscountRate"] = n.DiscountRate;
        })
      }
    })

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/JaviApp/setCampaign',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data: CampaignListData,
    })
      .then((response) => {
        dispatch(setModalCenterBrand(response.data.responseData));
      })
      .catch((error) => {
      });
  };
}

export async function getCampaign() {
  return async (dispatch, getState) => {
    var TenantId = cookie.load('TenantId');

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/JaviApp/getCampaign?TenantId=' + TenantId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const jsonData = JSON.parse(response.data.responseData);
          jsonData.map((n, i) => {
            if (n.CampaignType == 1) {
              n.DiscountRate = n.ProductsToBeAdded[0].DiscountRate;
            }
            if (n.ImageUrl == null) {
              n.ImageUrl = "";
            }
          })
          dispatch(setCampaignListData(jsonData));
        }
      })
      .catch((error) => {
      });
  };
}

export async function setFranchiseRegionVisible(visible, BrandId = "") {
  return async (dispatch, getState) => {
    if (visible) {
      await dispatch(getRegions(BrandId));
    }
    dispatch({
      type: FRANCHISE_JAVI_REGION_VISIBLE,
      payload: visible,
      brandid: BrandId
    });
  };
}

export async function getRegions(BrandId) {
  return async (dispatch, getState) => {

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/JaviApp/getRegions?BrandId=' + BrandId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const jsonData = JSON.parse(response.data.responseData);
          jsonData.map((n, i) => {
            n.IsSelected = false;
          })
          dispatch(getRegionsListData(jsonData));
        }
      })
      .catch((error) => {
      });
  };
}

export async function getRegionDelete(RegionId) {
  return async (dispatch, getState) => {
    const { franchiseRegionData } = getState().get('centerBrandReducer');

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/JaviApp/getRegionDelete?RegionId=' + RegionId,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        franchiseRegionData.splice(franchiseRegionData.findIndex(x => x.Id == RegionId), 1);
        dispatch({
          type: FRANCHISE_JAVI_REGION_DATA,
          payload: franchiseRegionData
        });
      })
      .catch((error) => {
      });
  };
}

export async function getRegionsListData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: FRANCHISE_JAVI_REGION_DATA,
      payload: data
    });
  };
}

export async function getOSMGeoLocation() {
  return async (dispatch, getState) => {
    const { regionSearchText, franchiseRegionData } = getState().get('centerBrandReducer');

    await axios({
      method: 'GET',
      url: brand.urlSocket + 'api/JaviApp/getOSMGeoLocation?region=' + regionSearchText,
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
    })
      .then((response) => {
        if (response.data.isStatus) {
          const jsonData = JSON.parse(response.data.responseData);
          if (jsonData[0].geojson.coordinates[0].length > 10) {
            var list = [];
            jsonData[0].geojson.coordinates[0].map((n, i) => {
              list.push({
                "Lat": n[0],
                "Lng": n[1]
              })
            })
            franchiseRegionData.find(x => x.IsSelected == true).PolygonArray.push(list);
            dispatch({
              type: FRANCHISE_JAVI_REGION_SEARCH_DATA,
              payload: list
            })
            dispatch({
              type: FRANCHISE_JAVI_REGION_DATA,
              payload: franchiseRegionData
            });
          }
        }
      })
      .catch((error) => {
      });
  };
}


export async function getRegionsListAdd() {
  return async (dispatch, getState) => {
    const { franchiseRegionData, franchiseRegionBrandId } = getState().get('centerBrandReducer');
    var newTemp = JSON.parse(JSON.stringify(franchiseRegionData));
    newTemp.map((n, i) => {
      n.IsSelected = false;
    })
    var newRegionAdd = {
      Id: uuidv4(),
      RegionName: "",
      MinAmount: 0,
      IsStatus: true,
      PolygonArray: [],
      BrandId: "",
      IsSelected: true
    };
    newRegionAdd.BrandId = franchiseRegionBrandId;
    newTemp.unshift(newRegionAdd);
    dispatch({
      type: FRANCHISE_JAVI_REGION_DATA,
      payload: newTemp
    });
  };
}

export async function getRegionsSearchData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: FRANCHISE_JAVI_REGION_SEARCH_TEXT,
      payload: data
    });
  };
}

export async function setRegion(poligonjson) {
  return async (dispatch, getState) => {
    const { franchiseRegionData } = getState().get('centerBrandReducer');
    var listRender = [];
    poligonjson.map((n, i) => {
      n.map((x, p) => {
        var list = [];
        x.map((e, r) => {
          list.push({ Lat: e[0], Lng: e[1] })
        })
        listRender.push(list)
      })
    })
    franchiseRegionData.find(x => x.IsSelected == true).PolygonArray = listRender;

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/JaviApp/setRegion',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data: franchiseRegionData
    })
      .then((response) => {
        dispatch(setModalCenterBrand(response.data.responseData));
      })
      .catch((error) => {
      });
  };
}

export async function setNotificationData(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_NOTIFICATION_DATA,
      payload: data
    });
  };
}

export async function sendNotification() {
  return async (dispatch, getState) => {
    const { sendNotificationData } = getState().get('centerBrandReducer');

    await axios({
      method: 'POST',
      url: brand.urlSocket + 'api/JaviApp/sendNotification',
      dataType: 'json',
      headers: {
        Authorization: 'Basic ' + base64.encode('JarvisPos' + ':' + 'Shark123*'),
      },
      data: sendNotificationData
    })
      .then((response) => {
        dispatch(setModalCenterBrand(response.data.responseData));
        dispatch(setNotificationData({
            "CenterBrandId": cookie.load('CenterBrandId'),
            "TenantId": cookie.load('TenantId'),
            "title": "",
            "body": ""
          }
        ));
      })
      .catch((error) => {
      });
  };
}

const centerBrandReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_DATA:
      return { ...state, sendNotificationData: action.payload, franchiseSadakatUpdate: !state.franchiseSadakatUpdate }
    case FRANCHISE_JAVI_REGION_SEARCH_DATA:
      return { ...state, regionSearchData: action.payload, franchiseRegionUpdate: !state.franchiseRegionUpdate }
    case FRANCHISE_JAVI_REGION_SEARCH_TEXT:
      return { ...state, regionSearchText: action.payload, franchiseRegionUpdate: !state.franchiseRegionUpdate }
    case FRANCHISE_JAVI_REGION_DATA:
      return { ...state, franchiseRegionData: action.payload, franchiseRegionUpdate: !state.franchiseRegionUpdate }
    case FRANCHISE_JAVI_REGION_LOADING:
      return { ...state, franchiseRegionLoading: action.payload, franchiseRegionUpdate: !state.franchiseRegionUpdate }
    case FRANCHISE_JAVI_REGION_UPDATE:
      return { ...state, franchiseRegionUpdate: !state.franchiseRegionUpdate }
    case FRANCHISE_JAVI_REGION_VISIBLE:
      return { ...state, franchiseRegionBrandId: action.brandid, franchiseRegionVisible: action.payload, franchiseRegionUpdate: !state.franchiseRegionUpdate }
    case SET_CAMPAIGN_UPDATE:
      return { ...state, franchiseSadakatUpdate: !state.franchiseSadakatUpdate }
    case SET_CAMPAIGN_LIST_DATA:
      return { ...state, CampaignListData: action.payload, franchiseSadakatUpdate: !state.franchiseSadakatUpdate }
    case FRANCHISE_REPORT_ENTEGRATION_LIST:
      return { ...state, franchiseBrandEntegrationList: action.payload, isOrderHeatUpdate: !state.isOrderHeatUpdate, isReviewsUpdate: !state.isReviewsUpdate }
    case FRANCHISE_REPORT_LOGS_FILTER:
      return { ...state, franchiseReportLogsFilter: action.payload, franchiseReportLogsUpdate: !state.franchiseReportLogsUpdate }
    case FRANCHISE_REPORT_LOGS_DATA:
      return { ...state, franchiseReportLogsList: action.payload, franchiseReportLogsUpdate: !state.franchiseReportLogsUpdate }
    case FRANCHISE_REPORT_ADDITIONLIST_UPDATE:
      return { ...state, franchiseReportAdditionListUpdate: !state.franchiseReportAdditionListUpdate }
    case FRANCHISE_REPORT_ENTEGRATION_DATA:
      return { ...state, franchiseReportEntegrationData: action.payload, franchiseReportEntegrationUpdate: !state.franchiseReportEntegrationUpdate }
    case FRANCHISE_REPORT_PRODUCTLIST_FILTER:
      return {
        ...state, filterReportProductList: action.payload, franchiseReportProductListUpdate: !state.franchiseReportProductListUpdate,
        franchiseReportEntegrationUpdate: !state.franchiseReportEntegrationUpdate
      }
    case FRANCHISE_REPORT_PRODUCTLIST_DATA:
      return { ...state, franchiseReportProductListData: action.payload, franchiseReportProductListUpdate: !state.franchiseReportProductListUpdate }
    case FRANCHISE_REPORT_GENERALTOTAL_DATA:
      return { ...state, franchiseReportGeneralTotalData: action.payload, franchiseReportGeneralTotalUpdate: !state.franchiseReportGeneralTotalUpdate }
    case FRANCHISE_REPORT_GRAFFIC_FILTER:
      return { ...state, franchiseReportGrafficFilter: action.payload, franchiseReportGrafficUpdate: !state.franchiseReportGrafficUpdate }
    case FRANCHISE_REPORT_REVIEWS_FILTER:
      return {
        ...state, filterReviews: action.payload, isReviewsUpdate: !state.isReviewsUpdate
      }
    case FRANCHISE_REPORT_REVIEWS_UPDATE:
      return {
        ...state, isReviewsUpdate: !state.isReviewsUpdate
      }
    case FRANCHISE_REPORT_ORDERHEAT_FILTER:
      return {
        ...state, filterOrderHeat: action.payload, isOrderHeatUpdate: !state.isOrderHeatUpdate,
        franchiseReportGeneralTotalUpdate: !state.franchiseReportGeneralTotalUpdate,
      }
    case FRANCHISE_REPORT_LOADING:
      return {
        ...state, isCenterReportLoading: action.payload, isOrderHeatUpdate: !state.isOrderHeatUpdate,
        isReviewsUpdate: !state.isReviewsUpdate,
        franchiseReportGrafficUpdate: !state.franchiseReportGrafficUpdate,
        franchiseReportGeneralTotalUpdate: !state.franchiseReportGeneralTotalUpdate,
        franchiseReportProductListUpdate: !state.franchiseReportProductListUpdate,
        franchiseReportEntegrationUpdate: !state.franchiseReportEntegrationUpdate,
        franchiseReportAdditionListUpdate: !state.franchiseReportAdditionListUpdate,
        franchiseReportLogsUpdate: !state.franchiseReportLogsUpdate,
      }
    case FRANCHISE_REPORT_ORDERHEAT_DATA:
      return { ...state, franchiseReportOrderHeatData: action.payload, isOrderHeatUpdate: !state.isOrderHeatUpdate }
    case FRANCHISE_REPORT_REVIEWS_DATA:
      return { ...state, franchiseReportReviewsData: action.payload, isReviewsUpdate: !state.isReviewsUpdate }
    case FRANCHISE_REPORT_BRAND_SELECTED:
      return {
        ...state, franchiseReportBrandSelected: action.payload, franchiseReportUpdate: !state.franchiseReportUpdate,
        isOrderHeatUpdate: !state.isOrderHeatUpdate,
        franchiseReportGrafficUpdate: !state.franchiseReportGrafficUpdate,
        franchiseReportGeneralTotalUpdate: !state.franchiseReportGeneralTotalUpdate,
        franchiseReportProductListUpdate: !state.franchiseReportProductListUpdate,
        franchiseReportEntegrationUpdate: !state.franchiseReportEntegrationUpdate,
        franchiseReportAdditionListUpdate: !state.franchiseReportAdditionListUpdate,
        franchiseReportLogsUpdate: !state.franchiseReportLogsUpdate,
        isReviewsUpdate: !state.isReviewsUpdate,
      }
    case FRANCHISE_REPORT_NAME: {
      var setting = state.centerSettingData;
      var date = getCenterDate(setting.ReportStartDate, setting.ReportEndDate);

      return {
        ...state, filterOrderHeat: (action.payload == "OrderHeatReport" || action.payload == "GeneralTotal" ? {
          BrandId: "00000000-0000-0000-0000-000000000000",
          StartDatetime: new Date(date.start),
          EndDatetime: new Date(date.end),
          TableType: "All",
          EntegrationId: ""
        } : state.filterOrderHeat),
        filterReportProductList: {
          BrandId: "00000000-0000-0000-0000-000000000000",
          CenterBrandId: cookie.load("CenterBrandId"),
          TenantId: cookie.load("TenantId"),
          StartDatetime: new Date(date.start),
          EndDatetime: new Date(date.end)
        }, franchiseReportName: action.payload, franchiseReportUpdate: !state.franchiseReportUpdate,
        isOrderHeatUpdate: !state.isOrderHeatUpdate,
        franchiseReportGrafficUpdate: !state.franchiseReportGrafficUpdate,
        franchiseReportGeneralTotalUpdate: !state.franchiseReportGeneralTotalUpdate,
        franchiseReportProductListUpdate: !state.franchiseReportProductListUpdate,
        franchiseReportEntegrationUpdate: !state.franchiseReportEntegrationUpdate,
        franchiseReportAdditionListUpdate: !state.franchiseReportAdditionListUpdate,
        franchiseReportLogsFilter: {
          BrandId: "00000000-0000-0000-0000-000000000000",
          Page: 1,
          StartDatetime: new Date(date.start),
          EndDatetime: new Date(date.end),
          LogType: 1,
          ProcessType: 0
        },
        franchiseReportLogsUpdate: !state.franchiseReportLogsUpdate,
        franchiseReportBrandSelected: (action.payload == "OrderHeatReport" || action.payload == "ReviewsReport" ? null : (action.payload == "DayMounthReport" || action.payload == "GeneralTotal" ? "00000000-0000-0000-0000-000000000000" : state.franchiseReportBrandSelected)),
        filterReviews: {
          StartDatetime: new Date(date.start),
          EndDatetime: new Date(date.end),
          Type: "",
          EntegrationId: ""
        },
        isReviewsUpdate: !state.isOrderHeatUpdate,
      }
    }
    case FRANCHISE_JAVI_SADAKAT_LOADING:
      return { ...state, franchiseSadakatLoading: action.payload, franchiseSadakatUpdate: !state.franchiseSadakatUpdate }
    case FRANCHISE_JAVI_SADAKAT_DATA:
      return { ...state, franchiseSadakatData: action.payload, franchiseSadakatUpdate: !state.franchiseSadakatUpdate }
    case FRANCHISE_JAVI_SADAKAT_VISIBLE:
      return { ...state, franchiseSadakatVisible: action.payload, franchiseSadakatUpdate: !state.franchiseSadakatUpdate }
    case FRANCHISE_REPORT_VISIBLE:
      return { ...state, franchiseReportVisible: action.payload, franchiseReportUpdate: !state.franchiseReportUpdate }
    case FRANCHISE_MAIN_REPORT_DATA:
      return { ...state, franchiseMainReportData: action.payload, franchiseReportGrafficUpdate: !state.franchiseReportGrafficUpdate }
    case FRANCHISE_ORDER_ADD_LIST_CLEAR:
      return {
        ...state, franchiseOrderAddData: {
          Id: "00000000-0000-0000-0000-000000000000",
          CreationDatetime: new Date(),
          StockId: "00000000-0000-0000-0000-000000000000",
          UnitId: "00000000-0000-0000-0000-000000000000",
          Quantity: 1,
          OrderNo: MakeRandomKey(8),
          IsDelete: false,
          FranchiseOrderType: 0,
          TenantId: "00000000-0000-0000-0000-000000000000",
          BrandId: "00000000-0000-0000-0000-000000000000",
          CenterBrandId: "00000000-0000-0000-0000-000000000000"
        }, franchiseOrderAddDataList: [], franchiseOrderAddUpdate: !state.franchiseOrderAddUpdate
      }
    case FRANCHISE_ORDER_ADD_LIST_DATAS:
      return { ...state, franchiseOrderAddDataList: action.payload, franchiseOrderAddUpdate: !state.franchiseOrderAddUpdate }
    case FRANCHISE_ORDER_ADD_LIST_DATA:
      return { ...state, franchiseOrderAddData: action.payload, franchiseOrderAddUpdate: !state.franchiseOrderAddUpdate }
    case FRANCHISE_ORDER_ADD_LIST_VISIBLE:
      return { ...state, franchiseOrderAddVisible: action.payload, franchiseOrderAddUpdate: !state.franchiseOrderAddUpdate }
    case FRANCHISE_ORDER_LIST_DATA:
      return { ...state, franchiseOrderListData: action.payload, franchiseOrderListUpdate: !state.franchiseOrderListUpdate }
    case FRANCHISE_ORDER_LIST_VISIBLE:
      return { ...state, franchiseOrderListVisible: action.payload, franchiseOrderListUpdate: !state.franchiseOrderListUpdate }
    case CENTER_PRODUCT_LIST_DATA:
      return { ...state, centerProductListData: action.payload, centerProductListUpdate: !state.centerProductListUpdate }
    case CENTER_PRODUCT_LIST_VISIBLE:
      return { ...state, centerProductListVisible: action.payload, centerProductListUpdate: !state.centerProductListUpdate }
    case CENTER_AUTHORITY_LIST_DATA:
      return { ...state, centerAuthorityData: action.payload, centerAuthorityUpdate: !state.centerAuthorityUpdate }
    case CENTER_AUTHORITY_LIST_VISIBLE:
      return { ...state, centerAuthorityVisible: action.payload, centerAuthorityUpdate: !state.centerAuthorityUpdate }
    case CENTER_REGION_LIST_DATA:
      return { ...state, centerRegionData: action.payload, centerRegionUpdate: !state.centerRegionUpdate }
    case CENTER_REGION_LIST_UPDATE:
      return { ...state, centerRegionUpdate: !state.centerRegionUpdate }
    case CENTER_REGION_LIST_VISIBLE:
      return {
        ...state, form_data: {
          ...state.form_data,
          BrandId: action.brandid,
        }, centerRegionVisible: action.payload, centerRegionUpdate: !state.centerRegionUpdate
      }
    case SET_CENTER_USER_ADD_DATA:
      return { ...state, centerUserAddVisible: (action.isvisible == true ? true : state.centerUserAddVisible), centerUserAddData: action.payload, centerUserAddUpdate: !state.centerUserAddUpdate }
    case SET_CENTER_USER_ADD_VISIBLE:
      return {
        ...state, centerUserAddData: {
          Id: "00000000-0000-0000-0000-000000000000",
          CenterBrandId: cookie.load('CenterBrandId'),
          TenantId: cookie.load('TenantId'),
          Name: "",
          Username: "",
          Password: "",
          Phone: "",
          IsStatus: true,
          UserType: 6
        }, centerUserAddVisible: action.payload, centerUserAddUpdate: !state.centerUserAddUpdate
      }
    case CENTER_USER_DATA:
      return { ...state, centerUserData: action.payload, centerUserUpdate: !state.centerUserUpdate }
    case CENTER_USER_VISIBLE:
      return { ...state, centerUserVisible: action.payload, centerUserUpdate: !state.centerUserUpdate }
    case SET_CENTER_BRAND_ADD_DATA:
      return { ...state, centerBrandAddVisible: (action.isvisible == true ? true : state.centerBrandAddVisible), centerBrandAddData: action.payload, centerBrandAddUpdate: !state.centerBrandAddUpdate }
    case SET_CENTER_BRAND_ADD_VISIBLE:
      return {
        ...state, centerBrandAddData: {
          Id: "00000000-0000-0000-0000-000000000000",
          CenterBrandId: cookie.load('CenterBrandId'),
          TenantId: cookie.load('TenantId'),
          Name: "",
          Latitude: "",
          Longitude: "",
          MobilePhone: "",
          Email: "",
          CreationDatetime: "",
          IsStatus: true,
          Language: "tr",
          Password: "",
          TurnoverShare: 0
        }, centerBrandAddVisible: action.payload, centerBrandAddUpdate: !state.centerBrandAddUpdate
      }
    case CENTER_BRAND_DATA:
      return { ...state, centerBrandData: action.payload, centerBrandUpdate: !state.centerBrandUpdate }
    case CENTER_BRAND_VISIBLE:
      return { ...state, centerBrandVisible: action.payload, centerBrandUpdate: !state.centerBrandUpdate }
    case CENTER_SETTING_DATA:
      return { ...state, centerSettingData: action.payload, centerSettingUpdate: !state.centerSettingUpdate }
    case CENTER_SETTING_VISIBLE:
      return { ...state, centerSettingVisible: action.payload, centerSettingUpdate: !state.centerSettingUpdate }
    case CENTERBRAND_START:
      return { ...state, loading: true }
    case CENTERBRAND_ERROR:
      return {
        ...state, form_data: {
          ...state.form_data,
          modalOpen: true,
          modalText: action.payload,
          modalTitle: "Uyarı"
        }, isCenterReportLoading: false, isOrderHeatUpdate: !state.isOrderHeatUpdate,
        franchiseReportGrafficUpdate: !state.franchiseReportGrafficUpdate,
        franchiseReportGeneralTotalUpdate: !state.franchiseReportGeneralTotalUpdate,
        franchiseReportProductListUpdate: !state.franchiseReportProductListUpdate,
        franchiseReportEntegrationUpdate: !state.franchiseReportEntegrationUpdate,
        franchiseReportAdditionListUpdate: !state.franchiseReportAdditionListUpdate,
        franchiseReportLogsUpdate: !state.franchiseReportLogsUpdate,
      }
    case CENTERBRAND_STOP:
      return { ...state, loading: false, data: action.payload }
    case CENTER_GLOBAL_FORM_DATA_SET:
      return {
        ...state,
        form_data: {
          ...state.form_data,
          [action.payload.key]: action.payload.val
        }
      }
    case CENTERBRAND_MODALCLOSE:
      return {
        ...state, form_data: {
          ...state.form_data,
          modalOpen: false
        }
      }
    default:
      return state;
  }
}

export default centerBrandReducer;
