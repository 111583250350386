import React from 'react';
import styled from 'styled-components';
import { FaCheck } from 'react-icons/fa';

const SuccessOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 998;
`;

const SuccessContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 2.5rem 2rem;
  transform: translateY(${({ isOpen }) => (isOpen ? '0' : '100%')});
  transition: transform 0.3s ease;
  z-index: 999;
  text-align: center;
`;

const CheckCircle = styled.div`
  width: 100px;
  height: 100px;
  background-color: #E8F5E9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.5rem;

  svg {
    color: #4CAF50;
    font-size: 2.5rem;
  }
`;

const Title = styled.h2`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
`;

const Message = styled.p`
  color: #666;
  font-size: 0.9rem;
  line-height: 1.5;
  margin-bottom: 2rem;
`;

const HomeButton = styled.button`
  background: #f5f5f5;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  color: #333;
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 0 auto;
  transition: background 0.2s;

  &:hover {
    background: #e5e5e5;
  }
`;

const SuccessMessage = ({ isOpen, onClose }) => {
  return (
    <>
      <SuccessOverlay isOpen={isOpen} onClick={onClose} />
      <SuccessContainer isOpen={isOpen}>
        <CheckCircle>
          <FaCheck />
        </CheckCircle>
        <Title>Teşekkürler !</Title>
        <Message>
          Geri bildiriminiz için çok teşekkürler, gerek duyulduğunda işletme yetkilimiz sizinle iletişime geçecektir.
        </Message>
        <HomeButton onClick={onClose}>
          Anasayfaya Dön <span>→</span>
        </HomeButton>
      </SuccessContainer>
    </>
  );
};

export default SuccessMessage;