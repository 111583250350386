import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";

import defaultCategoryImage from "dan-images/QrMenu/defaul-category-image.png";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import defaultImage from "dan-images/QrMenu/default_food.jpg";
import bigSalad from "dan-images/QrMenu/big-salad.jpg";
import fireIcon from "dan-images/QrMenu/fire-icons.png";
import fastTime from "dan-images/QrMenu/fast-time.png";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*": {
      fontFamily: "'Poppins', sans-serif !important",
    },
  },
  menuItem: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "10px",
    boxShadow: "0 0px 10px rgba(0,0,0,0.1)",
    cursor: "pointer",
    width: "100%",
    height: "120px",
  },
  menuItemContent: {
    display: "flex",
    gap: "16px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
      alignItems: "stretch",
    },
  },
  menuImage: {
    width: "100px",
    height: "100px",
    borderRadius: "5px",
    objectFit: "cover",
    flexShrink: 0,
  },
  menuInfo: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginTop: "-4px",
    justifyContent: "space-between",
  },
  menuTitle: {
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: "22.5px",
    marginBottom: "4px",
    color: "#E5094B",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  menuDescription: {
    color: "#666",
    fontSize: "13px",
    lineHeight: "19.5px",
    fontWeight: 300,
    marginBottom: "8px",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    minHeight: "33px",
    maxHeight: "38px",
  },
  statsContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "6px",
    marginTop: "auto",
  },
  priceTag: {
    fontWeight: 600,
    backgroundColor: "#E5094B",
    color: "white",
    borderRadius: "5px",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    display: "flex",
    alignItems: "center",
    height: "30px",
    width: "33.33%",
    justifyContent: "center",
  },
  durationTag: {
    backgroundColor: "#3E6976",
    color: "white",
    fontSize: "12px",
    fontWeight: 600,
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    gap: "4px",
    height: "30px",
    width: "33.33%",
    justifyContent: "center",
  },
  caloriesTag: {
    backgroundColor: "#AAAAAA",
    color: "white",
    borderRadius: "5px",
    fontWeight: 600,
    fontSize: "12px",
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    "& span": {
      color: "inherit",
    },
    height: "30px",
    width: "33.33%",
    justifyContent: "center",
  },
  detailPage: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#fff",
    zIndex: 1000,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: "360px",
  },
  mainImage: {
    width: "100%",
    height: "100%",
  },
  backButton: {
    position: "absolute",
    top: "46px",
    left: "16px",
    width: "50px",
    height: "50px",
    background: "rgba(255, 255, 255, 0.9)",
    border: "none",

    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    boxShadow: "0px 0px 11px 0px rgba(0, 0, 0, 0.05)",
  },
  content: {
    padding: "15px",
    flex: 1,
    overflowY: "auto",
    WebkitOverflowScrolling: "touch",
  },
  menuAccordion: {
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    backgroundColor: "transparent",
    borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
  },
  menuAccordionSummary: {
    padding: 0,
    "& .MuiAccordionSummary-content": {
      margin: "8px 0",
    },
  },
  menuTypeHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  menuTypeName: {
    fontSize: "16px",
    color: "#333",
  },
  menuAccordionDetails: {
    padding: "8px 0",
    display: "flex",
    flexDirection: "column",
  },
  menuItemRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 0",
    borderBottom: "1px solid #E8E8E8",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  extraItemsTitle: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#E5094B",
    marginTop: "24px",
    marginBottom: "16px",
  },
  detailTitleSection: {
    textAlign: "left",
    marginBottom: "16px",
  },
  detailTitle: {
    marginTop: "16px",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
    color: "#E5094B",
    marginBottom: "8px",
  },
  detailDescription: {
    color: "#424242",
    fontSize: "14px",
    textAlign: "left",
  },
}));

const MenuItem = ({ item }) => {
  const classes = useStyles();
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [expandedAccordions, setExpandedAccordions] = useState({});

  useEffect(
    () => {
      const handlePopState = (event) => {
        if (isDetailOpen) {
          event.preventDefault();

          setIsDetailOpen(false);

          document.body.style.overflow = "unset";
        }
      };

      window.addEventListener("popstate", handlePopState);
      return () => {
        window.removeEventListener("popstate", handlePopState);
        document.body.style.overflow = "unset";
      };
    },
    [isDetailOpen]
  );

  const handleDetailOpen = () => {
    window.history.pushState({ isDetail: true }, "", window.location.href);
    setIsDetailOpen(true);
    document.body.style.overflow = "hidden";
  };

  const handleDetailClose = () => {
    window.history.back();
    document.body.style.overflow = "unset";
    document.body.style.position = "static";
    document.body.style.width = "auto";
    document.body.style.height = "auto";
  };

  const renderMainCard = () => (
    <div className={classes.menuItem} onClick={handleDetailOpen}>
      <div className={classes.menuItemContent}>
        <img
          src={
            item.ImageUrl?.[0] == null ? defaultCategoryImage : item.ImageUrl
          }
          alt={item.Name}
          className={classes.menuImage}
        />
        <div className={classes.menuInfo}>
          <div>
            <h3 className={classes.menuTitle}>{item.Name}</h3>
            <p className={classes.menuDescription}>{item.Explanation}</p>
          </div>
          <div className={classes.statsContainer}>
            <span className={classes.priceTag}>{item.Price} </span>
            <span className={classes.durationTag}>
              <img src={fastTime} alt="fastTime" /> {item.CookingTime} Dk
            </span>
            <span className={classes.caloriesTag}>
              <span>
                <img src={fireIcon} alt="Calories" />
              </span>{" "}
              {item.Calori} Kcal
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  const renderDetailPage = () => (
    <div className={classes.detailPage}>
      <div className={classes.imageContainer}>
        <button onClick={handleDetailClose} className={classes.backButton}>
          <ChevronLeftIcon />
        </button>
        <img
          src={
            item.ImageUrl?.[0] == null ? defaultCategoryImage : item.ImageUrl
          }
          alt={item.Name}
          className={classes.mainImage}
        />
      </div>
      <div className={classes.content}>
        <div className={classes.statsContainer}>
          <span className={classes.priceTag}>{item.Price} TL</span>
          <span className={classes.durationTag}>
            <img src={fastTime} alt="fastTime" /> {item.CookingTime} Dk
          </span>
          <span className={classes.caloriesTag}>
            <span>
              <img src={fireIcon} alt="Calories" />
            </span>
            {item.Calori} Kcal
          </span>
        </div>

        <div className={classes.detailTitleSection}>
          <h2 className={classes.detailTitle}>{item.Name}</h2>
          <p className={classes.detailDescription}>{item.Explanation}</p>
        </div>

        {/* Property Items Section */}
        {item.Property?.length > 0 && (
          <>
            {item.Property.map(
              (property) =>
                !property.IsMenuVisible && (
                  <div key={property.Name}>
                    <div className={classes.menuTypeHeader}>
                      <Typography className={classes.menuTypeName}>
                        <h3 className={classes.extraItemsTitle}>
                          {property.Name}
                        </h3>
                      </Typography>
                    </div>

                    {property.Item?.map((subItem) => (
                      <div key={subItem.Id} className={classes.menuItemRow}>
                        <Typography className={classes.menuItemName}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "14px",
                              color: "#424242",
                              lineHeight: "21px",
                              fontWeight: "300",
                              fontFamily: "Poppins', sans-serif !important",
                            }}
                          >
                            {subItem.Default && (
                              <span
                                style={{
                                  width: "8px",
                                  height: "8px",
                                  backgroundColor: "red",
                                  borderRadius: "50%",
                                  display: "inline-block",
                                  marginRight: "8px",
                                  flexShrink: 0,
                                }}
                              />
                            )}
                            {subItem.Name}
                          </div>
                        </Typography>
                        <Typography className={classes.menuItemPrice}>
                          {subItem.Price == 0 || subItem.Price < 0
                            ? ""
                            : "+" + subItem.Price}
                        </Typography>
                      </div>
                    ))}
                  </div>
                )
            )}
          </>
        )}

        {/* Menu Items Section */}
        {item.Menu?.length > 0 && (
          <>
            {item.Menu.map(
              (menu) =>
                !menu.IsMenuVisible && (
                  <div key={menu.Name}>
                    <div className={classes.menuTypeHeader}>
                      <Typography className={classes.menuTypeName}>
                        <h3 className={classes.extraItemsTitle}>{menu.Name}</h3>
                      </Typography>
                    </div>

                    {menu.Item?.map((subItem) => (
                      <div key={subItem.Id} className={classes.menuItemRow}>
                        <Typography className={classes.menuItemName}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "14px",
                              color: "#424242",
                              lineHeight: "21px",
                              fontWeight: "300",
                              fontFamily: "Poppins', sans-serif !important",
                            }}
                          >
                            {subItem.Default && (
                              <span
                                style={{
                                  width: "8px",
                                  height: "8px",
                                  backgroundColor: "red",
                                  borderRadius: "50%",
                                  display: "inline-block",
                                  marginRight: "8px",
                                  flexShrink: 0,
                                }}
                              />
                            )}
                            {subItem.Name}
                          </div>
                        </Typography>
                        <Typography className={classes.menuItemPrice}>
                          {subItem.Price == 0 || subItem.Price < 0
                            ? ""
                            : "+" + subItem.Price}
                        </Typography>
                      </div>
                    ))}
                  </div>
                )
            )}
          </>
        )}
      </div>
    </div>
  );

  return (
    <>
      {renderMainCard()}
      {isDetailOpen && renderDetailPage()}
    </>
  );
};

export default MenuItem;
