import React, { Fragment, useState, memo } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import styles from "../../CenterBrandPage/centerbrandpage-jss";
import {
  setPaketYoldaCourierListVisible,
  setPaketYoldaNewCourierAddVisible,
  getCourier,
  setCourierRemove,
  setCourierInActive,
  setMileageChargeVisible,
  setCourierRegionAdd,
  getNewCourierList,
} from "../../../../redux/modules/paketyolda";
import { createSelector } from "reselect";
import moment from "moment";
import Icon from "@material-ui/core/Icon";
import "react-credit-cards/es/styles-compiled.css";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import classNames from "classnames";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Slide from "@material-ui/core/Slide";
import CourierAdd from "./CourierAdd";
import ShiftScheduleDialog from "../Settings/ShiftScheduleDialog";
import {
  setShiftBreakTimeVisible,
  saveShiftBreakTime,
  getShiftBreakTime,
} from "../../../../redux/modules/ShiftBreakTimeReducer";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class CourierList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shiftDialogOpen: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.paketYoldaCourierListUpdate !==
        this.props.paketYoldaCourierListUpdate ||
      nextProps.shiftBreakTimeVisible !== this.props.shiftBreakTimeVisible ||
      nextState.shiftDialogOpen !== this.state.shiftDialogOpen
    );
  }

  handleShiftBreakTimeClick = (courierId) => {
    this.props.setShiftBreakTimeVisible(true, courierId);
  };

  async componentDidMount() {}

  renderRegionList() {
    const {
      courierRegionListVisible,
      paketYoldaRegionListData,
      classes,
      courierRegionListCourierId,
      courierRegionListCourierName,
    } = this.props;

    if (paketYoldaRegionListData == null) {
      return null;
    }

    return (
      <Dialog
        open={courierRegionListVisible}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disabled={true}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ padding: "24px 24px 5px", marginBottom: 10 }}
        >
          {"* Kuryeyi Ekleyebileceğiniz Bölge Listeleri"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Table
              className={classNames(classes.table, classes.bordered)}
              style={{ marginTop: 10 }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>{"Bölge Adı"}</TableCell>
                  <TableCell align={"right"}>{"İşlemler"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paketYoldaRegionListData.map((n, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{n.RegionName}</TableCell>
                      <TableCell align={"right"}>
                        <Tooltip title={"Ekle"}>
                          <IconButton
                            style={{
                              backgroundColor: "#3e3e3e",
                              marginRight: 5,
                              fontSize: 12,
                              padding: 8,
                            }}
                            onClick={() =>
                              this.props
                                .setCourierRegionAdd(
                                  n.Id,
                                  courierRegionListCourierId,
                                  courierRegionListCourierName
                                )
                                .then(() => {})
                            }
                          >
                            <Icon>{"add"}</Icon>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  render() {
    const {
      classes,
      paketYoldaCourierListVisible,
      paketYoldaCourierList,
      shiftBreakTimeVisible,
      selectedCourierId,
    } = this.props;

    return (
      <Fragment>
        <ShiftScheduleDialog
          open={Boolean(shiftBreakTimeVisible)}
          onClose={() => {
            this.props.setShiftBreakTimeVisible(false, null);
          }}
          courierId={selectedCourierId}
          onSave={() => {
            this.props.getNewCourierList().then(() => {
              this.props.setShiftBreakTimeVisible(false, null);
            });
          }}
        />
        <Dialog
          open={paketYoldaCourierListVisible}
          fullScreen
          TransitionComponent={Transition}
        >
          {this.renderRegionList()}
          <CourierAdd classes={classes} />
          <AppBar
            className={classes.appBar}
            style={{
              position: "fixed",
              top: 0,
              width: "100%",
              backgroundImage:
                "linear-gradient(-45deg, rgb(239, 83, 80) 0%, rgb(239, 83, 80) 33%, rgb(96, 125, 139) 100%)",
            }}
          >
            <Toolbar>
              <Button
                variant="outlined"
                color="inherit"
                onClick={() =>
                  this.props
                    .setPaketYoldaCourierListVisible(false)
                    .then(() => {})
                }
              >
                <Icon style={{ marginRight: 5 }}>{"arrow_back"}</Icon>
              </Button>
              <Typography
                style={{ textAlign: "center", width: "100%", fontWeight: 600 }}
                variant="h7"
                color="inherit"
                className={classes.flex}
              >
                {"Kurye İşlemleri"}
              </Typography>
              <Button
                color="default"
                variant="contained"
                onClick={() =>
                  this.props
                    .setPaketYoldaNewCourierAddVisible(true)
                    .then(() => {})
                }
              >
                {"KURYE EKLE"}
              </Button>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ overflow: "hidden auto" }}>
            <div
              className={classes.detailWrap}
              style={{ backgroundColor: "#292929", marginTop: 64 }}
            >
              <Table
                className={classNames(classes.table, classes.bordered)}
                style={{ marginTop: 10 }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>{"Aktif"}</TableCell>
                    <TableCell>{"Başkasına Atama"}</TableCell>
                    <TableCell>{"Atama Bekleyenler"}</TableCell>
                    <TableCell>{"Adı Soyadı"}</TableCell>
                    <TableCell>{"Kullanıcı Adı (Telefon)"}</TableCell>
                    <TableCell>{"Şifre"}</TableCell>
                    <TableCell>{"Mola"}</TableCell>
                    <TableCell align={"right"}>{"İşlemler"}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paketYoldaCourierList.map((n, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>
                          <IconButton
                            style={{ color: n.IsActive ? "#1abc9c" : "" }}
                            onClick={() =>
                              this.props
                                .setCourierInActive(n.Id, !n.IsActive)
                                .then(() => {})
                            }
                          >
                            <Icon>
                              {n.IsActive
                                ? "check_box"
                                : "check_box_outline_blank"}
                            </Icon>
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <Icon>
                            {n.IsReceivingPackage
                              ? "check_box"
                              : "check_box_outline_blank"}
                          </Icon>
                        </TableCell>
                        <TableCell>
                          <Icon>
                            {n.IsReceivingCourierPackages
                              ? "check_box"
                              : "check_box_outline_blank"}
                          </Icon>
                        </TableCell>
                        <TableCell>{n.NameSurname}</TableCell>
                        <TableCell>{n.Phone}</TableCell>
                        <TableCell>{n.Password}</TableCell>
                        <TableCell>{n.AllowedTime}</TableCell>
                        <TableCell align={"right"}>
                          <Tooltip title="Kurye Mola Vardiye Sistemi">
                            <IconButton
                              style={{
                                backgroundColor: "#3e3e3e",
                                marginRight: 5,
                                fontSize: 12,
                                padding: 8,
                              }}
                              onClick={() =>
                                this.handleShiftBreakTimeClick(n.Id)
                              }
                            >
                              <Icon>{"schedule"}</Icon>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={"Kilometre Ücretlendirme"}>
                            <IconButton
                              style={{
                                backgroundColor: "#3e3e3e",
                                marginRight: 5,
                                fontSize: 12,
                                padding: 8,
                              }}
                              onClick={() =>
                                this.props
                                  .setMileageChargeVisible(true, n.Id, 0)
                                  .then(() => {})
                              }
                            >
                              <Icon>{"edit_road"}</Icon>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={"Düzenle"}>
                            <IconButton
                              style={{
                                backgroundColor: "#3e3e3e",
                                marginRight: 5,
                                fontSize: 12,
                                padding: 8,
                              }}
                              onClick={() =>
                                this.props.getCourier(n.Id).then(() => {})
                              }
                            >
                              <Icon>{"edit"}</Icon>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={"Sil"}>
                            <IconButton
                              style={{
                                backgroundColor: "#3e3e3e",
                                marginRight: 5,
                                fontSize: 12,
                                padding: 8,
                              }}
                              onClick={() =>
                                confirm(
                                  "Kuryeyi silmek istediğinize emin misiniz?"
                                )
                                  ? this.props
                                      .setCourierRemove(n.Id)
                                      .then(() => {})
                                  : null
                              }
                            >
                              <Icon>{"delete"}</Icon>
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

CourierList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const valueSelector = (state, props) =>
  state.get("paketYoldaReducer").paketYoldaCourierListUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
  paketYoldaCourierListUpdate: value(state, props),
  paketYoldaCourierListVisible: state.get("paketYoldaReducer")
    .paketYoldaCourierListVisible,
  paketYoldaCourierList: state.get("paketYoldaReducer").paketYoldaCourierList,
  paketYoldaCourierListLoading: state.get("paketYoldaReducer")
    .paketYoldaCourierListLoading,

  paketYoldaRegionListData: state.get("paketYoldaReducer")
    .paketYoldaRegionListData,
  courierRegionListVisible: state.get("paketYoldaReducer")
    .courierRegionListVisible,

  courierRegionListCourierId: state.get("paketYoldaReducer")
    .courierRegionListCourierId,
  courierRegionListCourierName: state.get("paketYoldaReducer")
    .courierRegionListCourierName,

  shiftBreakTimeVisible: state.getIn([
    "shiftBreakTime",
    "shiftBreakTimeVisible",
  ]),

  shiftBreakTimeVisible:
    state.getIn(["shiftBreakTime", "shiftBreakTimeVisible"]) || false,
  selectedCourierId: state.getIn(["shiftBreakTime", "selectedCourierId"]),
  workDays: state.getIn(["shiftBreakTime", "workDays"]),
  breakTimes: state.getIn(["shiftBreakTime", "breakTimes"]),
});

const mapDispatchToProps = {
  setPaketYoldaCourierListVisible,
  setPaketYoldaNewCourierAddVisible,
  getCourier,
  setCourierRemove,
  setCourierInActive,
  setMileageChargeVisible,
  setCourierRegionAdd,
  setShiftBreakTimeVisible,
  saveShiftBreakTime,
  getShiftBreakTime,
  getNewCourierList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(CourierList)));
