import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import defaultCategoryImage from "dan-images/QrMenu/defaul-category-image.png";

const Container = styled.div`
  display: block;
  @media (max-width: 600px) {
    display: ${(props) => (props.showSticky ? "none" : "block")};
  }
`;

const StickyContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 996;
  transform: ${(props) =>
    props.showSticky ? "translateY(0)" : "translateY(-100%)"};
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
`;

const ScrollWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  cursor: grab;
  scroll-behavior: smooth;

  &:active {
    cursor: grabbing;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const ScrollContainer = styled.div`
  display: flex;
  gap: 5px;
  width: max-content;
  position: relative;
  padding: 0 4px;
`;

const CategoryButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  cursor: pointer;
  flex: 0 0 auto;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  img {
    width: 5rem;
    height: 5rem;
    border-radius: 5px;
    object-fit: cover;
    margin-bottom: 8px;
    border: 2px solid ${(props) => (props.active ? "#E5094B" : "transparent")};
    transition: border-color 0.3s ease;
    pointer-events: none;
  }

  span {
    font-size: 14px;
    line-height: 21px;
    font-weight: ${(props) => (props.active ? "600" : "400")};
    color: ${(props) => (props.active ? "#E5094B" : "#727272")};
    width: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 4px;
    pointer-events: none;
  }
`;

const StickyButton = styled.button`
  font-size: 14px;
  line-height: 21px;
  padding: 8px 16px;
  border-radius: 5px;
  border: 2px solid #e5094b;
  background-color: ${(props) => (props.active ? "#E5094B" : "transparent")};
  color: ${(props) => (props.active ? "#fff" : "#E5094B")};
  font-weight: ${(props) => (props.active ? "600" : "500")};
  font-size: 1rem;
  height: 45px;
  cursor: pointer;
  white-space: nowrap;
  flex: 0 0 auto;
  transition: all 0.3s ease;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

const CategoryScrollbar = ({
  categories = [],
  onCategorySelect,
  activeCategory: propActiveCategory,
}) => {
  const [showSticky, setShowSticky] = useState(false);
  const [activeCategory, setActiveCategory] = useState(propActiveCategory);

  const regularScrollRef = useRef(null);
  const stickyScrollRef = useRef(null);

  const calculateScrollPosition = (container, activeButton) => {
    if (!container || !activeButton) return 0;
    const containerWidth = container.offsetWidth;
    const scrollWidth = container.scrollWidth;
    const buttonOffset = activeButton.offsetLeft;
    const buttonWidth = activeButton.offsetWidth;
    let targetScroll = buttonOffset - (containerWidth - buttonWidth) / 2;
    const minScroll = 0;
    const maxScroll = scrollWidth - containerWidth;
    targetScroll = Math.max(minScroll, Math.min(targetScroll, maxScroll));
    return targetScroll;
  };

  const scrollToPosition = (container, position) => {
    if (!container) return;
    container.scrollTo({
      left: position,
      behavior: "smooth",
    });
  };

  const updateScrollPosition = useCallback(() => {
    [regularScrollRef.current, stickyScrollRef.current].forEach((container) => {
      if (container) {
        const activeButton = container.querySelector('[data-active="true"]');
        if (activeButton) {
          const position = calculateScrollPosition(container, activeButton);
          scrollToPosition(container, position);
        }
      }
    });
  }, []);

  useEffect(
    () => {
      const handleScroll = () => {
        const bannerHeight = 230;
        const newSticky = window.scrollY > bannerHeight;
        if (newSticky !== showSticky) {
          setShowSticky(newSticky);

          setTimeout(updateScrollPosition, 300);
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    },
    [showSticky, updateScrollPosition]
  );

  useEffect(
    () => {
      updateScrollPosition();
    },
    [activeCategory, updateScrollPosition]
  );

  useEffect(
    () => {
      if (propActiveCategory !== activeCategory) {
        setActiveCategory(propActiveCategory);
      }
    },
    [propActiveCategory, activeCategory]
  );

  const handleCategoryClick = useCallback(
    (category) => {
      if (activeCategory === category.Id) return;
      setActiveCategory(category.Id);
      if (onCategorySelect) {
        onCategorySelect(category.Id);
      }
    },
    [activeCategory, onCategorySelect]
  );

  const renderButtons = (isSticky) => {
    const ButtonComponent = isSticky ? StickyButton : CategoryButton;
    return categories.map((category) => (
      <ButtonComponent
        key={category.Id}
        active={activeCategory === category.Id}
        onClick={() => handleCategoryClick(category)}
        data-active={activeCategory === category.Id ? "true" : "false"}
      >
        {!isSticky && (
          <img
            src={category.ImageUrl || defaultCategoryImage}
            alt={category.Name}
            onError={(e) => {
              e.target.src = defaultCategoryImage;
            }}
            draggable={false}
          />
        )}
        <span>{category.Name}</span>
      </ButtonComponent>
    ));
  };

  return (
    <>
      <Container showSticky={showSticky}>
        <ScrollWrapper ref={regularScrollRef}>
          <ScrollContainer>{renderButtons(false)}</ScrollContainer>
        </ScrollWrapper>
      </Container>
      <StickyContainer showSticky={showSticky}>
        <ScrollWrapper ref={stickyScrollRef}>
          <ScrollContainer>{renderButtons(true)}</ScrollContainer>
        </ScrollWrapper>
      </StickyContainer>
    </>
  );
};

export default CategoryScrollbar;
