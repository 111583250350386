import React, { useState } from "react";
import SlideMenu from "./SlideMenu";
import FormatAlignRight from "@material-ui/icons/FormatAlignRight";

const Header = ({ brandName, menudata }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const logo = menudata?.QrmenuLogo?.ImageUrl;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

          marginBottom: "1rem",
          position: "relative",
        }}
      >
        <div style={{ width: "40px" }} />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >
          {logo && (
            <img
              src={logo}
              alt={brandName || "Logo"}
              style={{ height: "4.0rem" }}
              onError={(e) => {
                console.log("Image load error:", e);
                e.target.style.display = "none";
              }}
            />
          )}
        </div>

        <button
          onClick={toggleMenu}
          style={{
            background: "#fff",
            border: "none",
            padding: "8px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            boxShadow: "0 0 11px 0 rgba(0, 0, 0, 0.05)",
            borderRadius: "4px",
            width: "50px",
            height: "50px",
            justifyContent: "center",
          }}
        >
          <FormatAlignRight />
        </button>
      </header>

      <SlideMenu
        menudata={menudata}
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      />
    </>
  );
};

export default Header;
