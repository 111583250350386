import React, { forwardRef } from "react";
import "leaflet";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw-src.css";
import "../Maps/stylesPopup.css";

const style = {
  width: "100%",
  height: "100%",
  borderRadius: 0,
};

class DeliveryAreaMap extends React.Component {
  constructor(props) {
    super(props);
    this.map = null;
    this.featureGroup = null;
    this.drawControl = null;
    this.mapContainer = React.createRef();
    this.handleEditSaveClick = this.handleEditSaveClick.bind(this);
  }

  handleEditSaveClick(e) {
    if (
      e.target &&
      (e.target.classList.contains("leaflet-draw-actions-top") ||
        e.target.closest(".leaflet-draw-actions-top"))
    ) {
      if (
        e.target.textContent === "Save" ||
        (e.target.closest(".leaflet-draw-actions-top") &&
          e.target
            .closest(".leaflet-draw-actions-top")
            .textContent.includes("Save"))
      ) {
        setTimeout(() => {
          const polygons = this.getPolygonCoordinates();
          if (polygons && polygons.length > 0) {
            const editedPolygon = {
              type: "Polygon",
              coordinates: polygons[0],
            };

            this.renderDeliveryArea(editedPolygon);
          }
        }, 100);
      }
    }
  }

  componentDidMount() {
    if (this.mapContainer.current) {
      this.initMap();

      document.addEventListener("click", this.handleEditSaveClick);

      this.initTimer = setTimeout(() => {
        if (!this.map || !this.featureGroup) {
          console.warn(
            "Map or feature group not initialized, reinitializing..."
          );
          this.initMap();
        }

        if (this.props.deliveryArea) {
          this.renderDeliveryArea(this.props.deliveryArea);
        }
      }, 500);
    }
  }

  componentDidUpdate(prevProps) {
    const { business, deliveryArea } = this.props;

    if (prevProps.business !== business && business) {
      if (this.featureGroup) {
        try {
          this.featureGroup.clearLayers();
        } catch (error) {
          console.warn("Error clearing feature group:", error);
        }
      }

      if (this.map) {
        try {
          this.map.off();
          this.map.remove();
          this.map = null;
          this.featureGroup = null;
          this.drawControl = null;
        } catch (error) {
          console.warn("Error resetting map:", error);
        }
      }

      // Reinitialize the map with a short delay
      setTimeout(() => {
        if (this.mapContainer.current) {
          this.initMap();

          if (business) {
            this.addBusinessMarker(business);
          }
        }
      }, 100);
    }

    if (prevProps.deliveryArea !== deliveryArea && deliveryArea) {
      if (this.featureGroup) {
        try {
          this.featureGroup.clearLayers();
        } catch (error) {
          console.warn(
            "Error clearing feature group for delivery area:",
            error
          );
        }
      }

      setTimeout(() => {
        this.renderDeliveryArea(deliveryArea);
      }, 150);
    }
  }

  componentWillUnmount() {
    if (this.initTimer) {
      clearTimeout(this.initTimer);
    }

    document.removeEventListener("click", this.handleEditSaveClick);

    if (this.featureGroup) {
      try {
        this.featureGroup.clearLayers();
        if (this.map) this.map.removeLayer(this.featureGroup);
        this.featureGroup = null;
      } catch (error) {
        console.error("Error cleaning up feature group:", error);
      }
    }

    if (this.map) {
      try {
        this.map.off();
        this.map.remove();
      } catch (error) {
        console.error("Error cleaning up map:", error);
      }
      this.map = null;
    }
  }

  initMap = () => {
    const { business, onMapReady } = this.props;
    let initialCenter = [0, 0];
    let initialZoom = 13;

    if (business && business.Lat && business.Lng) {
      const lat = parseFloat(business.Lat);
      const lng = parseFloat(business.Lng);
      if (!isNaN(lat) && !isNaN(lng) && !(lat === 0 && lng === 0)) {
        initialCenter = [lat, lng];
      }
    }

    this.map = L.map(this.mapContainer.current).setView(
      initialCenter,
      initialZoom
    );
    this.featureGroup = L.featureGroup().addTo(this.map);

    this.drawControl = new L.Control.Draw({
      draw: {
        polygon: true,
        polyline: false,
        rectangle: false,
        circle: true,
        marker: false,
        circlemarker: false,
      },
      edit: {
        featureGroup: this.featureGroup,
        edit: true,
      },
    }).addTo(this.map);

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 18,
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map);

    this.map.on("draw:created", (e) => {
      this.featureGroup.addLayer(e.layer);

      if (this.props.onAreaChange) {
        if (e.layerType === "circle") {
          const circle = e.layer;
          const center = circle.getLatLng();
          const radius = circle.getRadius();

          this.props.onAreaChange({
            type: "Circle",
            radius: radius,
            center: [center.lng, center.lat],
          });
        } else if (e.layerType === "polygon") {
          const coordinates = this.getPolygonCoordinates();
          if (coordinates && coordinates.length > 0) {
            this.props.onAreaChange({
              type: "Polygon",
              coordinates: coordinates,
            });
          }
        }
      }
    });

    this.map.on("draw:edited", (e) => {
      if (this.props.onAreaChange) {
        e.layers.eachLayer((layer) => {
          if (layer instanceof L.Polygon) {
            const geoJson = layer.toGeoJSON();
            const coordinates = geoJson.geometry.coordinates;

            if (coordinates && coordinates[0] && coordinates[0].length > 0) {
              const firstPoint = coordinates[0][0];
              const lastPoint = coordinates[0][coordinates[0].length - 1];

              if (
                firstPoint[0] !== lastPoint[0] ||
                firstPoint[1] !== lastPoint[1]
              ) {
                coordinates[0].push(firstPoint);
              }
            }

            const editedPolygon = {
              type: "Polygon",
              coordinates: coordinates,
            };

            this.props.onAreaChange(editedPolygon);

            setTimeout(() => {
              this.renderDeliveryArea(editedPolygon);
            }, 50);
          }
        });
      }
    });

    this.map.on("draw:deleted", () => {
      if (this.props.onAreaChange) {
        this.props.onAreaChange(null);
      }
    });

    this.map.on("draw:drawstart", () => {
      this.featureGroup.clearLayers();
      if (this.props.onAreaChange) {
        this.props.onAreaChange(null);
      }
    });

    if (business) {
      this.addBusinessMarker(business);
    }

    if (this.props.deliveryArea) {
      setTimeout(() => {
        this.renderDeliveryArea(this.props.deliveryArea);
      }, 300);
    }

    if (onMapReady && typeof onMapReady === "function") {
      setTimeout(() => {
        onMapReady();
      }, 100);
    }
  };

  getPolygonCoordinates = () => {
    if (!this.featureGroup) {
      console.warn("Feature group is not initialized in getPolygonCoordinates");
      return [];
    }

    const polygons = [];
    this.featureGroup.eachLayer((layer) => {
      if (layer instanceof L.Polygon) {
        polygons.push(layer.toGeoJSON().geometry.coordinates);
      }
    });
    return polygons;
  };

  addBusinessMarker = (business) => {
    if (!this.map || !business || !business.Lat || !business.Lng) return;

    const lat = parseFloat(business.Lat);
    const lng = parseFloat(business.Lng);

    if (isNaN(lat) || isNaN(lng) || (lat === 0 && lng === 0)) return;

    this.map.eachLayer((layer) => {
      if (layer.options && layer.options.type === "business") {
        layer.remove();
      }
    });

    const marker = L.marker([lat, lng], {
      type: "business",
      icon: new L.DivIcon({
        className: "my-div-icon",
        html: `
          <div style="background-color: #227093;" class="my-div-span-brand">
            <div style="border-top:10px solid #227093;" class="arrow-down-order"></div>
            <div class="arrow-down-text">${business.BusinessName ||
              "İşletme"}</div>
          </div>
          <div class="pulse"></div>
        `,
        iconSize: [80, 30],
        iconAnchor: [40, 30],
      }),
    }).addTo(this.map);

    marker.bindPopup(business.BusinessName || "İşletme");
    this.map.setView([lat, lng], 13);
  };

  renderDeliveryArea = (deliveryArea) => {
    try {
      if (!this.featureGroup) {
        console.warn("Feature group is not initialized");

        if (this.map) {
          this.featureGroup = L.featureGroup().addTo(this.map);
        } else {
          setTimeout(() => {
            if (!this.map && this.mapContainer.current) {
              this.initMap();
            }
          }, 200);
          return;
        }
      }

      this.featureGroup.clearLayers();

      if (!deliveryArea) {
        return;
      }

      let areaData =
        typeof deliveryArea === "string"
          ? JSON.parse(deliveryArea)
          : deliveryArea;

      if (
        areaData &&
        areaData.type === "Circle" &&
        areaData.center &&
        areaData.radius
      ) {
        return this.createCircle(areaData.center, areaData.radius, true);
      }

      if (
        areaData &&
        areaData.type === "Polygon" &&
        Array.isArray(areaData.coordinates)
      ) {
        let coordinates = null;

        if (
          Array.isArray(areaData.coordinates[0]) &&
          Array.isArray(areaData.coordinates[0][0])
        ) {
          coordinates = areaData.coordinates[0];
        } else if (Array.isArray(areaData.coordinates[0])) {
          coordinates = areaData.coordinates;
        }

        if (coordinates && coordinates.length > 0) {
          try {
            const latLngCoords = coordinates
              .map((coord) => {
                if (Array.isArray(coord) && coord.length >= 2) {
                  const lat = parseFloat(coord[1]);
                  const lng = parseFloat(coord[0]);
                  if (isNaN(lat) || isNaN(lng)) {
                    return null;
                  }
                  return [lat, lng];
                }
                return null;
              })
              .filter(Boolean);

            if (latLngCoords.length > 0) {
              try {
                const polygon = L.polygon(latLngCoords, {
                  color: "#2ecc71",
                  weight: 4,
                  opacity: 0.8,
                  fillColor: "#2ecc71",
                  fillOpacity: 0.2,
                  interactive: true,
                });

                this.featureGroup.addLayer(polygon);
                this.map.fitBounds(polygon.getBounds());
              } catch (polygonError) {}
            }
          } catch (coordError) {
            console.error("Error processing coordinates:", coordError);
          }
        }
      } else if (Array.isArray(areaData)) {
        let coordinates = null;

        if (Array.isArray(areaData[0]) && Array.isArray(areaData[0][0])) {
          coordinates = areaData[0];
        } else if (Array.isArray(areaData[0])) {
          coordinates = areaData;
        }

        if (coordinates && coordinates.length > 0) {
          try {
            const latLngCoords = coordinates
              .map((coord) => {
                if (Array.isArray(coord) && coord.length >= 2) {
                  const lat = parseFloat(coord[1]);
                  const lng = parseFloat(coord[0]);
                  if (isNaN(lat) || isNaN(lng)) {
                    return null;
                  }
                  return [lat, lng];
                }
                return null;
              })
              .filter(Boolean);

            if (latLngCoords.length > 0) {
              try {
                const polygon = L.polygon(latLngCoords, {
                  color: "#2ecc71",
                  weight: 4,
                  opacity: 0.8,
                  fillColor: "#2ecc71",
                  fillOpacity: 0.2,
                  interactive: true,
                });

                this.featureGroup.addLayer(polygon);
                this.map.fitBounds(polygon.getBounds());
              } catch (polygonError) {
                console.error("Error creating polygon:", polygonError);
              }
            }
          } catch (coordError) {
            console.error("Error processing coordinates:", coordError);
          }
        }
      }
    } catch (error) {
      console.error("Error rendering delivery area:", error);
    }
  };

  onSaveLocation = () => {
    const circleData = this.getCircleData();
    if (circleData) {
      if (this.props.onSave) {
        this.props.onSave(circleData);

        setTimeout(() => {
          this.renderDeliveryArea(circleData);
        }, 100);
      }
      return;
    }

    const polygons = this.getPolygonCoordinates();

    if (polygons && polygons.length > 0) {
      const allCoordinates = polygons[0];

      let coordinates = allCoordinates;

      if (
        coordinates.length > 0 &&
        (coordinates[0][0] !== coordinates[coordinates.length - 1][0] ||
          coordinates[0][1] !== coordinates[coordinates.length - 1][1])
      ) {
        coordinates = [...coordinates, coordinates[0]];
      }

      if (coordinates && coordinates.length > 0) {
        const deliveryAreaData = {
          type: "Polygon",
          coordinates: [coordinates],
        };

        if (this.props.onSave) {
          this.props.onSave(deliveryAreaData);

          setTimeout(() => {
            this.renderDeliveryArea(deliveryAreaData);
          }, 100);
        }
      }
    }
  };

  getCircleData = () => {
    if (!this.featureGroup) {
      console.warn("Feature group is not initialized in getCircleData");
      return null;
    }

    let circleData = null;
    this.featureGroup.eachLayer((layer) => {
      if (layer instanceof L.Circle) {
        const center = layer.getLatLng();
        const radius = layer.getRadius();
        circleData = {
          type: "Circle",
          radius: radius,
          center: [center.lng, center.lat],
        };
      }
    });
    return circleData;
  };

  createCircle = (center, radius, fitView = true) => {
    try {
      if (!this.map) {
        console.warn("Map is not initialized in createCircle");
        return false;
      }

      if (!this.featureGroup) {
        console.warn("Feature group is not initialized in createCircle");
        this.featureGroup = L.featureGroup().addTo(this.map);
      }

      this.featureGroup.clearLayers();

      const circle = L.circle([parseFloat(center[1]), parseFloat(center[0])], {
        radius: parseFloat(radius),
        color: "#2ecc71",
        weight: 4,
        opacity: 0.8,
        fillColor: "#2ecc71",
        fillOpacity: 0.2,
      });

      this.featureGroup.addLayer(circle);

      circle.addTo(this.map);

      if (fitView) {
        this.map.fitBounds(circle.getBounds());
      }

      return true;
    } catch (error) {
      console.error("Error creating circle:", error);
      return false;
    }
  };

  render() {
    const { business } = this.props;
    const hasValidCoordinates =
      business &&
      business.Lat &&
      business.Lng &&
      parseFloat(business.Lat) !== 0 &&
      parseFloat(business.Lng) !== 0;

    if (!hasValidCoordinates) {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#666",
          }}
        >
          İşletme için konum bilgisi girilmemiş.
        </div>
      );
    }

    return (
      <div style={{ width: "100%", height: "100%", position: "relative" }}>
        <div ref={this.mapContainer} className="search__map" style={style} />
      </div>
    );
  }
}

export default forwardRef((props, ref) => {
  const mapComponentRef = React.useRef(null);
  const [mapReady, setMapReady] = React.useState(false);
  const [pendingDeliveryArea, setPendingDeliveryArea] = React.useState(null);
  const [currentBusinessId, setCurrentBusinessId] = React.useState(
    props.business ? props.business.BrandId : null
  );

  React.useEffect(
    () => {
      if (props.business && props.business.BrandId !== currentBusinessId) {
        console.log("Business changed, resetting map state");
        setMapReady(false);
        setPendingDeliveryArea(null);
        setCurrentBusinessId(props.business.BrandId);

        setTimeout(() => {
          if (mapComponentRef.current && mapComponentRef.current.map) {
            setMapReady(true);
          }
        }, 300);
      }
    },
    [props.business]
  );

  React.useEffect(
    () => {
      if (mapReady && pendingDeliveryArea && mapComponentRef.current) {
        setTimeout(() => {
          try {
            mapComponentRef.current.renderDeliveryArea(pendingDeliveryArea);
            setPendingDeliveryArea(null);
          } catch (error) {
            console.error("Error rendering pending delivery area:", error);
          }
        }, 300);
      }
    },
    [mapReady, pendingDeliveryArea]
  );

  React.useEffect(
    () => {
      if (mapComponentRef.current && mapComponentRef.current.map) {
        setMapReady(true);
      }
    },
    [mapComponentRef.current]
  );

  React.useImperativeHandle(
    ref,
    () => ({
      onSaveLocation: () => {
        if (mapComponentRef.current) {
          try {
            mapComponentRef.current.onSaveLocation();
          } catch (error) {
            console.error("Error saving location:", error);
          }
        }
      },
      renderDeliveryArea: (deliveryArea) => {
        if (mapReady && mapComponentRef.current) {
          try {
            mapComponentRef.current.renderDeliveryArea(deliveryArea);
          } catch (error) {
            console.error("Error rendering delivery area:", error);
          }
        } else {
          setPendingDeliveryArea(deliveryArea);
        }
      },

      createCircle: (center, radius) => {
        if (mapReady && mapComponentRef.current) {
          try {
            return mapComponentRef.current.createCircle(center, radius);
          } catch (error) {
            return false;
          }
        } else {
          return false;
        }
      },

      getCircleData: () => {
        if (mapComponentRef.current) {
          return mapComponentRef.current.getCircleData();
        }
        return null;
      },
      getPolygonCoordinates: () => {
        if (mapComponentRef.current) {
          return mapComponentRef.current.getPolygonCoordinates();
        }
        return [];
      },
    }),
    [mapReady]
  );

  const handleMapReady = () => {
    setMapReady(true);
  };

  return (
    <DeliveryAreaMap
      ref={mapComponentRef}
      {...props}
      onMapReady={handleMapReady}
    />
  );
});
