import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MenuItem from "./MenuItem";
import { Typography, Paper } from "@material-ui/core";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";

const useStyles = makeStyles((theme) => ({
  emptyContainer: {
    padding: theme.spacing(6),
    textAlign: "center",
    backgroundColor: "white",
    borderRadius: theme.spacing(1),
    margin: theme.spacing(2),
  },
  menuContainer: {
    marginTop: 16,
  },

  emptyIcon: {
    fontSize: 48,
    color: "#E5094B",
    marginBottom: theme.spacing(2),
  },
  emptyText: {
    color: "#666",
    fontSize: "1.1rem",
  },
}));

const MenuItemList = ({ products = [] }) => {
  const classes = useStyles();

  if (!products || products.length === 0) {
    return (
      <Paper elevation={0} className={classes.emptyContainer}>
        <SentimentDissatisfiedIcon className={classes.emptyIcon} />
        <Typography className={classes.emptyText}>
          Üzgünüz, bu kategoride ürün bulunmamaktadır.
        </Typography>
      </Paper>
    );
  }

  return (
    <div className={classes.menuContainer}>
      <Grid container spacing={1}>
        {products.map((item, index) => (
          <Grid style={{ padding: 6 }} item xs={12} sm={6} key={item.Id || index}>
            <MenuItem item={item} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default MenuItemList;
